import makeReducer from '../makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';

// ------------------------------------
// Constants
// ------------------------------------

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

const LIST = 'whistlems/common/platformSelect/LIST';
const LIST_SUCCESS = LIST + SUCCESS;
const LIST_FAIL = LIST + FAIL;

// ------------------------------------
// Actions
// ------------------------------------

export const list = () => {
  return {
    types: [LIST, LIST_SUCCESS, LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('statistics', 'getPlatform', {})
    })
  };
};


export const actions = {
  list
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LIST]: (state, action) => {
    return {
      ...state,
      listLoading: true,
      listLoaded: false,
      listError: undefined
    };
  },
  [LIST_SUCCESS]: (state, action) => {
    let list = action.payload.data;
    if (list && list.length > 0) {
      list = list.map(item => Object.assign({value: item.value}, {label: item.title}));
    }
    list.unshift({value: 'all', label: '全部'});
    return {
      ...state,
      listLoading: false,
      listLoaded: true,
      listError: undefined,
      listResult: list
    };
  },
  [LIST_FAIL]: (state, action) => {
    return {
      ...state,
      listLoading: false,
      listLoaded: false,
      listError: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

const platformSelectorReducer = makeReducer(ACTION_HANDLERS, initialState);
export default platformSelectorReducer;
