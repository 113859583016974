import { getData, setData, clearData, isDataChanged } from 'helpers/storageUtils';

/** Key for user/login info in storage */
const USER_KEY = 'msuser';
/** Key for session info in storage */
const SESSION_KEY = 'mssession';
/** Key for login domain in storage */
const MS_DOMAIN = 'msdomain';
/* Key for login schoolName in storage */
const MS_SCHOOL_NAME = 'msSchoolName';
/* Key for login schoolIcon in storage */
const MS_SCHOOL_ICON = 'msSchoolIcon';
/* Key for user query in storage */
const USER_QUERY = 'userQuery';

const USER_QUERY_DATA = 'userQueryData';

const PASS_TIPS = 'passTips';

const PASS_SIMPLE = 'passSimple';

export function readAuthInfo() {
  return getData(USER_KEY);
};

export function writeAuthInfo(authInfo) {
  setData(USER_KEY, JSON.stringify(authInfo));
  setData(MS_DOMAIN, authInfo.domain);
  setData(MS_SCHOOL_NAME, authInfo.real_name);
  setData(MS_SCHOOL_ICON, authInfo.icon_url);
  setData(PASS_TIPS, authInfo && authInfo.password_ext && authInfo.password_ext.msg_text || '');
};

export function clearAuthInfo() {
  clearData(USER_KEY);
  clearData(PASS_TIPS);
  clearData(PASS_SIMPLE);
};

export function getPassTips() {
  return getData(PASS_TIPS);
}

export function clearPassTips() {
  clearData(PASS_TIPS);
}

export function setPassSimple(flag) {
  setData(PASS_SIMPLE, flag);
}

export function getPassSimple() {
  return getData(PASS_SIMPLE);
}

export function clearPassSimple() {
  clearData(PASS_SIMPLE);
  clearData(PASS_TIPS);
}

export function isLoginChanged(e) { // 登录状态变化
  return isDataChanged(e, USER_KEY);
};

export function getDomain() {
  return getData(MS_DOMAIN);
};

export function getSchoolName() {
  return getData(MS_SCHOOL_NAME);
}

export function getSchoolIcon() {
  return getData(MS_SCHOOL_ICON);
}

/** 超级管理员 */
export const USER_TYPE_ADMIN = 1;
/** 二级管理员 */
export const USER_TYPE_SUBADMIN = 2;

/** 管理员（超级/二级）普通账号类型 */
export const ACCOUNT_TYPE_ADMIN = 0;
/** 微哨账号类型 */
export const ACCOUNT_TYPE_WHISTLE = 3;
/** 助理账号类型 */
export const ACCOUNT_TYPE_ASSISTANT = 4;

export class AuthInfo {
  constructor(authInfo) {
    this.auth = authInfo;
  }

  get id() {
    return this.auth ? this.auth.Id : '';
  }

  get account() {
    return this.auth ? this.auth.user_name : '';
  }

  get password() {
    return this.auth ? this.auth.password : '';
  }

  get displayName() {
    return this.auth ? (this.auth.name ? this.auth.name : this.auth.real_name) : '';
  }

  get domain() {
    return this.auth ? this.auth.domain : '';
  }

  get downloadUrl() {
    return this.auth ? this.auth.urlDownload : '';
  }

  get uploadUrl() {
    return this.auth ? this.auth.urlUpload : '';
  }

  get remark() {
    return this.auth ? this.auth.remark : '';
  }

  get authType() {
    return this.auth ? parseInt(this.auth.authType) : -1;
  }

  get hideCredit() {
    return this.auth && this.auth.is_scores === '0';
  }

  get hideRobot() {
    return this.auth && this.auth.robot_status === '0';
  }

  get hideChatManage() {
    return this.auth && this.auth.chat_status === '0';
  }

  get hideComment() {
    return this.auth && this.auth.comment_status === '0';
  }

  get hidePortal() {
    return this.auth && this.auth.pc_open_status && Number(this.auth.pc_open_status) === 0;
  }

  get hideWorkPortal() {
    return this.auth && this.auth.portal_opens_status && Number(this.auth.portal_opens_status) === 0;
  }

  get isTestSchool() {
    return this.auth && this.auth.is_probation === '1';
  }

  get hideSystemManager() { // 是否隐藏日志管理
    return this.auth && this.auth.customized === '0';
  }

  get showSecondaryAppStore() { // 判断二级管理员是否显示二级管理员应用中心菜单
    return this.auth && !!this.auth.authority_app_json;
  }

  get authMenu() {
    return this.auth && this.auth.scope_id || '';
  }

  get role() {
    return this.auth && this.auth.role || '';
  }

  get loginAccount() {
    return this.auth && this.auth.login_account || '';
  }

  /**
   * 用户类型。
   *  USER_TYPE_ADMIN - 超级管理员
   *  USER_TYPE_SUBADMIN - 二级管理员
   */
  get userType() {
    return this.auth ? parseInt(this.auth.user_type) : -1;
  }

  /**
   * 账号类型。
   *  ACCOUNT_TYPE_ADMIN - 管理员账号
   *  ACCOUNT_TYPE_ASSISTANT - 助理账号
   */
  get accountType() {
    return this.auth ? parseInt(this.auth.accountType) : -1;
  }

  get verify() {
    return this.auth ? this.auth.verify : '';
  }

  get rootOrgId() {
    return this.auth ? (this.auth.root_org_id + '') : ''; // id是字符串
  }

  get isGroundUser() {
    return this.auth && Number(this.auth.user_center) === 0 && Number(this.auth.cloudGround_status) === 2 || false;
  }

  get passwordExt() {
    return this.auth && this.auth.password_ext || undefined;
  }
};

export function getAuthInfo() {
  const auth = readAuthInfo();
  if (auth) {
    return new AuthInfo(JSON.parse(auth));
  }
  return null;
};

export function getSessionTime() {
  const time = getData(SESSION_KEY);
  if (time) {
    return parseInt(time);
  }
  return NaN;
};

export function setSessionTime(time) {
  setData(SESSION_KEY, time + '');
};

export function clearSessionTime() {
  clearData(SESSION_KEY);
};

export function getUserQuery() {
  const userQuery = getData(USER_QUERY);
  if (userQuery) {
    return JSON.parse(userQuery) || [];
  }
  return [];
}

export function setUserQuery(data) {
  setData(USER_QUERY, JSON.stringify(data));
}

export function clearUserQuery() {
  clearData(USER_QUERY);
}

// 组织结构树搜索页面传递的筛选参数
export function getUserQueryData() {
  const userQueryData = getData(USER_QUERY_DATA);
  if (userQueryData) {
    return JSON.parse(userQueryData) || {};
  }
  return {};
}

export function setUserQueryData(data) {
  setData(USER_QUERY_DATA, JSON.stringify(data));
}

export function clearUserQueryData() {
  clearData(USER_QUERY_DATA);
}

