import S from 'string';

const join = rules => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];// first error
export const isEmpty = value => value === undefined || value === null || value === '';

/* eslint-disable */
// 有前缀和没前缀均合法的域名
export const REGEX_URL = /^((http|https):\/\/)?[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/;
/* eslint-disable */
// export const REGEX_URL_PREFIX = /^((http|https):\/\/)[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/;
export const REGEX_URL_PREFIX = /^((http|https):\/\/)[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/;
export const APP_URL_PREFIX = /^((http|https):\/\/|whistle*)[A-Za-z0-9-_]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/;
export const REGEX_URL_NO_PREFIX = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/;
const REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// const REGEX_PHONE = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,8}$/;
const REGEX_PHONE = /^[0-9#*+-\s]+$/;
const REGEX_MOBILE = /^[1]\d{10}$/;
export const REGEX_DIGIT = /^\d+$/;
const REGEX_DIGIT_ALPHABET = /^[a-zA-Z0-9]+$/;
// const REGEX_SPECIAL_CHAR = /^[\u4e00-\u9fa5a-z0-9]+$/gi;
export const REGEX_NO_SPECIAL = /^[A-Za-z0-9\u4e00-\u9fa5、——（）()]+$/;
export const REGEX_NO_EMOJI = /([\ud83c\udc00\-\ud83c\udfff]|[\ud83d\udc00\-\ud83d\udfff]|[\u2600\-\u27ff])+/;
const REGEX_NAME = /^[A-Za-z0-9 \u00b7\u2022\u4e00-\u9fa5]+$/; // {\u00b7}和{\u2022}用于名字中间的点分隔符
export const REGEX_DATE=/^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;//XXXX-XX-XX日期正则
export const REGEX_TIME=/^[0-2]\d{1}:[0-5]\d{1}:[0-5]\d{1}$/;//XX:XX:XX时间正则
export const REGEX_TIME_HHMM=/^[0-2]\d{1}:[0-5]\d{1}$/;//XX:XX:XX时间正则
const REGEX_VERSION = /^\d(.\d)(\.\d)(.\d)*/;
const REGEX_PASSWORD = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S+$/
/* eslint-enable */

/**
 * Helper function to test a value against a regular expression and return given text accordingly.
 */
function test(regex, value, failText, successText = '') {
  if (!isEmpty(value) && !regex.test(value)) {
    return failText;
  }
  return successText;
}

function reverseTest(regex, value, failText, successText = '') {
  if (!isEmpty(value) && regex.test(value)) {
    return failText;
  }
  return successText;
}

export function required(value) {
  if (isEmpty(value)) {
    return '此项为必填项';
  }
};

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `不能少于 ${min} 个字`;
    }
  };
};

export function notAllowedAllBlank(value) {
  if (value && S(value).isEmpty()) {
    return '不能全空格';
  }
};


export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `不能超过 ${max} 个字`;
    }
  };
};

export function notInclude(char) {
  return value => {
    if (!isEmpty(value) && value.indexOf(char) >= 0) {
      return '不能包含字符' + char;
    }
  };
};

export function prefixUrlValidator(value) {
  return test(REGEX_URL_PREFIX, value, '请输入有效的网址，必须以http://或https://开头');
};

export function appUrlValidator(value) {
  return test(APP_URL_PREFIX, value, '请输入有效的网址，必须以http://、https://或whistle开头');
};

export function urlValidator(value) {
  return test(REGEX_URL, value, '请输入有效的网址');
};

export function noPrefixUrlValidator(value) {
  return test(REGEX_URL_NO_PREFIX, value, '请输入有效的网址，无需输入http、https');
};

export function digitValidator(value) {
  return test(REGEX_DIGIT, value, '只可输入数字');
};
export function positiveIntValidator(value) {
  const v = Number(value);
  if (!Number.isInteger(v) || v <= 0) {
    return '请输入正整数';
  }
};

// export function specialCharacter(value) {
//   return test(REGEX_SPECIAL_CHAR, value, '含有特殊字符');
// };

export function alphanumeric(value) {
  return test(REGEX_DIGIT_ALPHABET, value, '只可输入数字和字母');
};

export function emailValidator(value) {
  return test(REGEX_EMAIL, value, '邮箱格式错误');
};

export function phoneValidator(value) {
  return test(REGEX_PHONE, value, '电话号码格式错误');
};

export function phoneCornetValidator(value) {
  return test(REGEX_DIGIT, value, '电话短号格式错误');
};

export function mobileValidator(value) {
  return test(REGEX_MOBILE, value, '手机号码格式错误');
};

export function mobileCornetValidator(value) {
  return test(REGEX_DIGIT, value, '手机短号格式错误');
};


export function nameValidator(value) {
  return test(REGEX_NAME, value, '仅支持汉字、字母及点分隔符组合');
};

export function noSpecial(value) {
  return test(REGEX_NO_SPECIAL, value, '仅支持汉字、数字和字母组合');
};

export function noEmoji(value) {
  value = value && value.replace(/\-/g, '');
  return reverseTest(REGEX_NO_EMOJI, value, '不能输入表情符号');
};

export function regexVersion(value) {
  return test(REGEX_VERSION, value, '格式不正确');
}

export function regexPassword(value) {
  return test(REGEX_PASSWORD, value, '请输入字母、数字或特殊字符组合');
}

export function createValidator(rules) {
  return (values = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(values[key], values);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
};
