/**
 * 登录页
 */
export default {
  'login.school': '微哨管理平台',
  'login.school.icon.alt': '微哨Logo',
  'login.tab.account': '微哨账号登录',
  'login.tab.admin': '管理员账号登录',
  'login.tab.qrcode': '扫码登录',
  'login.form.school.placeholder': '无匹配的结果',
  'login.form.account.placeholder': '学工号/已绑手机/证件号',
  'login.form.admin.placeholder': '管理员账号',
  'login.form.password.placeholder': '密码',
  'login.form.button.text': '登录',
  'login.qrcode.tips': '打开新版手机微哨，扫描二维码登录',

  // login error
  'login.error.account.cannotLogin': '此微哨用户名无法登录管理端，请联系管理员。',
  'login.error.account.disabled': '此微哨用户名已被禁用，请联系管理员。'
};

