import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import classes from './FilePicker.scss';

export class FilePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.pickFile = this.pickFile.bind(this);
  }

  pickFile() {
    this.refs.dropzone.open();
  }

  render() {
    const { accept, multiple, disabled, onPick, onReject, filename, tips, style } = this.props;
    const componentWidth = this.props.componentWidth || 744;
    const styleObj = style
      ? Object.assign({}, style, { width: componentWidth + 'px' })
      : { width: componentWidth + 'px' };
    return (
      <div className={classes.container} style={styleObj}>
        <Dropzone ref='dropzone' className={classes.dropZone} multiple={multiple || false}
          accept={accept || '.png'} onDropAccepted={onPick} onDropRejected={onReject} />
        <div className={classes.selectPanel}>
          <div className={classes.fileName} style={{width: (componentWidth - 144 + 'px')}}>{filename || ''}</div>
          <div className={classes.selectBtn} disabled={disabled} onClick={disabled ? void 0 : this.pickFile}>
            选择文件
          </div>
        </div>
        {tips && <div className={classes.tips}>{tips}</div>}
        {this.props.children}
      </div>
    );
  }
}

FilePicker.propTypes = {
  children: PropTypes.any,
  componentWidth: PropTypes.number,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onPick: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  filename: PropTypes.string,
  tips: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

export default FilePicker;
