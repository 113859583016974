import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback, getRouteReplace } from '../routeUtils';

export const route = (store) => ({
  name: '权限管理',
  path: 'permission',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) =>
    getRouteReplace(replace, 'permission', 'role', 'assistant') }, // IndexRedirect
  childRoutes: [
    {
      name: '角色管理',
      path: 'role',
      indexRoute: {
        name: '角色管理',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'permission', reducer });
            const Component = require('./containers/Role').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Permission');
        }
      },
      childRoutes: [
        {
          name: '新建角色',
          path: 'roleAdd',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'permission', reducer });
              const Component = require('./containers/RoleAddEdit').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Permission');
          }
        },
        {
          name: '编辑角色',
          path: 'roleEdit/:id',
          staticName: true,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'permission', reducer });
              const Component = require('./containers/RoleAddEdit').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Permission');
          }
        }
      ]
    },
    {
      name: '管理员账号',
      path: 'manager',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'permission', reducer });
          const Component = require('./containers/Manager').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Permission');
      }
    },
    {
      name: '助理账号',
      path: 'assistant(/:tab)',
      staticName: true,
      indexRoute: {
        name: '助理账号',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'permission', reducer });
            const Component = require('./containers/Assistant').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Permission');
        }
      }
    }
  ]
});

export default route;
