import React from 'react';
import PropTypes from 'prop-types';
import classes from './ErrorView.scss';
import ErrImage from 'styles/inline-images/defaultAppIcon.png';

const reload = () => {
  window.location.reload(true);
};

export function ErrorView({ text, btnText = '刷新', showBtn = true, style, innerStyle, refresh = reload }) {
  return (
    <div className={classes.blankContainer} style={style}>
      <div className={classes.blankInner} style={innerStyle}>
        <img className={classes.img} src={ErrImage} />
        <span>{text}</span>
        {showBtn && <span className={'wsbtn-ghost wsbtn-ghost-primary'} onClick={refresh}>{btnText}</span>}
      </div>
    </div>
  );
};

ErrorView.propTypes = {
  text: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  style: PropTypes.number,
  innerStyle: PropTypes.object,
  showBtn: PropTypes.bool,
  refresh: PropTypes.func
};

export default ErrorView;
