import PropTypes from 'prop-types';
import React from 'react';
import classes from './HoverNav.scss';

export class NavItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
  }

  onItemClick() {
    const {onItemClick} = this.props;
    if (onItemClick) {
      onItemClick(this.props.item.key);
    }
  }

  render() {
    const {item} = this.props;
    if (item.title === '-') {
      return <hr className={classes.line} />;
    } else {
      return <span className={classes.item} onClick={this.onItemClick}>{item.title}</span>;
    }
  }
}

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  onItemClick: PropTypes.func
};

export default NavItem;
