import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/16.
 */
import React, { Component } from 'react';
import classes from './OrganizationNavView.scss';
import { connect } from 'react-redux';
import { loadOrgRoot, setActiveNode, queryUsers } from '../modules/organization';
import OperatableTreeNode from './OperatableTreeNode';
// import LoadingView from 'components/LoadingView/LoadingView';
import {getAuthInfo} from 'helpers/AuthHelper';

const mapActionCreators = {
  loadOrgRoot,
  setActiveNode,
  queryUsers
};

const mapStateToProps = (state) => ({
  contentHeight: state.flexSize.contentHeight,
  rootLoaded: state.organization.rootLoaded,
  rootError: state.organization.rootError,
  organizationRoot: state.organization.organizationRoot,
  rooting: state.organization.rooting,
  nodeLoading: state.organization.nodeLoading,
  nodeOrdering: state.organization.nodeOrdering,
  activeNode: state.organization.activeNode,
  multiOrgRootResult: state.organization.multiOrgRootResult,
  openedResult: state.organization.openedResult
});
class OrganizationNavView extends Component {
  static propTypes = {
    rooting: PropTypes.bool,
    rootError: PropTypes.object,
    rootLoaded: PropTypes.bool,
    organizationRoot: PropTypes.object,
    activeNode: PropTypes.object,
    setActiveNode: PropTypes.func,
    queryUsers: PropTypes.func,
    style: PropTypes.object,
    multiOrgRootResult: PropTypes.object,
    openedResult: PropTypes.object
  }

  constructor(props) {
    super(props);
    const auth = getAuthInfo();
    const { activeNode } = this.props;
    this.openedNodeList = [auth ? auth.rootOrgId : '', activeNode ? activeNode.id : '']; // 记录了被展开的节点id,默认展开根节点
  }

  componentWillReceiveProps(newProps) {
    const {activeNode} = newProps;
    if (activeNode && activeNode !== this.props.activeNode && parseInt(activeNode.parentId) === 0) {
      this.openedNodeList.push(activeNode.id);
    }
  }

  onSelectNode = (node) => {
    if (node !== this.props.activeNode) {
      this.props.setActiveNode(node);
      this.props.queryUsers({org_id: node.id});
    }
  }

  render() {
    const {rooting, rootError, rootLoaded, organizationRoot, activeNode, multiOrgRootResult, style,
      openedResult} = this.props;
    return (<div className={classes.organizationNavView + ' treeDiv'} style={style}>
      <div className={classes.organizationScrollView}>
        {(rootLoaded || organizationRoot) && <OperatableTreeNode node={multiOrgRootResult}
          key={multiOrgRootResult && multiOrgRootResult.uuid} onClickCallback={this.onSelectNode}
          openedNodeList={openedResult || this.openedNodeList} width={232} height={22} levelOffset={10}
          activeNode={activeNode} />}
        {rootLoaded && !organizationRoot && <div style={{marginTop: '6px'}}>没有标签</div>}
        {rooting && <div>组织结构树加载中…</div>}
        {rootError && <div className='text-danger'>组织结构树请求失败!</div>}
        {/* {(nodeLoading || nodeOrdering) && <LoadingView style={{minHeight: contentHeight - 130}} />} */}
      </div>

      {/*
       <DropdownTabbedOrgTreePicker labelName='岗位/非常设机构' expandRoot />
       <SingleSelectionOrgTreePicker divWidth={302} showUser/>
       <CheckboxOrgTreePicker divWidth={302} />
       */}
    </div>);
  }
}

export default connect(mapStateToProps, mapActionCreators)(OrganizationNavView);
