import PropTypes from 'prop-types';
import React from 'react';
import classes from './ExcelImportView.scss';
import LoadingImg from './assets/loading.gif';

export const ImportLoadingView = ({tips}) => (
  <div className={classes.loadingDiv}>
    <img src={LoadingImg} />
    <div className={classes.loadingText} style={{ fontSize: '20px' }}>正在努力上传中…</div>
    <div className={classes.loadingText} style={{ color: '#262626' }}>由于受网速和导入数据的影响，可能需要较长时间，请耐心等待。</div>
    {tips && <div className={classes.loadingText} style={{ color: '#737373' }}>{tips}</div>}
  </div>
);

ImportLoadingView.propTypes = {
  tips: PropTypes.string
};

export default ImportLoadingView;
