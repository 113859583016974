/**
 * Created by liming on 16/6/14.
 * 非常设机构
 */
import makeReducer from 'store/makeReducer';
import uuid from 'uuid';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import {RELOGIN} from 'store/modules/reloginService';
import {LOGOUT_SUCCESS} from '../../Login/modules/strings';
import ApiClient, { formatUrl } from 'helpers/ApiClient';
import { sort } from 'helpers/arrayUtils';

// ------------------------------------
// Constants
// ------------------------------------
export const ADHOC_ORG_LIST = 'whistlems/adhocOrg/ADHOC_ORG_LIST';
export const ADHOC_ORG_LIST_SUCCESS = 'whistlems/adhocOrg/ADHOC_ORG_LIST_SUCCESS';
export const ADHOC_ORG_LIST_FAIL = 'whistlems/adhocOrg/ADHOC_ORG_LIST_FAIL';

export const ADHOC_ORG_ADD = 'whistlems/adhocOrg/ADHOC_ORG_ADD';
export const ADHOC_ORG_ADD_SUCCESS = 'whistlems/adhocOrg/ADHOC_ORG_ADD_SUCCESS';
export const ADHOC_ORG_ADD_FAIL = 'whistlems/adhocOrg/ADHOC_ORG_ADD_FAIL';

export const ADHOC_ORG_DELETE = 'whistlems/adhocOrg/ADHOC_ORG_DELETE';
export const ADHOC_ORG_DELETE_SUCCESS = 'whistlems/adhocOrg/ADHOC_ORG_DELETE_SUCCESS';
export const ADHOC_ORG_DELETE_FAIL = 'whistlems/adhocOrg/ADHOC_ORG_DELETE_FAIL';

export const ADHOC_ORG_MODIFY = 'whistlems/adhocOrg/ADHOC_ORG_MODIFY';
export const ADHOC_ORG_MODIFY_SUCCESS = 'whistlems/adhocOrg/ADHOC_ORG_MODIFY_SUCCESS';
export const ADHOC_ORG_MODIFY_FAIL = 'whistlems/adhocOrg/ADHOC_ORG_MODIFY_FAIL';

export const ADHOC_USER_ADD = 'whistlems/adhocOrg/ADHOC_USER_ADD';
export const ADHOC_USER_ADD_SUCCESS = 'whistlems/adhocOrg/ADHOC_USER_ADD_SUCCESS';
export const ADHOC_USER_ADD_FAIL = 'whistlems/adhocOrg/ADHOC_USER_ADD_FAIL';

export const ADHOC_USER_DELETE = 'whistlems/adhocOrg/ADHOC_USER_DELETE';
export const ADHOC_USER_DELETE_SUCCESS = 'whistlems/adhocOrg/ADHOC_USER_DELETE_SUCCESS';
export const ADHOC_USER_DELETE_FAIL = 'whistlems/adhocOrg/ADHOC_USER_DELETE_FAIL';

export const ADHOC_USER_LIST = 'whistlems/adhocOrg/ADHOC_USER_LIST';
export const ADHOC_USER_LIST_SUCCESS = 'whistlems/adhocOrg/ADHOC_USER_LIST_SUCCESS';
export const ADHOC_USER_LIST_FAIL = 'whistlems/adhocOrg/ADHOC_USER_LIST_FAIL';

export const IMPORT_ADHOC_USER = 'whistlems/adhocOrg/IMPORT_ADHOC_USER';
export const IMPORT_ADHOC_USER_SUCCESS = 'whistlems/adhocOrg/IMPORT_ADHOC_USER_SUCCESS';
export const IMPORT_ADHOC_USER_FAIL = 'whistlems/adhocOrg/IMPORT_ADHOC_USER_FAIL';

// 非常设机构全局搜索
export const ADHOC_USER_SEARCH = 'whistlems/adhocOrg/ADHOC_USER_SEARCH';
export const ADHOC_USER_SEARCH_SUCCESS = ADHOC_USER_SEARCH + '_SUCCESS';
export const ADHOC_USER_SEARCH_FAIL = ADHOC_USER_SEARCH + '_FAIL';

export const SET_ACTIVE_NODE = 'whistlems/adhocOrg/SET_ACTIVE_NODE';

export const CLEAR_USER_LIST = 'whistlems/adhocOrg/CLEAR_USER_LIST';

export const USER_COUNT_PER_PAGE = 20; // 每页显示最大用户数

// ------------------------------------
// Utils
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export const adhocOrgList = () => {
  return {
    types: [ADHOC_ORG_LIST, ADHOC_ORG_LIST_SUCCESS, ADHOC_ORG_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocOrgList', {})
    })
  };
};

export const addAdhocOrg = (name) => {
  return {
    types: [ADHOC_ORG_ADD, ADHOC_ORG_ADD_SUCCESS, ADHOC_ORG_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocOrgAdd', {
        name
      })
    })
  };
};

export const deleteAdhocOrg = (id) => {
  return {
    types: [ADHOC_ORG_DELETE, ADHOC_ORG_DELETE_SUCCESS, ADHOC_ORG_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocOrgDelete', {
        org_id: id
      })
    }),
    id
  };
};

export const modifyAdhocOrg = (id, name) => {
  return {
    types: [ADHOC_ORG_MODIFY, ADHOC_ORG_MODIFY_SUCCESS, ADHOC_ORG_MODIFY_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocOrgModify', {
        org_id: id,
        name
      })
    }),
    id,
    name
  };
};


/*
 orgId：组织节点id（必填）
 postId：岗位id（必填）
 postName：岗位名称（必填）
 userName：uid（必填）
 studentNo：学工号（必填）
 name：姓名（必填）
 */
export const addAdhocUser = (orgId, postId, postName, userName, studentNo, name) => {
  return {
    types: [ADHOC_USER_ADD, ADHOC_USER_ADD_SUCCESS, ADHOC_USER_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocUserAdd', {
        org_id: orgId,
        post_id: postId,
        post_name: postName,
        username: userName,
        student_number: studentNo,
        name
      })
    })
  };
};

/*
 orgId：组织节点id （必填）
 post_id：岗位id （必填）
 username：uid的数组
 */
export const deleteAdhocUser = (userInfo) => {
  const delLen = userInfo.length;
  const orgIds = userInfo.map(item => item.orgId).join();
  const postIdStrs = userInfo.map(item => item.post_id).join();
  const userStrs = userInfo.map(item => item.uid).join();
  return {
    types: [ADHOC_USER_DELETE, ADHOC_USER_DELETE_SUCCESS, ADHOC_USER_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocUserDelete', {
        org_id: orgIds,
        post_id: postIdStrs,
        username: userStrs
      })
    }),
    delLen
  };
};

export const adhocUserList = (id, page, count) => {
  return {
    types: [ADHOC_USER_LIST, ADHOC_USER_LIST_SUCCESS, ADHOC_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adhocUserList', {
        org_id: id,
        page,
        count
      })
    }),
    id,
    page,
    count
  };
};



export const importAdhocUser = (orgId, file) => {
  const pathObj = formatParams('org', 'importAdhocUser', {file_length: file.size, org_id: orgId});
  return (dispatch, getState) => {
    dispatch({
      type: IMPORT_ADHOC_USER
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: IMPORT_ADHOC_USER_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: IMPORT_ADHOC_USER_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 非常设机构搜索方法
 * searchObj: { page, search_text, post_id }
 */
export const adhocUserSearch = (searchObj) => {
  if (!searchObj.page) {
    searchObj.page = 1;
  }
  return {
    types: [ADHOC_USER_SEARCH, ADHOC_USER_SEARCH_SUCCESS, ADHOC_USER_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'globalSearch', {
        data_type: 1,
        count: USER_COUNT_PER_PAGE,
        ...searchObj
      })
    })
  };
};



export const setActiveNode = (node) => {
  return {
    type: SET_ACTIVE_NODE,
    node
  };
};

export const clearUserList = (node) => {
  return {
    type: CLEAR_USER_LIST
  };
};



export const actions = {
  adhocOrgList,
  addAdhocOrg,
  deleteAdhocOrg,
  modifyAdhocOrg,
  addAdhocUser,
  deleteAdhocUser,
  adhocUserList,
  setActiveNode,
  importAdhocUser,
  clearUserList,
  adhocUserSearch
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ADHOC_ORG_LIST]: (state, action) => {
    return {
      ...state,
      listing: true,
      listLoaded: undefined,
      listError: undefined,
      adhocOrgList: []
    };
  },
  [ADHOC_ORG_LIST_SUCCESS]: (state, action) => {
    const list = action.payload.data;
    list.forEach((item) => {
      item.uuid = uuid.v4();
    });
    return {
      ...state,
      listing: undefined,
      listLoaded: true,
      adhocOrgList: sort(list)
    };
  },
  [ADHOC_ORG_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      listing: undefined,
      listLoaded: undefined,
      adhocOrgList: []
    };
  },
  [ADHOC_ORG_ADD]: (state, action) => {
    return {
      ...state,
      addOrging: true,
      addOrgLoaded: undefined,
      addOrgError: undefined
    };
  },
  [ADHOC_ORG_ADD_SUCCESS]: (state, action) => {
    const newOrg = action.payload.data;
    newOrg.uuid = uuid.v4();
    const newList = [newOrg, ...state.adhocOrgList.slice(0)];
    return {
      ...state,
      addOrging: undefined,
      addOrgLoaded: true,
      addOrgError: undefined,
      adhocOrgList: newList
    };
  },
  [ADHOC_ORG_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      addOrging: undefined,
      addOrgLoaded: undefined,
      addOrgError: action.payload
    };
  },
  [ADHOC_ORG_DELETE]: (state, action) => {
    return {
      ...state,
      deleteOrging: true,
      deleteOrgLoaded: undefined,
      deleteOrgError: undefined
    };
  },
  [ADHOC_ORG_DELETE_SUCCESS]: (state, action) => {
    const id = action.id;
    const list = state.adhocOrgList;
    const index = list.findIndex(function(value, index, arr) {
      return value.id === id;
    });
    return {
      ...state,
      deleteOrging: undefined,
      deleteOrgLoaded: true,
      adhocOrgList: [...list.slice(0, index), ...list.slice(index + 1)]
    };
  },
  [ADHOC_ORG_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deleteOrging: undefined,
      deleteOrgLoaded: undefined,
      deleteOrgError: action.payload
    };
  },
  [ADHOC_ORG_MODIFY]: (state, action) => {
    return {
      ...state,
      modifyOrging: true,
      modifyOrgLoaded: undefined,
      modifyOrgError: undefined
    };
  },
  [ADHOC_ORG_MODIFY_SUCCESS]: (state, action) => {
    const id = action.id;
    const list = state.adhocOrgList.slice(0);
    const item = list.find(function(value, index, arr) {
      return value.id === id;
    });
    item.name = action.name;
    item.uuid = uuid.v4();
    return {
      ...state,
      modifyOrging: undefined,
      modifyOrgLoaded: true,
      adhocOrgList: list
    };
  },
  [ADHOC_ORG_MODIFY_FAIL]: (state, action) => {
    return {
      ...state,
      modifyOrging: undefined,
      modifyOrgLoaded: undefined,
      modifyOrgError: action.payload
    };
  },
  [ADHOC_USER_LIST]: (state, action) => {
    const newList = {list: [], total: 0}; // 初始状态
    newList.query = {id: action.id, page: action.page, count: action.count};
    return {
      ...state,
      userListing: true,
      userListLoaded: undefined,
      userListError: undefined,
      userList: newList
    };
  },
  [ADHOC_USER_LIST_SUCCESS]: (state, action) => {
    if (action.id !== state.userList.query.id
      || action.page !== state.userList.query.page && action.count !== state.userList.query.count) {
      return state;
    }
    const list = action.payload.data.list;
    list.forEach((item) => {
      item.uuid = uuid.v4();
      item.id = `${item.uid}_${item.post_id}`;
    });
    const obj = Object.assign({}, state.userList);
    obj.list = list;
    obj.total = action.payload.data.total;
    return {
      ...state,
      userListing: undefined,
      userListLoaded: true,
      userListError: undefined,
      userList: obj
    };
  },
  [ADHOC_USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      userListing: undefined,
      userListLoaded: undefined,
      userListError: action.payload
    };
  },
  // 非常设机构搜索结果
  [ADHOC_USER_SEARCH]: (state, action) => {
    const newList = {list: [], total: 0}; // 初始状态
    return {
      ...state,
      userListing: true,
      userListLoaded: undefined,
      userListError: undefined,
      userList: newList
    };
  },
  [ADHOC_USER_SEARCH_SUCCESS]: (state, action) => {
    const list = action.payload.data.list;
    list.forEach((item) => {
      item.uuid = uuid.v4();
      item.id = `${item.uid}_${item.post_id}`;
    });
    const obj = Object.assign({}, state.userList);
    obj.list = list;
    obj.total = action.payload.data.count;
    return {
      ...state,
      userListing: undefined,
      userListLoaded: true,
      userListError: undefined,
      userList: obj
    };
  },
  [ADHOC_USER_SEARCH_FAIL]: (state, action) => {
    return {
      ...state,
      userListing: undefined,
      userListLoaded: undefined,
      userListError: action.payload
    };
  },
  [ADHOC_USER_ADD]: (state, action) => {
    return {
      ...state,
      addUsering: true,
      addUserLoaded: undefined,
      addUserError: undefined
    };
  },
  [ADHOC_USER_ADD_SUCCESS]: (state, action) => {
    const newUser = action.payload.data;
    newUser.uuid = uuid.v4();
    const userList = Object.assign({}, state.userList);
    userList.list = [newUser, ...userList.list.slice(0)];
    userList.total++;
    return {
      ...state,
      addUsering: undefined,
      addUserLoaded: true,
      addUserError: undefined,
      userList: userList
    };
  },
  [ADHOC_USER_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      addUsering: undefined,
      addUserLoaded: undefined,
      addUserError: action.payload
    };
  },

  [ADHOC_USER_DELETE]: (state, action) => {
    return {
      ...state,
      deleteUsering: true,
      deleteUserLoaded: undefined,
      deleteUserError: undefined,
      delLen: 0
    };
  },
  [ADHOC_USER_DELETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      deleteUsering: undefined,
      deleteUserLoaded: true,
      deleteUserError: undefined,
      delLen: action.delLen
    };
  },
  [ADHOC_USER_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deleteUsering: undefined,
      deleteUserLoaded: undefined,
      deleteUserError: action.payload,
      delLen: 0
    };
  },

  [IMPORT_ADHOC_USER]: (state, action) => {
    return {
      ...state,
      importUsering: true,
      importUserLoaded: undefined,
      importUserError: undefined
    };
  },
  [IMPORT_ADHOC_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      importUsering: undefined,
      importUserLoaded: true,
      importUserError: undefined,
      importMsg: action.payload.body
    };
  },
  [IMPORT_ADHOC_USER_FAIL]: (state, action) => {
    return {
      ...state,
      importUsering: undefined,
      importUserLoaded: undefined,
      importUserError: action.payload
    };
  },

  [SET_ACTIVE_NODE]: (state, action) => {
    return {
      ...state,
      activeNode: action.node
    };
  },
  [CLEAR_USER_LIST]: (state, action) => {
    return {
      ...state,
      userList: { list: [], total: 0 },
      userListing: undefined,
      userListLoaded: undefined,
      userListError: undefined
    };
  },
  [LOGOUT_SUCCESS]: (state, action) => {
    return initialState;
  },
  [RELOGIN]: (state, action) => {
    return initialState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  adhocOrgList: [],
  userList: { list: [], total: 0 }
};

const adhocOrgReducer = makeReducer(ACTION_HANDLERS, initialState);
export default adhocOrgReducer;
