import makeReducer from '../makeReducer';
// ------------------------------------
// Constants
// ------------------------------------

const CHANGE_FLAG = 'whistlems/common/checkboxSelect/CHANGE_FLAG';

// ------------------------------------
// Actions
// ------------------------------------

export const changeFlag = (flag = false) => {
  return {
    type: CHANGE_FLAG,
    flag
  };
};


export const actions = {
  changeFlag
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CHANGE_FLAG]: (state, action) => {
    return {
      ...state,
      flag: action.flag
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {flag: false};

const checkboxSelectorReducer = makeReducer(ACTION_HANDLERS, initialState);
export default checkboxSelectorReducer;
