import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import {preAppendToArray} from 'helpers/arrayUtils';

const PREFIX = 'whistlems/organization/';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

const MULTI_USER_LIST = PREFIX + 'MULTI_USER_LIST';
const MULTI_USER_LIST_AT_PAGE = MULTI_USER_LIST + '_AT_PAGE';
const MULTI_USER_LIST_SUCCESS = MULTI_USER_LIST + SUCCESS;
const MULTI_USER_LIST_FAIL = MULTI_USER_LIST + FAIL;

const SET_CHECKBOX = PREFIX + 'SET_CHECKBOX';
const CLEAR_CHECKBOX = PREFIX + 'CLEAR_CHECKBOX';

const MULTI_USER_DELETE = PREFIX + 'MULTI_USER_DELETE';
const MULTI_USER_DELETE_SUCCESS = MULTI_USER_DELETE + SUCCESS;
const MULTI_USER_DELETE_FAIL = MULTI_USER_DELETE + FAIL;

const MULTI_ORG = PREFIX + 'MULTI_ORG';
const MULTI_ORG_SUCCESS = MULTI_ORG + SUCCESS;
const MULTI_ORG_FAIL = MULTI_ORG + FAIL;

export const MULTI_USER_COUNT_PER_PAGE = 20;

export const multiUserList = (info) => {
  return {
    types: [MULTI_USER_LIST, MULTI_USER_LIST_SUCCESS, MULTI_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'searchUserList', {
        ...info
      })
    }),
    info
  };
};

export const multiUserListAtPage = (info) => {
  return {
    types: [MULTI_USER_LIST_AT_PAGE, MULTI_USER_LIST_SUCCESS, MULTI_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'searchUserList', {
        ...info
      })
    }),
    info
  };
};

export const setCheckbox = (user, all = false) => {
  return {
    type: SET_CHECKBOX,
    user,
    all
  };
};

export const clearCheckbox = () => {
  return {
    type: CLEAR_CHECKBOX
  };
};

export const multiUserDelete = (userArray, orgId) => {
  return {
    types: [MULTI_USER_DELETE, MULTI_USER_DELETE_SUCCESS, MULTI_USER_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userDeleteBatch', {
        usernames: userArray.join(','),
        search_id: orgId
      })
    }),
    userArray
  };
};

export const multiOrgList = () => {
  return {
    types: [MULTI_ORG, MULTI_ORG_SUCCESS, MULTI_ORG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgUserList', {
        org_id: '0'
      })
    })
  };
};

export const actions = {
  multiUserList
};

const ACTION_HANDLERS = {
  [MULTI_USER_LIST]: (state, action) => {
    const query = action.info;
    if (!query.page) {
      query.page = 1;
    }
    if (!query.count) {
      query.count = MULTI_USER_COUNT_PER_PAGE;
    }
    return {
      ...state,
      multiQuery: query,
      multiUserListLoading: true,
      multiUserListLoaded: false,
      multiUserListError: undefined
    };
  },
  [MULTI_USER_LIST_AT_PAGE]: (state, action) => {
    return {
      ...state,
      multiQuery: action.info,
      multiUserListLoading: true,
      multiUserListLoaded: false,
      multiUserListError: undefined
    };
  },
  [MULTI_USER_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      multiUserListLoading: false,
      multiUserListLoaded: true,
      multiUserListError: undefined,
      multiUserListResult: action.payload.data,
      checkboxResult: undefined
    };
  },
  [MULTI_USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      multiUserListLoading: false,
      multiUserListLoaded: false,
      multiUserListError: action.payload.errmsg
    };
  },
  [SET_CHECKBOX]: (state, action) => {
    const list = state.multiUserListResult && state.multiUserListResult.list || [];
    let checkboxList = [];
    if (action.all) {
      list && list.map(item => checkboxList.push(item.username));
    } else {
      checkboxList = [];
    }
    if (action.user) {
      if (state.checkboxResult) {
        const userId = state.checkboxResult.find(item => item === action.user.username);
        if (userId) {
          checkboxList = state.checkboxResult.filter(item => item !== userId);
        } else {
          checkboxList = preAppendToArray(state.checkboxResult, [action.user.username]);
        }
      } else {
        checkboxList.push(action.user.username);
      }
    }
    return {
      ...state,
      checkboxResult: checkboxList
    };
  },
  [CLEAR_CHECKBOX]: (state, action) => {
    return {
      ...state,
      checkboxResult: undefined
    };
  },
  [MULTI_USER_DELETE]: (state, action) => {
    return {
      ...state,
      multiUserDeleteLoading: true,
      multiUserDeleteLoaded: false,
      multiUserDeleteError: undefined
    };
  },
  [MULTI_USER_DELETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      multiUserDeleteLoading: false,
      multiUserDeleteLoaded: true,
      multiUserDeleteError: undefined,
      multiUserDeleteResult: action.payload.data,
      multiUserListResult: Object.assign({}, state.multiUserListResult,
        {total_count: state.multiUserListResult.total_count - action.userArray.length},
        {list_length: state.multiUserListResult.list.length - action.userArray.length})
    };
  },
  [MULTI_USER_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      multiUserDeleteLoading: false,
      multiUserDeleteLoaded: false,
      multiUserDeleteError: action.payload.errmsg
    };
  },
  [MULTI_ORG]: (state, action) => {
    return {
      ...state,
      multiOrgLoading: true,
      multiOrgLoaded: false,
      multiOrgError: undefined
    };
  },
  [MULTI_ORG_SUCCESS]: (state, action) => {
    let list = action.payload.data.org1 || [];
    if (list && list.length > 0) {
      list = list.map(item => Object.assign({value: item.id}, {label: item.name}, {orgType: item.org_type}));
    }
    list.unshift({value: 'all', label: '全部'});
    return {
      ...state,
      multiOrgLoading: false,
      multiOrgLoaded: true,
      multiOrgError: undefined,
      multiOrgResult: list
    };
  },
  [MULTI_ORG_FAIL]: (state, action) => {
    return {
      ...state,
      multiOrgLoading: false,
      multiOrgLoaded: false,
      multiOrgError: action.payload.errmsg
    };
  }
};

const initialState = {};

const multiQueryReducer = makeReducer(ACTION_HANDLERS, initialState);
export default multiQueryReducer;
