/**
 * 判断不同的浏览器方法
 */

// 获取 userAgent, 并将其转换为大写
const ua = navigator && navigator.userAgent && navigator.userAgent.toUpperCase();

// 是否是 IE
export const isIE = ua && ua.indexOf('TRIDENT') > -1;

// 是否是Edge
export const isEdge = ua && ua.indexOf('EDGE') > -1;

// 是否是火狐浏览器
export const isFireFox = ua.indexOf('FIREFOX') > -1;
