import React from 'react';
import PropTypes from 'prop-types';
import classes from './SubNodes.scss';

const SubNodes = ({ node, makeChildren }) => (
  <div className={classes.subNodesContainer}>
    {node.dataLoading && <div className={classes.info}>加载中…</div>}
    {node.dataError && <div className={classes.info}>无法加载</div>}
    {node.childrenLoaded && makeChildren()}
  </div>
);

SubNodes.propTypes = {
  node: PropTypes.object.isRequired,
  makeChildren: PropTypes.func.isRequired
};

export default SubNodes;
