export const LOAD = '_LOAD';
export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';

const BIND_WHISTLE_ACCOUNT = 'whistlems/account/BIND_WHISTLE_ACCOUNT';
export const BIND_WHISTLE_ACCOUNT_LOAD = BIND_WHISTLE_ACCOUNT + LOAD;
export const BIND_WHISTLE_ACCOUNT_SUCCESS = BIND_WHISTLE_ACCOUNT + SUCCESS;
export const BIND_WHISTLE_ACCOUNT_FAIL = BIND_WHISTLE_ACCOUNT + FAIL;

const BINDED_WHISTLE_ACCOUNT = 'whistlems/account/BINDED_WHISTLE_ACCOUNT';
export const BINDED_WHISTLE_ACCOUNT_LOAD = BINDED_WHISTLE_ACCOUNT + LOAD;
export const BINDED_WHISTLE_ACCOUNT_SUCCESS = BINDED_WHISTLE_ACCOUNT + SUCCESS;
export const BINDED_WHISTLE_ACCOUNT_FAIL = BINDED_WHISTLE_ACCOUNT + FAIL;

const ASSISTANT_ACCOUNT = 'whistlems/account/ASSISTANT_ACCOUNT';
export const ASSISTANT_ACCOUNT_LOAD = ASSISTANT_ACCOUNT + LOAD;
export const ASSISTANT_ACCOUNT_SUCCESS = ASSISTANT_ACCOUNT + SUCCESS;
export const ASSISTANT_ACCOUNT_FAIL = ASSISTANT_ACCOUNT + FAIL;

const DELETE_BINDED_WHISTLE_ACCOUNT = 'whistlems/account/DELETE_BINDED_WHISTLE_ACCOUNT';
export const DELETE_BINDED_WHISTLE_ACCOUNT_LOAD = DELETE_BINDED_WHISTLE_ACCOUNT + LOAD;
export const DELETE_BINDED_WHISTLE_ACCOUNT_SUCCESS = DELETE_BINDED_WHISTLE_ACCOUNT + SUCCESS;
export const DELETE_BINDED_WHISTLE_ACCOUNT_FAIL = DELETE_BINDED_WHISTLE_ACCOUNT + FAIL;

const DELETE_ASSISTANT_ACCOUNT = 'whistlems/account/DELETE_ASSISTANT_ACCOUNT';
export const DELETE_ASSISTANT_ACCOUNT_LOAD = DELETE_ASSISTANT_ACCOUNT + LOAD;
export const DELETE_ASSISTANT_ACCOUNT_SUCCESS = DELETE_ASSISTANT_ACCOUNT + SUCCESS;
export const DELETE_ASSISTANT_ACCOUNT_FAIL = DELETE_ASSISTANT_ACCOUNT + FAIL;

const ADD_ASSISTANT_ACCOUNT = 'whistlems/account/ADD_ASSISTANT_ACCOUNT';
export const ADD_ASSISTANT_ACCOUNT_LOAD = ADD_ASSISTANT_ACCOUNT + LOAD;
export const ADD_ASSISTANT_ACCOUNT_SUCCESS = ADD_ASSISTANT_ACCOUNT + SUCCESS;
export const ADD_ASSISTANT_ACCOUNT_FAIL = ADD_ASSISTANT_ACCOUNT + FAIL;
