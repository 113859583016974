import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classes from './UserListView.scss';
import { connect } from 'react-redux';
import LoadingView from 'components/LoadingView/LoadingView';
import { USER_COUNT_PER_PAGE, queryUsersAtPage, setUserToDelete, setUserToTop } from '../modules/organization';
import { showErrorToast } from 'helpers/toastUtils';
import Table from 'components/Table';
import { USER_TYPE, SEX_LIST, STUDENT_STATUS_LIST, TEACHER_STATUS_LIST } from './UserAddEditForm/data';
import { push } from 'react-router-redux';
import { getOrgPathText } from 'helpers/orgUtils';
import { CUSTOM_GRADE, CUSTOM_JOB, CUSTOM_STATUS, CUSTOM_ACTIVE } from '../strings/strings';
import { getAuthInfo } from 'helpers/AuthHelper';

const STATUS_LIST = [ ...STUDENT_STATUS_LIST, ...TEACHER_STATUS_LIST ];

const USER_GRADUATION = '2';

const mapActionCreators = {
  queryUsersAtPage: (query) => queryUsersAtPage(query),
  push,
  setUserToDelete, // 用户删除
  setUserToTop // 置顶、取消置顶
};

const mapStateToProps = (state) => ({
  userListing: state.organization.userListing,
  userListResult: state.organization.userListResult,
  userListError: state.organization.userListError,
  deleteUserLoaded: state.organization.deleteUserLoaded,
  isReset: state.organization.isReset,
  userQuery: state.organization.userQuery,
  activeNode: state.organization.activeNode
});

class UserListView extends Component {

  state = {
    page: 1
  }

  static propTypes = {
    userListing: PropTypes.bool,
    userListResult: PropTypes.object,
    userListError: PropTypes.object,
    userQuery: PropTypes.object,
    queryUsersAtPage: PropTypes.func,
    deleteUserLoaded: PropTypes.bool,
    boxWidth: PropTypes.number,
    boxHeight: PropTypes.number,
    isReset: PropTypes.bool,
    activeNode: PropTypes.object,
    push: PropTypes.func,
    setUserToDelete: PropTypes.func,
    setUserToTop: PropTypes.func,
    onViewClick: PropTypes.func,
    onEditClick: PropTypes.func,
    page: PropTypes.number,
    customFields: PropTypes.array
  }

  componentDidMount() {
    const { page } = this.props;
    if (page) {
      this.setState({page});
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const {deleteUserLoaded, userListResult, page} = this.props;
    if (nextProps.page && nextProps.page !== page) {
      this.setState({ page: nextProps.page });
    }
    if (!deleteUserLoaded && nextProps.deleteUserLoaded) {
      const pageCount = userListResult ? Math.ceil(userListResult.total_count / USER_COUNT_PER_PAGE) : 0;
      if (nextProps.userListResult.total_count >= USER_COUNT_PER_PAGE) {
        const { page } = this.state;
        if (nextProps.userListResult.list.length === 0) {
          this.setState({ page: page - 1 });
          const query = Object.assign({}, this.props.userQuery, { page: page - 1 });
          this.props.queryUsersAtPage(query);
        } else {
          if (page < pageCount) {
            const query = Object.assign({}, this.props.userQuery, { page });
            this.props.queryUsersAtPage(query);
          }
        }
      }
    }
    if (nextProps.userListError && nextProps.userListError !== this.props.userListError) {
      showErrorToast(nextProps.userListError.errmsg);
    }
  }
  onChangePage = (page) => {
    this.setState({ page }, () => {
      const query = Object.assign({}, this.props.userQuery, { page });
      this.props.queryUsersAtPage(query);
    });
  }

  onViewClick = (user) => {
    const uid = user && user.username;
    if (uid) {
      this.props.onViewClick(uid);
    } else {
      showErrorToast('数据错误');
    }
  }

  onEditClick = (user) => {
    const uid = user && user.username;
    if (uid) {
      this.props.onEditClick(uid);
    } else {
      showErrorToast('数据错误');
    }
  }

  onDelClick = (user) => {
    this.props.setUserToDelete(user);
  }

  onTop = (user) => {
    const { userQuery } = this.props;
    const { page } = this.state;
    const query = Object.assign({}, userQuery, { page });
    this.props.setUserToTop(user, 1, query);
  }

  onCancelTop = (user) => {
    const { userQuery } = this.props;
    const { page } = this.state;
    const query = Object.assign({}, userQuery, { page });
    this.props.setUserToTop(user, 2, query);
  }

  render() {
    const {userListing, userListResult, userListError, boxWidth, boxHeight, userQuery,
      customFields, activeNode} = this.props;
    const auth = getAuthInfo();
    const isGroundUser = auth && auth.isGroundUser || false;
    const { page } = this.state;
    let customGrade;
    let customJob;
    let customStatus;
    let customActive;
    if (customFields && customFields.length > 0) {
      customGrade = customFields.find(item => item === CUSTOM_GRADE);
      customJob = customFields.find(item => item === CUSTOM_JOB);
      customStatus = customFields.find(item => item === CUSTOM_STATUS);
      customActive = customFields.find(item => item === CUSTOM_ACTIVE);
    }
    const showInfo = userQuery.isSearchMode ? '没有查到符合条件的用户' : '该组织没有用户';
    // table props
    let width = boxWidth - 340;
    if (customGrade) {
      width = width - 80;
    }
    if (customStatus) {
      width = width - 90;
    }
    if (customActive) {
      width = width - 70;
    }
    const maxWidth = Math.floor(customJob ? (width / 5) : (width / 4));
    const opt = {
      title: '操作',
      maxWidth: 220,
      customize: true,
      ops: (item) => {
        const graduated = item.user_type === USER_GRADUATION;
        if (graduated) {
          // 毕业生
          let graduatedOps = [];
          if (isGroundUser && activeNode && parseInt(activeNode.orgType) === 1) {
            graduatedOps = [{ text: '查看', op: this.onViewClick }];
          } else {
            graduatedOps = [{ text: '查看', op: this.onViewClick }, { text: '删除', op: this.onDelClick }];
          }
          return graduatedOps;
        } else if (isGroundUser && activeNode && parseInt(activeNode.orgType) === 1) {
          const userOps = [
            { text: '编辑', op: this.onEditClick }
          ];
          const hasTopOption = item.isgray === 0; // 1 置灰 0 不置灰
          if (hasTopOption) {
            const topUser = item.istop === 1; // 1 置顶； 0取消置顶
            if (topUser) {
              userOps.unshift({ text: '置顶', op: this.onTop });
            } else {
              userOps.unshift({ text: '取消置顶', op: this.onCancelTop });
            }
          }
          return userOps;
        } else {
          // 未毕业
          const userOps = [
            { text: '编辑', op: this.onEditClick },
            { text: '删除', op: this.onDelClick }
          ];
          const hasTopOption = item.isgray === 0; // 1 置灰 0 不置灰
          if (hasTopOption) {
            const topUser = item.istop === 1; // 1 置顶； 0取消置顶
            if (topUser) {
              userOps.unshift({ text: '置顶', op: this.onTop });
            } else {
              userOps.unshift({ text: '取消置顶', op: this.onCancelTop });
            }
          }
          return userOps;
        }
      }
    };
    let tableHead = [
      {
        title: '学工号',
        field: 'student_number',
        maxWidth: maxWidth,
        textAlign: 'center'
      },
      {
        title: '姓名',
        field: 'name',
        maxWidth: maxWidth,
        textAlign: 'center'
      },
      {
        title: '性别',
        handler: (item) => {
          const sexItem = SEX_LIST.find(sex => sex.value === item.sex);
          return (sexItem && sexItem.label) || '';
        },
        maxWidth: 50,
        textAlign: 'center'
      },
      {
        title: '身份',
        handler: (item) => {
          const userType = USER_TYPE.find(user => user.value === item.identity);
          return (userType && userType.label) || '';
        },
        maxWidth: 70,
        textAlign: 'center'
      },
      {
        title: '所属组织',
        handler: (item) => getOrgPathText(item.path_name),
        maxWidth: 2 * maxWidth
      }
    ];
    if (customGrade) {
      tableHead.push({
        title: '所在年级',
        handler: (item) => item.identity === 'teacher' ? '-' : (item.sznj || '-'),
        maxWidth: 80,
        textAlign: 'center'
      });
    }
    if (customJob) {
      tableHead.push({
        title: '职务',
        handler: (item) => item.user_job || '-',
        maxWidth,
        textAlign: 'center'
      });
    }
    if (customStatus) {
      tableHead.push({
        title: '状态',
        handler: (item) => {
          const userStatus = STATUS_LIST.find(status => status.value === item.user_type);
          return (userStatus && userStatus.label) || '-';
        },
        maxWidth: 90,
        textAlign: 'center'
      });
    }
    if (customActive) {
      tableHead.push({
        title: '激活',
        handler: (item) => item.activity ? (parseInt(item.activity) === 1 ? '已激活' : '未激活') : '-',
        maxWidth: 70,
        textAlign: 'center'
      });
    }
    tableHead.push(opt);
    const listCount = userListResult && userListResult.total_count;
    const tableProps = {
      contentWidth: boxWidth + 24 - 1,
      contentHeight: boxHeight + (listCount < 20 ? 104 : 94),
      meta: tableHead,
      data: userListResult && userListResult.list,
      pageNum: page,
      pageCount: 20,
      onPageChange: this.onChangePage,
      total: listCount,
      emptyText: showInfo,
      emptyStyle: { borderWidth: 0 }
    };
    return (<div className={classes.userListView}>
      {!(userListing || userListError || userListResult) && <div>请选择加载条件.</div>}
      {userListError && <div className='text-danger'>{'数据加载失败:' + userListError.errmsg}</div>}
      <div className={classes.listContainer}>
        <Table {...tableProps} />
      </div>
      {userListing && <LoadingView />}
    </div>);
  }
}

export default connect(mapStateToProps, mapActionCreators)(UserListView);
