import { getAuthInfo } from 'helpers/AuthHelper';
import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

function checkCredit(nextState, replace) {
  checkAuth(nextState, replace);
  const auth = getAuthInfo();
  if (!auth || auth.hideCredit) {
    replace('/');
  }
}

export const route = (store) => ({
  name: '积分商店',
  path: 'credit',
  staticName: true,
  component: ModuleLayout,
  onEnter: checkCredit,
  indexRoute: { onEnter: (nextState, replace) => replace('/credit/list') },
  childRoutes: [
    {
      name: '商店列表',
      staticName: true,
      path: 'list(/:tab)',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const Component = require('./containers/Credit').default;
          const reducer = require('./modules/credit').default;
          injectReducer(store, {key: 'credit', reducer});
          callback(null, Component);
        }, getErrorCallback(callback), 'Credit');
      }
    },
    {
      name: '编辑',
      staticName: true,
      path: 'edit/:giftId',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const Component = require('./containers/ModifyCreditContainer').default;
          const reducer = require('./modules/credit').default;
          injectReducer(store, { key: 'credit', reducer });
          callback(null, Component);
        }, getErrorCallback(callback), 'Credit');
      }
    },
    {
      name: '新建',
      path: 'add',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const Component = require('./containers/ModifyCreditContainer').default;
          const reducer = require('./modules/credit').default;
          injectReducer(store, { key: 'credit', reducer });
          callback(null, Component);
        }, getErrorCallback(callback), 'Credit');
      }
    }
  ]
});

export default route;
