import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import classes from './ExpandableList.scss';

const arrayify = obj => [].concat(obj);

export class List extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expandingItem: props.activeItem
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activeItem !== this.state.expandingItem) {
      this.setState({
        expandingItem: newProps.activeItem
      });
    }
  }

  handleClick(key, hasChildren) {
    // clone active items state
    let expandingItem = this.state.expandingItem;

    if (key === expandingItem) {
      if (hasChildren) {
        expandingItem = -1;
      }
    } else {
      expandingItem = key;
    }

    if (this.props.onChange) {
      this.props.onChange(expandingItem);
    }

    if (expandingItem !== this.state.expandingItem) {
      this.setState({
        expandingItem
      });
    }
  }

  renderItems() {
    if (!this.props.children) {
      return null;
    }

    const children = arrayify(this.props.children);
    return children.map((item, index) => {
      const key = item.props.slug || index;
      const expanded = this.state.expandingItem === key;
      const active = this.props.activeItem === key;
      return React.cloneElement(item, {
        expanded,
        active,
        key,
        slug: key,
        onClick: this.handleClick.bind(this, key, item.props.arrow),
        ref: `item-${key}`
      });
    });
  }

  render() {
    return (
      <div className={classNames(classes.list, this.props.className)}
        role='tablist'
        style={this.props.style}>
        {this.renderItems()}
      </div>
    );
  }

}

List.defaultProps = {
  activeItem: -1
};

List.propTypes = {
  activeItem: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  activeOnExpand: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.element)
};

export default List;
