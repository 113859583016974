import ModuleLayout from 'layouts/ModuleLayout';
import RouteLayout from 'layouts/RouteLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '系统管理',
  path: 'system',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/system/version') }, // IndexRedirect
  childRoutes: [
    {
      name: '版本管理',
      path: 'version',
      component: RouteLayout,
      indexRoute: { onEnter: (nextState, replace) => replace('/system/version/list') },
      childRoutes: [
        {
          name: '版本列表',
          staticName: true,
          path: 'list(/:tab)',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/Version').default;
              const reducer = require('./modules/version').default;
              injectReducer(store, { key: 'version', reducer });
              callback(null, Component);
            }, getErrorCallback(callback), 'SystemManagement');
          }
        },
        {
          name: '发布新版本',
          staticName: true,
          path: 'add/:versionType',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/VersionToAddEdit').default;
              const reducer = require('./modules/version').default;
              injectReducer(store, { key: 'version', reducer });
              callback(null, Component);
            }, getErrorCallback(callback), 'SystemManagement');
          }
        },
        {
          name: '版本编辑',
          staticName: true,
          path: 'edit/:versionType/:versionId',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/VersionToAddEdit').default;
              const reducer = require('./modules/version').default;
              injectReducer(store, { key: 'version', reducer });
              callback(null, Component);
            }, getErrorCallback(callback), 'SystemManagement');
          }
        }
      ]
    },
    {
      name: 'APP包名配置',
      path: 'appName',
      indexRoute: {
        name: '包名配置列表',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/appName').default;
            injectReducer(store, { key: 'appName', reducer });
            const Component = require('./containers/AppName').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'SystemManagement');
        }
      }
    },
    {
      name: '消息推送配置',
      path: 'messagePush',
      indexRoute: {
        name: '消息推送列表',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/messagePush').default;
            injectReducer(store, { key: 'messagePush', reducer });
            const Component = require('./containers/MessagePush').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'SystemManagement');
        }
      }
    }
  ]
});

export default route;
