import PropTypes from 'prop-types';
/**
 * Created by liming on 16/5/19.
 */

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { List, Item } from 'components/ExpandableList';
import Icon from 'components/Icon';
import SubNav from './SubNav';
import { getItem } from './utils';
import classes from './LeftNavBar.scss';
import LogoIcon from './assets/logo.png';
import Police from './assets/police.png';
import { COPY_RIGHT } from 'config/copyRightInfo';
import schoolConfig from 'locals/schoolConfig';
import { FormattedMessage } from 'components/FormattedMessage';

export const Brand = () => {
  const curSchoolConfig = schoolConfig[__DOMAIN__] || {};
  const logoSrc = curSchoolConfig['menu.header.icon'] || LogoIcon;
  return (
    <div className={classes.brand}><img src={logoSrc} />
      <FormattedMessage id='menu.header' />
    </div>
  );
};

export const FeedBack = ({onClick}) => (
  <div className={classes.feedback} onClick={onClick}>
    <Icon type='feedback' /><span className={classes.content}>意见反馈</span>
  </div>
);

export const Security = ({ICPHerf, secuHerf}) => (
  <div className={classes.security}>
    <div className={classes.copyright}>{COPY_RIGHT}</div>
    <a className={classes.icpText} href={ICPHerf} target='_blank' >京ICP备13025710号-4</a>
    <div className={classes.policeContent}>
      <a className={classes.policeText} href={secuHerf} target='_blank'>京公网安备11010802023529号</a>
      <img className={classes.policeIcon} src={Police} />
    </div>
  </div>
);
FeedBack.propTypes = {
  onClick: PropTypes.func
};
Security.propTypes = {
  ICPHerf: PropTypes.func,
  secuHerf: PropTypes.func
};

const fixedHeight = 60 + 42 + 42; // 60:上部头；42:copyright;42:List marginTop值，42：List MarginBottom值 ICPlist：42

export class LeftNavBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onNavChange = this.onNavChange.bind(this);
    this.onSubNavClick = this.onSubNavClick.bind(this);
    this.state = {
      activeItem: props.activeItem
    };
  }

  componentWillReceiveProps(newProps) {
    const activeItem = newProps.activeItem;
    if (activeItem !== this.state.activeItem) {
      this.setState({
        activeItem
      });
    }
  }

  onNavChange(activeItem) {
    const menuItem = getItem(this.props.menu, activeItem);
    if (menuItem) {
      const handled = this.props.onMenuClick(menuItem);
      if (handled && this.state.activeItem !== activeItem) {
        this.setState({
          activeItem
        });
      }
      return handled;
    }
    return false;
  }

  onSubNavClick(menuItem, parentItem) {
    const handled = menuItem && this.props.onMenuClick(menuItem);
    if (handled && this.state.activeItem !== parentItem.id) {
      this.setState({
        activeItem: parentItem.id
      });
    }
    return handled;
  }

  render() {
    const listStyle = {height: this.props.contentHeight - fixedHeight};
    return (
      <div className={classes.container}>
        <Brand />
        <List className={classes.nav} onChange={this.onNavChange} activeItem={this.state.activeItem} style={listStyle}>
          {
            this.props.menu.map(item => {
              const subMenu = item.subMenu;
              let subNav;
              if (subMenu) {
                const activeItem = this.state.activeItem === item.id ? this.props.activeSubItem : -1;
                subNav = <SubNav item={item} onMenuClick={this.onSubNavClick} activeItem={activeItem} />;
              }
              return (
                <Item key={item.id} slug={item.id} title={item.title} icon={item.icon}
                  arrow={!!subMenu}>
                  {subNav}
                </Item>
              );
            })
          }
        </List>
        <FeedBack onClick={this.onFeedback} />
        <Security
          ICPHerf='http://www.beian.miit.gov.cn'
          secuHerf='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802023529'
        />
      </div>
    );
  }
}

LeftNavBar.propTypes = {
  menu: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  activeItem: PropTypes.number,
  activeSubItem: PropTypes.number,
  contentHeight: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    contentHeight: state.flexSize.contentHeight
  };
};
export default connect((mapStateToProps), {push})(LeftNavBar);
