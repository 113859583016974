import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tree } from 'antd';
import { getOrgData, setSelectedNode } from 'routes/Organization/modules/manualUpdateOrg';
import classes from './ManualUpdateOrg.scss';
import { showErrorToast } from 'helpers/toastUtils';
import { get } from 'lodash';


const OrgTree = (props) => {
  const { onTreeSelect } = props;
  const { getOrgData, justWorkOrgList, setSelectedNode } = props;
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    getOrgData(0); // 加载根节点
    setSelectedNode(undefined); // 初始化数据
  }, []);

  useEffect(() => {
    if (!justWorkOrgList || !justWorkOrgList.length) {
      return;
    }
    if (!expandedKeys.length && justWorkOrgList.length === 1) {
      setExpandedKeys([justWorkOrgList[0].path]);
    }
  }, [justWorkOrgList]);

  // Event
  const onSelect = (selectedKeys, e) => {
    const node = get(e, 'node.props.node');
    onTreeSelect(node).then(() => {
      setSelectedKeys(selectedKeys);
      setSelectedNode(node);
    });
  };
  const onLoadData = (node) => {
    const getLast = arry => [...arry].pop();
    const orgId = getLast((node.props.eventKey || '').split(','));
    if (!orgId) {
      return showErrorToast('参数错误(orgId)');
    }
    return getOrgData(orgId);
  };
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };
  // Render
  const { TreeNode, DirectoryTree } = Tree;
  const renderTreeNode = (data) => (data || []).map((item) => {
    if (!item) { return; }
    if (item.children && item.children.length) {
      return <TreeNode key={item.path} title={item.name} isLeaf={item.isLeaf} node={item}
        disabled={item.disabled} className={classes.activeNode} >
        {renderTreeNode(item.children)}
      </TreeNode>;
    }
    return <TreeNode key={item.path} title={item.name} disabled={item.disabled}
      isLeaf={item.isLeaf} node={item} className={classes.activeNode} />;
  });

  return (
    <DirectoryTree
      showIcon={false}
      onSelect={onSelect}
      loadData={onLoadData}
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      onExpand={onExpand}
    >
      {renderTreeNode(justWorkOrgList)}
    </DirectoryTree>
  );
};

OrgTree.propTypes = {
  onTreeSelect: PropTypes.func, // 树节点选中事件
  getOrgData: PropTypes.func, // 获取节点数据
  setSelectedNode: PropTypes.func, // 设置节点数据
  justWorkOrgList: PropTypes.array // 行政节点树
};

const mapActionCreators = {
  getOrgData,
  setSelectedNode
};

const mapStateToProps = (state) => ({
  justWorkOrgList: state.manualUpdateOrg.justWorkOrgList
});

export default connect(mapStateToProps, mapActionCreators)(OrgTree);
