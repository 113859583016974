/**
 * Helper for flex size calculation. Units for all dimension related numbers are in px.
 * @author liming
 * @date 16/7/5
 */
export const PAGE_MIN_WIDTH = 1200;
export const PAGE_MIN_HEIGHT = 650;
export const SIDE_BAR_WIDTH = 230;
export const CONTENT_HEADER_HEIGHT = 60;
export const CONTENT_BREADCRUMBS_HEIGHT = 38;
export const CONTENT_PADDING = 12;
export const SCROLL_BAR_WIDTH = 0;
