import PropTypes from 'prop-types';
import React from 'react';
import Header from 'containers/HeaderContainer';
import NavBar from 'containers/NavBarContainer';
import BreadCrumbs from 'components/BreadCrumbs';
import classes from './ModuleLayout.scss';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { changeFlexSize } from 'store/modules/flexSize';
import { CONTENT_PADDING } from 'helpers/FlexSizeHelper';
import { getErrorMessage } from 'helpers/ApiHelper';
import { isLoginChanged } from 'helpers/AuthHelper';
import { addStorageListener, removeStorageListener } from 'helpers/storageUtils';
import { isIE } from 'helpers/browserUtils';

export class ModuleLayout extends React.Component {
  componentDidMount() {
    !isIE && addStorageListener(this.onLoginChange); // FIXME IE下有问题
    window.onresize = this.onResize;
    this.calcFlexSize();
  }

  componentWillUnmount() {
    !isIE && removeStorageListener(this.onLoginChange);
  }

  componentWillReceiveProps(newProps) {
    const { loginExpired: { flag } } = newProps;
    if (flag && flag !== this.props.loginExpired.flag) {
      this.toLoginPage(getErrorMessage(newProps.loginExpired.code));
    }
  }

  toLoginPage = (message) => {
    const source = this.props.location;
    source.notsso = true;
    this.props.replace({
      pathname: '/login',
      state: {
        source,
        message
      }
    });
  }

  onLoginChange = (e) => {
    e.preventDefault();
    if (isLoginChanged(e)) {
      this.toLoginPage('');
    }
  }

  onResize = () => {
    this.calcFlexSize();
  }

  calcFlexSize = () => {
    const {innerWidth, innerHeight} = window;
    this.props.changeFlexSize(innerWidth, innerHeight);
  }

  render() {
    const { flexWidth, flexHeight, contentWidth, contentHeight } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.content} style={{ width: flexWidth, height: flexHeight }}>
          <div className={classes.sidebar}>
            <NavBar location={this.props.location} />
          </div>
          <div className={classes.child} style={{ width: contentWidth + 2 * CONTENT_PADDING }}>
            <Header />
            <BreadCrumbs routes={this.props.routes} params={this.props.params} />
            <div className={classes.realContent} style={{ height: contentHeight + CONTENT_PADDING }}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModuleLayout.propTypes = {
  children: PropTypes.element.isRequired,
  loginExpired: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  routes: PropTypes.array,
  params: PropTypes.object,
  changeFlexSize: PropTypes.func,
  flexWidth: PropTypes.number,
  flexHeight: PropTypes.number,
  contentWidth: PropTypes.number,
  contentHeight: PropTypes.number
};

const mapStateToProps = (state) => ({
  loginExpired: state.relogin,
  flexWidth: state.flexSize.flexWidth,
  flexHeight: state.flexSize.flexHeight,
  contentWidth: state.flexSize.contentWidth,
  contentHeight: state.flexSize.contentHeight
});

const mapActionCreators = {
  changeFlexSize,
  replace
};


export default connect(mapStateToProps, mapActionCreators)(ModuleLayout);
