import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/Icon';
import classes from './ClearSearch.scss';

export class ClearSearch extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchFlag: false
    };
  }

  setQuery = (query) => {
    if (query !== this.state.searchText) {
      this.setState({
        searchText: query
      });
    }
  }

  componentDidMount() {
    const {value} = this.props;
    if (value) {
      this.setQuery(value);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.forceClear) { // 强制清空，非常设机构/岗位/标签 需要，add by xjw
      this.setQuery(newProps.value);
    }
    // 当外部调用该组件，手动清除文本框值，但未进行搜素，保留原输入值
    if (newProps.value !== this.props.value) {
      this.setQuery(newProps.value);
    }
  }

  autoSearch = (e) => {
    var theEvent = e || window.event;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    if (code === 13) {
      this.props.onSearchHandler(this.state.searchText);
    }
  }

  inputHandleChange = (e) => {
    e.preventDefault();
    this.setState({
      searchText: e.target.value
    });
    if (this.props.onClearChange) {
      this.props.onClearChange(e.target.value);
    }
  }

  clearHandler = (e) => {
    e.preventDefault();
    this.setState({
      searchText: ''
    });
    this.props.onClearHandler();
  }

  searchHandler = () => {
    this.setState({
      searchFlag: true
    });
    this.props.onSearchHandler(this.state.searchText);
  }

  render() {
    const { disabled, maxLength, placeholder } = this.props;
    const width = this.props.width || '297';
    const { searchText } = this.state;
    const showCross = searchText.length > 0;
    return (
      <span className={classes.searchcontainer} style={{ width: width + 'px' }}>
        <div className='form-group' style={{ marginBottom: '0px' }}>
          <input className='form-control'
            type='text'
            disabled={disabled}
            value={searchText}
            maxLength={maxLength}
            placeholder={placeholder || '请输入'}
            onChange={this.inputHandleChange}
            onKeyDown={this.autoSearch}
            style={{ height: '32px', paddingLeft: '5px', paddingRight: '76px', width: width, marginBottom: '0px' }}
          />
        </div>
        {
          showCross ? <Icon type='cross' className={classes.searchcross} style={this.props.crossStyle}
            onClick={this.clearHandler} /> : null
        }
        <Icon type='search' className={classes.searchicon} style={this.props.searchStyle}
          onClick={this.searchHandler} />
      </span>
    );
  }
}

ClearSearch.propTypes = {
  width: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSearchHandler: PropTypes.func,
  onClearHandler: PropTypes.func,
  searchStyle: PropTypes.object,
  crossStyle: PropTypes.object,
  onClearChange: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number
};

export default ClearSearch;
