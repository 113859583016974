/**
 * ACL(Access Control List)
 */
import { USER_TYPE_SUBADMIN, ACCOUNT_TYPE_ASSISTANT } from 'helpers/AuthHelper';
import { getAuthMenu } from 'config/menu';

/**
 * 助理账号ACL黑名单（为简化匹配规则，暂时只支持一级路由）
 */
const ACL_ASSISTANT_DENY = [
  'account'
];

/**
 * 检查访问权限
 * @param userType 用户类型
 * @param accountType 账号类型
 * @param path 路由
 * @returns true if given path falls in the ACL of given account type.
 */
export function checkAcl(userType, accountType, path) {
  const aclSubAllow = ['main', 'logs', 'auditlog'];
  if (userType === USER_TYPE_SUBADMIN) {
    const menu = getAuthMenu();
    if (menu && menu.length > 0) {
      menu.map(item => {
        const subMenu = item.subMenu || [];
        if (subMenu && subMenu.length > 0) {
          subMenu.map(sub => aclSubAllow.push(sub.target));
        }
      });
    }
  }
  const within = item => path.includes(item);
  let allow;
  switch (userType) {
    case USER_TYPE_SUBADMIN:
      allow = aclSubAllow.some(within);
      break;
    default:
      allow = true;
      break;
  }
  if (allow && accountType === ACCOUNT_TYPE_ASSISTANT) { // 助理账号需检查路径是否在限制访问列表中
    allow = !ACL_ASSISTANT_DENY.some(within);
  }
  return allow;
};
