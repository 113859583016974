import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/12.
 */
import React from 'react';
import Dialog from 'components/Dialog';
import IconHeader from 'components/IconHeader';
import Note from 'components/Note';
import FilePicker from 'components/FilePicker';
import ImportLoadingView from './ImportLoadingView';
import ImportResultView from './ImportResultView';
import ImportContrastView from './ImportContrastView';
import { parseExcelFile } from './fileUtils';
import { loginExpired } from 'helpers/ApiHelper';
import { showErrorToast } from 'helpers/toastUtils';
import { relogin } from 'store/modules/reloginService';
import { connect } from 'react-redux';
import classes from './ExcelImportView.scss';

const TEMPLATE_ERROR = '表格内字段与模板不一致，请检查后重新上传';

export class ExcelImportView extends React.Component {
  constructor(props) {
    super(props);
    this.onCancelDialog = this.onCancelDialog.bind(this);
    this.onConfirmDialog = this.onConfirmDialog.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.submitImport = this.submitImport.bind(this);
    this.state = {
      excelName: '',
      excelParsing: false,
      importEnabled: false,
      showDialog: false,
      showContrastDialog: false,
      contrastData: [],
      contrastSuccessCount: 0,
      contrastFailCount: 0,
      contrastErrorFile: '',
      importLoading: false,
      importResult: {},
      showImportAllTips: false
    };
    this.excelFile = void 0;
    this.errorFileUrl = void 0;
  }

  componentWillUnmount() {
    this.excelFile = void 0;
    this.errorFileUrl = void 0;
  }

  componentWillReceiveProps(newProps) {
    const { importMsg, importErrmsg } = newProps;
    if (importErrmsg && importErrmsg !== this.props.importErrmsg) {
      this.setState({
        importLoading: false
      });
      showErrorToast('文件上传失败，请重新尝试。');
    } else {
      if (importMsg && importMsg !== this.props.importMsg) {
        const {ret, errcode} = importMsg;
        if (ret !== 0 || errcode !== 0) {
          this.setState({
            importLoading: false
          });
          if (loginExpired(errcode)) {
            this.props.relogin({
              flag: true,
              code: errcode
            });
          } else {
            showErrorToast(importMsg.errmsg || '导入失败，请重试！');
          }
        } else {
          const result = importMsg.data;
          if (result.flag === 'diff_count') {
            const diffArray = result.data;
            this.setState({
              importLoading: false,
              contrastData: diffArray,
              contrastSuccessCount: result.success_count,
              contrastFailCount: result.fail_count,
              contrastErrorFile: result.error_file
            }, this.showContrastDialog);
          } else {
            this.setState({
              importLoading: false,
              importResult: result,
              showDialog: true
            });
          }
          this.errorFileUrl = result.fail_count > 0 && result.error_file;
          if (this.props.onFinish) {
            this.props.onFinish();
          }
        }
      }
    }
  }

  showContrastDialog = () => {
    this.setState({
      showContrastDialog: true
    });
  }

  onCancelDialog() {
    this.setState({
      showDialog: false
    });
  }

  submitImport() {
    const { allTips } = this.props;
    if (allTips) {
      this.setState({showImportAllTips: true});
    } else {
      if (this.excelFile.size === 0) {
        showErrorToast('文件不存在，请重新选择');
      } else {
        this.setState({importLoading: true});
        this.props.submit(this.excelFile, this.shaCode);
      }
    }
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({
        excelName: files[0].name,
        excelParsing: true,
        importEnabled: false
      });
      this.excelFile = files[0];
      parseExcelFile(this.excelFile)
        .then(result => {
          let valid = false;
          const header = [];
          for (let index in result.parsedResult) {
            let item = result.parsedResult[index];
            if (item) {
              item = item.trim();
              if (item.length > 0 && item !== 'undefined') {
                header.push(item);
              }
            }
          }
          if (this.props.templates.join(',') === header.join(',')) {
            valid = true;
          } else {
            showErrorToast(TEMPLATE_ERROR);
            this.excelFile = void 0;
            this.setState({
              excelName: ''
            });
          }
          this.setState({
            importEnabled: valid,
            excelParsing: false
          });
        })
        .catch(err => {
          showErrorToast(err.errmsg);
          this.excelFile = void 0;
          this.setState({
            excelName: '',
            importEnabled: false,
            excelParsing: false
          });
        });
    }
  }

  onDropRejected() {
    showErrorToast('请选择Excel格式（.xls或.xlsx）的模板文件！');
  }

  onConfirmDialog() {
    if (this.errorFileUrl) {
      document.location.href = this.errorFileUrl;
    }
    this.setState({
      showDialog: false
    });
  }

  onClickDownload() {
    document.location.href = this.props.templateFileUrl;
  }

  importResultSubmit = (data) => {
    const { contrastSuccessCount, contrastFailCount, contrastErrorFile } = this.state;
    this.setState({showContrastDialog: false});
    this.props.importResultSubmit(contrastSuccessCount, contrastFailCount, contrastErrorFile, data);
  }

  importAllSubmit = () => {
    if (this.excelFile.size === 0) {
      this.setState({
        showImportAllTips: false
      });
      showErrorToast('文件不存在，请重新选择');
    } else {
      this.setState({
        showImportAllTips: false,
        importLoading: true
      });
      this.props.submit(this.excelFile, this.shaCode);
    }
  }

  importAllCancel = () => {
    this.setState({showImportAllTips: false});
  }

  render() {
    const { contentHeight, excelHeight, name, btnName, tips, importResultFlag, stepDesc } = this.props;
    const { importResult } = this.state;
    const height = excelHeight || contentHeight;
    const resultBtnText = importResult.fail_count > 0 ? '下载错误信息' : '';
    const noteStyle = { margin: '0 20px' };
    return (
      <div className='container content-container' style={{ minHeight: height }}>
        <ImportContrastView show={this.state.showContrastDialog} title='提示'
          contrastData={this.state.contrastData} importResultSubmit={this.importResultSubmit}
          importResultFlag={importResultFlag} />
        <Dialog title='提示' show={this.state.showImportAllTips}
          positiveButton={{onClick: this.importAllSubmit}}
          negativeButton={{onClick: this.importAllCancel}}>
          <div style={{marginTop: '40px'}}>
            本次操作以新文档的数据为准，将替换本岗位下原有的全部数据，确定要继续操作吗？
          </div>
        </Dialog>
        {/* 结果弹窗 */}
        <Dialog show={this.state.showDialog} onHide={this.onCancelDialog}
          title='提示' positiveButton={{ onClick: this.onConfirmDialog, text: resultBtnText || '确定' }}>
          <ImportResultView data={this.state.importResult} />
        </Dialog>
        {
          this.state.importLoading && [
            (<div className={classes.loadingBackdrop} style={height && { minHeight: height }} />),
            (<ImportLoadingView tips={this.props.loadingTips} />)
          ]
        }
        {name && <IconHeader title={name} iconClassName='userimport' />}
        <form className='form-horizontal'>
          <div className={'form-group ' + classes.group}>
            <Note style={noteStyle} text='步骤说明：' />
          </div>
          {stepDesc || <div className={'form-group ' + classes.group}>
            <span className={classes.explainSpan}>1. 请先下载导入模板</span>
            <button type='button' className={'btn btn-primary wsbtn-large ' + classes.btn}
              onClick={this.onClickDownload}>
              下载模板
            </button>
            <br />
            <span className={classes.explainSpan}>
              2. 打开已下载的文件，根据下面书写规范的规则输入或粘贴数据，为保障粘贴信息被有效导入，请使用纯文本或数字。
            </span>
            <br />
            <span className={classes.explainSpan}>
              3. 信息输入完毕，点击“选择文件”按钮，选择excel文档，上传导入文件，点击“导入”按钮开始导入数据。
            </span>
            <br />
          </div>}
          <div className={'form-group ' + classes.group}>
            <Note style={noteStyle} text='上传文件' />
          </div>
          <div className={'form-group ' + classes.group}>
            <div className='col-sm-12'>
              <FilePicker
                accept='.xls,.xlsx'
                filename={this.state.excelName}
                disabled={this.state.excelParsing}
                onPick={this.onDrop}
                onReject={this.onDropRejected}
              />
            </div>
          </div>
          <div className={'form-group ' + classes.group}>
            <div className={'col-md-6 ' + classes.impBtnDiv}>
              <button
                type='button'
                className='btn btn-primary wsbtn-large'
                disabled={!this.state.importEnabled}
                onClick={this.submitImport}>
                {this.state.excelParsing ? '读取中' : btnName || '导入'}
              </button>
            </div>
          </div>
          <div className={'form-group ' + classes.group}>
            <Note style={noteStyle} text='注意事项：' />
          </div>
          <div className={'form-group ' + classes.group}>
            {
              tips.map((item, index) => {
                const key = 'tip-' + index;
                if (item && item.length > 0) {
                  return <span key={key} className={classes.explainSpan}>{item}</span>;
                } else {
                  return <br key={key} />;
                }
              })
            }
          </div>
        </form>
      </div>
    );
  }
}

const propTypes = {
  contentHeight: PropTypes.number.isRequired,
  relogin: PropTypes.func.isRequired
};

ExcelImportView.propTypes = {
  ...propTypes,
  name: PropTypes.string.isRequired,
  btnName: PropTypes.string.isRequired,
  tips: PropTypes.arrayOf(PropTypes.string).isRequired,
  templates: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateFileUrl: PropTypes.string.isRequired,
  loadingTips: PropTypes.string,
  submit: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  importMsg: PropTypes.object,
  importErrmsg: PropTypes.object,
  allTips: PropTypes.bool,
  excelHeight: PropTypes.number,
  importResultFlag: PropTypes.string
};

const mapStateToProps = (state) => ({
  contentHeight: state.flexSize.contentHeight
});

const actionsToMap = {
  relogin
};

export default connect(mapStateToProps, actionsToMap)(ExcelImportView);
