import CoreLayout from 'layouts/CoreLayout';
import NotFound from 'components/NotFound';
import Main from './Main';
import Login from './Login';
import Account from './Account';
import AppStore from './AppStore';
import Customize from './Customize';
import User from './Organization';
import Notice from './Notice';
import Permission from './AuthManage';
import ChatGroup from './ChatGroupManage';
import Statistics from './Statistics';
import Logs from './OperationLog';
import Feedback from './Feedback';
import Announce from './Announcement';
import ThirdParty from './ThirdParty';
import Credit from './Credit';
import SystemManagement from './SystemManagement';
import SmartQa from './SmartQa';
import PortalSet from './PortalSet';
import General from './General';
import AuditLog from './AuditLog';
import Oauth from './Oauth';

export default (store) => (
  {
    name: 'App',
    path: '/',
    component: CoreLayout,
    indexRoute: { onEnter: (nextState, replace) => replace('/main') }, // IndexRedirect
    childRoutes: [
      Main(store),
      Login(store),
      Account(store),
      Statistics(store),
      Customize(store),
      AppStore(store),
      User(store),
      Notice(store),
      Permission(store),
      ChatGroup(store),
      Logs(store),
      Feedback(store),
      Announce(store),
      ThirdParty(store),
      Credit(store),
      SystemManagement(store),
      SmartQa(store),
      PortalSet(store),
      General(store),
      AuditLog(store),
      Oauth(store),
      { path: '*', component: NotFound }
    ]
  }
);
