import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '意见反馈',
  path: 'feedback',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: {
    name: '意见反馈',
    getComponent(nextState, callback) {
      require.ensure([], (require) => {
        const Component = require('./containers/FeedbackView').default;
        const reducer = require('./modules/feedback').default;
        injectReducer(store, { key: 'feedback', reducer });
        callback(null, Component);
      }, getErrorCallback(callback), 'Feedback');
    }
  }
});

export default route;
