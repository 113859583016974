module.exports = {

  // colors

  color_primary: '#0dac67',
  color_warning: '#f38686',
  color_danger: '#ec4545',
  color_disabled: '#bfbfbf',

  color_border: '#ccc',

  color_titlebg: '#f6f6f6',

  color_navbg_default: '#3d403f',
  color_navbg_info: '#282c2b',

  color_text_default: '#464646',
  color_text_info: '#737373',

  color_input_disabled: '#eee', // input disable时背景颜色

  color_title: '#333',

  // sizes

  // Fonts
  font_default: '14px',
  font_large: '16px',
  font_small: '12px',

  font_title: '16px',

  // sizes

  space_base: '16px',
  space_small: '8px',

  border_radius: '4px',

  // Flex sizes
  flex_min_width: '1200px',
  flex_min_height: '650px',
  sidebar_width: '230px',
  header_height: '60px',
  content_pad: '12px',
  breadcrumb_height: '38px',

  // Buttons
  wsbtn_height: '32px',
  wsbtn_width_s: '32px',
  wsbtn_width_m: '72px',
  wsbtn_width_l: '144px'
};
