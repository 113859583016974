import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '智能问答',
  path: 'smartqa',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/smartqa/library') }, // IndexRedirect
  childRoutes: [
    {
      name: '知识库',
      path: 'library',
      indexRoute: {
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'smartqa', reducer });
            const Component = require('./containers/Library').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'SmartQa');
        }
      },
      childRoutes: [
        {
          name: '添加知识',
          path: 'add(/:id/:name/:clusterType)',
          staticName: true,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'smartqa', reducer });
              const Component = require('./containers/QuestionForm').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'SmartQa');
          }
        },
        {
          name: '编辑知识',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'smartqa', reducer });
              const Component = require('./containers/QuestionForm').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'SmartQa');
          }
        },
        {
          name: '导入知识',
          path: 'import',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'smartqa', reducer });
              const Component = require('./containers/ImportQuestions').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'SmartQa');
          }
        },
        {
          name: '搜索结果',
          path: 'search',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'smartqa', reducer });
              const Component = require('./containers/Library').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'SmartQa');
          }
        }
      ]
    },
    {
      name: '知识学习',
      path: 'learning',
      indexRoute: {
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'smartqa', reducer });
            const Component = require('./containers/Learning').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'SmartQa');
        }
      },
      childRoutes: [
        {
          name: '合并问题',
          path: 'clustering/:clusterType',
          staticName: true,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'smartqa', reducer });
              const Component = require('./containers/Clustering').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'SmartQa');
          }
        }
      ]
    },
    {
      name: '数据看板',
      path: 'statistics',
      indexRoute: {
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'smartqa', reducer });
            const Component = require('./containers/QuAnStatistics').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'SmartQa');
        }
      },
      childRoutes: [
        {
          name: '更多数据',
          path: 'moreData',
          staticName: true,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'smartqa', reducer });
              const Component = require('./containers/MoreData').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'SmartQa');
          }
        }
      ]
    },
    {
      name: '机器人设置',
      path: 'setting',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'smartqa', reducer });
          const Component = require('./containers/Setting').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'SmartQa');
      }
    }
  ]
});

export default route;
