import React from 'react';
import PropTypes from 'prop-types';
import classes from './CheckBox.scss';
import classNames from 'classnames';

export const CheckBox = ({ checked, className, onClick }) => {
  const icon = checked ? 'wsicon-box-sel' : 'wsicon-box';
  const checkedClass = checked ? classes.cb : classes.checkedCb;
  return <span
    className={classNames('wsicon', icon, checkedClass, className)}
    onClick={onClick || null}
  />;
};

CheckBox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

export default CheckBox;
