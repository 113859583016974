import PropTypes from 'prop-types';
import React from 'react';
import Breadcrumbs from 'react-breadcrumbs';
import classes from './BreadCrumbs.scss';

export const BreadCrumbs = ({ routes, params, isLogin }) => {
  let newParams = {};
  if (params) {
    Object.keys(params).forEach(item => {
      newParams[item] = encodeURIComponent(params[item]);
    });
  }
  return <div className={classes.container}>
    <Breadcrumbs routes={routes} params={newParams} setDocumentTitle excludes={['App']} />
  </div>;
};

BreadCrumbs.propTypes = {
  routes: PropTypes.array,
  params: PropTypes.object,
  isLogin: PropTypes.bool
};

export default BreadCrumbs;
