import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/15.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './OrganizationNodeChooserView.scss';
import {loadOrgRoot, loadSecondOrgRoot, addUserOrgRoot} from 'routes/Organization/modules/organization';
import SelectableTreeNode from './SelectableTreeNode';
import onClickOutside from 'react-onclickoutside';
import LoadingView from 'components/LoadingView/LoadingView';
import {getAuthInfo, USER_TYPE_SUBADMIN} from 'helpers/AuthHelper';
import {MULTI_ORG, ADMIN_USER, MULTI_USER} from 'helpers/strings';

const mapActionCreators = {
  loadOrgRoot,
  loadSecondOrgRoot,
  addUserOrgRoot
};

const mapStateToProps = (state) => ({
  rootLoaded: state.organization.rootLoaded,
  rootError: state.organization.rootError,
  organizationRoot: state.organization.organizationRoot,
  nodeLoading: state.organization.nodeLoading,
  rooting: state.organization.rooting,
  multiOrgRootResult: state.organization.multiOrgRootResult,
  addUserOrgRootResult: state.organization.addUserOrgRootResult
});
class OrganizationNodeChooserView extends Component {
  static propTypes = {
    rooting: PropTypes.bool,
    rootError: PropTypes.object,
    rootLoaded: PropTypes.bool,
    nodeLoading: PropTypes.bool,
    show: PropTypes.bool,
    expandRoot: PropTypes.bool,
    boxWidth: PropTypes.number,
    organizationRoot: PropTypes.object,
    multiOrgRootResult: PropTypes.object,
    handlePanelClose: PropTypes.func,
    selectNodeCallback: PropTypes.func,
    presetSelectedNode: PropTypes.object,
    loadOrgRoot: PropTypes.func,
    loadSecondOrgRoot: PropTypes.func,
    style: PropTypes.object, // 自定义style
    fromFlag: PropTypes.string,
    addUserOrgRoot: PropTypes.func,
    addUserOrgRootResult: PropTypes.object,
    supportClickOutside: PropTypes.bool
  };

  componentWillMount = () => {
    const authInfo = getAuthInfo();
    const forSecond = authInfo && authInfo.userType === USER_TYPE_SUBADMIN;

    if (!this.props.rootLoaded) {
      if (!forSecond) {
        this.props.loadOrgRoot();
      } else {
        this.props.loadSecondOrgRoot();
      }
    }
    this.selectedNode = this.props.presetSelectedNode;
    if (authInfo && this.props.expandRoot) {
      this.openedNodeList.push(authInfo.rootOrgId); // 根节点的id
    }
  }

  componentDidMount() {
    const {fromFlag, addUserOrgRoot, organizationRoot} = this.props;
    if (fromFlag && organizationRoot) {
      addUserOrgRoot(fromFlag);
    }
  }

  componentWillReceiveProps(newProps) {
    const {organizationRoot} = newProps;
    if (organizationRoot && organizationRoot !== this.props.organizationRoot) {
      this.props.addUserOrgRoot(this.props.fromFlag);
    }
  }

  onSelectNode = (node) => {
    this.selectedNode = node;
    if (this.props.selectNodeCallback) {
      this.props.selectNodeCallback(node);
    }
    this.handleClickOutside();
  }

  // 通过外面的参数控制是否触发 outside 点击事件
  handleClickOutside = () => {
    const { supportClickOutside } = this.props;
    if (!supportClickOutside) {
      return;
    }
    this.props.handlePanelClose();
  }

  selectedNode = null; // 记录已经被选中的节点
  openedNodeList = []; // 记录了被展开的节点id

  render() {
    const {rooting, rootError, rootLoaded, show, organizationRoot, nodeLoading, boxWidth,
      style, fromFlag, multiOrgRootResult, addUserOrgRootResult} = this.props;
    const rootNode = fromFlag && (fromFlag === MULTI_ORG ? multiOrgRootResult
      : fromFlag === ADMIN_USER ? addUserOrgRootResult : fromFlag === MULTI_USER ? addUserOrgRootResult
        : organizationRoot) || organizationRoot;
    const entryWidth = boxWidth ? (boxWidth - 20) : 298;
    const showClass = show ? '' : 'hidden';
    return (
      <div className={classes.organizationNodeChooserView + ' ' + showClass} style={style}>
        <div className={classes.treeContainer} style={{width: entryWidth}}>
          {
            rootLoaded && <SelectableTreeNode showFocus
              node={rootNode} key={rootNode.uuid} activeNode={this.selectedNode}
              onClickCallback={this.onSelectNode} openedNodeList={this.openedNodeList} width={entryWidth}
              height={22} levelOffset={10} />
          }
          {rooting && <div>组织结构树加载中…</div>}
          {rootError && <div className='text-danger'>组织结构树请求失败!</div>}
          {nodeLoading && <LoadingView />}
        </div>
      </div>
    );
  }

}

OrganizationNodeChooserView.defaultProps = {
  supportClickOutside: true // 是否支持 outside 点击事件，默认支持
};

export default connect(mapStateToProps, mapActionCreators)(onClickOutside(OrganizationNodeChooserView));
