import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '认证',
  path: 'oauthCode',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const reducer = require('./modules/oauth').default;
      injectReducer(store, { key: 'oauth', reducer });
      const Component = require('./containers/OauthContainer').default;
      callback(null, Component);
    }, getErrorCallback(callback), 'Oauth');
  }
});

export default route;
