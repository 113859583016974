/**
 * Created by liming on 16/5/19.
 */
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Dialog from 'components/Dialog';
import IconHeader from 'components/IconHeader';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import classes from './OrganizationHome.scss';
import NewOrEditTreeNodeView from '../components/NewOrEditTreeNodeView';
import BatchToppingView from '../components/BatchTopping';
import UserListView from '../components/UserListView';
import * as organizationActions from '../modules/organization';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';
import QueryOperation from 'components/QueryOperation';
import Select from 'react-select';
import { MenuItem, Dropdown } from 'react-bootstrap';
import MultiOrganization from '../components/MultiOrganization';
import MultiOrgUserForm from '../components/MultiOrgUserForm';
import { STUDENT_STATUS_LIST, TEACHER_STATUS_LIST, USER_TYPE, SEX_LIST } from '../components/UserAddEditForm/data';
import SelectInput from 'components/SelectInput';
import { jobList } from '../modules/job';
import CustomListDialog from '../components/CustomListDialog';
import { getUserQuery, setUserQueryData, getAuthInfo } from 'helpers/AuthHelper';
import { CUSTOM_GRADE, CUSTOM_JOB, CUSTOM_STATUS, CUSTOM_ACTIVE, GRADE, ACTIVE_OPTIONS } from '../strings/strings';
import classNames from 'classnames';
export const POST_USER_SEARCH_TYPE = '1';
export const POST_PHONE_SEARCH_TYPE = '2';
export const POST_NUMBER_TYPE = '3';

const SEARCH_TYPE_LIST = [
  { label: '按人员搜索', value: POST_USER_SEARCH_TYPE },
  { label: '按联系电话搜索', value: POST_PHONE_SEARCH_TYPE },
  { label: '按证件号码搜索', value: POST_NUMBER_TYPE }
];
const STATUS_LIST = [
  { label: '全部', value: '' },
  ...STUDENT_STATUS_LIST,
  ...TEACHER_STATUS_LIST
];

const USER_TYPE_LIST = [
  { label: '全部', value: '' },
  ...USER_TYPE
];

const SEX = [
  { label: '全部', value: '' },
  ...SEX_LIST
];

const mapActionCreators = {
  ...organizationActions,
  push,
  jobList // 获取职务列表
};

const mapStateToProps = (state) => ({
  rootLoaded: state.organization.rootLoaded,
  userToDisable: state.organization.userToDisable,
  userToDelete: state.organization.userToDelete,
  userToEnable: state.organization.userToEnable,
  userToEdit: state.organization.userToEdit,
  nodeToEdit: state.organization.nodeToEdit,
  nodeToDelete: state.organization.nodeToDelete,
  activeNode: state.organization.activeNode,
  organizationRoot: state.organization.organizationRoot,
  newNodeParentPreset: state.organization.newNodeParentPreset,
  nodeCreating: state.organization.nodeCreating,
  nodeCreated: state.organization.nodeCreated,
  nodeCreateError: state.organization.nodeCreateError,
  nodeDeleting: state.organization.nodeDeleting,
  nodeDeleteError: state.organization.nodeDeleteError,
  nodeDeleted: state.organization.nodeDeleted,
  nodeEdited: state.organization.nodeEdited,
  userListing: state.organization.userListing,
  userQuery: state.organization.userQuery,
  userListResult: state.organization.userListResult,
  userListError: state.organization.userListError,
  setUserStatusError: state.organization.setUserStatusError,
  deleteUserLoading: state.organization.deleteUserLoading,
  deleteUserError: state.organization.deleteUserError,
  deleteUserLoaded: state.organization.deleteUserLoaded,
  setUserStatusLoaded: state.organization.setUserStatusLoaded,
  setUserStatusLoading: state.organization.setUserStatusLoading,
  markGraduationSuccess: state.organization.markGraduationSuccess,
  markGraduationFail: state.organization.markGraduationFail,
  multiOrgLoading: state.organization.multiOrgLoading,
  multiOrgLoaded: state.organization.multiOrgLoaded,
  multiOrgError: state.organization.multiOrgError,
  multiOrgResult: state.organization.multiOrgResult,
  nodeLoading: state.organization.nodeLoading,
  nodeOrdering: state.organization.nodeOrdering,
  multiOrgAddLoading: state.organization.multiOrgAddLoading,
  multiOrgAddLoaded: state.organization.multiOrgAddLoaded,
  multiOrgAddError: state.organization.multiOrgAddError,
  multiOrgAddResult: state.organization.multiOrgAddResult,
  activeIndex: state.organization.activeIndex,
  optFlag: state.organization.optFlag,
  contentWidth: state.flexSize.contentWidth,
  contentHeight: state.flexSize.contentHeight,
  // 职务列表
  jobListLoading: state.job.jobListLoading,
  jobListLoaded: state.job.jobListLoaded,
  jobListError: state.job.jobListError,
  jobListResult: state.job.jobListResult,
  // 人员批量置顶
  batchTopNode: state.organization.batchTopNode
});

class OrganizationHome extends Component {
  static propTypes = {
    rootLoaded: PropTypes.bool,
    loadOrgRoot: PropTypes.func,
    push: PropTypes.func,
    createNewOrgNode: PropTypes.func,
    deleteNode: PropTypes.func,
    nodeDeleting: PropTypes.bool,
    nodeDeleted: PropTypes.bool,
    nodeDeleteError: PropTypes.object,
    editNode: PropTypes.func,
    nodeCreated: PropTypes.bool,
    nodeEdited: PropTypes.bool,
    userToDisable: PropTypes.object,
    disableUser: PropTypes.func,
    enableUser: PropTypes.func,
    deleteUser: PropTypes.func,
    clearUserToDisable: PropTypes.func,
    userToDelete: PropTypes.object,
    clearUserToDelete: PropTypes.func,
    userToEnable: PropTypes.object,
    clearUserToEnable: PropTypes.func,
    userToEdit: PropTypes.object,
    clearUserToEdit: PropTypes.func,
    newNodeParentPreset: PropTypes.object,
    nodeToDelete: PropTypes.object,
    nodeToEdit: PropTypes.object,
    clearNodeToDelete: PropTypes.func,
    clearNodeToEdit: PropTypes.func,
    clearNewNodeParent: PropTypes.func,
    queryUsers: PropTypes.func,
    queryUserBySearch: PropTypes.func,
    setActiveNode: PropTypes.func,
    clearOrgRoot: PropTypes.func,
    clearActiveNode: PropTypes.func,
    activeNode: PropTypes.object,
    organizationRoot: PropTypes.object,
    userListing: PropTypes.bool,
    userListResult: PropTypes.object,
    userListError: PropTypes.object,
    userQuery: PropTypes.object,
    setUserStatus: PropTypes.func,
    deleteUserLoaded: PropTypes.bool,
    deleteUserLoading: PropTypes.bool,
    setUserStatusLoaded: PropTypes.bool,
    setUserStatusLoading: PropTypes.bool,
    markGraduationSuccess: PropTypes.bool,
    markGraduationFail: PropTypes.object,
    multiOrgLoading: PropTypes.bool,
    multiOrgLoaded: PropTypes.bool,
    multiOrgError: PropTypes.string,
    multiOrgResult: PropTypes.array,
    multiOrgAddLoading: PropTypes.bool,
    multiOrgAddLoaded: PropTypes.bool,
    multiOrgAddError: PropTypes.string,
    nodeLoading: PropTypes.bool,
    nodeOrdering: PropTypes.bool,
    contentWidth: PropTypes.number,
    contentHeight: PropTypes.number,
    jobListResult: PropTypes.array,
    jobListLoading: PropTypes.bool,
    jobListLoaded: PropTypes.bool,
    jobList: PropTypes.func,
    setUserStatusError: PropTypes.object,
    deleteUserError: PropTypes.object,
    optFlag: PropTypes.bool,
    setOptFlag: PropTypes.func,
    clearOpenedList: PropTypes.func,
    nodeCreateError: PropTypes.string,
    loadOrgNode: PropTypes.func,
    activeIndex: PropTypes.string,
    nodeCreating: PropTypes.bool,
    multiOrgRoot: PropTypes.func,
    batchTopNode: PropTypes.string
  }

  state = {
    showUserDialog: false, // 用户添加、编辑
    showNewTreeNodeDialog: false,
    showEditTreeNodeDialog: false,
    showDeleteTreeNodeDialog: false,
    showDisableUserDialog: false,
    showEnableUserDialog: false,
    showDeleteUserDialog: false,
    userDialogTitle: undefined,
    searchContent: '', // 搜索框中输入名字或学工号
    queryActive: '',
    queryGrade: '',
    querySex: '',
    queryRole: '',
    queryStatus: '',
    queryJob: '',
    personCountWidth: '',
    showMultiUserDialog: false,
    isClearOrgRoot: true,
    customFields: [],
    isSearch: '',
    searchType: '1'
  }

  componentDidMount = () => {
    const { optFlag, userQuery, loadOrgRoot, jobList, queryUsers } = this.props;
    const customQuery = getUserQuery(); // 获取当前自定义筛选框
    if (customQuery && customQuery.length) {
      this.setState({ customFields: customQuery });
    }
    if (!optFlag) {
      loadOrgRoot();
    } else {
      if (userQuery) {
        const queryStatus = userQuery.status_s;
        const queryJob = userQuery.job;
        const queryActive = userQuery.activity;
        const queryGrade = userQuery.sznj;
        this.setState({
          querySex: userQuery.sex || '',
          queryRole: userQuery.identity || '',
          queryStatus: queryStatus ? (queryStatus === 'all' ? '' : queryStatus) : '',
          queryJob: queryJob ? (queryJob === 'all' ? '' : queryJob) : '',
          queryActive: queryActive ? (queryActive === 'all' ? '' : queryActive) : '',
          queryGrade: queryGrade ? (queryGrade === 'all' ? '' : queryGrade) : ''
        });
        queryUsers(userQuery);
      }
    }
    jobList();
  }

  componentWillUnmount = () => {
    if (this.state.isClearOrgRoot) {
      this.props.clearOrgRoot(); // 页面销毁时，重置rootloaded等状态位，重新进入时加载根节点数据
    }
    this.clearSearch = void 0;
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.userToDisable && !this.props.userToDisable) { // 申请禁用某用户,显示弹窗
      this.setState({ showDisableUserDialog: true });
    }
    if (nextProps.setUserStatusError && !this.props.setUserStatusError) {
      showErrorToast('修改失败：' + nextProps.setUserStatusError.errmsg);
    }
    if (!nextProps.userToDisable && this.props.userToDisable) { // 关闭弹窗
      this.setState({ showDisableUserDialog: false });
    }
    if (nextProps.userToDelete && !this.props.userToDelete) { // 申请删除某用户,显示弹窗
      this.setState({ showDeleteUserDialog: true });
    }
    if (nextProps.deleteUserError && !this.props.deleteUserError) {
      showErrorToast('删除失败：' + nextProps.deleteUserError.errmsg);
    }
    if (!nextProps.userToDelete && this.props.userToDelete) { // 关闭弹窗
      this.setState({ showDeleteUserDialog: false });
    }
    if (nextProps.userToEnable && !this.props.userToEnable) { // 申请启用某用户,显示弹窗
      this.setState({ showEnableUserDialog: true });
    }
    if (!nextProps.userToEnable && this.props.userToEnable) { // 关闭弹窗
      this.setState({ showEnableUserDialog: false });
    }
    if (nextProps.newNodeParentPreset && !this.props.newNodeParentPreset) { // 申请创建节点,某节点的平级或下级,显示弹窗
      this.setState({ showNewTreeNodeDialog: true });
    }
    if (!nextProps.newNodeParentPreset && this.props.newNodeParentPreset) { // 关闭弹窗
      this.setState({ showNewTreeNodeDialog: false });
    }
    if (nextProps.nodeCreated && !this.props.nodeCreated) { // 关闭弹窗,显示toast
      showSuccessToast('新建成功');
      this.setState({ showNewTreeNodeDialog: false });
    }
    if (nextProps.nodeToEdit && !this.props.nodeToEdit) { // 申请编辑节点,显示弹窗
      this.setState({ showEditTreeNodeDialog: true });
    }
    if (!this.props.nodeEdited && nextProps.nodeEdited) {
      showSuccessToast('保存成功');
      this.resetUserQueryToNode(nextProps.activeNode.id);
    }
    if (nextProps.nodeDeleted && !this.props.nodeDeleted) {
      showSuccessToast('删除成功');
    }
    if (!this.props.nodeDeleteError && nextProps.nodeDeleteError) {
      showErrorToast('删除失败：' + nextProps.nodeDeleteError.errmsg);
    }
    if (!nextProps.nodeToEdit && this.props.nodeToEdit) { // 关闭弹窗
      this.setState({ showEditTreeNodeDialog: false });
    }
    if (nextProps.nodeToDelete && !this.props.nodeToDelete) { // 申请删除节点,显示弹窗
      this.setState({ showDeleteTreeNodeDialog: true });
    }
    if (!nextProps.nodeToDelete && this.props.nodeToDelete) { // 关闭弹窗
      this.setState({ showDeleteTreeNodeDialog: false });
    }
    if (nextProps.userToEdit && !this.props.userToEdit) { // 申请编辑用户,显示弹窗
      this.setState({ userDialogTitle: '编辑用户', showUserDialog: true });
    }
    if (!nextProps.userToEdit && this.props.userToEdit) { // 关闭弹窗
      // this.setState({ userDialogTitle: undefined, showUserDialog: false });
    }
    const { activeNode } = nextProps;
    if (activeNode && activeNode !== this.props.activeNode) {
      this.setState({
        querySex: '',
        queryRole: '',
        queryStatus: '',
        queryJob: '',
        queryGrade: '',
        queryActive: ''
      });
      if (parseInt(activeNode.parentId) === 0) {
        this.resetUserQueryToNode(activeNode.id);
      }
    }
    if (!this.props.deleteUserLoaded && nextProps.deleteUserLoaded) {
      showSuccessToast('删除成功');
      this.props.queryUsers(this.props.userQuery);
    }
    if (!this.props.setUserStatusLoaded && nextProps.setUserStatusLoaded) {
      showSuccessToast('修改成功');
    }

    if (nextProps.markGraduationSuccess && nextProps.markGraduationSuccess !== this.props.markGraduationSuccess) {
      this.resetUserQueryToNode(this.props.activeNode.id);
    }
    if (nextProps.markGraduationFail && nextProps.markGraduationFail !== this.props.markGraduationFail) {
      showErrorToast(nextProps.markGraduationFail.errmsg);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { userListing, userListError, userListResult } = this.props;
    if (!userListing && !userListError && userListResult && userListResult !== prevProps.userListResult
      && this.refs.personCount) {
      const dom = document.defaultView.getComputedStyle(this.refs.personCount, null);
      const width = dom.width;
      this.setState({ personCountWidth: width });
    }
  }

  onCreateNewTreeNode = () => {
    this.setState({ showNewTreeNodeDialog: true });
  }

  onNewTreeNodeClose = () => {
    this.setState({ showNewTreeNodeDialog: false });
    this.props.clearNewNodeParent();
  }

  onEditTreeNodeClose = () => {
    this.props.clearNodeToEdit();
  }
  onCommitNewTreeNode = (parentNode, name, nameEn, attribute, teachers, counselors) => {
    this.props.createNewOrgNode(parentNode, name, nameEn, attribute, teachers, counselors);
  }
  onCommitEditTreeNode = (newParent, newName, nameEn, attribute, teachers, counselors) => {
    this.props.editNode(newParent, newName, nameEn, attribute, teachers, counselors);
  }
  onCommitDeleteTreeNode = () => {
    this.props.deleteNode(this.props.nodeToDelete);
  }
  onCancelDeleteTreeNode = () => {
    this.props.clearNodeToDelete();
  }
  onClearSearch = () => {
    this.setState({
      searchContent: ''
    });
  }
  // 添加用户
  onAddUserClick = () => {
    this.setState({ isClearOrgRoot: false }, () => {
      this.props.setOptFlag();
      this.props.push('/user/organization/userAdd');
    });
  }

  onViewClick = (uid) => {
    this.setState({ isClearOrgRoot: false }, () => {
      this.props.setOptFlag();
      this.props.push('/user/organization/userView?uid=' + uid);
    });
  };

  onEditClick = (uid) => {
    this.setState({ isClearOrgRoot: false }, () => {
      this.props.setOptFlag();
      this.props.push('/user/organization/userEdit?uid=' + uid);
    });
  };

  onCancelUser = () => {
    this.props.clearUserToEdit();
    this.setState({ showUserDialog: false });
  }

  onConfirmDisableUser = () => {
    this.props.setUserStatus(this.props.userToDisable, '0');
  }

  onCancelDisableUser = () => {
    this.props.clearUserToDisable();
  }

  onConfirmDeleteUser = () => {
    this.props.deleteUser(this.props.userToDelete);
  }

  onCancelDeleteUser = () => {
    this.props.clearUserToDelete();
  }

  onConfirmEnableUser = () => {
    this.props.setUserStatus(this.props.userToEnable, '1');
  }

  onCancelEnableUser = () => {
    this.props.clearUserToEnable();
  }

  onChangeToBatchImport = () => {
    this.props.push('/user/organization/batchImport');
  }

  onChangeToBatchUpdate = () => {
    this.props.push('/user/organization/batchUpdate');
  }

  onChangeToBatchUpdateOrg = () => {
    this.props.push('/user/organization/batchUpdateOrg');
  }

  onChangeToManalUpdateOrg = () => {
    this.props.push('/user/organization/manualUpdateOrg');
  }

  onChangeToMarkGraduation = () => {
    this.props.push('/user/organization/markGraduation');
  }

  onChangeToBatchImportCounselor = () => {
    this.props.push('/user/organization/batchImportCounselor');
  }

  resetUserQueryToNode = (nodeId) => {
    if (parseInt(nodeId) === 0) { // 根节点（学校）不可能被选中
      return;
    }
    const { customFields } = this.state;
    let customGrade;
    let customJob;
    let customStatus;
    let customActive;
    if (customFields && customFields.length > 0) {
      customGrade = customFields.find(item => item === CUSTOM_GRADE);
      customJob = customFields.find(item => item === CUSTOM_JOB);
      customStatus = customFields.find(item => item === CUSTOM_STATUS);
      customActive = customFields.find(item => item === CUSTOM_ACTIVE);
    }
    this.props.queryUsers({
      org_id: nodeId,
      isSearchMode: false,
      status_s: customStatus ? 'all' : '',
      job: customJob ? 'all' : '',
      sznj: customGrade ? 'all' : '',
      activity: customActive ? 'all' : ''
    });
    this.setState({
      queryActive: '',
      queryGrade: '',
      querySex: '',
      queryRole: '',
      queryStatus: '',
      queryJob: ''
    });
  }

  sexChange = (querySex) => {
    this.setState({ querySex });
    const { queryRole, queryStatus, queryJob, queryActive, queryGrade } = this.state;
    this.combineQuery({ queryRole, queryStatus, querySex, queryJob, queryActive, queryGrade });
  };

  jobChange = (queryJob) => {
    this.setState({ queryJob });
    const { queryRole, queryStatus, querySex, queryActive, queryGrade } = this.state;
    this.combineQuery({ queryRole, queryStatus, querySex, queryJob, queryActive, queryGrade });
  };

  roleChange = (queryRole) => {
    this.setState({ queryRole });
    const { querySex, queryStatus, queryJob, queryActive, queryGrade } = this.state;
    this.combineQuery({ queryRole, queryStatus, querySex, queryActive, queryGrade, queryJob });
  }

  statusChange = (queryStatus) => {
    this.setState({ queryStatus });
    const { queryRole, querySex, queryJob, queryActive, queryGrade } = this.state;
    this.combineQuery({ queryRole, queryStatus, querySex, queryActive, queryGrade, queryJob });
  }

  gradeChange = (queryGrade) => {
    this.setState({ queryGrade });
    const { queryRole, querySex, queryJob, queryStatus, queryActive } = this.state;
    this.combineQuery({ queryRole, querySex, queryJob, queryStatus, queryActive, queryGrade });
  };

  activeChange = (queryActive) => {
    this.setState({ queryActive });
    const { queryRole, querySex, queryJob, queryStatus, queryGrade } = this.state;
    this.combineQuery({ queryRole, querySex, queryJob, queryStatus, queryGrade, queryActive });
  };
  changeSearchType = (value) => {
    this.setState({ searchType: value, searchText: '' });
  }

  onSearchHandler = (searchText) => {
    if (searchText) {
      const { queryRole, querySex, queryJob, queryStatus, queryActive, queryGrade, searchType } = this.state;
      const queryData = {
        roleValue: queryRole,
        sexValue: querySex,
        selectJob: queryJob,
        statusValue: queryStatus,
        activeValue: queryActive,
        gradeValue: queryGrade
      };
      setUserQueryData(queryData);
      const data = { searchText, searchType };
      this.props.push(`user/organization/multiQuery?data=${encodeURIComponent(JSON.stringify(data))}&from=home`);
    }
    this.setState({
      searchView: true,
      searchText,
      isSearch: true
    });
  }

  onClearSearch = () => {
    this.setState({
      searchText: '',
      isSearch: ''
    });
  }

  onClearChange = (searchText) => {
    this.setState({ searchText });
  }

  backToListHandler = () => {
    this.setState({
      searchView: false,
      searchText: '',
      isSearch: ''
    });
    this.props.push('/user/organization');
  }

  // 组合条件查询
  combineQuery = (queryObj) => {
    const { queryRole, querySex, queryStatus, queryJob, queryGrade, queryActive } = queryObj;
    const { customFields } = this.state;
    let customGrade;
    let customJob;
    let customStatus;
    let customActive;
    if (customFields && customFields.length > 0) {
      customGrade = customFields.find(item => item === CUSTOM_GRADE);
      customJob = customFields.find(item => item === CUSTOM_JOB);
      customStatus = customFields.find(item => item === CUSTOM_STATUS);
      customActive = customFields.find(item => item === CUSTOM_ACTIVE);
    }
    this.props.queryUsers({
      org_id: this.props.activeNode.id,
      isSearchMode: false,
      identity: queryRole,
      sex: querySex,
      status_s: customStatus ? (queryStatus || 'all') : '',
      job: customJob ? (queryJob || 'all') : '',
      sznj: customGrade ? (queryGrade || 'all') : '',
      activity: customActive ? (queryActive || 'all') : ''
    });
  }

  onMultiOrgUser = () => {
    this.setState({ showMultiUserDialog: true });
  };

  onMultiOrgUserHide = () => {
    this.setState({ showMultiUserDialog: false });
  };

  onCustomList = (data) => {
    this.setState({ customFields: data });
    const { queryRole, querySex, queryJob, queryStatus, queryGrade, queryActive } = this.state;
    if (data && data.length > 0) {
      const customGrade = data.find(item => item === CUSTOM_GRADE);
      const customJob = data.find(item => item === CUSTOM_JOB);
      const customStatus = data.find(item => item === CUSTOM_STATUS);
      const customActive = data.find(item => item === CUSTOM_ACTIVE);
      if (!customGrade) {
        this.setState({ queryGrade: '' });
      }
      if (!customJob) {
        this.setState({ customJob: '' });
      }
      if (!customStatus) {
        this.setState({ customStatus: '' });
      }
      if (!customActive) {
        this.setState({ customActive: '' });
      }
      this.props.queryUsers({
        org_id: this.props.activeNode.id,
        isSearchMode: false,
        identity: queryRole,
        sex: querySex,
        status_s: customStatus ? (queryStatus || 'all') : '',
        job: customJob ? (queryJob || 'all') : '',
        sznj: customGrade ? (queryGrade || 'all') : '',
        activity: customActive ? (queryActive || 'all') : ''
      });
    } else {
      this.props.queryUsers({
        org_id: this.props.activeNode.id,
        isSearchMode: false,
        identity: queryRole,
        sex: querySex
      });
    }
  }

  render() {
    const { nodeDeleting, activeNode, userListing, userListResult,
      userListError, deleteUserLoading, setUserStatusLoading, contentWidth, contentHeight,
      nodeLoading, nodeOrdering, organizationRoot, multiOrgRoot, createNewOrgNode, nodeCreating,
      nodeCreated, nodeCreateError, loadOrgNode, queryUsers, jobListResult,
      clearOpenedList, activeIndex, userQuery, optFlag, batchTopNode } = this.props;
    const { queryRole, querySex, queryStatus, queryJob, queryGrade, queryActive,
      customFields, searchType, searchText} = this.state;
    const treeHeight = contentHeight - 46 - 20; // navbar - queryrow - padding
    const userPanelWidth = contentWidth - 292; // 页面左右5的margin, 两框间距12, 组织结构树框260, 10个预留的滚动条空间
    let panelHeader = 50;
    let userListHeight = treeHeight - 50; /* 50是头部高, 20是留白 */
    let userCountStyle = 'pull-left ';
    let queryStyle = 'pull-left';
    let queryBoxStyle = 'pull-right';
    // 500筛选下拉宽度，30pull样式左右panding，将筛选条件放到第二行
    if (userPanelWidth - parseInt(this.state.personCountWidth) - 30 - 20
      <= (260 + (customFields && customFields.length > 0 ? customFields.length * 125 : 0))) {
      panelHeader = 84;
      queryBoxStyle = 'pull-left';
      userCountStyle = '';
      userListHeight = treeHeight - 84;
    }
    const multiProps = {
      contentHeight,
      nodeLoading,
      nodeOrdering,
      onCreateNewTreeNode: this.onCreateNewTreeNode,
      organizationRoot,
      multiOrgRoot,
      createNewOrgNode,
      nodeCreating,
      nodeCreated,
      nodeCreateError,
      activeNode,
      loadOrgNode,
      queryUsers,
      clearOpenedList,
      activeIndex,
      optFlag
    };
    const isMultiOrgActive = activeNode && parseInt(activeNode.orgType) === 2;
    const jobOptions = (jobListResult && jobListResult.map(item => ({ label: item.name, value: item.id }))) || [];
    jobOptions.unshift({ label: '全部', value: '' });
    let customGrade;
    let customJob;
    let customStatus;
    let customActive;
    if (customFields && customFields.length > 0) {
      customGrade = customFields.find(item => item === CUSTOM_GRADE);
      customJob = customFields.find(item => item === CUSTOM_JOB);
      customStatus = customFields.find(item => item === CUSTOM_STATUS);
      customActive = customFields.find(item => item === CUSTOM_ACTIVE);
    }
    const auth = getAuthInfo();
    const isGroundUser = auth && auth.isGroundUser || false;
    let searchTypePLh;
    switch (searchType) {
      case POST_USER_SEARCH_TYPE:
        searchTypePLh = '姓名/学工号';
        break;
      case POST_PHONE_SEARCH_TYPE:
        searchTypePLh = '手机号/固话/短号';
        break;
      case POST_NUMBER_TYPE:
        searchTypePLh = '证件号码';
        break;
      default:
        break;
    }
    return (
      <div className={'container content-container ' + classes.organizationHome}
        style={{ height: contentHeight }}>
        {/* 新建部门院系弹窗 */}
        {this.state.showNewTreeNodeDialog && <NewOrEditTreeNodeView onHide={this.onNewTreeNodeClose}
          onCommitTreeNode={this.onCommitNewTreeNode}
          operationType='create' />}
        {/* 人员批量置顶弹窗 */}
        {batchTopNode && <BatchToppingView />}
        {/* 编辑部门院系弹窗 */}
        {this.state.showEditTreeNodeDialog && <NewOrEditTreeNodeView onHide={this.onEditTreeNodeClose}
          onCommitEditTreeNode={this.onCommitEditTreeNode}
          operationType='edit' />}
        {/* 删除部门院系弹窗 */}
        <Dialog show={this.state.showDeleteTreeNodeDialog} onHide={this.onCancelDeleteTreeNode}
          closable={!nodeDeleting} title='提示'
          content={isMultiOrgActive
            ? '确定删除该组织节点？\n将删除此节点及包含的子节点与用户\n删除操作不影响用户在其他组织架构中的信息'
            : '确定删除该组织节点？\n此节点下的子节点及用户将同步删除\n用户在其他组织架构中的信息也将同步删除'}
          positiveButton={{ onClick: this.onCommitDeleteTreeNode, loading: nodeDeleting }}
          negativeButton={{ onClick: this.onCancelDeleteTreeNode, disabled: nodeDeleting }} />
        {/* 确认禁用 */}
        <Dialog show={this.state.showDisableUserDialog} onHide={this.onCancelDisableUser}
          closable={!setUserStatusLoading} title='提示' content={'确定禁用此用户吗？\n禁用后将不能登录任何平台'}
          positiveButton={{ onClick: this.onConfirmDisableUser, loading: setUserStatusLoading }}
          negativeButton={{ onClick: this.onCancelDisableUser, disabled: setUserStatusLoading }} />
        {/* 确认开启 */}
        <Dialog show={this.state.showEnableUserDialog} onHide={this.onCancelEnableUser}
          closable={!setUserStatusLoading} title='提示' content='确定恢复启用此用户吗？'
          positiveButton={{ onClick: this.onConfirmEnableUser, loading: setUserStatusLoading }}
          negativeButton={{ onClick: this.onCancelEnableUser, disabled: setUserStatusLoading }} />
        {/* 确认删除 */}
        <Dialog show={this.state.showDeleteUserDialog} onHide={this.onCancelDeleteUser}
          closable={!deleteUserLoading} title='提示' content={isMultiOrgActive
            ? '确定从当前组织架构中删除此用户吗？\n删除操作不影响用户在其他组织架构中的信息'
            : '确定执行删除操作吗？\n删除后用户将不能登录任何平台'}
          positiveButton={{ onClick: this.onConfirmDeleteUser, loading: deleteUserLoading }}
          negativeButton={{ onClick: this.onCancelDeleteUser, disabled: deleteUserLoading }} />
        {this.state.showMultiUserDialog && <MultiOrgUserForm onHide={this.onMultiOrgUserHide}
          activeNode={activeNode} jobListResult={jobListResult} />}
        <div className={classes.orgNavBar}>
          <IconHeader iconClassName='organization' title='组织架构'>
            <div className={classes.orgOperations}>
              <div className={classes.outer}>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <CustomListDialog onSubmit={this.onCustomList} />
                </div>
                <Dropdown className={classes.dropdownButton} title='批量操作' id='dropdown-size-small'
                  disabled={userListing}>
                  <Dropdown.Toggle className={classes.name}>
                    更多操作
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={classes.menuItem}>
                    {
                      !isGroundUser
                      && <MenuItem eventKey='1' className={classes.item} onClick={this.onAddUserClick}>
                        添加用户至<br />行政组织架构
                      </MenuItem>
                    }
                    <MenuItem eventKey='2' className={classes.item} onClick={this.onMultiOrgUser}>
                      添加用户至<br />其他组织架构
                    </MenuItem>
                    {
                      !isGroundUser
                      && <Fragment>
                        <MenuItem eventKey='3' className={classes.item} onClick={this.onChangeToBatchImport}>
                          批量导入用户</MenuItem>
                        <MenuItem eventKey='4' className={classes.item} onClick={this.onChangeToBatchUpdate}>
                          批量更新用户</MenuItem>
                        <MenuItem eventKey='5' className={classes.item} onClick={this.onChangeToBatchUpdateOrg}>
                          批量更新组织</MenuItem>
                        <MenuItem eventKey='6' className={classes.item} onClick={this.onChangeToManalUpdateOrg}>
                          手动调整组织数据
                        </MenuItem>
                      </Fragment>
                    }
                    <MenuItem eventKey='7' className={classes.item} onClick={this.onChangeToMarkGraduation}>
                      标记已毕业
                    </MenuItem>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <QueryOperation onSearchHandler={this.onSearchHandler} placeholder={searchTypePLh}
                onClearChange={this.onClearChange} onClearHandler={this.onClearSearch} searchValue={searchText}
                onSelectSearch={this.onSelectSearch} onSelectSearchClear={this.onSelectSearchClear}>
                <div className={classes.selectContainer}>
                  <Select options={SEARCH_TYPE_LIST} simpleValue matchProp='label' style={{ borderRight: 'none' }}
                    clearable={false} searchable={false} noResultsText='' onChange={this.changeSearchType}
                    value={searchType || POST_USER_SEARCH_TYPE} disabled={false} />
                </div>
              </QueryOperation>
            </div>
          </IconHeader>
        </div>
        <div className={classes.treeRow} style={{ height: treeHeight }}>
          <MultiOrganization {...multiProps} loadOrgRoot={this.props.loadOrgRoot} />
          <div className={classes.orgTreeDetailPanel} style={{ width: userPanelWidth }}>
            {/* 17 是 20 - 3,其中20是右边预留的宽度,3是border造成的偏差 */}
            <div className='panel panel-default' style={{ width: userPanelWidth, borderBottom: 'none' }}>
              <div className={'panel-heading ' + classes.orgTreeDetailHeader} style={{ height: panelHeader }}>
                <div ref='personCount' className={userCountStyle + classes.opText} style={{ color: '#737373' }}>
                  {userListing && <span>人数加载中…</span>}
                  {userListError && <span className='text-danger'>加载失败，{userListError.errmsg}</span>}
                  {!userListing && !userListError && userListResult && <span>
                    <span>
                      <AutoShrinkTextWithTip
                        text={(activeNode && activeNode.name) || ''}
                        maxSize={contentWidth - 700}
                        fontSize={14}
                        showTips
                      />
                      （共查到{userListResult.total_count || 0}人
                      <span>{customActive && <span>，在校激活{userListResult.active || 0}人</span>}
                        {!isMultiOrgActive && <span>，已毕业{userListResult.graduation || 0}人</span>}</span>
                      ，机构ID：{activeNode && activeNode.id}）
                    </span>
                  </span>}
                </div>
                {!userListing && !userListError && userListResult
                  && <div className={classNames(classes.queryContainer, queryBoxStyle)}>
                    {customActive && <div className={queryStyle} style={{ marginLeft: '12px', width: '100px' }}>
                      <SelectInput value={queryActive} options={ACTIVE_OPTIONS} showName='激活' placeholder='激活'
                        simpleValue onChange={this.activeChange} maxSize={72} />
                    </div>}
                    {customStatus && <div className={queryStyle} style={{ marginLeft: '12px', width: '132px' }}>
                      <SelectInput value={queryStatus} options={STATUS_LIST} showName='状态' placeholder='状态'
                        simpleValue onChange={this.statusChange} />
                    </div>}
                    {customJob && <div className={queryStyle} style={{ marginLeft: '12px', width: '104px' }}>
                      <SelectInput value={queryJob} options={jobOptions} showName='职务' placeholder='职务'
                        simpleValue onChange={this.jobChange} maxSize={72} />
                    </div>}
                    {customGrade && <div className={queryStyle} style={{ marginLeft: '12px', width: '132px' }}>
                      <SelectInput value={queryGrade} options={GRADE} showName='所在年级' placeholder='所在年级'
                        simpleValue onChange={this.gradeChange} />
                    </div>}
                    <div className={queryStyle} style={{ marginLeft: '12px', width: '118px' }}>
                      <SelectInput value={queryRole} options={USER_TYPE_LIST} showName='身份' placeholder='身份'
                        simpleValue onChange={this.roleChange} />
                    </div>
                    <div className={queryStyle} style={{ marginLeft: '12px', width: '100px' }}>
                      <SelectInput value={querySex} options={SEX} showName='性别' placeholder='性别'
                        simpleValue onChange={this.sexChange} />
                    </div>
                  </div>}
              </div>
            </div>
            <div className={classes.usersView}
              style={{ height: userListHeight }}>
              <UserListView boxWidth={userPanelWidth} boxHeight={userListHeight} customFields={customFields}
                onViewClick={this.onViewClick} onEditClick={this.onEditClick} page={userQuery && userQuery.page || 1} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionCreators)(OrganizationHome);
