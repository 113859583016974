export const LOAD = '_LOAD';
const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

export const DEFAULT_TAG = 'default_tag';
export const WHISTLE_ACCOUNT = 'whistle_account';
export const ADMIN_ACCOUNT = 'admin_account';

const LOGIN = 'whistlems/auth/LOGIN';
const QRCODELOGIN = 'QRCODE';
const RESET = 'RESET';
export const LOGIN_LOAD = LOGIN + LOAD;
export const LOGIN_SUCCESS = LOGIN + SUCCESS;
export const LOGIN_FAIL = LOGIN + FAIL;
export const QRCODE_LOGIN_FAIL = LOGIN + QRCODELOGIN + FAIL;
export const LOGIN_RESET = LOGIN + RESET;
export const QRCODE_LOGIN_RESET = LOGIN + QRCODELOGIN + RESET;

const LOGOUT = 'whistlems/auth/LOGOUT';
export const LOGOUT_LOAD = LOGOUT + LOAD;
export const LOGOUT_SUCCESS = LOGOUT + SUCCESS;

const QRCODE = 'whistlems/auth/QRCODE';
export const QRCODE_LOAD = QRCODE + LOAD;
export const QRCODE_SUCCESS = QRCODE + SUCCESS;
export const QRCODE_FAIL = QRCODE + FAIL;

const CHECKQRCODE = 'whistlems/auth/CHECKQRCODE';
export const CHECKQRCODE_LOAD = CHECKQRCODE + LOAD;
export const CHECKQRCODE_SUCCESS = CHECKQRCODE + SUCCESS;
export const CHECKQRCODE_FAIL = CHECKQRCODE + FAIL;

export const SET_SCAN_LOGIN = CHECKQRCODE + 'SET_SCAN_LOGIN';
export const CLEAN_SCAN_LOGIN = CHECKQRCODE + 'CLEAN_SCAN_LOGIN';

export const QRCODE_NO_PERMISSIONS = 20002; // 扫码登录没有权限

export const GET_VERIFY_KEY = 'whistlems/auth/GETVERIFYKEY';
export const GET_VERIFY_KEY_FAIL = GET_VERIFY_KEY + FAIL;
export const GET_VERIFY_KEY_SUCCESS = GET_VERIFY_KEY + SUCCESS;
