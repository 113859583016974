import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import classes from './CustomListDialog.scss';
import CheckBox from 'components/CheckBox';
import { getUserQuery, setUserQuery } from 'helpers/AuthHelper';
import { CUSTOM_GRADE, CUSTOM_JOB, CUSTOM_STATUS, CUSTOM_ACTIVE } from '../../strings/strings';

const CUSTOM_FIELD = [
  {text: '所在年级', value: CUSTOM_GRADE},
  {text: '职务', value: CUSTOM_JOB},
  {text: '状态', value: CUSTOM_STATUS},
  {text: '激活', value: CUSTOM_ACTIVE}
];

const CustomField = ({item, onCheckbox, customFields}) => {
  const onClick = () => {
    onCheckbox(item.value);
  };
  const field = customFields.find(it => it === item.value);
  return <div className={classes.fieldContainer}>
    <span onClick={onClick}><CheckBox checked={field} /></span>
    <span className={classes.fieldText}>{item.text}</span>
  </div>;
};

CustomField.propTypes = {
  item: PropTypes.object,
  onCheckbox: PropTypes.func,
  customFields: PropTypes.array
};

const CustomList = ({onCheckbox, customFields}) => (
  <div className={classes.checkboxContainer}>
    {
      CUSTOM_FIELD.map((item, index) =>
        <CustomField key={'custom_' + index} item={item} onCheckbox={onCheckbox} customFields={customFields} />
      )
    }
  </div>
);

CustomList.propTypes = {
  onCheckbox: PropTypes.func,
  customFields: PropTypes.array
};

class CustomListDialog extends PureComponent {

  state = {
    showDialog: false,
    customFields: []
  };

  componentDidMount() {
    const userQuery = getUserQuery();
    if (userQuery && userQuery.length) {
      this.setState({ customFields: userQuery });
    }
  }

  onHide = () => {
    this.setState({ showDialog: false });
    const userQuery = getUserQuery();
    if (userQuery && userQuery.length) {
      this.setState({ customFields: userQuery });
    }
  };

  onSubmit = () => {
    const { customFields } = this.state;
    const { onSubmit } = this.props;
    this.setState({ showDialog: false }, () => {
      setUserQuery(customFields);
      onSubmit(customFields);
    });
  };

  onCustomClick = () => {
    this.setState({ showDialog: true });
  };

  onCheckbox = (data) => {
    const { customFields } = this.state;
    const list = [];
    if (customFields && customFields.length > 0) {
      const info = customFields.find(item => item === data);
      if (info) {
        customFields.map(item => item !== data && list.push(item));
      } else {
        list.push(data);
        customFields.map(item => list.push(item));
      }
    } else {
      list.push(data);
    }
    this.setState({ customFields: list });
  };

  render() {
    const { showDialog, customFields } = this.state;
    return (
      <div className={classes.customContainer}>
        <Dialog className={classes.dialogContainer} show={showDialog}
          onHide={this.onHide} title={'自定义列表字段'}
          positiveButton={{ onClick: this.onSubmit }}
          negativeButton={{ onClick: this.onHide }}
        >
          <div className={classes.formContainer}>
            <div className={classes.textContainer}>请选择要展示的字段，设置后仅对本次登录生效</div>
            <CustomList onCheckbox={this.onCheckbox} customFields={customFields} />
          </div>
        </Dialog>
        <div>
          <span className={'wsbtn-ghost wsbtn-ghost-primary wsbtn-large'}
            onClick={this.onCustomClick}>自定义列表字段</span>
        </div>
      </div>
    );
  }
}

CustomListDialog.propTypes = {
  onSubmit: PropTypes.func
};

export default CustomListDialog;
