import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/Icon';
import classes from './ExcelImportView.scss';

export const ImportResultView = ({ data }) => {
  const successCount = data.success_count;
  const failCount = data.fail_count;
  const success = parseInt(failCount) === 0;
  const iconType = success ? 'success' : 'failure';
  const successCountText = successCount > 0 ? `成功${successCount}条数据` : '';
  const failCountText = failCount > 0 ? `失败${failCount}条数据` : '';
  const commaSucc = successCount > 0 ? '，' : '';
  const commaFail = failCount > 0 ? '，' : '';
  return (
    <div className={classes.result}>
      <div className={classes.icon}><Icon type={iconType} className={success && 'text-primary'} /></div>
      <div className={classes.text}>{`操作完成${commaSucc}${successCountText}${commaFail}`}</div>
      {failCountText && <div className={classes.text}>{failCountText}</div>}
    </div>
  );
};

ImportResultView.propTypes = {
  data: PropTypes.object.isRequired
};

export default ImportResultView;
