/**
 * 组织结构相关工具类
 */
export function getOrgPathArray(path) {
  if (path) {
    return path.split(',');
  }
  return path;
}

export function getLeafName(path) {
  const pathArr = getOrgPathArray(path);
  return pathArr && pathArr[pathArr.length - 1] || '';
}

export function getOrgPathText(path, delimiter = '<') {
  const pathArr = Array.isArray(path) ? path : getOrgPathArray(path);
  if (pathArr) {
    return pathArr.reverse().join(delimiter);
  }
  return path;
};
