import PropTypes from 'prop-types';
/**
 * Created by pyt on 2016/6/22.
 */
import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'components/Dialog';
import TextInputWithErrorTips from 'components/TextInputWithErrorTips';
import { modifyPasswordRequest, MODIFY_PASSWORD_SUCCESS, MODIFY_PASSWORD_FAIL, passwordFlag } from '../modules/admin';
import { getAuthInfo } from 'helpers/AuthHelper';
import classes from './Account.scss';
import { replace } from 'react-router-redux';
import { logoutRequest } from 'routes/Login/modules/login';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';
import { reduxForm, propTypes, Field } from 'redux-form';
import md5 from 'md5';
import { LOGOUT_SUCCESS } from 'routes/Login/modules/strings';

const INPUT_IDS = {
  OLD_PASSWORD: 'modify_password_old_password',
  NEW_PASSWORD: 'modify_password_new_password',
  CONFIRM_NEW_PASSWORD: 'modify_password_confirm_new_password'
};

const mapStateToProps = (state) => {
  return {
    modifyPassword: state.admin,
    login: state.login,
    passwordFlagResult: state.admin.passwordFlagResult
  };
};

const mapActionCreators = {
  modifyPasswordRequest,
  logoutRequest,
  passwordFlag,
  replace
};

const validate = values => {
  const { oldPwd, newPwd, confirmPwd, newPwd2 } = values;
  const errors = {};
  if (!oldPwd) {
    errors.oldPwd = '请输入旧密码';
  }
  let reg = /^(?![A-Za-z]+$)(?!\d+$)(?![\W_]+$)\S{8,24}$/;
  // eslint-disable-next-line max-len
  let reg2 = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,24}$/;
  if (!newPwd && !newPwd2) {
    errors.newPwd = '请输入新密码';
    errors.newPwd2 = '请输入新密码';
  } else {
    if (newPwd) {
      if (!reg.test(newPwd)) {
        errors.newPwd = '密码格式有误，请重新输入';
      } else if (newPwd === oldPwd) {
        errors.newPwd = '新密码与旧密码不能相同';
      }
      if (!confirmPwd) {
        errors.confirmPwd = '请再次输入新密码';
      } else if (newPwd !== confirmPwd) {
        errors.confirmPwd = '两次密码输入不一致';
      }
    } else if (newPwd2) {
      if (!reg2.test(newPwd2)) {
        errors.newPwd2 = '密码格式有误，请重新输入';
      } else if (newPwd2 === oldPwd) {
        errors.newPwd2 = '新密码与旧密码不能相同';
      }
      if (!confirmPwd) {
        errors.confirmPwd = '请再次输入新密码';
      } else if (newPwd2 !== confirmPwd) {
        errors.confirmPwd = '两次密码输入不一致';
      }
    }
  }
  return errors;
};

const reduxFormProps = {
  form: 'modifyPassword',
  validate
};

const LABEL_CLASS = 'col-sm-3 control-label ' + classes['modify-password-form-label'];

export const Input = ({ input, id, type, label, meta: { touched, error } }) => (
  <div className='form-group' style={{ marginBottom: '30px' }}>
    <label htmlFor={id} className={LABEL_CLASS}>{label}</label>
    <div className='col-sm-8'>
      <TextInputWithErrorTips inputProps={{
        id,
        type,
        placeholder: label
      }} reduxFormProps={input} error={touched ? error : ''} />
    </div>
    <div className='col-sm-1' />
  </div>
);

export class PasswordModifyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { passwordFlag } = this.props;
    passwordFlag();
  }

  onSubmit(data) {
    this.setState({
      loading: true
    });
    const { oldPwd, newPwd, newPwd2 } = data;
    const accountInfo = {
      account: this.props.account || getAuthInfo().account,
      oldPassword: md5(oldPwd),
      newPassword: md5(newPwd || newPwd2)
    };
    this.props.modifyPasswordRequest.call(this, accountInfo);
  }

  componentWillReceiveProps(newProps) {
    const { state } = newProps.modifyPassword;
    if (state !== this.props.modifyPassword.state) {
      if (MODIFY_PASSWORD_SUCCESS === state) {
        showSuccessToast('密码修改成功，请重新登录。');
        this.props.closeDialog.call(this);
        this.props.logoutRequest.call(this);
        this.props.replace('/login');
      } else if (MODIFY_PASSWORD_FAIL === state) {
        this.setState({
          loading: false
        });
        showErrorToast(newProps.modifyPassword.text);
      }
    }
    const {login} = newProps;
    if (login && login !== this.props.login) {
      const { state } = login;
      if (state === LOGOUT_SUCCESS) {
        this.props.replace('/login');
      }
    }
  }

  render() {
    const { handleSubmit, dirty, invalid, passwordFlagResult } = this.props;
    const passwordSet = passwordFlagResult && passwordFlagResult.password_set
      && Number(passwordFlagResult.password_set) === 1;
    return (
      <Dialog modalProps={{ id: 'password-modify-modal' }}
        title='修改密码' size='md' show onHide={this.props.closeDialog}
        positiveButton={{
          loading: this.state.loading,
          disabled: !dirty || invalid,
          onClick: handleSubmit(this.onSubmit)
        }}>
        <form className='form-horizontal'>
          <Field name='oldPwd' type='password' id={INPUT_IDS.OLD_PASSWORD} label='旧密码' component={Input} />
          {
            passwordSet
              ? <Field name='newPwd2' type='password' id={INPUT_IDS.NEW_PASSWORD} label='新密码' component={Input} />
              : <Field name='newPwd' type='password' id={INPUT_IDS.NEW_PASSWORD} label='新密码' component={Input} />
          }
          <Field name='confirmPwd' type='password' id={INPUT_IDS.CONFIRM_NEW_PASSWORD}
            label='确认新密码' component={Input} />
        </form>
        <div style={{ marginLeft: '126px', marginBottom: '12px' }}>
          {
            passwordSet
              ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>注：</span>
                <span>1、密码长度8-24，由数字、大小写字母、特殊符号至少3种组成；</span>
                <span>2、更换的密码5次内不能重复；</span>
                <span>3、建议至少90天更换一次密码；</span>
              </div>
              : <div>注：密码需包含字母、符号、数字中至少两种且长度为8-24位，同时建议定期更换密码。</div>
          }
        </div>
      </Dialog>
    );
  }
}

PasswordModifyView.propTypes = {
  ...propTypes,
  closeDialog: PropTypes.func,
  account: PropTypes.string,
  modifyPassword: PropTypes.object,
  modifyPasswordRequest: PropTypes.func,
  login: PropTypes.object
};

export default connect(mapStateToProps, mapActionCreators)(reduxForm(reduxFormProps)(PasswordModifyView));

