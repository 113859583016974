import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/Icon';
import NavItem from './NavItem';
import classes from './HoverNav.scss';

export class HoverNav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMenuClicked = this.onMenuClicked.bind(this);
  }

  toggleState(flag) {
    this.setState({
      show: flag
    });
  }

  onMouseEnter() {
    this.toggleState(true);
  }

  onMouseLeave() {
    this.toggleState(false);
  }

  onMenuClicked(key) {
    this.setState({
      show: false
    });
    this.props.onMenuClicked && this.props.onMenuClicked.call(this, key);
  }

  getMenu() {
    return this.props.menu.map((item, index) => (
      <NavItem item={item} key={item.key || ('divider' + index)} onItemClick={this.onMenuClicked} />
    ));
  }

  render() {
    const { showArrow, title } = this.props;
    const { show } = this.state;
    const icon = show ? 'triangleup' : 'triangledown';
    return (
      <div className={classes.container} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <span className={classes.title}>{title}</span>
        {showArrow === false ? '' : <Icon type={icon} className={classes.arrowdown} />}
        {show && (
          <span className={classes.containerBody} style={{marginTop: `${this.props.marginTop}px`}}>
            {this.getMenu()}
          </span>
        )}
      </div>
    );
  }
}

HoverNav.propTypes = {
  title: PropTypes.string,
  menu: PropTypes.array,
  marginTop: PropTypes.number,
  onMenuClicked: PropTypes.func,
  showArrow: PropTypes.bool
};

export default HoverNav;

