/**
 * Created by liming on 16/6/17.
 */
import { uniqBy as _uniqBy } from 'lodash';

export function replaceArrayElement(anArray, oldElement, newElement) {
  const index = anArray.indexOf(oldElement);
  const newArray = anArray.slice();
  newArray[index] = newElement;
  return newArray;
};

export function preAppendToArray(anArray, elements) {
  return [...elements, ...anArray];
};

export function appendToArray(anArray, elements) {
  return [...anArray, ...elements];
};

export function removeArrayElement(anArray, elementToRemove) {
  if (!elementToRemove) {
    return anArray;
  }
  const index = anArray.indexOf(elementToRemove);
  return [...anArray.slice(0, index), ...anArray.slice(index + 1)];
};

export function removeArrayEleByIndex(anArray, index) {
  if (index < 0) {
    return anArray;
  }
  return [...anArray.slice(0, index), ...anArray.slice(index + 1)];
};

export function ascendElement(anArray, elementToAscend) {
  const index = anArray.indexOf(elementToAscend);
  return [...anArray.slice(0, index - 1), anArray[index], anArray[index - 1], ...anArray.slice((index + 1))];
};

export function descendElement(anArray, elementToDescend) {
  const index = anArray.indexOf(elementToDescend);
  return [...anArray.slice(0, index), anArray[index + 1], anArray[index], ...anArray.slice((index + 2))];
};

export function uniqBy(array, identity) {
  if (!array || !identity) {
    return array;
  }
  return _uniqBy(array, identity);
};

/**
 * Sort an array of object by given keys.
 *
 * @param {any} array Array to sort.
 * @param {any} keys Keys of an array item to compare, prioritized in order. Default keys will be ['sort'].
 * @returns The sorted array. Note that the array is sorted *in place*, and no copy is made.
 */
export function sort(array, keys) {
  if (!array) {
    return array;
  }
  if (!keys || keys.length === 0) {
    keys = ['sort'];
  }
  return array.sort((a, b) => {
    let sign;
    for (const key of keys) {
      sign = Math.sign(parseInt(b[key]) - parseInt(a[key]));
      if (sign !== 0) {
        return sign;
      }
    }
    return 0;
  });
}

/**
 * 比对两个数组是否有差异，有差异返回true,否则返回false
 */
export function diff(array1, array2) {
  if (!array1 || !array2 || !(array1 instanceof Array) || !(array2 instanceof Array)) {
    return true;
  }
  const l1 = array1.length;
  const l2 = array2.length;
  if (l1 !== l2) {
    return true;
  }
  const set1 = new Set(array1);
  const notExistEle = array2.find(item => !set1.has(item));
  if (notExistEle) {
    return true;
  } else {
    return false;
  }
}
