import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '审计日志',
  path: 'auditlog',
  staticName: true,
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/auditlog/list') },
  childRoutes: [
    {
      name: '日志列表',
      staticName: true,
      path: 'list(/:tab)',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const Component = require('./containers/AuditLog').default;
          const reducer = require('./modules/auditLog').default;
          injectReducer(store, {key: 'auditLog', reducer});
          callback(null, Component);
        }, getErrorCallback(callback), 'AuditLog');
      }
    }
  ]
});

export default route;
