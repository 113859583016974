import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Icon = ({type, className, ...props}) => (
  <i className={classNames('wsicon', `wsicon-${type}`, className)} {...props} />
);

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Icon;
