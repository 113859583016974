import PropTypes from 'prop-types';
import React from 'react';
import { List, Item } from 'components/ExpandableList';
import { getItem } from './utils';
import classes from './LeftNavBar.scss';

export class SubNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onNavChange = this.onNavChange.bind(this);
    this.state = {
      activeItem: this.props.activeItem
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activeItem !== this.state.activeItem) {
      this.setState({
        activeItem: newProps.activeItem
      });
    }
  }

  onNavChange(activeItem) {
    const menuItem = getItem(this.props.item.subMenu, activeItem);
    if (menuItem) {
      const handled = this.props.onMenuClick(menuItem, this.props.item);
      if (handled) {
        this.setState({
          activeItem
        });
      }
      return handled;
    }
    return false;
  }

  getListProps() {
    const props = {
      className: classes.subnav,
      onChange: this.onNavChange,
      activeItem: this.state.activeItem
    };
    return props;
  }

  render() {
    const menu = this.props.item.subMenu;
    if (menu && menu.length > 0) {
      return (
        <List {...this.getListProps()}>
          {
            menu.map(item => (
              <Item
                key={item.id}
                slug={item.id}
                title={item.title}
                icon={item.icon}
                className={classes.subitem}
                expandedClassName={classes.active}
                titleClassName={classes.subitem_title}
              />
            ))
          }
        </List>
      );
    }
    return null;
  }
}

SubNav.defaultProps = {
  activeItem: -1
};

SubNav.propTypes = {
  item: PropTypes.object.isRequired,
  onMenuClick: PropTypes.func,
  activeItem: PropTypes.number
};

export default SubNav;
