// ------------------------------------
// Constants
// ------------------------------------
import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import { LOAD, SUCCESS, FAIL } from './strings';

const MODIFY_PASSWORD = 'whistlems/admin/MODIFY_PASSWORD';
export const MODIFY_PASSWORD_LOAD = MODIFY_PASSWORD + LOAD;
export const MODIFY_PASSWORD_SUCCESS = MODIFY_PASSWORD + SUCCESS;
export const MODIFY_PASSWORD_FAIL = MODIFY_PASSWORD + FAIL;
export const RESET_ORGANIZATION = 'whistlems/admin/RESET_ORGANIZATION';
export const RESET_ORGANIZATION_SUCCESS = RESET_ORGANIZATION + SUCCESS;
export const RESET_ORGANIZATION_FAIL = RESET_ORGANIZATION + FAIL;
export const CLEAR_RESET_ORGANIZATION_STATUS = 'whistlems/admin/CLEAR_RESET_ORGANIZATION_STATUS';

const PASSWORD_FLAG = 'whistlems/admin/PASSWORD_FLAG';
const PASSWORD_FLAG_SUCCESS = PASSWORD_FLAG + SUCCESS;
const PASSWORD_FLAG_FAIL = PASSWORD_FLAG + FAIL;

// ------------------------------------
// Actions
// ------------------------------------
export const modifyPasswordRequest = (authInfo) => {
  return {
    types: [MODIFY_PASSWORD_LOAD, MODIFY_PASSWORD_SUCCESS, MODIFY_PASSWORD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'changePasswd', {
        account: authInfo.account,
        password: authInfo.oldPassword,
        new_passwd: authInfo.newPassword
      })
    })
  };
};
export const resetOrg = (password) => {
  return {
    types: [RESET_ORGANIZATION, RESET_ORGANIZATION_SUCCESS, RESET_ORGANIZATION_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgReset', {
        password
      })
    })
  };
};
export const clearResetOrg = () => {
  return {
    type: CLEAR_RESET_ORGANIZATION_STATUS
  };
};

export const passwordFlag = () => {
  return {
    types: [PASSWORD_FLAG, PASSWORD_FLAG_SUCCESS, PASSWORD_FLAG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('customer', 'getPasswordType', {})
    })
  };
};

export const actions = {
  modifyPasswordRequest,
  resetOrg,
  clearResetOrg
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [MODIFY_PASSWORD_LOAD]: (state, action) => {
    return { text: '正在修改，请稍候…', state: MODIFY_PASSWORD_LOAD };
  },
  [MODIFY_PASSWORD_SUCCESS]: (state, action) => {
    return { text: '修改成功!', state: MODIFY_PASSWORD_SUCCESS };
  },
  [MODIFY_PASSWORD_FAIL]: (state, action) => {
    const err = action.payload;
    return {
      text: err.errmsg || '网络错误',
      state: MODIFY_PASSWORD_FAIL
    };
  },
  [RESET_ORGANIZATION]: (state, action) => {
    return {
      ...state,
      orgResetting: true,
      orgReset: undefined,
      orgResetError: undefined
    };
  },
  [RESET_ORGANIZATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      orgResetting: undefined,
      orgReset: true,
      orgResetError: undefined
    };
  },
  [RESET_ORGANIZATION_FAIL]: (state, action) => {
    return {
      ...state,
      orgResetting: undefined,
      orgResetError: action.payload
    };
  },
  [CLEAR_RESET_ORGANIZATION_STATUS]: (state, action) => {
    return {
      ...state,
      orgResetting: undefined,
      orgReset: undefined,
      orgResetError: undefined
    };
  },
  [PASSWORD_FLAG]: (state, action) => {
    return {
      ...state,
      passwordFlagLoading: true,
      passwordFlagLoaded: false,
      passwordFlagError: undefined
    };
  },
  [PASSWORD_FLAG_SUCCESS]: (state, action) => {
    return {
      ...state,
      passwordFlagLoading: false,
      passwordFlagLoaded: true,
      passwordFlagError: undefined,
      passwordFlagResult: action.payload.data
    };
  },
  [PASSWORD_FLAG_FAIL]: (state, action) => {
    return {
      ...state,
      passwordFlagLoading: false,
      passwordFlagLoaded: false,
      passwordFlagError: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  text: '正在加载,请稍候…',
  state: MODIFY_PASSWORD_LOAD
};

const modifyPasswordReducer = makeReducer(ACTION_HANDLERS, initialState);
export default modifyPasswordReducer;
