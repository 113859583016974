import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import ApiClient, { formatUrl } from 'helpers/ApiClient';
import {preAppendToArray, removeArrayElement} from 'helpers/arrayUtils';
import {RELOGIN} from 'store/modules/reloginService';

const PREFIX = 'whistlems/organization/';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

const JOB_LIST = PREFIX + 'JOB_LIST';
const JOB_LIST_SUCCESS = JOB_LIST + SUCCESS;
const JOB_LIST_FAIL = JOB_LIST + FAIL;

const JOB_ADD = PREFIX + 'JOB_ADD';
const JOB_ADD_SUCCESS = JOB_ADD + SUCCESS;
const JOB_ADD_FAIL = JOB_ADD + FAIL;

const JOB_UPDATE = PREFIX + 'JOB_UPDATE';
const JOB_UPDATE_SUCCESS = JOB_UPDATE + SUCCESS;
const JOB_UPDATE_FAIL = JOB_UPDATE + FAIL;

const JOB_DELETE = PREFIX + 'JOB_DELETE';
const JOB_DELETE_SUCCESS = JOB_DELETE + SUCCESS;
const JOB_DELETE_FAIL = JOB_DELETE + FAIL;

const JOB_USER_LIST = PREFIX + 'JOB_USER_LIST';
const JOB_USER_LIST_AT_PAGE = JOB_USER_LIST + '_AT_PAGE';
const JOB_USER_LIST_SUCCESS = JOB_USER_LIST + SUCCESS;
const JOB_USER_LIST_FAIL = JOB_USER_LIST + FAIL;

const JOB_USER_ADD = PREFIX + 'JOB_USER_ADD';
const JOB_USER_ADD_SUCCESS = JOB_USER_ADD + SUCCESS;
const JOB_USER_ADD_FAIL = JOB_USER_ADD + FAIL;

const JOB_USER_DELETE = PREFIX + 'JOB_USER_DELETE';
const JOB_USER_DELETE_SUCCESS = JOB_USER_DELETE + SUCCESS;
const JOB_USER_DELETE_FAIL = JOB_USER_DELETE + FAIL;

const JOB_USER_IMPORT = PREFIX + 'JOB_USER_IMPORT';
const JOB_USER_IMPORT_SUCCESS = JOB_USER_IMPORT + SUCCESS;
const JOB_USER_IMPORT_FAIL = JOB_USER_IMPORT + FAIL;

const JOB_ORG_LIST = PREFIX + 'JOB_ORG_LIST';
const JOB_ORG_LIST_SUCCESS = JOB_ORG_LIST + SUCCESS;
const JOB_ORG_LIST_FAIL = JOB_ORG_LIST + FAIL;

const SET_ACTIVE_ITEM = PREFIX + 'SET_ACTIVE_ITEM';
const CLEAR_ACTIVE_ITEM = PREFIX + 'CLEAR_ACTIVE_ITEM';

export const JOB_USER_COUNT_PER_PAGE = 20;

export const jobList = () => {
  return {
    types: [JOB_LIST, JOB_LIST_SUCCESS, JOB_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'jobList', {})
    })
  };
};

export const setActiveItem = (item) => {
  return {
    type: SET_ACTIVE_ITEM,
    item
  };
};

export const clearActiveItem = () => {
  return {
    type: CLEAR_ACTIVE_ITEM
  };
};

export const jobAdd = (name) => {
  return {
    types: [JOB_ADD, JOB_ADD_SUCCESS, JOB_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'jobAdd', {
        name
      })
    })
  };
};

export const jobUpdate = (job) => {
  return {
    types: [JOB_UPDATE, JOB_UPDATE_SUCCESS, JOB_UPDATE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'jobModify', {
        ...job
      })
    }),
    job
  };
};

export const jobDelete = (jobId) => {
  return {
    types: [JOB_DELETE, JOB_DELETE_SUCCESS, JOB_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'jobDelete', {
        job_id: jobId
      })
    }),
    jobId
  };
};

export const jobUserList = (info) => {
  return {
    types: [JOB_USER_LIST, JOB_USER_LIST_SUCCESS, JOB_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', info.data_type === 4 ? 'globalSearch' : 'jobUserList', {
        ...info
      })
    }),
    info
  };
};

export const jobUserListAtPage = (info) => {
  return {
    types: [JOB_USER_LIST_AT_PAGE, JOB_USER_LIST_SUCCESS, JOB_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', info.data_type === 4 ? 'globalSearch' : 'jobUserList', {
        ...info
      })
    }),
    info
  };
};


export const jobUserAdd = (info) => {
  return {
    types: [JOB_USER_ADD, JOB_USER_ADD_SUCCESS, JOB_USER_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'jobUserAdd', {
        ...info
      })
    })
  };
};

export const jobUserDelete = (userArray) => {
  return {
    types: [JOB_USER_DELETE, JOB_USER_DELETE_SUCCESS, JOB_USER_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'jobUserDelete', {
        data: JSON.stringify(userArray)
      })
    }),
    userArray
  };
};

export const jobUserImport = (file, jobId) => {
  const pathObj = formatParams('org', 'importJobUser', { file_length: file.size, post_id: jobId });
  return (dispatch, getState) => {
    dispatch({
      type: JOB_USER_IMPORT
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: JOB_USER_IMPORT_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: JOB_USER_IMPORT_FAIL,
          payload: err
        });
      });
  };
};

export const jobOrgList = () => {
  return {
    types: [JOB_ORG_LIST, JOB_ORG_LIST_SUCCESS, JOB_ORG_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'getRootOrgInfo', {
      })
    })
  };
};

export const actions = {
  jobUserList
};

const ACTION_HANDLERS = {
  [JOB_LIST]: (state, action) => {
    return {
      ...state,
      jobListLoading: true,
      jobListLoaded: false,
      jobListError: undefined,
      activeItem: undefined
    };
  },
  [JOB_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      jobListLoading: false,
      jobListLoaded: true,
      jobListError: undefined,
      jobListResult: action.payload.data
    };
  },
  [JOB_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      jobListLoading: false,
      jobListLoaded: false,
      jobListError: action.payload.errmsg
    };
  },
  [JOB_ADD]: (state, action) => {
    return {
      ...state,
      jobAddLoading: true,
      jobAddLoaded: false,
      jobAddError: undefined
    };
  },
  [JOB_ADD_SUCCESS]: (state, action) => {
    const jobInfo = action.payload.data;
    return {
      ...state,
      jobAddLoading: false,
      jobAddLoaded: true,
      jobAddError: undefined,
      jobListResult: preAppendToArray(state.jobListResult, [jobInfo])
    };
  },
  [JOB_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      jobAddLoading: false,
      jobAddLoaded: false,
      jobAddError: action.payload.errmsg
    };
  },
  [JOB_UPDATE]: (state, action) => {
    return {
      ...state,
      jobUpdateLoading: true,
      jobUpdateLoaded: false,
      jobUpdateError: undefined
    };
  },
  [JOB_UPDATE_SUCCESS]: (state, action) => {
    const updateJob = Object.assign({}, state.activeItem, {name: action.job.name});
    const list = state.jobListResult.map(item => {
      if (item.id === updateJob.id) {
        item.name = updateJob.name;
      }
      return item;
    });
    return {
      ...state,
      jobUpdateLoading: false,
      jobUpdateLoaded: true,
      jobUpdateError: undefined,
      jobListResult: list,
      activeItem: updateJob
    };
  },
  [JOB_UPDATE_FAIL]: (state, action) => {
    return {
      ...state,
      jobUpdateLoading: false,
      jobUpdateLoaded: false,
      jobUpdateError: action.payload.errmsg
    };
  },
  [JOB_DELETE]: (state, action) => {
    return {
      ...state,
      jobDeleteLoading: true,
      jobDeleteLoaded: false,
      jobDeleteError: undefined
    };
  },
  [JOB_DELETE_SUCCESS]: (state, action) => {
    const jobInfo = state.jobListResult.find(item => item.id === action.jobId);
    return {
      ...state,
      jobDeleteLoading: false,
      jobDeleteLoaded: true,
      jobDeleteError: undefined,
      jobListResult: removeArrayElement(state.jobListResult, jobInfo),
      activeItem: undefined,
      jobUserListResult: undefined
    };
  },
  [JOB_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      jobDeleteLoading: false,
      jobDeleteLoaded: false,
      jobDeleteError: action.payload.errmsg
    };
  },
  [JOB_USER_LIST]: (state, action) => {
    const query = action.info;
    if (!query.page) {
      query.page = 1;
    }
    if (!query.count) {
      query.count = JOB_USER_COUNT_PER_PAGE;
    }
    return {
      ...state,
      jobUserQuery: query,
      jobUserListLoading: true,
      jobUserListLoaded: false,
      jobUserListError: undefined
    };
  },
  [JOB_USER_LIST_AT_PAGE]: (state, action) => {
    return {
      ...state,
      jobUserQuery: action.info,
      jobUserListLoading: true,
      jobUserListLoaded: false,
      jobUserListError: undefined
    };
  },
  [JOB_USER_LIST_SUCCESS]: (state, action) => {
    const data = action.payload.data;
    return {
      ...state,
      jobUserListLoading: false,
      jobUserListLoaded: true,
      jobUserListError: undefined,
      jobUserListResult: {
        total: action.info.data_type === 4 ? data.count : data.total,
        list: data.list
      }
    };
  },
  [JOB_USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      jobUserListLoading: false,
      jobUserListLoaded: false,
      jobUserListError: action.payload.errmsg
    };
  },
  [JOB_USER_ADD]: (state, action) => {
    return {
      ...state,
      jobUserAddLoading: true,
      jobUserAddLoaded: false,
      jobUserAddError: undefined
    };
  },
  [JOB_USER_ADD_SUCCESS]: (state, action) => {
    return {
      ...state,
      jobUserAddLoading: false,
      jobUserAddLoaded: true,
      jobUserAddError: undefined,
      jobUserAddResult: action.payload.data
    };
  },
  [JOB_USER_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      jobUserAddLoading: false,
      jobUserAddLoaded: false,
      jobUserAddError: action.payload.errmsg
    };
  },
  [JOB_USER_DELETE]: (state, action) => {
    return {
      ...state,
      jobUserDeleteLoading: true,
      jobUserDeleteLoaded: false,
      jobUserDeleteError: undefined
    };
  },
  [JOB_USER_DELETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      jobUserDeleteLoading: false,
      jobUserDeleteLoaded: true,
      jobUserDeleteError: undefined,
      jobUserDeleteResult: action.payload.data,
      jobUserListResult: Object.assign({}, state.jobUserListResult,
        {total: state.jobUserListResult.total - action.userArray.length},
        {list_length: state.jobUserListResult.list.length - action.userArray.length})
    };
  },
  [JOB_USER_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      jobUserDeleteLoading: false,
      jobUserDeleteLoaded: false,
      jobUserDeleteError: action.payload.errmsg
    };
  },
  [JOB_USER_IMPORT]: (state, action) => {
    return {
      ...state,
      jobUserImportLoading: true,
      jobUserImportLoaded: false,
      jobUserImportError: undefined
    };
  },
  [JOB_USER_IMPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      jobUserImportLoading: false,
      jobUserImportLoaded: true,
      jobUserImportError: undefined,
      importMsg: action.payload.body
    };
  },
  [JOB_USER_IMPORT_FAIL]: (state, action) => {
    return {
      ...state,
      jobUserImportLoading: false,
      jobUserImportLoaded: false,
      jobUserImportError: action.payload
    };
  },
  [JOB_ORG_LIST]: (state, action) => {
    return {
      ...state,
      jobOrgListLoading: true,
      jobOrgListLoaded: false,
      jobOrgListError: undefined
    };
  },
  [JOB_ORG_LIST_SUCCESS]: (state, action) => {
    const orgList = action.payload.data;
    let list = [];
    if (orgList && orgList.length > 0) {
      list = orgList.map(item => Object.assign({value: item.id}, {label: item.name}));
    }
    list.unshift({value: 'all', label: '全部组织架构'});
    return {
      ...state,
      jobOrgListLoading: false,
      jobOrgListLoaded: true,
      jobOrgListError: undefined,
      jobOrgListResult: list
    };
  },
  [JOB_ORG_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      jobOrgListLoading: false,
      jobOrgListLoaded: false,
      jobOrgListError: action.payload.errmsg
    };
  },
  [SET_ACTIVE_ITEM]: (state, action) => {
    return {
      ...state,
      activeItem: action.item
    };
  },
  [CLEAR_ACTIVE_ITEM]: (state, action) => {
    return {
      ...state,
      activeItem: undefined
    };
  },
  [RELOGIN]: (state, action) => {
    return initialState;
  }
};

const initialState = {};

const jobReducer = makeReducer(ACTION_HANDLERS, initialState);
export default jobReducer;
