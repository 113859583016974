import React from 'react';
import Select from 'react-select';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import PropTypes from 'prop-types';

const SelectInput = ({maxSize, value, options, placeholder, onChange, showName, disabled, valueStyle, style = {},
  showTips = true, searchable = false, simpleValue, ...props}) => {
  const valueRenderer = (option) => {
    const label = showName && showName + '(' + option.label + ')' || option.label;
    return (
      <div style={valueStyle || {}}>
        <AutoShrinkTextWithTip text={label} maxSize={maxSize || 120} fontSize={14} showTips={showTips} />
      </div>
    );
  };

  const optionRenderer = (option) => {
    return <AutoShrinkTextWithTip text={option.label} maxSize={maxSize || 120} fontSize={14} showTips={showTips} />;
  };

  const selectStyle = Object.assign({
    borderRadius: '0px',
    border: '1px solid #ccc',
    height: '32px',
    textAlign: 'left'
  }, style);

  return (<Select
    value={value}
    options={options}
    simpleValue={simpleValue}
    clearable={false}
    disabled={disabled}
    onChange={onChange}
    style={selectStyle}
    matchProp='label'
    searchable={searchable}
    noResultsText={'暂无数据'}
    placeholder={placeholder || '请选择'}
    valueRenderer={valueRenderer}
    optionRenderer={optionRenderer}
    {...props}
  />);
};

SelectInput.propTypes = {
  maxSize: PropTypes.number,
  value: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  showName: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  showTips: PropTypes.bool,
  searchable: PropTypes.bool,
  simpleValue: PropTypes.bool,
  valueStyle: PropTypes.object
};

export default SelectInput;
