import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'react-spinner';
import classNames from 'classnames';
import classes from './LoadingView.scss';

/**
 * 本LoadingView使用absolute定位,必须保证所包含它的容器有定位(relative, fixed, absolute均可)
 * @param className
 * @param style
 * @constructor
 */
export const LoadingView = ({className, style}) => (
  <div className={classNames(classes.container, className)} style={style}>
    <Spinner className={classes.spinner} />
  </div>
);

LoadingView.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default LoadingView;
