import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/20.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './OrganizationOperationView.scss';
import classNames from 'classnames';
import {ascendNode, descendNode} from '../modules/organization';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Icon from 'components/Icon';
import { getAuthInfo } from 'helpers/AuthHelper';

const mapActionCreators = {
  ascendNode,
  descendNode
};

const mapStateToProps = (state) => ({
  activeNode: state.organization.activeNode,
  nodeOrdered: state.organization.nodeOrdered,
  nodeOrderError: state.organization.nodeOrderError,
  nodeOrdering: state.organization.nodeOrdering
});

const TOOLTIP = (<Tooltip id='tooltip'>先选择组织节点,点击按钮即可排序</Tooltip>);

class OrganizationOperationView extends Component {
  static propTypes = {
    nodeOrdering: PropTypes.bool,
    nodeOrdered: PropTypes.bool,
    nodeOrderError: PropTypes.object,
    activeNode: PropTypes.object,
    ascendNode: PropTypes.func,
    descendNode: PropTypes.func,
    onCreateNewTreeNode: PropTypes.func
  };

  componentWillReceiveProps = (nextProps) => {
    const {nodeOrdered, nodeOrderError} = this.props;
    if (!nodeOrdered && nextProps.nodeOrdered) {
      showSuccessToast('排序完毕');
    }
    if (!nodeOrderError && nextProps.nodeOrderError) {
      showErrorToast('排序失败，请稍后重试');
    }
  }

  onCreateNewTreeNode = () => {
    this.props.onCreateNewTreeNode();
  }

  onAscendNode = () => {
    const {activeNode} = this.props;
    const currentIndex = activeNode.parent.children.indexOf(activeNode);
    this.props.ascendNode(activeNode, activeNode.parent.children[currentIndex - 1]);
  }

  onDescendNode = () => {
    const {activeNode} = this.props;
    const currentIndex = activeNode.parent.children.indexOf(activeNode);
    this.props.descendNode(activeNode, activeNode.parent.children[currentIndex + 1]);
  }

  isFirstChild = () => {
    const {activeNode} = this.props;
    return activeNode.parent.children[0] === activeNode;
  }

  isLastChild = () => {
    const {activeNode} = this.props;
    return activeNode.parent.children[activeNode.parent.children.length - 1] === activeNode;
  }

  render() {
    const {activeNode, nodeOrdering} = this.props;
    const isGoUpDisabled = activeNode && activeNode.parentId !== '0'
      && activeNode.parent ? this.isFirstChild() : true;
    const isGoDownDisabled = activeNode && activeNode.parentId !== '0'
      && activeNode.parent ? this.isLastChild() : true;
    const notAdminOrg = activeNode && activeNode.orgType !== '1'; // 行政组织架构是1，其他组织架构是2
    const auth = getAuthInfo();
    const isGroundUser = auth && auth.isGroundUser || false;
    return (
      <div className={classes.orgOperationView}>
        <div className={classNames(classes.lineCell, classes.title)} style={{color: '#737373'}}>调整排序</div>
        {isGoUpDisabled && <div className={classNames(classes.lineCell, classes.ordering)}>
          <OverlayTrigger placement='top' overlay={TOOLTIP}>
            <Icon type='up' className='wsicon-lg text-muted' />
          </OverlayTrigger>
        </div>}
        {!isGoUpDisabled && <div className={classNames(classes.lineCell, classes.ordering)}
          style={{ cursor: 'pointer' }}>
          {nodeOrdering && <Icon type='up' className='wsicon-lg text-muted' />}
          {!nodeOrdering && <OverlayTrigger placement='top' overlay={TOOLTIP}>
            <Icon type='up' className='wsicon-lg text-primary' onClick={this.onAscendNode} />
          </OverlayTrigger>}
        </div>}
        {isGoDownDisabled && <div className={classNames(classes.lineCell, classes.ordering)}>
          <OverlayTrigger placement='top' overlay={TOOLTIP}>
            <Icon type='down' className='wsicon-lg text-muted' />
          </OverlayTrigger>
        </div>}
        {!isGoDownDisabled && <div className={classNames(classes.lineCell, classes.ordering)}
          style={{ cursor: 'pointer' }}>
          {nodeOrdering && <Icon type='down' className='wsicon-lg text-muted' />}
          {!nodeOrdering && <OverlayTrigger placement='top' overlay={TOOLTIP}>
            <Icon type='down' className='wsicon-lg text-primary' onClick={this.onDescendNode} />
          </OverlayTrigger>}
        </div>}
        <div className={classNames(classes.lineCell, classes.newOrgButton)}>
          {
            notAdminOrg
              ? <button type='button' className='btn btn-link' onClick={this.onCreateNewTreeNode}>
                <Icon type='add' className='wsicon-lg' />新建
              </button>
              : !isGroundUser ? <button type='button' className='btn btn-link' onClick={this.onCreateNewTreeNode}>
                <Icon type='add' className='wsicon-lg' />新建
              </button> : ''
          }
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapActionCreators)(OrganizationOperationView);
