import PropTypes from 'prop-types';
/**
 * Created by liming on 16/7/11.
 */
import React, { Component } from 'react';
import {reduxForm, propTypes, Field} from 'redux-form';
import TextInputWithErrorTips from 'components/TextInputWithErrorTips';
import Dialog from 'components/Dialog';
import classes from './DataResetView.scss';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = '请输入重置密码';
  }
  return errors;
};

const Input = ({input, type, onKeyDown, meta: {touched, error}}) => (
  <TextInputWithErrorTips
    inputProps={{ name: 'confirmResetPassword', id: 'confirmResetPassword', type, onKeyDown }}
    reduxFormProps={input} error={touched ? error : ''} />
);

class DataResetView extends Component {
  static propTypes = {
    ...propTypes,
    resetOrg: PropTypes.func,
    clearResetOrg: PropTypes.func,
    orgResetting: PropTypes.bool,
    orgReset: PropTypes.bool,
    orgResetError: PropTypes.object,
    cancelReset: PropTypes.func
  }

  componentDidMount() {
    if (!this.props.rootLoaded) {
      this.props.loadOrgRoot();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.orgReset && !this.props.orgReset) { // 关闭重置组织结构树弹窗
      showSuccessToast('重置成功');
      this.onCancel();
    }
    if (!this.props.orgResetError && nextProps.orgResetError) {
      showErrorToast('重置失败：' + nextProps.orgResetError.errmsg);
    }
  }
  onSubmit = (data) => {
    const {password} = data;
    this.props.resetOrg(password);
  }
  onCancel = () => {
    this.props.reset();
    this.props.clearResetOrg();
    this.props.cancelReset();
    if (this.props.activeNode) {
      this.props.queryUsers({
        org_id: this.props.activeNode.id,
        isSearchMode: false
      });
    }
  }
  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const {orgResetting, pristine, invalid, handleSubmit} = this.props;
    return (
      <Dialog title='重置' show
        positiveButton={{
          disabled: pristine || invalid,
          loading: orgResetting,
          onClick: handleSubmit(this.onSubmit)
        }}
        negativeButton={{
          onClick: this.onCancel
        }}>
        <form>
          <div className={classes.tip}>
            重置之后会删除学校下的所有内容。<br />
            包括用户、权限、应用、群聊、个性化设置等，请慎重选择。
          </div>
          <div className='form-group'>
            <label htmlFor='confirmResetPassword'>确认删除，请输入管理员密码</label>
            <Field name='password' type='password' component={Input} onKeyDown={this.onKeyDown} />
          </div>
        </form>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'dataResetForm',
  validate
})(DataResetView);
