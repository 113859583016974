/**
 * Created by liming on 16/4/8.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {getTextWidth, findTextFitPosition} from 'helpers/textUtils';
import { purifyHtml } from 'helpers/XssUtils';
import classNames from 'classnames';
import convert from 'htmr';
import styleVariables from '../../../config/style.variables';

import classes from './AutoShrinkTextWithTip.scss';

const PRIMARY_COLOR = styleVariables && styleVariables.color_primary;

class AutoShrinkTextWithTip extends Component {

  state = {
    text: '',
    targetCount: 0
  };

  componentDidMount() {
    if (this.node) {
      this.computeLine();
    }
  }

  componentDidUpdate(perProps) {
    const { lines } = this.props;
    if (lines !== perProps.lines) {
      this.computeLine();
    }
  }

  computeLine = () => {
    const { lines } = this.props;
    const text = this.shadowChildren.innerText || this.shadowChildren.textContent; // 兼容node innerText
    const lineHeight = parseInt(getComputedStyle(this.root).lineHeight, 10); // 获取行高
    const targetHeight = lines * lineHeight; // 总高
    this.content.style.height = `${targetHeight}px`;
    const totalHeight = this.shadowChildren.offsetHeight;
    const shadowNode = this.shadow.firstChild;

    if (totalHeight <= targetHeight) {
      this.setState({
        text,
        targetCount: text.length
      }, () => {
        this.content.style.height = `${totalHeight}px`;
      });
      return;
    }

    // bisection
    const len = text.length;
    const mid = Math.ceil(len / 2);

    const count = this.bisection(targetHeight, mid, 0, len, text, shadowNode);

    this.setState({
      text,
      targetCount: count
    });
  }

  // th : target height
  bisection = (th, m, b, e, text, shadowNode) => {
    const suffix = '…';
    let mid = m;
    let end = e;
    let begin = b;
    shadowNode.innerHTML = text.substring(0, mid) + suffix;
    let sh = shadowNode.offsetHeight; // shadow height

    if (sh <= th) {
      shadowNode.innerHTML = text.substring(0, mid + 1) + suffix;
      sh = shadowNode.offsetHeight;
      if (sh > th || mid === begin) {
        return mid;
      }
      begin = mid;
      if (end - begin === 1) {
        mid = 1 + begin;
      } else {
        mid = Math.floor((end - begin) / 2) + begin;
      }
      return this.bisection(th, mid, begin, end, text, shadowNode);
    }
    if (mid - 1 < 0) {
      return mid;
    }
    shadowNode.innerHTML = text.substring(0, mid - 1) + suffix;
    sh = shadowNode.offsetHeight;
    if (sh <= th) {
      return mid - 1;
    }
    end = mid;
    mid = Math.floor((end - begin) / 2) + begin;
    return this.bisection(th, mid, begin, end, text, shadowNode);
  }

  handleRoot = n => {
    this.root = n;
  };

  handleContent = n => {
    this.content = n;
  };

  handleNode = n => {
    this.node = n;
  };

  handleShadow = n => {
    this.shadow = n;
  };

  handleShadowChildren = n => {
    this.shadowChildren = n;
  };

  render() {
    const { text, fontSize, maxSize, showTips, placement, highlight, lines, className, ...props } = this.props;

    const tooltipStyle = {
      maxWidth: '310px',
      wordWrap: 'break-word',
      textAlign: 'left'
    };
    const tooltip = (
      <Tooltip bsClass='tooltip'>
        <div style={tooltipStyle}>{text}</div>
      </Tooltip>
    );

    // 显示指定行数
    if (lines && lines > 1) {
      const { targetCount, text: displayText } = this.state;
      const cls = classNames(className, classes.ellipsis, classes.lines);

      const realTooltipFlag = showTips && text.length > targetCount;
      const childNode = (
        <span ref={this.handleNode} className={classes.linesTextStyle}>
          {targetCount > 0 && text.substring(0, text.length > targetCount ? targetCount - 1 : targetCount)}
          {targetCount > 0 && targetCount < text.length && '…'}
        </span>
      );
      return (
        <div ref={this.handleRoot} className={cls}>
          <div ref={this.handleContent}>
            {realTooltipFlag
              ? <OverlayTrigger placement={placement} overlay={tooltip}>
                <div style={{ fontSize: fontSize }} {...props}>
                  {childNode}
                </div>
              </OverlayTrigger>
              : <div style={{ fontSize: fontSize }} {...props}>
                {childNode}
              </div>}
            <div style={{ width: maxSize + 'px' }} className={classes.shadow} ref={this.handleShadowChildren}>
              {text}
            </div>
            <div className={classes.shadow} ref={this.handleShadow} style={{ width: maxSize + 'px' }}>
              <span>{displayText}</span>
            </div>
          </div>
        </div>
      );
    }

    const textWidth = getTextWidth(text, fontSize); // 获取文本宽度
    const highlightRegExp = new RegExp(highlight, 'g'); // 高亮正则

    // 文本宽度小于给定宽度 直接返回
    if (textWidth < maxSize) {
      let renderText = text;
      if (highlight) {
        renderText = renderText.replace(highlightRegExp,
          `<span style="color:${PRIMARY_COLOR}">${highlight}</span>`);
        renderText = <span>{convert(purifyHtml(renderText))}</span>;
      }
      return (<span {...props}>{renderText}</span>);
    }
    const fitTextPosition = findTextFitPosition(text, fontSize, maxSize);
    let fitText = text.substr(0, fitTextPosition);
    if (highlight) {
      fitText = fitText.replace(highlightRegExp,
        `<span style="color:${PRIMARY_COLOR}">${highlight}</span>`);
      fitText = fitText + '…';
      fitText = <span>{convert(fitText)}</span>;
    } else {
      fitText = fitText + '…';
    }
    const fontSizePx = fontSize + 'px';
    if (showTips) {
      return (
        <OverlayTrigger placement={placement} overlay={tooltip}>
          <span style={{ whiteSpace: 'nowrap', wordBreak: 'break-all', fontSize: fontSizePx }} {...props}>
            {fitText}
          </span>
        </OverlayTrigger>
      );
    } else {
      return (
        <span style={{ whiteSpace: 'nowrap', wordBreak: 'break-all', fontSize: fontSizePx }} {...props}>
          {fitText}
        </span>
      );
    }
  }
}

AutoShrinkTextWithTip.defaultProps = {
  placement: 'top',
  showTips: true
};

AutoShrinkTextWithTip.propTypes = {
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
  showTips: PropTypes.bool,
  placement: PropTypes.oneOf([ 'top', 'bottom', 'left', 'right' ]),
  highlight: PropTypes.string,
  lines: PropTypes.number, // 行数
  className: PropTypes.string
};

export default AutoShrinkTextWithTip;
