import ModuleLayout from 'layouts/ModuleLayout';
import RouteLayout from 'layouts/RouteLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback, getRouteReplace } from '../routeUtils';
import { getAuthInfo } from 'helpers/AuthHelper';

const checkCommentFactory = (url) => (nextState, replace) => {
  const auth = getAuthInfo();
  if (!auth || auth.hideComment) {
    replace(url);
  }
};

export const route = (store) => ({
  name: '应用中心',
  path: 'appstore',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) =>
    getRouteReplace(replace, 'appstore', 'board', 'subadmin') }, // IndexRedirect
  childRoutes: [
    {
      name: '学校应用',
      path: 'board',
      component: RouteLayout,
      indexRoute: { onEnter: (nextState, replace) => replace('/appstore/board/list') },
      childRoutes: [
        {
          name: '应用列表',
          staticName: true,
          path: 'list(/:tab)',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppBoardView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '搜索结果',
          path: 'query',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppBoardView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '添加应用',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppAddView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用编辑',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppEditView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用编辑',
          path: 'wsedit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/WhistleAppEdit').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用完善资料',
          path: 'filling',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppEditView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用详情',
          path: 'detail',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppDetailView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '使用统计详情',
          staticName: true,
          path: 'statistics/:id/:name',
          indexRoute: {
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, {key: 'app', reducer});
                const Component = require('./containers/AppUseInfo').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'AppStore');
            }
          },
          childRoutes: [
            {
              name: '使用明细',
              staticName: true,
              path: 'appUseDetail',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/AppUseDetail').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            }
          ]
        },
        {
          name: '评论管理',
          path: 'comment',
          onEnter: checkCommentFactory('/appstore/board'),
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/CommentManage').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '消息管理',
          path: 'message/:id/:name',
          staticName: true,
          indexRoute: {
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, { key: 'app', reducer });
                injectReducer(store, { key: 'notice', reducer: require('routes/Notice/modules').default });
                const Component = require('./containers/MessageManage').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'AppStore');
            }
          },
          childRoutes: [
            {
              name: '发消息',
              path: 'add',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/AddMessage').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            },
            {
              name: '消息详情',
              path: 'detail/:msgid',
              staticName: true,
              indexRoute: {
                getComponent(nextState, callback) {
                  require.ensure([], (require) => {
                    const reducer = require('./modules').default;
                    injectReducer(store, { key: 'app', reducer });
                    injectReducer(store, { key: 'notice', reducer: require('routes/Notice/modules').default });
                    const Component = require('./containers/MessageDetail').default;
                    callback(null, Component);
                  }, getErrorCallback(callback), 'AppStore');
                }
              },
              childRoutes: [
                {
                  name: '消息发送详情',
                  path: 'list',
                  getComponent(nextState, callback) {
                    require.ensure([], (require) => {
                      const reducer = require('./modules').default;
                      injectReducer(store, { key: 'app', reducer });
                      injectReducer(store, { key: 'notice', reducer: require('routes/Notice/modules').default });
                      const Component = require('routes/Notice/containers/MsgSendDetail').default;
                      callback(null, Component);
                    }, getErrorCallback(callback), 'AppStore');
                  }
                }
              ]
            }
          ]
        },
        {
          name: '类型管理',
          path: 'typeManage',
          indexRoute: {
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, {key: 'app', reducer});
                const Component = require('./containers/TypeManage/typeList').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'AppStore');
            }
          },
          childRoutes: [
            {
              name: '添加类型',
              staticName: true,
              path: 'addType',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/TypeManage/typeOperate').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            },
            {
              name: '编辑类型',
              staticName: true,
              path: 'editType',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/TypeManage/typeOperate').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            },
            {
              name: '类型排序',
              staticName: true,
              path: 'sortType',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/TypeManage/typeSort').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            }
          ]
        }
      ]
    },
    {
      name: '应用商店',
      path: 'market',
      component: RouteLayout,
      indexRoute: { onEnter: (nextState, replace) => replace('/appstore/market/list') },
      childRoutes: [
        {
          name: '后台',
          path: 'admin',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppAdmin').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用列表',
          staticName: true,
          path: 'list(/:tab)',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppMarket').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '搜索结果',
          path: 'query',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppMarket').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用编辑',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppEditView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用编辑',
          path: 'wsedit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/WhistleAppEdit').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '应用详情',
          path: 'detail',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppDetailView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '评论管理',
          path: 'comment',
          onEnter: checkCommentFactory('/appstore/market'),
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/CommentManage').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        }
      ]
    },
    {
      name: '内外网配置',
      path: 'netConfig',
      component: RouteLayout,
      indexRoute: {
        name: '内外网配置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'app', reducer });
            const Component = require('./containers/NetConfig').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AppStore');
        }
      }
    },
    {
      name: '评论管理',
      path: 'comment',
      onEnter: checkCommentFactory('/appstore'),
      component: RouteLayout,
      indexRoute: {
        name: '评论管理',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'app', reducer });
            const Component = require('./containers/CommentManage').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AppStore');
        }
      }
    },
    {
      name: '卡片管理',
      path: 'cardManage',
      component: RouteLayout,
      indexRoute: {
        name: '卡片管理',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'app', reducer });
            const Component = require('./containers/CardManage').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AppStore');
        }
      },
      childRoutes: [
        {
          name: '卡片排序',
          path: 'cardSort',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/SortCard').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        }
      ]
    },
    {
      name: '应用列表',
      path: 'subadmin',
      component: RouteLayout,
      indexRoute: {
        name: '应用列表',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'app', reducer });
            const Component = require('./containers/AppBoardView').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AppStore');
        }
      },
      childRoutes: [
        {
          name: '应用详情',
          path: 'detail',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/AppDetailView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '使用统计详情',
          staticName: true,
          path: 'statistics/:id/:name',
          indexRoute: {
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, {key: 'app', reducer});
                const Component = require('./containers/AppUseInfo').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'AppStore');
            }
          },
          childRoutes: [
            {
              name: '使用明细',
              staticName: true,
              path: 'appUseDetail',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/AppUseDetail').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            }
          ]
        },
        {
          name: '评论管理',
          path: 'comment',
          onEnter: checkCommentFactory('/appstore/subadmin'),
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/CommentManage').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '消息管理',
          path: 'message/:id/:name',
          staticName: true,
          indexRoute: {
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, { key: 'app', reducer });
                injectReducer(store, { key: 'notice', reducer: require('routes/Notice/modules').default });
                const Component = require('./containers/MessageManage').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'AppStore');
            }
          },
          childRoutes: [
            {
              name: '发消息',
              path: 'add',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'app', reducer });
                  const Component = require('./containers/AddMessage').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'AppStore');
              }
            },
            {
              name: '消息详情',
              path: 'detail/:msgid',
              staticName: true,
              indexRoute: {
                getComponent(nextState, callback) {
                  require.ensure([], (require) => {
                    const reducer = require('./modules').default;
                    injectReducer(store, { key: 'app', reducer });
                    injectReducer(store, { key: 'notice', reducer: require('routes/Notice/modules').default });
                    const Component = require('./containers/MessageDetail').default;
                    callback(null, Component);
                  }, getErrorCallback(callback), 'AppStore');
                }
              },
              childRoutes: [
                {
                  name: '消息发送详情',
                  path: 'list',
                  getComponent(nextState, callback) {
                    require.ensure([], (require) => {
                      const reducer = require('./modules').default;
                      injectReducer(store, { key: 'app', reducer });
                      injectReducer(store, { key: 'notice', reducer: require('routes/Notice/modules').default });
                      const Component = require('routes/Notice/containers/MsgSendDetail').default;
                      callback(null, Component);
                    }, getErrorCallback(callback), 'AppStore');
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: '业务系统',
      path: 'businessSystem',
      component: RouteLayout,
      indexRoute: {
        name: '业务系统',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'app', reducer });
            const Component = require('./containers/BusinessSystem').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AppStore');
        }
      },
      childRoutes: [
        {
          name: '添加系统',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/SystemOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '编辑系统',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/SystemOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        },
        {
          name: '业务系统排序',
          path: 'sort',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'app', reducer });
              const Component = require('./containers/SortBusiness').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AppStore');
          }
        }
      ]
    }
  ]
});

export default route;
