import makeReducer from '../makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';

const ORG_SEARCH = 'whistlems/common/orgsearch/ORG_SEARCH';
const ORG_SEARCH_LOADING = ORG_SEARCH + '_LOADING';
const ORG_SEARCH_LOADING_SUCCESS = ORG_SEARCH + '_SUCCESS';
const ORG_SEARCH_LOADING_FAIL = ORG_SEARCH + '_FAIL';

/**
 * 查询组织和用户
 */
export const queryOrgAndUser = (obj) => {
  return {
    types: [ORG_SEARCH_LOADING, ORG_SEARCH_LOADING_SUCCESS, ORG_SEARCH_LOADING_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('permission', 'infoSearch', {
        page: obj.page,
        org_count: obj.org_count,
        user_count: obj.user_count,
        searchInfo: obj.query
      })
    }),
    query: obj.query
  };
};

const ACTION_HANDLERS = {
  [ORG_SEARCH_LOADING]: (state, action) => {
    return {
      ...state,
      orgSearchResult: undefined,
      query: action.query,
      orgSearchLoading: true,
      orgSearchSuccess: false,
      orgSearchFail: undefined
    };
  },
  [ORG_SEARCH_LOADING_SUCCESS]: (state, action) => {
    if (state.query === action.query) {
      return {
        ...state,
        orgSearchResult: action.payload.data,
        orgSearchLoading: false,
        orgSearchSuccess: true,
        orgSearchFail: undefined
      };
    }
    return {...state};
  },
  [ORG_SEARCH_LOADING_FAIL]: (state, action) => {
    if (state.query === action.query) {
      return {
        ...state,
        orgSearchResult: undefined,
        orgSearchLoading: false,
        orgSearchSuccess: false,
        orgSearchFail: action.payload
      };
    }
    return {...state};
  }
};

const initialState = {};

const orgSearchReducer = makeReducer(ACTION_HANDLERS, initialState);
export default orgSearchReducer;
