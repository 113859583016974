import React from 'react';
import { browserHistory } from 'react-router';
import classes from './NotFound.scss';
import NotFoundImage from './assets/404.png';

const goBack = (e) => {
  e.preventDefault();
  return browserHistory.goBack();
};

const refresh = (e) => {
  e.preventDefault();
  return window.location.reload();
};

export const NotFound = () => (
  <div className={classes.wrapper}>
    <img src={NotFoundImage} alt='找不到网页' />
    <div>
      <button type='button' className='btn btn-primary wsbtn-large' onClick={refresh}>刷 新</button>
      <button type='button' className='btn btn-primary wsbtn-large' onClick={goBack}>返 回</button>
    </div>
  </div>
);

export default NotFound;
