/**
 * 个性化
 */
export default {
  'customize.accounttips.title': '账号密码高级设置',
  'customize.accounttips.tip': '微哨手机端登录的账号和密码说明，会同步到手机登录页面',
  'customize.accounttips.form.memo.label': '账号密码高级设置',
  'customize.accounttips.form.config.passset': '密码设置复杂度',
  'customize.accounttips.form.config.passsuggest': '提示修改密码',
  'customize.accounttips.form.config.attack': '防暴力破解',
  'customize.accounttips.form.config.logout': 'APP超时自动退出',
  'customize.splash.title': '启动页设置',
  'customize.splash.tip': '学校可以自定义本学校微哨手机端的启动页'
};
