/**
 * 用户中心
 */
export default {

  // 导入/更新/替换 提示信息
  'org.batchImport.adhoc.tip': [
    '1. 姓名、学工号、岗位均为必填项。', '',
    '2. 姓名、学工号用于确认微哨中存在的用户。', '',
    '3. 岗位必须是微哨岗位（审批节点）中存在的岗位。', '',
    '4. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。'
  ],
  'org.batchUpdate.adminOrg.tip': [
    '1. 查找条件用于确认要更新的用户。', '',
    '2. 其他选项不填=不更新。', '',
    '3. 身份：教职工、学生、其他。', '',
    '4. 姓名：不得超过40字。', '',
    '5. 性别：男，女。', '',
    '6. 证件类型：身份证、护照、军官证、其他。', '',
    '7. 证件号码：所选证件类型对应的证件号。', '',
    '8. 学历：专科、本科、硕士研究生、博士研究生。', '',
    '9. 学制：填写“3、4、……”等数字。', '',
    '10. 职务：同一用户导入多职务时，使用“，”分隔。', '',
    '11. 入学时间：填写“2012、2013、……”。', '',
    '12. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。', '',
    '13. 模版中的“1级组织名称”请填写“行政组织架构名称”。', '',
    '14. 手机1：对于用户通过微哨绑定的手机号，在进行批量更新时将直接替换为未绑定的新手机号。', '',
    '15. 模版中姓名字段支持更新英文名，格式为中文名<<$>>英文名。'
  ],
  'org.batchImport.job.tip': [
    '1. 姓名、学工号均为必填项。', '',
    '2. 姓名、学工号用于确认微哨中存在的用户。', '',
    '3. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。'
  ],
  'org.batchImport.label.tip': [
    '1. 姓名、学工号均为必填项。', '',
    '2. 姓名、学工号用于确认微哨中存在的用户。', '',
    '3. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。'
  ],
  'org.batchReplace.postUser.tip': [
    '1. 姓名、学工号、岗位名称均为必填项。', '',
    '2. 姓名、学工号用于确认微哨中存在的用户。', '',
    '3. 非常设机构和组织信息选填一种，必须是微哨中存在的非常设机构/组织节点。', '',
    '4. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。'
  ],
  'org.batchImport.postUser.tip': [
    '1. 姓名、学工号、岗位名称均为必填项。', '',
    '2. 姓名、学工号用于确认微哨中存在的用户。', '',
    '3. 非常设机构和组织信息选填一种，必须是微哨中存在的非常设机构/组织节点。', '',
    '4. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。'
  ]
};

