/**
 * 提示信息 封装 antd message
 */
import { message } from 'antd';

const DURATION_DEFAULT = 3;
const THEME_DEFAULT = 'info';

message.config({ maxCount: 1 });

const showToast = (text, { duration = DURATION_DEFAULT, theme = THEME_DEFAULT } = {}) => {
  if (!text || !text.trim()) {
    return;
  }
  message[theme](text, duration);
};

export function showSuccessToast(text, duration) {
  showToast(text, { theme: 'success' });
};

export function showErrorToast(text, duration) {
  showToast(text, { theme: 'error' });
};

export function showInfoToast(text, duration) {
  showToast(text, { theme: 'info' });
};

export function showWarningToast(text, duration) {
  showToast(text, { theme: 'warning' });
};

export function showLoadingToast(text, duration) {
  showToast(text, { theme: 'loading' });
};
