/**
 * Created by anguo on 16/6/27.
 */

function validateImportData(workbook) {
  if (workbook.SheetNames.length > 1) {
    return { hasError: true, errmsg: '仅支持单工作表。请使用正确的模板文件。' };
  }
  const parsedExcelObject = xlsxToJson(workbook);
  if (parsedExcelObject) {
    const sourceDataEntries = Object.entries(parsedExcelObject);
    if (sourceDataEntries.length === 0) {
      return { hasError: true, errmsg: '在文件中没有找到任何数据.' };
    }
    const [mainKey, rawEntries] = sourceDataEntries[0];
    if (rawEntries.length === 0) {
      return { hasError: true, errmsg: '在"' + mainKey + '"表单中没有找到任何数据.' };
    }
    const result = Object.keys(rawEntries[0]);
    return { hasError: false, result: result };
  }
  return { hasError: true, errmsg: '没有解析结果!' };
}

function xlsxToJson(workbook) {
  const result = {};
  const sheetName = workbook.SheetNames[0];
  const roa = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
  if (roa.length > 0) {
    result[sheetName] = roa;
  }
  return result;
}

export function parseExcelFile(file) {
  return new Promise((resolve, reject) => {
    const fileNames = file.name.split('.');
    const fileType = fileNames.pop().toLowerCase();
    const result = {
      hasError: false, // 解析是否成功,错误类型有下面几种
      // 文件类型是否合法,必须是xls或xlsx
      // 文件度失败, 系统原因,比如io失败
      // 文件内容是否合法
      errmsg: '', // 统一错误描述信息
      parsedResult: null // 解析出的内容json对象
    };
    if (fileType === 'xls' || fileType === 'xlsx') {
      const reader = new window.FileReader();
      reader.onload = (event) => {
        try {
          const data = event.target.result;
          const wb = window.XLSX.read(data, { type: 'binary', sheetRows: 2 });
          const parsedResult = validateImportData(wb);
          if (!parsedResult.hasError) {
            result.hasWarning = parsedResult.hasWarning;
            result.errmsg = parsedResult.errmsg;
            result.parsedResult = parsedResult.result;
          } else {
            result.hasError = true;
            result.errmsg = parsedResult.errmsg;
          }
        } catch (err) {
          result.hasError = true;
          result.errmsg = err.message || '文件解析错误！';
        }
        result.hasError ? reject(result) : resolve(result);
      };
      reader.onerror = (error) => {
        result.hasError = true;
        result.errmsg = error.message || '文件读取失败，请重试！';
        reject(result);
      };
      reader.readAsBinaryString(file);
    } else {
      result.hasError = true;
      result.errmsg = '不能识别的文件类型，请使用.xlsx或.xls类型的文件进行导入。';
      reject(result);
    }
  });
}
