/**
 * Created by liming on 16/7/4.
 */
import makeReducer from '../makeReducer';
import {
  PAGE_MIN_WIDTH, PAGE_MIN_HEIGHT, SIDE_BAR_WIDTH,
  CONTENT_HEADER_HEIGHT, CONTENT_BREADCRUMBS_HEIGHT,
  CONTENT_PADDING, SCROLL_BAR_WIDTH
} from 'helpers/FlexSizeHelper';

/** 框架宽度，为导航栏宽+左右padding+滚动条宽 */
const FRAME_WIDTH = SIDE_BAR_WIDTH + 2 * CONTENT_PADDING + SCROLL_BAR_WIDTH;
/** 框架高度，为Header高+BreadCrums高+底部padding */
const FRAME_HEIGHT = CONTENT_HEADER_HEIGHT + CONTENT_BREADCRUMBS_HEIGHT + CONTENT_PADDING;

const CHANGE_FLEX_SIZE = 'whistlems/common/flexsize/CHANGE_FLEX_SIZE';

// ------------------------------------
// Actions
// ------------------------------------
export function changeFlexSize(flexWidth, flexHeight) {
  const contentWidth = (flexWidth > PAGE_MIN_WIDTH ? flexWidth : PAGE_MIN_WIDTH) - FRAME_WIDTH;
  const contentHeight = (flexHeight > PAGE_MIN_HEIGHT ? flexHeight : PAGE_MIN_HEIGHT) - FRAME_HEIGHT;
  return {
    type: CHANGE_FLEX_SIZE,
    payload: {
      flexWidth,
      flexHeight,
      contentWidth,
      contentHeight
    }
  };
};

export const actions = {
  changeFlexSize
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CHANGE_FLEX_SIZE]: (state, action) => {
    const {flexWidth, flexHeight, contentWidth, contentHeight} = action.payload;
    return {
      flexWidth,
      flexHeight,
      contentWidth,
      contentHeight
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  flexWidth: PAGE_MIN_WIDTH,
  flexHeight: PAGE_MIN_HEIGHT,
  contentWidth: PAGE_MIN_WIDTH - FRAME_WIDTH,
  contentHeight: PAGE_MIN_HEIGHT - FRAME_HEIGHT
};

const flexSizeReducer = makeReducer(ACTION_HANDLERS, initialState);
export default flexSizeReducer;
