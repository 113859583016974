import serialize from 'serialize-javascript';
import DOMPurify from 'dompurify';

export function safeSerialize(obj) {
  return serialize(obj, { isJSON: true });
};
/**
 * 净化 html 文本，防止解析错误以及攻击
 *
 * @export
 * @param {String} rawHtml html源文本
 * @returns 净化后的 html 文本
 */
export function purifyHtml(rawHtml) {
  return DOMPurify.sanitize(rawHtml);
};
