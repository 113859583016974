import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Dialog';
import ContrastItem from './ContrastItem';
import classes from './ExcelImportView.scss';

export class ImportContrastView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      chkall: true
    };
  }

  componentWillReceiveProps(newProps) {
    const { contrastData } = newProps;
    if (contrastData && contrastData !== this.props.contrastData) {
      let result = contrastData.map((item) => {
        let oldType = item.old_type.map((item) => {
          return Object.assign({}, item, {on: true});
        });
        return Object.assign({}, item, {old_type: oldType}, {on: true});
      });
      this.setState({list: result});
    }
  }

  checkAll = () => {
    const { list, chkall } = this.state;
    let result = list.map((item) => {
      let oldType = item.old_type.map((item, index) => {
        return Object.assign({}, item, {on: !chkall});
      });
      return Object.assign({}, item, {old_type: oldType}, {on: !chkall});
    });
    this.setState({
      list: result,
      chkall: !chkall
    });
  }

  contrastItemClick = (id, idx, on) => {
    const { list } = this.state;
    let result = list.map((item) => {
      if (item.uid === id) {
        let oldType = item.old_type.map((item, index) => {
          if (index === idx) {
            return Object.assign({}, item, {on: !on});
          } else {
            return item;
          }
        });
        let itemChkall = oldType.every(item => item.on === true);
        return Object.assign({}, item, {old_type: oldType}, {on: itemChkall});
      } else {
        return item;
      }
    });
    let chkall = result.every(item => item.on === true);
    this.setState({
      list: result,
      chkall
    });
  }

  renderBody = () => {
    const { list } = this.state;
    return list.map((item) => {
      return <ContrastItem contrastItem={item} contrastItemClick={this.contrastItemClick}
        importResultFlag={this.props.importResultFlag} />;
    });
  }

  onUpdate = () => {
    const { list } = this.state;
    let result = list.map((item) => {
      let oldType = item.old_type.map((item, index) => {
        return Object.assign({}, item, {status: item.on});
      });
      return Object.assign({}, item, {old_type: oldType});
    });
    this.props.importResultSubmit(result);
  }

  render() {
    const { show, title, importResultFlag } = this.props;
    const { list, chkall } = this.state;
    const chkAllCss = chkall ? 'wsicon-box-sel ' + classes.chksel : 'wsicon-box ' + classes.chk;
    return (
      <Dialog show={show} title={title} size='lg' closable={false}
        positiveButton={{
          text: '保存',
          onClick: this.onUpdate
        }}>
        <div className={classes.updateWrap}>
          <span>
          以下数据出现相同岗位不同范围的情况，在导入新管理范围的基础上，请选择是否保留原管理范围，勾选将保留，取消勾选将删除。
          （注：该表仅展示新旧范围不一致的部分，当原范围包含了全部新范围时，新范围显示为空）
          </span>
          <div className={classes.updateTableWrap}>
            <div className={classes.tableHeaderWrap}>
              <table className={classes.tableHeader}>
                <tr>
                  <td style={{width: '75px'}}>{importResultFlag === 'user' ? '姓名' : '职务'}</td>
                  <td style={{width: '100px'}}>{importResultFlag === 'user' ? '学工号' : '所属组织'}</td>
                  <td style={{width: '125px'}}>岗位名称</td>
                  <td>
                    <div className={classes.chkAllWarp}>
                      <i className={'wsicon ' + chkAllCss} style={{marginTop: '-3px', cursor: 'pointer'}}
                        onClick={this.checkAll} />
                      <span style={{marginLeft: '-4px'}}>原管理范围</span>
                    </div>
                  </td>
                  <td style={{width: '155px'}}>新管理范围</td>
                </tr>
              </table>
            </div>
            <div className={classes.tableBodyWrap}>
              <table className={classes.tableBody}>
                <tbody>
                  {list.length > 0 && this.renderBody()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

ImportContrastView.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  importResultSubmit: PropTypes.func,
  importResultFlag: PropTypes.string,
  onHide: PropTypes.func
};

export default ImportContrastView;
