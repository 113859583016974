import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import {getAuthInfo, USER_TYPE_SUBADMIN} from 'helpers/AuthHelper';
import { uniqBy } from 'lodash';

const PREFIX = 'whistlems/manualUpdateOrg/';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

const GET_ORG_DETAIL = PREFIX + 'GET_ORG_DETAIL';
const GET_ORG_DETAIL_SUCCESS = GET_ORG_DETAIL + SUCCESS;
const GET_ORG_DETAIL_FAIL = GET_ORG_DETAIL + FAIL;

const GET_ORG_DATA = PREFIX + 'GET_ORG_DATA';
const GET_ORG_DATA_SUCCESS = GET_ORG_DATA + SUCCESS;
const GET_ORG_DATA_FAIL = GET_ORG_DATA + FAIL;

const DELETE_ORG = PREFIX + 'DELETE_ORG';
const DELETE_ORG_SUCCESS = DELETE_ORG + SUCCESS;
const DELETE_ORG_FAIL = DELETE_ORG + FAIL;

const SAVE_ORG = PREFIX + 'SAVE_ORG';
const SAVE_ORG_SUCCESS = SAVE_ORG + SUCCESS;
const SAVE_ORG_FAIL = SAVE_ORG + FAIL;

const SET_SELECTED_NODE = PREFIX + 'SET_SELECTED_NODE';

const RESET_DATA = PREFIX + 'RESET_DATA';

// 获取节点详情信息
export const getOrgDetail = (info) => {
  return {
    types: [GET_ORG_DETAIL, GET_ORG_DETAIL_SUCCESS, GET_ORG_DETAIL_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'getOrgListByOrgId', {
        ...info
      })
    }),
    info
  };
};

// 获取组织节点
export const getOrgData = (orgId) => {
  const auth = getAuthInfo();
  return {
    types: [GET_ORG_DATA, GET_ORG_DATA_SUCCESS, GET_ORG_DATA_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgUserList', {
        org_id: orgId,
        org_flag: auth && auth.userType === USER_TYPE_SUBADMIN || undefined
      })
    }),
    orgId
  };
};

// 设置选中节点
export const setSelectedNode = (node) => {
  return {
    type: SET_SELECTED_NODE,
    node
  };
};

// 删除已选的组织架构
export const deleteOrg = (orgId) => {
  return {
    types: [DELETE_ORG, DELETE_ORG_SUCCESS, DELETE_ORG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'delOrgListByOrgId', {
        org_id: orgId
      })
    }),
    orgId
  };
};

// 保存调整后的组织结构
export const saveManualOrg = (data = {}) => {
  return {
    types: [SAVE_ORG, SAVE_ORG_SUCCESS, SAVE_ORG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'adjustOrgListByOrgId', {...data})
    }),
    data
  };
};

/**
 * 组装节点数据
 *
 * @param {*} data 初始值
 * @param {*} check 检查项目
 * @param {*} child 需要附件到的组节点
 * @returns
 */
const handleTreeData = (data, check, child) => {
  if (Array.isArray(data)) {
    return data.map(x => handleTreeData({...x, disabled: true}, check, child));
  }
  const isHaveChildren = data.children && data.children.length > 0;
  const mapFun = isHaveChildren
    ? data.children.map(x => handleTreeData(x, check, child))
    : [];
  return {
    ...data,
    disabled: +data.disabled === 1 ? data.disabled : !!data.remote_orgid,
    children: data.id === check
      ? uniqBy((data.children || []).concat(child), 'id')
      : mapFun
  };
};

// 重置 store 数据
export const resetData = () => {
  return {
    type: RESET_DATA
  };
};

const ACTION_HANDLERS = {
  [GET_ORG_DETAIL]: (state, action) => {
    return {
      ...state,
      getOrgDetailLoading: true,
      getOrgDetailLoaded: false,
      getOrgDetailError: undefined
    };
  },
  [GET_ORG_DETAIL_SUCCESS]: (state, action) => {
    return {
      ...state,
      getOrgDetailLoading: false,
      getOrgDetailLoaded: true,
      getOrgDetailError: undefined,
      getOrgDetailResult: action.payload.data
    };
  },
  [GET_ORG_DETAIL_FAIL]: (state, action) => {
    return {
      ...state,
      getOrgDetailLoading: false,
      getOrgDetailLoaded: false,
      getOrgDetailError: action.payload.errmsg
    };
  },
  [GET_ORG_DATA]: (state, action) => {
    return {
      ...state,
      getOrgDataLoading: true,
      getOrgDataLoaded: false,
      getOrgDataError: undefined
    };
  },
  [GET_ORG_DATA_SUCCESS]: (state, action) => {
    let [justWorkOrgList, result] = [[], []];
    // 根节点
    const { org, org1 } = action.payload.data;
    if (action.orgId === 0) {
      result = org1;
      justWorkOrgList = [org1.find(x => x.org_type === '1') || '']
        .filter(x => !!x)
        .map(x => ({...x, disabled: true})); // 只有行政组织
    } else {
      // 数据处理
      const temp = org.map(x => ({...x, children: [], isLeaf: +x.child_list === 0, disabled: !!x.remote_orgid}));
      // 组装
      result = handleTreeData(state.getOrgDataResult, action.orgId, temp);
      justWorkOrgList = handleTreeData(state.justWorkOrgList, action.orgId, temp);
    }
    return {
      ...state,
      getOrgDataLoading: false,
      getOrgDataLoaded: true,
      getOrgDataError: undefined,
      getOrgDataResult: result,
      justWorkOrgList: justWorkOrgList
    };
  },
  [GET_ORG_DATA_FAIL]: (state, action) => {
    return {
      ...state,
      getOrgDataLoading: false,
      getOrgDataLoaded: false,
      getOrgDataError: action.payload.errmsg
    };
  },
  [DELETE_ORG]: (state, action) => {
    return {
      ...state,
      deleteOrgLoading: true,
      deleteOrgLoaded: false,
      deleteOrgError: undefined
    };
  },
  [DELETE_ORG_SUCCESS]: (state, action) => {
    const filter = (data, id) => {
      var newData = data.filter(x => x.id !== id);
      newData.forEach(x => x.children && (x.children = filter(x.children, id)));
      return newData;
    };
    const justWorkOrgList = filter(state.justWorkOrgList || [], action.orgId);
    return {
      ...state,
      deleteOrgLoading: false,
      deleteOrgLoaded: true,
      deleteOrgError: undefined,
      deleteOrgResult: action.payload.data,
      justWorkOrgList: justWorkOrgList
    };
  },
  [DELETE_ORG_FAIL]: (state, action) => {
    return {
      ...state,
      deleteOrgLoading: false,
      deleteOrgLoaded: false,
      deleteOrgError: action.payload.errmsg
    };
  },
  [SAVE_ORG]: (state, action) => {
    return {
      ...state,
      saveOrgLoading: true,
      saveOrgLoaded: false,
      saveOrgError: undefined
    };
  },
  [SAVE_ORG_SUCCESS]: (state, action) => {
    return {
      ...state,
      saveOrgLoading: false,
      saveOrgLoaded: true,
      saveOrgError: undefined,
      saveOrgResult: action.payload.data
    };
  },
  [SAVE_ORG_FAIL]: (state, action) => {
    return {
      ...state,
      saveOrgLoading: false,
      saveOrgLoaded: false,
      saveOrgError: action.payload.errmsg
    };
  },
  [SET_SELECTED_NODE]: (state, action) => {
    return {
      ...state,
      selectedNode: action.node
    };
  },
  [RESET_DATA]: (state, action) => {
    return {
      ...state,
      getOrgDetailResult: [], // 详情数据
      selectedNode: undefined,
      deleteOrgResult: undefined,
      deleteOrgError: undefined,
      saveOrgResult: undefined,
      saveOrgError: undefined
    };
  }
};

const initialState = {};

const manualUpdateOrg = makeReducer(ACTION_HANDLERS, initialState);
export default manualUpdateOrg;
