import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '系统公告',
  path: 'announcement/:page',
  staticName: true,
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: {
    name: '公告列表',
    getComponent(nextState, callback) {
      require.ensure([], (require) => {
        const reducer = require('./modules/announceList').default;
        injectReducer(store, { key: 'announceList', reducer });
        const Component = require('./containers/Announcement').default;
        callback(null, Component);
      }, getErrorCallback(callback), 'Announce');
    }
  },
  childRoutes: [
    {
      name: '公告详情',
      path: 'detail',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules/announceInfo').default;
          injectReducer(store, { key: 'announceInfo', reducer });
          const Component = require('./containers/AnnouncementDetail').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Announce');
      }
    }
  ]
});

export default route;
