import PropTypes from 'prop-types';
import React from 'react';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import HoverNav from 'components/HoverNav';
import classNames from 'classnames';
import classes from './Header.scss';
import AnnouncementNotice from 'components/NoticeEntry';
import { Image } from 'react-bootstrap';

export const Header = (props) => {
  return (
    <nav className={classNames('navbar', 'navbar-default', classes.container)}>
      <div className='container-fluid'>
        <div className={classNames('navbar-header', classes.header)}>
          {props.icon ? <Image src={props.icon} circle className={classes.schoolBadge} /> : null}
          <span className={classNames('navbar-brand', classes.brand)}>
            <AutoShrinkTextWithTip text={props.school} fontSize={16} maxSize={400} placement='bottom' />
          </span>
        </div>
        <AnnouncementNotice title={props.realName} />
        <div className={classes.navRight}>
          <HoverNav menu={props.menu} title={props.realName} marginTop={20} onMenuClicked={props.onMenuClicked} />
        </div>
      </div>
      {props.showModal && props.children}
    </nav>
  );
};

Header.propTypes = {
  menu: PropTypes.array.isRequired,
  onMenuClicked: PropTypes.func,
  school: PropTypes.string.isRequired,
  icon: PropTypes.string,
  realName: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  children: PropTypes.any
};

export default Header;
