/** API接口路径 */
export const API_ROOT = __API_PATH__ + '/index.php';
export const API_ROOT2 = __API_PATH2__;


/**
 * 根据模块和接口名称生成API接口参数
 */
export function formatParams(moduleName, apiName, params) {
  return {
    m: moduleName,
    a: apiName,
    ...params
  };
};

/** verify或device_type错误 */
export const ERR_INVALID_VERIFY = 10000;
/** verify失效 */
export const ERR_VERIFY_EXPIRED = 10001;
/** 账户被禁用或删除 */
export const ERR_INVALID_ACCOUNT = 20012;

/**
 * 根据错误码判断是否需要重新登录
 */
export function loginExpired(errcode) {
  switch (errcode) {
    case ERR_INVALID_VERIFY:
    case ERR_VERIFY_EXPIRED:
    case ERR_INVALID_ACCOUNT:
      return true;
    default:
      break;
  }
  return false;
};

/**
 * 根据错误码获取错误提示信息
 */
export function getErrorMessage(errcode) {
  switch (errcode) {
    case ERR_INVALID_VERIFY:
    case ERR_VERIFY_EXPIRED:
      return '';// '你的账号在别处登录，此处自动下线，若非本人操作，请尽快修改密码。';
    case ERR_INVALID_ACCOUNT:
      return '你的账号已被禁用或删除，请联系管理员。';
    default:
      break;
  }
  return '';
};
