/**
 * Created by liuzhijia on 2017/7/17.
 * 标记已毕业
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classes from './MarkGraduation.scss';
import Icon from 'components/Icon';
import IconHeader from 'components/IconHeader';
import LoadingView from 'components/LoadingView';
import ClearSearch from 'components/ClearSearch';
import Pagination from 'components/Pagination';
import MarkGraduationItem from '../components/MarkGraduationItem';
import {searchOrgLast, changeGraduationStatus} from '../modules/organization';
import { showErrorToast } from 'helpers/toastUtils';
import styleVariables from '../../../../config/style.variables';

const PRIMARY_COLOR = styleVariables && styleVariables.color_primary;

const PAGE_COUNT = 20;
const GRADUATION = '9'; // 毕业状态
const UNDERGRADUATE = '0';
class MarkGraduation extends React.PureComponent {

  constructor(props) {
    super();
    this.state = {
      chkall: false,
      searchContent: '',
      list: [], // 存放当前数据
      searchModule: false,
      pageNum: 1, // 当前页码
      btnDisable: true
    };
  }

  componentWillReceiveProps(newProps) {
    const {orgLastList, searchOrgLastSuccess, searchOrgLastFail, markGraduationFail, markGraduationSuccess} = newProps;
    if (searchOrgLastSuccess && searchOrgLastSuccess !== this.props.searchOrgLastSuccess) {
      this.setState({
        list: orgLastList.org_list,
        btnDisable: true
      });
    }
    if (searchOrgLastFail && searchOrgLastFail !== this.props.searchOrgLastFail) {
      showErrorToast(searchOrgLastFail.errmsg);
    }
    if (markGraduationSuccess && markGraduationSuccess !== this.props.markGraduationSuccess) {
      this.setState({
        chkall: false,
        btnDisable: true
      });
      const {searchContent, pageNum} = this.state;
      this.props.searchOrgLast(searchContent, pageNum, PAGE_COUNT);
    }
    if (markGraduationFail && markGraduationFail !== this.props.markGraduationFail) {
      showErrorToast(markGraduationFail.errmsg);
    }
  }

  handleSearch = (value) => {
    if (value && value.trim().length > 0) {
      this.setState({
        searchModule: true,
        searchContent: value.trim(),
        chkall: false,
        pageNum: 1
      });
      this.props.searchOrgLast(value.trim(), 1, PAGE_COUNT);
    }
  }

  handleClearSearch = () => {
    this.setState({
      searchModule: false,
      pageNum: 1,
      chkall: false,
      list: [],
      btnDisable: true
    });
  }

  // 全选
  checkAll = (e) => {
    e.preventDefault();
    this.toggleChkState(this.state.chkall, 'all');
  }

  // 勾选操作
  toggleChkState = (flag, key) => {
    const listData = this.state.list.slice();
    if (key === 'all') {
      const list = [];
      listData.forEach((item, index) => {
        item.on = !flag;
        if (item.on) {
          list.push(item);
        }
      });
      this.setState({
        chkall: !flag,
        list: listData,
        btnDisable: !(list.length >= 2)
      });
    } else {
      listData.forEach((item, index) => {
        if (item.id === key) {
          item.on = !item.on;
          return true;
        }
      });
      const list = listData.filter((item) => {
        return item.on;
      });
      let flag = true;
      let allChkFlag = false;
      if (list.length === listData.length) {
        allChkFlag = true;
      }
      if (list.length >= 2) {
        flag = false;
      }
      this.setState({
        chkall: allChkFlag,
        list: listData,
        btnDisable: flag
      });
    }
  }

  // 批量标记毕业
  batchMark = () => {
    const {list} = this.state;
    const markList = [];
    list.map((item) => {
      if (item.on) {
        markList.push(item.id);
      }
    });
    this.props.changeGraduationStatus(markList.join(','), GRADUATION);
  }

  // 批量恢复
  batchRestore = () => {
    const {list} = this.state;
    const restoreList = [];
    list.map((item) => {
      if (item.on) {
        restoreList.push(item.id);
      }
    });
    this.props.changeGraduationStatus(restoreList.join(','), UNDERGRADUATE);
  }

  // 标记毕业
  markItem = (id) => {
    this.props.changeGraduationStatus(id, GRADUATION);
  }

  // 恢复
  restoreItem = (id) => {
    this.props.changeGraduationStatus(id, UNDERGRADUATE);
  }

  // 分页
  handlePaging = (page) => {
    this.setState({pageNum: page, chkall: false});
    this.props.searchOrgLast(this.state.searchContent, page, PAGE_COUNT);
  }

  makeNoDataView = () => {
    const {contentWidth, contentHeight, searchOrgLastFail} = this.props;
    const {searchModule} = this.state;
    const width = contentWidth - 50;
    let msg = searchModule ? '没有查到符合条件的组织' : '请先搜索需要标记毕业的部门院系';
    msg = searchOrgLastFail ? searchOrgLastFail.errmsg : msg;
    return (<div>
      <table className={classes.detailtable}>
        <thead>
          <tr>
            <th style={{width: width * 0.3}}>部门院系名称</th>
            <th style={{width: width * 0.1}}>状态</th>
            <th style={{width: width * 0.5}}>所属组织</th>
            <th style={{width: width * 0.1}}>操作</th>
          </tr>
        </thead>
      </table>
      <div className='empty' style={{height: contentHeight - 190}}>
        {/* 80 图片高度，40 文字高度 200 iconhder + searcherHeader + button + tableHeader */}
        <div className={classes.img} style={{paddingTop: (contentHeight - 200 - 120) / 2}}>
          <Icon type='search' className={classes.emptyImg} />
        </div>
        <div className={classes.msg}>{msg}</div>
      </div>
    </div>);
  }

  makeList = () => {
    const {contentWidth, contentHeight, orgLastList} = this.props;
    const {pageNum} = this.state;
    const width = contentWidth - 50;
    const listContent = this.makeContent();
    const showPage = orgLastList && orgLastList.org_count > PAGE_COUNT;
    // 出现滚动条时，将宽度 - 6px(谷歌浏览器滚动条宽度)
    const headerWidth = orgLastList && orgLastList.org_list.length > 12 ? 'calc(100% - 6px)' : '100%';
    return (<div>
      <div>
        <table className={'table-hover ' + classes.detailtable} style={{width: headerWidth}}>
          <thead>
            <tr>
              <th style={{width: width * 0.3}}>部门院系名称</th>
              <th style={{width: width * 0.1}}>状态</th>
              <th style={{width: width * 0.5}}>所属组织</th>
              <th style={{width: width * 0.1}}>操作</th>
            </tr>
          </thead>
        </table>
      </div>
      <div style={{height: contentHeight - 192, overflow: 'auto'}}>
        <table className={'table-hover ' + classes.detailtable}>
          <tbody >
            {listContent}
          </tbody>
        </table>
      </div>
      {showPage && <Pagination pageIndex={pageNum} pageCount={parseInt((orgLastList.org_count - 1) / PAGE_COUNT) + 1}
        selfMargin isShowJumpPage sidePages={5} changePage={this.handlePaging} />}
    </div>);
  }

  makeContent = () => {
    const width = this.props.contentWidth - 50;
    const {list} = this.state;
    return list.map((item) => {
      return (<MarkGraduationItem key={item.id} item={item} checked={item.on} width={width}
        onChkStateChange={this.toggleChkState} markItem={this.markItem} restoreItem={this.restoreItem} />);
    });
  }

  render () {
    const {contentWidth, contentHeight, searchOrgLastLoading, searchOrgLastSuccess, searchOrgLastFail,
      orgLastList, markGraduationLoading} = this.props;
    const {searchModule, btnDisable, chkall} = this.state;
    const headerWidth = searchModule && orgLastList && orgLastList.org_list.length > 12 ? 'calc(100% - 6px)' : '100%';
    const showList = !markGraduationLoading && searchModule && searchOrgLastSuccess
                     && parseInt(orgLastList.org_count) > 0;
    const btnColor = btnDisable ? '#bfbfbf' : PRIMARY_COLOR;
    return (<div className={classes.mainContaner + ' container content-container'}
      style={{width: contentWidth, height: contentHeight}}>
      <IconHeader iconClassName='organization' title='批量标记已毕业' />
      <div className={classes.opearatorHeader} style={{width: headerWidth}}>
        <ClearSearch placeholder='支持对末级组织的搜索' width={284} onSearchHandler={this.handleSearch}
          crossStyle={{marginRight: '20px'}}
          searchStyle={{fontSize: '20px'}}
          onClearHandler={this.handleClearSearch} />
      </div>
      {(searchOrgLastLoading || markGraduationLoading) && <LoadingView />}
      {(!searchModule || searchOrgLastSuccess && parseInt(orgLastList.org_count) === 0) && this.makeNoDataView()}
      {showList && this.makeList()}
      {searchModule && searchOrgLastFail && this.makeNoDataView()}
      {!markGraduationLoading && <div className={classes.btnContainer}>
        <button className={'wsbtn-ghost wsbtn-ghost-primary wsbtn-large ' + classes.checkAllBtn}
          onClick={this.checkAll} disabled={!showList}
          style={{color: !showList ? '#bfbfbf' : PRIMARY_COLOR, borderColor: !showList ? '#bfbfbf' : PRIMARY_COLOR}}>
          {chkall ? '取消全选' : '全选'}
        </button>
        <button className={'wsbtn-ghost wsbtn-ghost-primary wsbtn-large ' + classes.markBtn}
          onClick={this.batchMark} disabled={btnDisable} style={{color: btnColor, borderColor: btnColor}}>
          批量标记
        </button>
        <button className={'wsbtn-ghost wsbtn-ghost-primary wsbtn-large ' + classes.restoreBtn}
          onClick={this.batchRestore} disabled={btnDisable}
          style={{color: btnColor, borderColor: btnColor}}>
          批量恢复
        </button>
      </div>}
    </div>);
  }
}

MarkGraduation.propTypes = {
  contentWidth: PropTypes.number,
  contentHeight: PropTypes.number,
  searchOrgLast: PropTypes.func,
  changeGraduationStatus: PropTypes.func,
  orgLastList: PropTypes.object,
  searchOrgLastLoading: PropTypes.bool,
  searchOrgLastSuccess: PropTypes.bool,
  searchOrgLastFail: PropTypes.object,
  markGraduationLoading: PropTypes.bool,
  markGraduationSuccess: PropTypes.bool,
  markGraduationFail: PropTypes.object
};

const mapActionCreators = {
  searchOrgLast,
  changeGraduationStatus
};

const mapStateToProps = (state) => {
  return {
    contentWidth: state.flexSize.contentWidth,
    contentHeight: state.flexSize.contentHeight,
    orgLastList: state.organization.orgLastList,
    searchOrgLastLoading: state.organization.searchOrgLastLoading,
    searchOrgLastSuccess: state.organization.searchOrgLastSuccess,
    searchOrgLastFail: state.organization.searchOrgLastFail,
    markGraduationLoading: state.organization.markGraduationLoading,
    markGraduationSuccess: state.organization.markGraduationSuccess,
    markGraduationFail: state.organization.markGraduationFail
  };
};

export default connect(mapStateToProps, mapActionCreators)(MarkGraduation);
