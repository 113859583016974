import ModuleLayout from 'layouts/ModuleLayout';
import RouteLayout from 'layouts/RouteLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '数据统计',
  path: 'statistics',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/statistics/overview') }, // IndexRedirect
  childRoutes: [
    {
      name: '数据概况',
      path: 'overview',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'statistics', reducer });
          const Component = require('./containers/Statistics').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Statistics');
      }
    },
    {
      name: '活跃用户分析',
      path: 'activeUser',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'statistics', reducer });
          const Component = require('./containers/ActiveUser').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Statistics');
      }
    },
    {
      name: '用户留存分析',
      path: 'userRetention',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'statistics', reducer });
          const Component = require('./containers/UserRetention').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Statistics');
      }
    },
    {
      name: '平台版本分析',
      path: 'appVersion',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'statistics', reducer });
          const Component = require('./containers/AppVersion').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Statistics');
      }
    },

    {
      name: '应用使用分析',
      path: 'appUse',
      component: RouteLayout,
      indexRoute: { onEnter: (nextState, replace) => replace('/statistics/appUse/use') },
      childRoutes: [
        {
          name: '应用分析',
          staticName: true,
          path: 'use(/:flag)',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'statistics', reducer });
              const Component = require('./containers/AppUse').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Statistics');
          }
        },
        {
          name: '应用对比',
          staticName: true,
          path: 'contrast',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'statistics', reducer });
              const Component = require('./containers/AppContrast').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Statistics');
          }
        },
        {
          name: '使用统计详情',
          staticName: true,
          path: 'appUseInfo/:id/:name',
          component: RouteLayout,
          indexRoute: {
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, { key: 'statistics', reducer });
                const Component = require('./containers/AppUseInfo').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'Statistics');
            }
          },
          childRoutes: [
            {
              name: '使用明细',
              staticName: true,
              path: 'appUseDetail',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'statistics', reducer });
                  const Component = require('./containers/AppUseDetail').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'Statistics');
              }
            }
          ]
        }
      ]
    },
    {
      name: '用户终端分析',
      path: 'terminal',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'statistics', reducer });
          const Component = require('./containers/Terminal').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Statistics');
      }
    }
  ]
});

export default route;
