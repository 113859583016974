import ModuleLayout from 'layouts/ModuleLayout';
import RouteLayout from 'layouts/RouteLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '移动端管理',
  path: 'customize',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/customize/splash') }, // IndexRedirect
  childRoutes: [
    {
      name: '启动页设置',
      path: 'splash',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'customize', reducer });
          const Component = require('./containers/SplashSetting').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Customize');
      }
    },
    {
      name: '轮播图设置',
      path: 'banner',
      component: RouteLayout,
      indexRoute: {
        name: '轮播图设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'customize', reducer });
            const Component = require('./containers/BannerSetting').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Customize');
        }
      },
      childRoutes: [
        {
          name: '创建横幅',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'customize', reducer });
              const Component = require('./containers/BannerEditor').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Customize');
          }
        },
        {
          name: '修改横幅',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'customize', reducer });
              const Component = require('./containers/BannerEditor').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Customize');
          }
        },
        {
          name: '使用统计',
          path: 'statistics',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'customize', reducer });
              const Component = require('./containers/BannerStatistics').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Customize');
          }
        }
      ]
    },
    {
      name: '首页标题栏',
      path: 'homeTitleBar',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'customize', reducer });
          const Component = require('./containers/HomeTitleBar').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Customize');
      }
    },
    {
      name: '账号密码高级设置',
      path: 'accpasstips',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'customize', reducer });
          const Component = require('./containers/AccountTipsSetting').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Customize');
      }
    },
    {
      name: '校园卡背景',
      path: 'schoolBackground',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'customize', reducer });
          const Component = require('./containers/SchoolBackground').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Customize');
      }
    },
    {
      name: '扫一扫白名单',
      path: 'whiteList',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'customize', reducer });
          const Component = require('./containers/WhiteListContainer').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Customize');
      }
    },
    {
      name: '启动声明协议',
      path: 'disclaimer',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'customize', reducer });
          const Component = require('./containers/DisclaimerContainer').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'Customize');
      }
    }
  ]
});

export default route;
