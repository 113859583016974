import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import orgTreeClasses from './OrgTree.scss';

const CheckViewItem = ({item, handleClick, classes}) => {
  const clickEvent = (key, e) => (handleClick(item.key, e));
  return (<span onClick={clickEvent} className={classes.chkView || orgTreeClasses.chkView}>
    <Icon type={item.on ? 'box-sel' : 'box'} className={item.on ? classes.chksel : classes.chk} />
    <span className={classes.checktitle}>{item.name}</span>
  </span>);
};

CheckViewItem.propTypes = {
  item: PropTypes.object,
  handleClick: PropTypes.func,
  classes: PropTypes.object
};

export default CheckViewItem;
