import PropTypes from 'prop-types';
import React from 'react';
import LeftNavBar from 'components/LeftNavBar';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  ADMIN_MENU,
  MENU_CREDIT,
  MENU_AUDIT_LOG,
  MENU_SYSTEM_MANAGEMENT,
  MENU_SMART_QA,
  MENU_CHATGROUP,
  MENU_APPSTORE,
  MENU_PORTAL,
  getAuthMenu } from 'config/menu';
import { getAuthInfo, USER_TYPE_ADMIN, ACCOUNT_TYPE_ASSISTANT } from 'helpers/AuthHelper';
import { replaceArrayElement } from 'helpers/arrayUtils';

class NavBarContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.menu = [];
    this.state = {
      activeItem: -1,
      activeSubItem: -1
    };
  }

  componentWillMount() {
    const auth = getAuthInfo();
    if (auth) {
      if (auth.userType === USER_TYPE_ADMIN) {
        // 超级管理员及其助理账号
        this.menu = ADMIN_MENU;
        this.menu = this.menu.filter(item => item !== MENU_AUDIT_LOG); // 管理员无法查看审计日志
        if (auth.hideCredit) {
          this.menu = this.menu.filter(item => item !== MENU_CREDIT);
        }
        if (auth.hideChatManage) {
          const chatGroupSubMenu = MENU_CHATGROUP.subMenu;
          const newChatGroupMenu = Object.assign({}, MENU_CHATGROUP, {
            subMenu: chatGroupSubMenu.filter(item => item.id !== 201)
          });
          this.menu = replaceArrayElement(this.menu, MENU_CHATGROUP, newChatGroupMenu);
        }
        if (auth.hideComment) {
          const appStoreSubMenu = MENU_APPSTORE.subMenu;
          const newAppStoreMenu = Object.assign({}, MENU_APPSTORE, {
            subMenu: appStoreSubMenu.filter(item => item.id !== 303)
          });
          this.menu = replaceArrayElement(this.menu, MENU_APPSTORE, newAppStoreMenu);
        }
        if (auth.hideSystemManager) {
          this.menu = this.menu.filter(item => item !== MENU_SYSTEM_MANAGEMENT);
        }
        if (auth.hideRobot) {
          this.menu = this.menu.filter(item => item !== MENU_SMART_QA);
        }
        if (auth.hidePortal) {
          this.menu = this.menu.filter(item => item !== MENU_PORTAL);
        }
        if (!auth.hidePortal && auth.hideWorkPortal) {
          this.menu = this.menu.map(item => {
            if (item.id === MENU_PORTAL.id) {
              const subMenu = item.subMenu.filter(sub => sub.id !== 1507);
              return Object.assign({}, item, { subMenu });
            }
            return item;
          });
        }
        if (auth.accountType === ACCOUNT_TYPE_ASSISTANT) {
          const list = [];
          this.menu.map(item => {
            const subList = item.subMenu;
            const listSub = [];
            if (subList && subList.length > 0) {
              if (item.id === 12) {
                subList.map(itemSub => itemSub.id !== 1202 && listSub.push(itemSub));
              } else {
                subList.map(itemSub => listSub.push(itemSub));
              }
            }
            const temp = Object.assign({}, item, listSub.length ? {subMenu: listSub} : {});
            list.push(temp);
          });
          this.menu = list;
        }
      } else {
        // 角色关联的管理员及其助理账号
        this.menu = getAuthMenu();
        if (auth.hideChatManage) {
          this.menu = this.menu.filter(item => item.id !== MENU_CHATGROUP.id);
        }
        if (auth.hidePortal) {
          this.menu = this.menu.filter(item => item.id !== MENU_PORTAL.id);
        }
        if (!auth.hidePortal && auth.hideWorkPortal) {
          this.menu = this.menu.map(item => {
            if (item.id === MENU_PORTAL.id) {
              const subMenu = item.subMenu.filter(sub => sub.id !== 1507);
              return Object.assign({}, item, { subMenu });
            }
            return item;
          });
        }
      }
      const { activeItem, activeSubItem } = this.findActiveItem(this.props);
      this.setState({
        activeItem,
        activeSubItem
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const { activeItem, activeSubItem } = this.findActiveItem(newProps);
    if (activeItem !== this.state.activeItem || activeSubItem !== this.state.activeSubItem) {
      this.setState({
        activeItem,
        activeSubItem
      });
    }
  }

  findActiveItem(props) {
    let currentPath = props.location.pathname;
    currentPath = currentPath[0] === '/' ? currentPath.slice(1) : currentPath;
    const activeItem = this.menu.find(item => currentPath.startsWith(item.target));
    let activeSubItem;
    if (activeItem) {
      const subMenu = activeItem.subMenu;
      if (subMenu && subMenu.length > 0) {
        activeSubItem = subMenu.find(item => currentPath.startsWith(item.target));
      }
    }
    return {
      activeItem: activeItem ? activeItem.id : -1,
      activeSubItem: activeSubItem ? activeSubItem.id : -1
    };
  }

  onMenuClick(menuItem) {
    if (menuItem.subMenu) {
      return false; // 与旧版行为保持一致，有子菜单的不跳转
    }
    if (menuItem.target) {
      this.props.push(menuItem.target);
      return true;
    }
    return false;
  }

  render() {
    const { activeItem, activeSubItem } = this.state;
    return (
      <LeftNavBar
        menu={this.menu}
        onMenuClick={this.onMenuClick}
        activeItem={activeItem}
        activeSubItem={activeSubItem}
      />
    );
  }
}

NavBarContainer.propTypes = {
  push: PropTypes.func.isRequired
};

const mapActionCreators = {
  push
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionCreators)(NavBarContainer);
