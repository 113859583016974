import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import { useRouterHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import AppContainer from './containers/AppContainer';
import createStore from './store/createStore';
import { init } from './helpers/errorReport';

init(() => {
  // ========================================================
  // Browser History Setup
  // ========================================================
  const browserHistory = useRouterHistory(createBrowserHistory)({
    basename: __BASENAME__
  });

  // ========================================================
  // Store and History Instantiation
  // ========================================================
  // Create redux store and sync with react-router-redux. We have installed the
  // react-router-redux reducer under the key "router" in src/routes/index.js,
  // so we need to provide a custom `selectLocationState` to inform
  // react-router-redux of its location.
  const initialState = window.__INITIAL_STATE__;
  const store = createStore(browserHistory, initialState);
  const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: (state) => state.router
  });

  // ========================================================
  // Render Setup
  // ========================================================
  const MOUNT_NODE = document.getElementById('root');

  let render = () => {
    // Now that we have the Redux store, we can create our routes. We provide
    // the store to the route definitions so that routes have access to it for
    // hooks such as `onEnter`.
    const routes = require('./routes/index').default(store);
    ReactDOM.render(
      <AppContainer store={store} history={history} routes={routes} />,
      MOUNT_NODE
    );
  };

  // Enable HMR and catch runtime errors in RedBox
  // This code is excluded from production bundle
  if (__DEV__) {
    if (module.hot) {
      const renderApp = render;
      const renderError = (error) => {
        const RedBox = require('redbox-react').default;
        ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
      };
      render = () => {
        try {
          renderApp();
        } catch (error) {
          if (__DEV__) {
            console.error('App error: ' + error);
          }
          renderError(error);
        }
      };

      // Setup hot module replacement
      module.hot.accept(['./containers/AppContainer', './routes/index'], () => {
        setTimeout(() => {
          ReactDOM.unmountComponentAtNode(MOUNT_NODE);
          render();
        });
      });
    }
  }

  render();
});

// site statistics for production mode
if (__PROD__) {
  require('./helpers/statistics')();
}
