import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '账号管理',
  path: 'account',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: {
    name: '账号设置',
    getComponent(nextState, callback) {
      require.ensure([], (require) => {
        injectReducer(store, { key: 'bindwhistle', reducer: require('./modules/bindwhistle').default });
        injectReducer(store, { key: 'assistantaccount', reducer: require('./modules/assistantaccount').default });
        const Component = require('./containers/AccountSettings').default;
        callback(null, Component);
      }, getErrorCallback(callback), 'Account');
    }
  }
});

export default route;
