/**
 * Created on 19/4/26.
 * 批量更新组织
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExcelImportView from 'components/ExcelImportView';
import IconHeader from 'components/IconHeader';
import { batchUpdateOrgSubmit, exportOrg } from '../modules/user';
import { loadOrgRoot } from '../modules/organization';
import classes from './BatchImportUpdate.scss';

const TIPS = [
  '1. 批量更新组织功能当前仅支持批量导入/更新组织的英文名。', '',
  '2. 模版中仅允许操作“英文名”字段。', '',
  '3. 不支持通过模版对组织已有英文名进行删除操作。', '',
  '4. 上传的文档中英文名为空，表示不对该组织英文名进行导入更新操作。', '',
  '5. 上传模版中出现重复的组织时，以最后更新为准。', '',
  '6. 不支持修改“行政组织架构”的英文名。'
];

const TEMPLATE_TITLE = [
  '1级组织-名称', '2级组织-名称', '3级组织-名称', '4级组织-名称', '5级组织-名称', '6级组织-名称', '7级组织-名称', '英文名'
];

class BatchUpdateOrg extends PureComponent {

  componentDidUpdate(prevProps) {
    const { orgExportResult } = this.props;
    if (orgExportResult && orgExportResult !== prevProps.orgExportResult) {
      document.location.href = orgExportResult.downloadUrl;
    }
  }

  onClickExport = () => {
    this.props.exportOrg();
  }

  render() {
    const { contentHeight, batchUpdateOrgSubmit, onFinish, importMsg, importErrmsg } = this.props;
    const importBoxStyle = {
      paddingTop: '12px',
      borderTop: 'none',
      height: contentHeight - 120,
      overflowY: 'auto'
    };
    const stepDesc = (
      <div className={'form-group ' + classes.group}>
        <span className={classes.explainSpan}>1. 请先导出当前组织</span>
        <button type='button' className={'btn btn-primary wsbtn-large ' + classes.btn}
          onClick={this.onClickExport}>
          导出当前组织
        </button>
        <br />
        <span className={classes.explainSpan}>
          2. 打开已下载的文件，根据下面书写规范的规则输入或粘贴数据，为保障粘贴信息被有效导入，请使用纯文本或数字。
        </span>
        <br />
        <span className={classes.explainSpan}>
          3. 信息输入完毕，点击“选择文件”按钮，选择excel文档，上传导入文件，点击“导入”按钮开始导入数据。
        </span>
        <br />
      </div>
    );
    const ownProps = {
      templates: TEMPLATE_TITLE,
      stepDesc,
      tips: TIPS,
      loadingTips: '',
      submit: batchUpdateOrgSubmit,
      onFinish,
      importMsg,
      importErrmsg,
      excelHeight: contentHeight - 200
    };
    return (
      <div className='container content-container' style={{ height: contentHeight }}>
        <IconHeader title={'批量更新组织'} iconClassName='userimport' />
        <div style={importBoxStyle}>
          <ExcelImportView {...ownProps} />
        </div>
      </div>
    );
  }
}

BatchUpdateOrg.propTypes = {
  contentHeight: PropTypes.number,
  batchUpdateOrgSubmit: PropTypes.func,
  onFinish: PropTypes.func,
  importMsg: PropTypes.object,
  importErrmsg: PropTypes.object,
  orgExportResult: PropTypes.object,
  exportOrg: PropTypes.func
};

const mapActionCreators = {
  batchUpdateOrgSubmit,
  onFinish: loadOrgRoot,
  exportOrg
};

const mapStateToProps = (state) => ({
  contentHeight: state.flexSize.contentHeight,
  importMsg: state.user.updateMsg,
  importErrmsg: state.user.updateOrgError,
  orgExportLoading: state.user.orgExportLoading,
  orgExportResult: state.user.orgExportResult,
  orgExportError: state.user.orgExportError
});

export default connect(mapStateToProps, mapActionCreators)(BatchUpdateOrg);
