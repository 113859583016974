import { getData, setData } from 'helpers/storageUtils';

const LS_KEY = 'schools';

export function getList() {
  const list = getData(LS_KEY);
  if (list) {
    return JSON.parse(list);
  }
  return null;
};

export function setList(list) {
  if (list) {
    setData(LS_KEY, JSON.stringify(list));
    return true;
  }
  return false;
};
