import PropTypes from 'prop-types';
/**
 * Created by lzj on 2016/8/29.
 * 暂无数据展现组件
 */
import React from 'react';
import classes from './NoDataView.scss';
import noDataImg from './noDataImg.png';

export const NoDataView = ({width, height, text}) => {
  return (
    /* 300:img; 34:text */
    <div className={classes.mainBody} style={{ width, height, paddingTop: (height - 300 - 34) / 2 }}>
      <div className={classes.container}>
        <img src={noDataImg} className={classes.noDataImg} />
        <div >{text}</div>
      </div>
    </div>
  );
};

NoDataView.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string
};

export default NoDataView;
