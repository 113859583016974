/**
 * 第三方门户
 */
import React, { Fragment } from 'react';

export default {

  // 服务号
  'thirdparty.fwh.form.bindMode.option.userbind.label': '用户自行绑定',
  'thirdparty.fwh.form.bindMode.option.userbind.help': '仅显示用户如何通过服务号自行绑定的提示此选项适用于微信公众平台无法配置微哨服务器地址的情况',

  // 企业微信
  'thirdparty.qywx.form.bindPage.label': '5、配置企业微信绑定页面',
  'thirdparty.qywx.form.bindPage.help': '需设置企业微信的相关配置后生效，绑定页面的登录说明调取“移动端管理-账号密码高级设置”的相关配置信息。',
  'thirdparty.qywx.form.syncRule.option.custom.label': '自定义（接口）',
  'thirdparty.qywx.form.syncRule.option.custom.help': <Fragment>
    <li>该接口返回学号与微信企业号中的userid的映射关系。</li>
    <li>接口访问方式：POST</li>
    <li>例如 接口为：https://www.example.com/mapping</li>
    <li>访问时微哨服务器会提交JSON结构的数据：</li>
    <li>{'{"student_number":"121242"}'}</li>
    <li>接口需返回：</li>
    <li>{'{"userid":"XhdskjgD4sdgjDdsfdf","errcode":"0"}'}</li>
    <li>错误返回：</li>
    <li>{'{"errcode":"-1","errmsg":"info not exist!"}'}</li>
  </Fragment>

};
