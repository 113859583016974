/**
 * Created by liming on 16/7/12.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classes from './SelectableTreeNode.scss';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import Icon from 'components/Icon';

export class SelectableUserNode extends PureComponent {
  static propTypes = {
    node: PropTypes.object,
    showFocus: PropTypes.bool,
    checkMode: PropTypes.bool, // true表示用checkbox来选择
    checkedList: PropTypes.array, // checkMode下使用,记录check的node记录
    width: PropTypes.number,
    height: PropTypes.number,
    levelOffset: PropTypes.number,
    activeNode: PropTypes.object,
    onClickCallback: PropTypes.func, // 非checkMode模式下,用户的选择节点的回调
    onCheckCallback: PropTypes.func, // checkMode模式下,用户check的回调
    onUncheckCallback: PropTypes.func, // checkMode模式下,用户uncheck的回调
    replacePresetCallback: PropTypes.func
  }

  state = {
    isCheck: false,
    updateUI: false
  }

  componentWillMount = () => {
    if (this.props.checkMode) {
      if (this.isNodeChecked()) {
        this.props.replacePresetCallback(this.props.node);
        this.setState({isCheck: true});
      } else {
        this.setState({isCheck: false});
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {

  }

  componentDidUpdate = (preProps, preState) => {
    if (preProps.checkedList !== this.props.checkedList) {
      if (this.isNodeChecked()) {
        this.setState({isCheck: true});
      } else {
        this.setState({isCheck: false});
      }
    }
  }

  onNodeClick = () => {
    if (this.props.onClickCallback) {
      this.props.onClickCallback(this.props.node);
    }
  }

  isNodeActive = () => {
    const {node, activeNode} = this.props;
    if (!activeNode) {
      return false;
    }
    return !activeNode.isOrg && (node.id === activeNode.id);
  }

  isNodeChecked = () => {
    const {node, checkedList} = this.props;
    let ret = checkedList.find((ele, index, arr) => ele.id === node.id);
    if (ret) {
      return true;
    }
    let parentNode = node.parent;
    while (parentNode) {
      ret = checkedList.find((ele, index, arr) => ele.id === parentNode.id);
      if (ret) {
        return true;
      }
      parentNode = parentNode.parent;
    }
    return false;
  }

  onToggleNodeCheck = (event) => {
    event.stopPropagation();
    if (this.state.isCheck) {
      this.props.onUncheckCallback(this.props.node);
    } else {
      this.props.onCheckCallback(this.props.node);
    }
    // this.setState({isCheck: !this.state.isCheck}, {isHalfCheck: false});
  }

  render() {
    const {node, width, height, levelOffset, checkMode, showFocus} = this.props;
    const boxWidth = width - 20;
    // const boxWidth = width;
    let level = 0;
    let helperNode = node;
    while (helperNode.parent) {
      level++;
      helperNode = helperNode.parent;
    }
    const myOffset = level * levelOffset;
    const activeClass = (showFocus && this.isNodeActive()) ? classes.activeNode : '';
    if (!checkMode) {
      return (
        <div className={classes.nodeContainer}>
          <div className={classes.nodeContent + ' ' + activeClass}
            style={{ height: height + 'px', paddingLeft: myOffset, width: boxWidth }}
            onClick={this.onNodeClick}>
            <div className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }} />
            <div className={classes.addOnContainer}>
              <label><Icon type='person' /></label>
            </div>
            {/* 18是nodeIcon的宽度加上左右两边的border + 1 */}
            <div className={classes.bodyText}
              style={{ width: (boxWidth - 18 - 20 - myOffset) + 'px', lineHeight: height + 'px' }}>
              <AutoShrinkTextWithTip text={node.name} maxSize={(boxWidth - 18 - 20 - myOffset)} fontSize={14} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.nodeContainer}>
          <div className={classes.nodeContent + ' ' + activeClass} onClick={this.onNodeClick}
            style={{ height: height + 'px', paddingLeft: myOffset, width: boxWidth }}>
            <div className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }} />
            <div className={classes.addOnContainer} onClick={this.onToggleNodeCheck}>
              <label>
                {this.state.isCheck && <Icon type='box-sel' className={classes.checkEffect} />}
                {!this.state.isCheck && !this.state.isHalfCheck && <Icon type='box' />}
              </label>
            </div>
            <div className={classes.addOnContainer}>
              <label><Icon type='person' /></label>
            </div>
            {/* 18是nodeIcon的宽度加上左右两边的border + 1, 20是addContainer的宽度  */}
            <div className={classes.bodyText}
              style={{ width: (boxWidth - 18 - 20 - myOffset - 20) + 'px', lineHeight: height + 'px' }}>
              <AutoShrinkTextWithTip text={node.name} maxSize={(boxWidth - 18 - 20 - myOffset - 20)} fontSize={14} />
            </div>
          </div>
        </div>
      );
    }
  }
};

export default SelectableUserNode;
