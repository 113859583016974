/**
 * Created by liming on 16/6/14.
 */
import makeReducer from 'store/makeReducer';
import uuid from 'uuid';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import {RESET_ORGANIZATION_SUCCESS} from 'routes/Account/modules/admin';
import {RELOGIN} from 'store/modules/reloginService';
import {LOGOUT_SUCCESS} from 'routes/Login/modules/strings';
import {BATCH_UPDATE} from './user';
import {appendToArray, preAppendToArray, replaceArrayElement, removeArrayElement, ascendElement, descendElement, sort}
  from 'helpers/arrayUtils';
import { getLeafName } from 'helpers/orgUtils';
import {isMyChild} from 'components/OrgTreePicker/treeUtils';
import {ADMIN_USER, MULTI_USER} from 'helpers/strings';
import {getAuthInfo, USER_TYPE_SUBADMIN, getUserQuery} from 'helpers/AuthHelper';
import { CUSTOM_GRADE, CUSTOM_JOB, CUSTOM_STATUS, CUSTOM_ACTIVE } from '../strings/strings';
// ------------------------------------
// Constants
// ------------------------------------

const PREFIX = 'whistlems/organization/';

const LOADING = '_LOADING';
const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';
const ATPAGE = '_AT_PAGE';

export const ROOT = PREFIX + 'ROOT';
export const ROOT_SUCCESS = ROOT + SUCCESS;
export const ROOT_FAIL = ROOT + FAIL;

export const RESET_ROOT = PREFIX + 'RESET_ROOT';

export const SECOND_ROOT = PREFIX + 'SECOND_ROOT';
export const SECOND_ROOT_SUCCESS = SECOND_ROOT + SUCCESS;
export const SECOND_ROOT_FAIL = SECOND_ROOT + FAIL;

export const NODE = PREFIX + 'NODE';
export const NODE_SUCCESS = NODE + SUCCESS;
export const NODE_FAIL = NODE + FAIL;

export const RESET_NODE_OPERATION = PREFIX + 'RESET_NODE_OPERATION';

export const NEW_NODE = PREFIX + 'NEW_NODE';
export const NEW_NODE_SUCCESS = NEW_NODE + SUCCESS;
export const NEW_NODE_FAIL = NEW_NODE + FAIL;

export const SET_NODE_TO_DELETE = PREFIX + 'SET_NODE_TO_DELETE';

export const DELETE_NODE = PREFIX + 'DELETE_NODE';
export const DELETE_NODE_SUCCESS = DELETE_NODE + SUCCESS;
export const DELETE_NODE_FAIL = DELETE_NODE + FAIL;

export const CLEAR_NODE_TO_DELETE = PREFIX + 'CLEAR_NODE_TO_DELETE';

export const SET_NODE_TO_EDIT = PREFIX + 'SET_NODE_TO_EDIT';

export const EDIT_NODE = PREFIX + 'EDIT_NODE';
export const EDIT_NODE_SUCCESS = EDIT_NODE + SUCCESS;
export const EDIT_NODE_FAIL = EDIT_NODE + FAIL;

export const CLEAR_NODE_TO_EDIT = PREFIX + 'CLEAR_NODE_TO_EDIT';

export const NODE_DESCEND = PREFIX + 'NODE_DESCEND';
export const NODE_DESCEND_SUCCESS = NODE_DESCEND + SUCCESS;
export const NODE_DESCEND_FAIL = NODE_DESCEND + FAIL;

export const NODE_ASCEND = PREFIX + 'NODE_ASCEND';
export const NODE_ASCEND_SUCCESS = NODE_ASCEND + SUCCESS;
export const NODE_ASCEND_FAIL = NODE_ASCEND + FAIL;

export const SET_NEW_NODE_PARENT = PREFIX + 'SET_NEW_NODE_PARENT';
export const SELECT_NEW_NODE_PARENT = PREFIX + 'SELECT_NEW_NODE_PARENT';
export const CLEAR_NEW_NODE_PARENT = PREFIX + 'CLEAR_NEW_NODE_PARENT';
export const SET_ACTIVE_NODE = PREFIX + 'SET_ACTIVE_NODE';
export const CLEAR_ACTIVE_NODE = PREFIX + 'CLEAR_ACTIVE_NODE';
export const SET_USER_TO_DELETE = PREFIX + 'SET_USER_TO_DELETE';

export const DELETE_USER = PREFIX + 'DELETE_USER';
export const DELETE_USER_SUCCESS = DELETE_USER + SUCCESS;
export const DELETE_USER_FAIL = DELETE_USER + FAIL;

export const CLEAR_USER_TO_DELETE = PREFIX + 'CLEAR_USER_TO_DELETE';
export const SET_USER_TO_DISABLE = PREFIX + 'SET_USER_TO_DISABLE';
export const DISABLE_USER = PREFIX + 'DISABLE_USER';
export const CLEAR_USER_TO_DISABLE = PREFIX + 'CLEAR_USER_TO_DISABLE';
export const SET_USER_TO_ENABLE = PREFIX + 'SET_USER_TO_ENABLE';
export const ENABLE_USER = PREFIX + 'ENABLE_USER';
export const CLEAR_USER_TO_ENABLE = PREFIX + 'CLEAR_USER_TO_ENABLE';
export const SET_USER_TO_EDIT = PREFIX + 'SET_USER_TO_EDIT';
export const CLEAR_USER_TO_EDIT = PREFIX + 'CLEAR_USER_TO_EDIT';

export const USER_LIST = PREFIX + 'USER_LIST';
export const USER_LIST_AT_PAGE = USER_LIST + ATPAGE;
export const USER_LIST_SUCCESS = USER_LIST + SUCCESS;
export const USER_LIST_FAIL = USER_LIST + FAIL;

export const SET_USER_STATUS = PREFIX + 'SET_USER_STATUS';
export const SET_USER_STATUS_AT_PAGE = SET_USER_STATUS + ATPAGE;
export const SET_USER_STATUS_SUCCESS = SET_USER_STATUS + SUCCESS;
export const SET_USER_STATUS_FAIL = SET_USER_STATUS + FAIL;

export const USER_INSERT_UPDATE_HANDLE = PREFIX + 'USER_INSERT_UPDATE_HANDLE';

export const USER_COUNT_PER_PAGE = 20; // 每页显示最大用户数

export const NODE_GRADUATION = '9'; // 标示组织结构已毕业

// 搜索末级组织节点
const SEARCH_ORG_LAST = PREFIX + 'SEARCH_ORG_LAST';
export const SEARCH_ORG_LAST_LOADING = SEARCH_ORG_LAST + LOADING;
export const SEARCH_ORG_LAST_SUCCESS = SEARCH_ORG_LAST + SUCCESS;
export const SEARCH_ORG_LAST_FAIL = SEARCH_ORG_LAST + FAIL;

// 标记组织节点毕业状态
const CHANGE_GRADUATION = PREFIX + 'CHANGE_GRADUATION';
const CHANGE_GRADUATION_LOADING = CHANGE_GRADUATION + LOADING;
const CHANGE_GRADUATION_SUCCESS = CHANGE_GRADUATION + SUCCESS;
const CHANGE_GRADUATION_FAIL = CHANGE_GRADUATION + FAIL;

// 搜索组织节点
const SEARCH_ORG = PREFIX + 'SEARCH_ORG';
const SEARCH_ORG_LOADING = SEARCH_ORG + LOADING;
const SEARCH_ORG_SUCCESS = SEARCH_ORG + SUCCESS;
const SEARCH_ORG_FAIL = SEARCH_ORG + FAIL;

const MULTI_ORG = PREFIX + 'MULTI_ORG';
const MULTI_ORG_SUCCESS = MULTI_ORG + SUCCESS;
const MULTI_ORG_FAIL = MULTI_ORG + FAIL;

const MULTI_ORG_ADD = PREFIX + 'MULTI_ORG_ADD';
const MULTI_ORG_ADD_SUCCESS = MULTI_ORG_ADD + SUCCESS;
const MULTI_ORG_ADD_FAIL = MULTI_ORG_ADD + FAIL;

const MULTI_ORG_ROOT = PREFIX + 'MULTI_ORG_ROOT';

const ADD_USER_ORG_ROOT = PREFIX + 'ADD_USER_ORG_ROOT';

const MULTI_ORG_ADD_USER = PREFIX + 'MULTI_ORG_ADD_USER';
const MULTI_ORG_ADD_USER_SUCCESS = MULTI_ORG_ADD_USER + SUCCESS;
const MULTI_ORG_ADD_USER_FAIL = MULTI_ORG_ADD_USER + FAIL;

const SET_OPENED_LIST = PREFIX + 'SET_OPENED_LIST';
const CLEAR_OPENED_LIST = PREFIX + 'CLEAR_OPENED_LIST';
const SET_OPT_FLAG = PREFIX + 'SET_OPT_FLAG';

// 人员批量置顶
const SET_BATCH_TOP_NODE = PREFIX + 'SET_BATCH_TOP_NODE';
const CLEAR_BATCH_TOP_NODE = PREFIX + 'CLEAR_BATCH_TOP_NODE';

const ORG_USER_LIST = PREFIX + 'ORG_USER_LIST'; // 组织结构树下直接的节点
const ORG_USER_LIST_SUCCESS = ORG_USER_LIST + SUCCESS; // 组织结构树下直接的节点
const ORG_USER_LIST_FAIL = ORG_USER_LIST + FAIL; // 组织结构树下直接的节点

const SET_BATCH_TOP = PREFIX + 'SET_BATCH_TOP'; // 批量置顶设置
const SET_BATCH_TOP_SUCCESS = SET_BATCH_TOP + SUCCESS; // 批量置顶设置
const SET_BATCH_TOP_FAIL = SET_BATCH_TOP + FAIL; // 批量置顶设置



// ------------------------------------
// 数据结构
// ------------------------------------
/**
 * 组织树节点
 * @param id
 * @param name
 * @param childrenCount
 * @param path
 * @param sort
 * @param parentNode
 * @constructor
 */
function TreeNode(orgType, parentId, id, name, nameEn, childrenCount, path, sort, parentNode, attribute,
  nodeStatus, teacherList, counselorList, statusIm) {
  this.orgType = orgType;
  this.parentId = parentId;
  this.id = id;
  this.name = name;
  this.nameEn = nameEn;
  this.children = [];
  this.children.length = childrenCount;
  this.path = path;
  this.parent = parentNode;
  this.attribute = attribute;
  this.sort = sort;
  if (this.children.length === 0) {
    this.isLeaf = true;
    this.childrenLoaded = true; // 标记子节点的信息是否已经加载
  } else {
    this.isLeaf = false;
    this.childrenLoaded = false; // 标记子节点的信息是否已经加载
  }
  this.usersLoaded = false; // 标记用户节点是否加载
  this.users = [];
  this.isOrg = true;
  this.status = nodeStatus; // 标记节点状态，9为已毕业
  this.teacherList = teacherList; // 存放班主任人员列表信息
  this.counselorList = counselorList; // 存放辅导员人员列表信息
  this.status_im = statusIm; // 存放当前节点是否绑定群聊
  this.uuid = uuid.v4();
}

function UserNode(parent, id, name, path, studentNumber) {
  this.id = 'u' + id; // 由于用户节点的id可能会和组织部门节点的id一样,因此加上前缀u来区分,各个业务环节如果需要从此处拿到id,必须把前缀去掉
  this.name = name;
  this.parent = parent;
  this.path = path;
  this.isOrg = false;
  this.uuid = uuid.v4();
  this.student_number = studentNumber;
}
// ------------------------------------
// Utils
// ------------------------------------
/**
 * 根据服务器返回的结果构造二级管理员的授权组织结构树
 * @param result 服务器返回的原始数据
 * @returns {TreeNode}
 */
export function buildSecondRootFromResult(result) {
  let treeNode = null;
  treeNode = new TreeNode('0', '-2', '-2', '授权范围', '授权范围', result.org.length, '-2', 0, '-2'); // 二级管理员的虚拟根的id是-2
  treeNode.isVirtual = true; // 标记为虚构节点
  let children = [];
  result.org.forEach((node) => {
    children.push(new TreeNode(node.org_type, node.parent_id, node.id, node.name, node.name_en, node.child_list,
      node.path, node.sort, treeNode, node.attribute));
  });
  treeNode.children = sort(children);
  treeNode.childrenLoaded = true;
  treeNode.users = [];
  result.user.forEach((user) => {
    treeNode.users.push(new UserNode(treeNode, user.id, user.name, user.path, user.student_number || ''));
  });
  treeNode.usersLoaded = true;
  return treeNode;
}

/**
 * 根据服务器返回的组织结构树内容来构造{TreeNode}对象
 * @param parent 父节点{TreeNode}
 * @param result 服务器返回的原始数据
 * @returns {TreeNode}
 */
export function buildNewNodeFromResult(parent, result) {
  let parentNode = null;
  let treeNode = null;
  if (!parent) { // 根节点
    parentNode = result.org[0];
    treeNode = new TreeNode(parentNode.org_type, parentNode.parent_id, parentNode.id, parentNode.name,
      parentNode.name_en,
      parentNode.child_list, parentNode.path, parentNode.sort, undefined, '0', '1', parentNode.org_head_teac_list,
      parentNode.org_fudao_teac_list, parentNode.status_im);
    let children = [];
    result.org1.forEach((node) => {
      children.push(new TreeNode(node.org_type, node.parent_id, node.id, node.name, node.name_en,
        node.child_list, node.path, node.sort, treeNode, node.attribute,
        node.status, node.org_head_teac_list, node.org_fudao_teac_list, node.status_im));
    });
    treeNode.children = sort(children);
    treeNode.childrenLoaded = true;
  } else {
    treeNode = parent;
    let children = [];
    result.org.forEach((node) => {
      children.push(new TreeNode(node.org_type, node.parent_id, node.id, node.name, node.name_en,
        node.child_list, node.path, node.sort, treeNode, node.attribute,
        node.status, node.org_head_teac_list, node.org_fudao_teac_list, node.status_im));
    });
    treeNode.children = sort(children);
    treeNode.uuid = uuid.v4();
  }
  const users = [];
  result.user_list.forEach((user) => {
    users.push(new UserNode(treeNode, user.user_id, user.name, user.path, user.student_number || ''));
  });
  treeNode.users = users;
  return treeNode;
}

function updateToNewRoot(newRoot) {
  if (!newRoot) {
    return;
  }
  newRoot.children && newRoot.children.forEach((child) => {
    child.parent = newRoot;
  });
  newRoot.users && newRoot.users.forEach((user) => {
    user.parent = newRoot;
  });
  newRoot.uuid = uuid.v4();
}

function isMyUser(node, user) {
  return node.users.find(ele => ele.id === user.id);
}

function findParentNodeOfUserById(root, user) {
  if (isMyUser(root, user)) {
    return root;
  }
  if (root.childrenLoaded) {
    for (let index = 0; index < root.children.length; index++) {
      const bingo = findParentNodeOfUserById(root.children[index], user);
      if (bingo) {
        return bingo;
      }
    }
  }
  return undefined;
}

function findParentNodeOfUser(root, user) {
  const userPath = user.path;
  let endFlag = false;
  let node = root;
  while (!endFlag) {
    if (node.path === userPath) {
      endFlag = true;
    } else {
      if (!node.childrenLoaded) {
        endFlag = true;
        node = undefined;
      } else {
        node = node.children.find(ele => isMyChild(ele, user));
      }
    }
  }

  return node;
}

// ------------------------------------
// Actions
// ------------------------------------

export const multiOrgList = () => {
  return {
    types: [MULTI_ORG, MULTI_ORG_SUCCESS, MULTI_ORG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgUserList', {
        org_id: '0'
      })
    })
  };
};

export const multiOrgAdd = (orgName) => {
  return {
    types: [MULTI_ORG_ADD, MULTI_ORG_ADD_SUCCESS, MULTI_ORG_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgAddOther', {
        name: orgName
      })
    })
  };
};

export const loadOrgRoot = (treeNode = undefined, flag = false) => {
  return {
    types: [ROOT, ROOT_SUCCESS, ROOT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgUserList', {
        org_id: treeNode && treeNode.id || '0'
      })
    })
  };
};
export const loadSecondOrgRoot = (roleId) => {
  return {
    types: [SECOND_ROOT, SECOND_ROOT_SUCCESS, SECOND_ROOT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgSecondList', {
        org_id: '0',
        role_id: roleId
      })
    })
  };
};
export const loadOrgNode = (treeNode) => {
  const auth = getAuthInfo();
  return {
    types: [NODE, NODE_SUCCESS, NODE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgUserList', {
        org_id: treeNode.id,
        org_flag: auth && auth.userType === USER_TYPE_SUBADMIN || undefined
      })
    }),
    treeNode
  };
};
export const resetNodeOperation = () => {
  return {
    type: RESET_NODE_OPERATION
  };
};
export const createNewOrgNode = (parentTreeNode, newOrgName, nameEn, attribute, teachers, counselors) => {
  const teacherList = teachers && teachers.map(item => item.value) || [];
  const counselorsList = counselors && counselors.map(item => item.value) || [];
  return {
    types: [NEW_NODE, NEW_NODE_SUCCESS, NEW_NODE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgAdd', {
        org_id: parentTreeNode.id,
        path: parentTreeNode.path || '',
        name: newOrgName,
        name_en: nameEn,
        attribute: attribute,
        org_head_teac_ids: teacherList.join(','),
        org_fudao_teac_ids: counselorsList.join(',')
      })
    }),
    parentTreeNode,
    newOrgName,
    nameEn,
    attribute,
    org_fudao_teac_list: counselors,
    org_head_teac_list: teachers
  };
};

export const setNodeToDelete = (treeNode) => {
  if (!treeNode || !treeNode.parent) { // 根节点不允许删除的
    return false;
  }
  return {
    type: SET_NODE_TO_DELETE,
    treeNode
  };
};
export const deleteNode = (treeNode) => {
  if (!treeNode || !treeNode.parent) { // 根节点不允许删除的
    return false;
  }
  return {
    types: [DELETE_NODE, DELETE_NODE_SUCCESS, DELETE_NODE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgDelete', {
        org_id: treeNode.id
      })
    }),
    treeNode
  };
};
export const setUserStatus = (user, status) => {
  return {
    types: [SET_USER_STATUS, SET_USER_STATUS_SUCCESS, SET_USER_STATUS_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userStatus', {
        username: user.username,
        status
      })
    }),
    user,
    status
  };
};

export const clearNodeToDelete = () => {
  return {
    type: CLEAR_NODE_TO_DELETE
  };
};

export const setNodeToEdit = (treeNode) => {
  return {
    type: SET_NODE_TO_EDIT,
    treeNode
  };
};
export const editNode = (treeNode, newName, nameEn, attribute, teachers, counselors) => {
  const teacherList = [];
  const counselorsList = [];
  teachers && teachers.map((item) => {
    teacherList.push(item.value);
  });
  counselors && counselors.map((item) => {
    counselorsList.push(item.value);
  });
  return {
    types: [EDIT_NODE, EDIT_NODE_SUCCESS, EDIT_NODE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgModify', {
        org_id: treeNode.id,
        name: newName,
        name_en: nameEn,
        attribute: attribute,
        org_head_teac_ids: teacherList.join(','),
        org_fudao_teac_ids: counselorsList.join(',')
      })
    }),
    newName,
    nameEn,
    attribute,
    org_fudao_teac_list: counselors,
    org_head_teac_list: teachers
  };
};
export const clearNodeToEdit = () => {
  return {
    type: CLEAR_NODE_TO_EDIT
  };
};

export const ascendNode = (treeNode, destNode) => {
  return {
    types: [NODE_ASCEND, NODE_ASCEND_SUCCESS, NODE_ASCEND_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgOrder', {
        org_id: treeNode.id,
        sort: treeNode.sort,
        dest_org_id: destNode.id,
        dest_sort: destNode.sort
      })
    }),
    treeNode,
    destNode
  };
};
export const descendNode = (treeNode, destNode) => {
  return {
    types: [NODE_DESCEND, NODE_DESCEND_SUCCESS, NODE_DESCEND_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgOrder', {
        org_id: treeNode.id,
        sort: treeNode.sort,
        dest_org_id: destNode.id,
        dest_sort: destNode.sort
      })
    }),
    treeNode,
    destNode
  };
};

export const setNewNodeParent = (treeNode) => {
  return {
    type: SET_NEW_NODE_PARENT,
    treeNode
  };
};
export const selectNewNodeParent = (treeNode) => {
  return {
    type: SELECT_NEW_NODE_PARENT,
    treeNode
  };
};

export const clearNewNodeParent = () => {
  return {
    type: CLEAR_NEW_NODE_PARENT
  };
};

export const setActiveNode = (treeNode) => {
  return {
    type: SET_ACTIVE_NODE,
    treeNode
  };
};

export const clearActiveNode = () => {
  return {
    type: CLEAR_ACTIVE_NODE
  };
};

export const queryUsers = (query) => {
  const customFields = getUserQuery();
  let queryParams = Object.assign({}, query, { SearchFlag: query && query.isSearchMode ? 1 : 0 });
  if (customFields && customFields.length > 0) {
    const customGrade = customFields.find(item => item === CUSTOM_GRADE);
    const customJob = customFields.find(item => item === CUSTOM_JOB);
    const customStatus = customFields.find(item => item === CUSTOM_STATUS);
    const customActive = customFields.find(item => item === CUSTOM_ACTIVE);
    if (customGrade && !queryParams.sznj) {
      queryParams.sznj = 'all';
      query.sznj = 'all';
    }
    if (customJob && !queryParams.job) {
      queryParams.job = 'all';
      query.job = 'all';
    }
    if (customStatus && !queryParams.status_s) {
      queryParams.status_s = 'all';
      query.status_s = 'all';
    }
    if (customActive && !queryParams.activity) {
      queryParams.activity = 'all';
      query.activity = 'all';
    }
  }
  return {
    types: [USER_LIST, USER_LIST_SUCCESS, USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userList', queryParams)
    }),
    query,
    searchMode: query && query.isSearchMode,
    resetCurrentPage: 1
  };
};

export const queryUserBySearch = (query) => {
  return {
    types: [USER_LIST, USER_LIST_SUCCESS, USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'searchUserList', query)
    }),
    query,
    resetCurrentPage: 1
  };
};

export const queryUsersAtPage = (query) => {
  const queryParams = Object.assign({}, query, { SearchFlag: query && query.isSearchMode ? 1 : 0 });
  const pageingApi = query && query.isSearchMode ? 'searchUserList' : 'userList'; // 全局搜索分页、组织搜索分页
  return {
    types: [USER_LIST_AT_PAGE, USER_LIST_SUCCESS, USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', pageingApi, queryParams)
    }),
    query
  };
};

export const setUserToDelete = (user) => {
  return {
    type: SET_USER_TO_DELETE,
    user
  };
};

export const deleteUser = (user) => {
  return {
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userDelete', {
        username: user.username,
        orgId: getLeafName(user.path)
      })
    }),
    user
  };
};

export const clearUserToDelete = (user) => {
  return {
    type: CLEAR_USER_TO_DELETE
  };
};

export const setUserToDisable = (user) => {
  return {
    type: SET_USER_TO_DISABLE,
    user
  };
};
export const disableUser = () => {
  return {
    type: DISABLE_USER
  };
};
export const clearUserToDisable = (user) => {
  return {
    type: CLEAR_USER_TO_DISABLE
  };
};
export const setUserToEnable = (user) => {
  return {
    type: SET_USER_TO_ENABLE,
    user
  };
};
export const enableUser = () => {
  return {
    type: ENABLE_USER
  };
};
export const clearUserToEnable = (user) => {
  return {
    type: CLEAR_USER_TO_ENABLE
  };
};
export const setUserToEdit = (user) => {
  return {
    type: SET_USER_TO_EDIT,
    user
  };
};

export const clearUserToEdit = (user) => {
  return {
    type: CLEAR_USER_TO_EDIT
  };
};

export const userToHandle = (user) => {
  return {
    type: USER_INSERT_UPDATE_HANDLE,
    user
  };
};

/**
 * 部门院系置顶
 * @param  flag 1-设置置顶用户 2-取消置顶用户； user对象
 * @returns
 */
export const setUserToTop = (user, flag, query) => {
  const customFields = getUserQuery();
  let queryData = query;
  if (customFields && customFields.length > 0) {
    const customGrade = customFields.find(item => item === CUSTOM_GRADE);
    const customJob = customFields.find(item => item === CUSTOM_JOB);
    const customStatus = customFields.find(item => item === CUSTOM_STATUS);
    const customActive = customFields.find(item => item === CUSTOM_ACTIVE);
    if (customGrade && !queryData.sznj) {
      queryData.sznj = 'all';
    }
    if (customJob && !queryData.job) {
      queryData.job = 'all';
    }
    if (customStatus && !queryData.status_s) {
      queryData.status_s = 'all';
    }
    if (customActive && !queryData.activity) {
      queryData.activity = 'all';
    }
  }
  if (!queryData.isSearchMode) {
    queryData = Object.assign({}, queryData, {top_user: user.username});
  }
  queryData = Object.assign({}, queryData, {top_flag: flag});
  return {
    types: [USER_LIST, USER_LIST_SUCCESS, USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userList', queryData)
    }),
    query
  };
};

/**
 * 根节点状态位重置
 * @returns  默认组织对应状态位重置
 */
export const clearOrgRoot = () => {
  return {
    type: RESET_ROOT
  };
};

/**
 * 标记毕业生，搜索末级组织
 * @param searchContent 组织名称
 * @param page 起始页
 * @param count 单页数量
 * @returns {{types: Array, promise: (function(*): *)}}
 */
export const searchOrgLast = (searchContent, page, count) => {
  return {
    types: [SEARCH_ORG_LAST_LOADING, SEARCH_ORG_LAST_SUCCESS, SEARCH_ORG_LAST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgSearchLast', {
        name: searchContent,
        page: page,
        count: count
      })
    })
  };
};

/**
 * 改变组织是否毕业状态
 * @param orgIds 组织id， 如果 1，2，3
 * @param status 是否毕业， '9' 已毕业，'1' 未毕业
 * @param node 编辑的节点
 * @returns {{types: [*,*,*], promise: (function(*): *)}}
 */
export const changeGraduationStatus = (orgIds, status, node) => {
  return {
    types: [CHANGE_GRADUATION_LOADING, CHANGE_GRADUATION_SUCCESS, CHANGE_GRADUATION_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgSetGraduation', {
        org_ids: orgIds,
        status
      })
    }),
    node,
    status
  };
};

/**
 * 部门院系- > 用户资料搜索组织名称
 * @param name
 * @returns {{types: [*,*,*], promise: (function(*)), queryName: *}}
 */
export const searchOrg = (name) => {
  return {
    types: [SEARCH_ORG_LOADING, SEARCH_ORG_SUCCESS, SEARCH_ORG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgSearchAll', {
        name: name
      })
    }),
    queryName: name
  };
};

export const multiOrgRoot = (orgId, flag = false) => {
  return {
    type: MULTI_ORG_ROOT,
    orgId,
    flag
  };
};

export const addUserOrgRoot = (addType) => {
  return {
    type: ADD_USER_ORG_ROOT,
    addType
  };
};

export const multiOrgAddUser = (userId, orgId, jobId) => {
  return {
    types: [MULTI_ORG_ADD_USER, MULTI_ORG_ADD_USER_SUCCESS, MULTI_ORG_ADD_USER_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userAdd2', {
        uid: userId,
        orgId: orgId,
        job_id: jobId
      })
    }),
    userId,
    orgId
  };
};

export const setOpenedList = (openedList) => {
  return {
    type: SET_OPENED_LIST,
    openedList
  };
};

export const clearOpenedList = (index) => {
  return {
    type: CLEAR_OPENED_LIST,
    index
  };
};

export const setOptFlag = () => {
  return {
    type: SET_OPT_FLAG
  };
};

// 人员批量置顶
export const setBatchTopNode = ({ node }) => {
  return {
    type: SET_BATCH_TOP_NODE,
    node
  };
};

export const clearBatchTopNode = () => {
  return {
    type: CLEAR_BATCH_TOP_NODE
  };
};

export const getOrgUserList = (query) => {
  return {
    types: [ORG_USER_LIST, ORG_USER_LIST_SUCCESS, ORG_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userSortList', { ...query })
    })
  };
};

export const setBatchTop = (data) => {
  return {
    types: [SET_BATCH_TOP, SET_BATCH_TOP_SUCCESS, SET_BATCH_TOP_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userSort', { ...data })
    })
  };
};

export const actions = {
  loadOrgRoot,
  loadSecondOrgRoot,
  loadOrgNode,
  resetNodeOperation,
  createNewOrgNode,
  setNodeToDelete,
  deleteNode,
  clearNodeToDelete,
  setNodeToEdit,
  editNode,
  clearNodeToEdit,
  ascendNode,
  descendNode,
  setNewNodeParent,
  selectNewNodeParent,
  clearNewNodeParent,
  setActiveNode,
  clearActiveNode,
  queryUsers,
  queryUsersAtPage,
  setUserToDelete,
  deleteUser,
  clearUserToDelete,
  setUserToDisable,
  disableUser,
  clearUserToDisable,
  setUserToEnable,
  enableUser,
  clearUserToEnable,
  setUserToEdit,
  clearUserToEdit,
  setUserStatus,
  userToHandle,
  setUserToTop,
  clearOrgRoot,
  searchOrgLast,
  changeGraduationStatus,
  searchOrg,
  multiOrgList,
  multiOrgAdd,
  setOpenedList,
  clearOpenedList,
  setOptFlag,
  setBatchTopNode,
  clearBatchTopNode,
  getOrgUserList,
  setBatchTop
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_OPT_FLAG]: (state, action) => {
    return {
      ...state,
      optFlag: true
    };
  },
  [CLEAR_OPENED_LIST]: (state, action) => {
    return {
      ...state,
      openedResult: undefined,
      activeIndex: action.index
    };
  },
  [SET_OPENED_LIST]: (state, action) => {
    return {
      ...state,
      openedResult: action.openedList
    };
  },
  [MULTI_ORG_ADD_USER]: (state, action) => {
    return {
      ...state,
      multiOrgAddUserLoading: true,
      multiOrgAddUserLoaded: false,
      multiOrgAddUserError: undefined
    };
  },
  [MULTI_ORG_ADD_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      multiOrgAddUserLoading: false,
      multiOrgAddUserLoaded: true,
      multiOrgAddUserError: undefined
    };
  },
  [MULTI_ORG_ADD_USER_FAIL]: (state, action) => {
    return {
      ...state,
      multiOrgAddUserLoading: false,
      multiOrgAddUserLoaded: false,
      multiOrgAddUserError: action.payload.errmsg
    };
  },
  [ADD_USER_ORG_ROOT]: (state, action) => {
    const rootChildren = state.organizationRoot && state.organizationRoot.children;
    let child = [];
    if (rootChildren && rootChildren.length > 0) {
      child = action.addType === ADMIN_USER ? rootChildren.filter(item => item.orgType === '1')
        : action.addType === MULTI_USER ? rootChildren.filter(item => item.orgType !== '1')
          : rootChildren;
    }
    return {
      ...state,
      addUserOrgRootResult: Object.assign({}, state.organizationRoot, {children: child})
    };
  },
  [MULTI_ORG_ROOT]: (state, action) => {
    const parentId = state.activeNode && state.activeNode.parentId;
    const orgId = action.orgId;
    const orgs = state.organizationRoot.children;
    const multiOrgRoot = orgs && orgs.length > 0 && (orgs.find(item => item.id === orgId) || orgs[0]);
    if (multiOrgRoot) {
      multiOrgRoot.uuid = uuid.v4();
    }
    return {
      ...state,
      multiOrgRootResult: multiOrgRoot,
      activeNode: (parentId === '-1' || action.flag) && multiOrgRoot || state.activeNode
    };
  },
  [MULTI_ORG_ADD]: (state, action) => {
    return {
      ...state,
      multiOrgAddLoading: true,
      multiOrgAddLoaded: false,
      multiOrgAddError: undefined
    };
  },
  [MULTI_ORG_ADD_SUCCESS]: (state, action) => {
    const org = action.payload.data;
    return {
      ...state,
      multiOrgAddLoading: false,
      multiOrgAddLoaded: true,
      multiOrgAddError: undefined,
      multiOrgAddResult: org
      // multiOrgList: state.multiOrgList.push(org)
    };
  },
  [MULTI_ORG_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      multiOrgAddLoading: false,
      multiOrgAddLoaded: false,
      multiOrgAddError: action.payload.errmsg
    };
  },
  [MULTI_ORG]: (state, action) => {
    return {
      ...state,
      multiOrgLoading: true,
      multiOrgLoaded: false,
      multiOrgError: undefined
    };
  },
  [MULTI_ORG_SUCCESS]: (state, action) => {
    const newRoot = buildNewNodeFromResult(undefined, action.payload.data);
    return {
      ...state,
      multiOrgLoading: false,
      multiOrgLoaded: true,
      multiOrgError: undefined,
      multiOrgResult: newRoot && newRoot.children || []
    };
  },
  [MULTI_ORG_FAIL]: (state, action) => {
    return {
      ...state,
      multiOrgLoading: false,
      multiOrgLoaded: false,
      multiOrgError: action.payload.errmsg
    };
  },
  [RESET_ORGANIZATION_SUCCESS]: (state, action) => {
    const newRoot = buildNewNodeFromResult(undefined, {
      org: [{
        id: state.organizationRoot.id,
        name: state.organizationRoot.name,
        parent_id: '0',
        path: state.organizationRoot.path,
        sort: state.organizationRoot.sort,
        child_list: 0
      }],
      org1: [],
      user_list: []
    });
    newRoot.usersLoaded = true;
    return {
      ...initialState,
      rootLoaded: true,
      organizationRoot: newRoot,
      activeNode: newRoot
    };
  },
  [BATCH_UPDATE]: (state, action) => {
    return {
      ...state,
      rootLoaded: undefined
    };
  },
  [ROOT]: (state, action) => { // 加载组织结构树根节点
    return {
      ...initialState,
      multiOrgResult: state.multiOrgResult,
      nodeLoading: true,
      rooting: true
    };
  },
  [ROOT_SUCCESS]: (state, action) => {
    const newRoot = buildNewNodeFromResult(undefined, action.payload.data);
    return {
      ...state,
      nodeLoading: undefined,
      rooting: undefined,
      rootLoaded: true,
      activeNode: newRoot,
      orgType: newRoot.orgType,
      organizationRoot: newRoot
    };
  },
  [ROOT_FAIL]: (state, action) => {
    return {
      ...state,
      nodeLoading: undefined,
      rooting: undefined,
      rootLoaded: undefined,
      organizationRoot: undefined,
      rootError: action.payload
    };
  },
  [RESET_ROOT]: (state, action) => {
    return {
      ...state,
      nodeLoading: false,
      rooting: false,
      rootLoaded: false,
      optFlag: false,
      activeIndex: undefined
    };
  },
  [SECOND_ROOT]: (state, action) => { // 加载组织结构树根节点
    return {
      ...initialState,
      nodeLoading: undefined,
      rooting: true,
      rootLoaded: undefined,
      rootError: undefined,
      organizationRoot: undefined
    };
  },
  [SECOND_ROOT_SUCCESS]: (state, action) => {
    const newRoot = buildSecondRootFromResult(action.payload.data);
    return {
      ...state,
      nodeLoading: undefined,
      rooting: undefined,
      rootLoaded: true,
      activeNode: newRoot,
      organizationRoot: newRoot
    };
  },
  [SECOND_ROOT_FAIL]: (state, action) => {
    return {
      ...state,
      nodeLoading: undefined,
      rooting: undefined,
      rootLoaded: undefined,
      organizationRoot: undefined,
      rootError: action.payload
    };
  },
  [NODE]: (state, action) => { // 加载非根的组织节点
    action.treeNode.dataLoading = true;
    action.treeNode.dataError = undefined;
    action.treeNode.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot);
    updateToNewRoot(newRoot);
    return {
      ...state,
      nodeLoading: true,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      organizationRoot: newRoot
    };
  },
  [NODE_SUCCESS]: (state, action) => {
    buildNewNodeFromResult(action.treeNode, action.payload.data);
    action.treeNode.dataLoading = undefined;
    action.treeNode.childrenLoaded = true;
    action.treeNode.usersLoaded = true;
    // 最后一个条件是根节点更新
    const newRoot = Object.assign({}, state.organizationRoot, action.treeNode.parent ? {} : action.treeNode);
    updateToNewRoot(newRoot);
    return {
      ...state,
      nodeLoading: undefined,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      organizationRoot: newRoot
    };
  },
  [NODE_FAIL]: (state, action) => {
    action.treeNode.dataLoading = undefined;
    action.treeNode.dataError = action.payload;
    action.treeNode.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot, action.treeNode.parent ? {} : action.treeNode);
    updateToNewRoot(newRoot);
    return {
      ...state,
      nodeLoading: undefined,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      organizationRoot: newRoot
    };
  },
  [RESET_NODE_OPERATION]: (state, action) => {
    return {
      ...state,
      nodeCreating: undefined,
      nodeCreateError: undefined,
      nodeCreated: undefined
    };
  },
  [SET_NODE_TO_DELETE]: (state, action) => {
    return {
      ...state,
      nodeToDelete: action.treeNode
    };
  },
  [DELETE_NODE]: (state, action) => {
    if (!state.nodeToDelete.parent) { // 根节点应该不允许删除的
      return state;
    }
    return {
      ...state,
      nodeDeleting: true,
      nodeDeleted: undefined,
      nodeDeleteError: undefined
    };
  },
  [DELETE_NODE_SUCCESS]: (state, action) => {
    const {nodeToDelete, organizationRoot, activeNode} = state;
    const {parent} = nodeToDelete;
    parent.children = removeArrayElement(parent.children, nodeToDelete);
    if (parent.children.length === 0) {
      parent.isLeaf = true;
    }
    parent.uuid = uuid.v4();
    const newRoot = Object.assign({}, organizationRoot);
    updateToNewRoot(newRoot);
    const newActiveNode = activeNode === nodeToDelete ? newRoot : activeNode;
    return {
      ...state,
      nodeToDelete: undefined,
      nodeDeleting: undefined,
      nodeDeleted: true,
      activeNode: newActiveNode,
      organizationRoot: newRoot
    };
  },
  [DELETE_NODE_FAIL]: (state, action) => {
    return {
      ...state,
      nodeDeleting: undefined,
      nodeDeleteError: action.payload
    };
  },
  [CLEAR_NODE_TO_DELETE]: (state, action) => {
    return {
      ...state,
      nodeToDelete: undefined,
      nodeDeleting: undefined,
      nodeDeleted: undefined,
      nodeDeleteError: undefined
    };
  },
  [SET_NODE_TO_EDIT]: (state, action) => {
    return {
      ...state,
      nodeToEdit: action.treeNode
    };
  },
  [EDIT_NODE]: (state, action) => {
    return {
      ...state,
      nodeEditing: true,
      nodeEdited: undefined,
      nodeEditError: undefined
    };
  },
  [EDIT_NODE_SUCCESS]: (state, action) => {
    state.nodeToEdit.name = action.newName;
    state.nodeToEdit.nameEn = action.nameEn;
    state.nodeToEdit.attribute = action.attribute;
    const teacherList = [];
    action.org_head_teac_list && action.org_head_teac_list.map((item) => {
      teacherList.push({username: item.value, name: item.label});
    });
    const counselorsList = [];
    action.org_fudao_teac_list && action.org_fudao_teac_list.map((item) => {
      counselorsList.push({username: item.value, name: item.label});
    });
    state.nodeToEdit.counselorList = counselorsList;
    state.nodeToEdit.teacherList = teacherList;
    state.nodeToEdit.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot);
    updateToNewRoot(newRoot);
    return {
      ...state,
      nodeEditing: false,
      nodeEdited: true,
      nodeToEdit: undefined,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      organizationRoot: newRoot
    };
  },
  [EDIT_NODE_FAIL]: (state, action) => {
    return {
      ...state,
      nodeEditing: undefined,
      nodeEditError: action.payload
    };
  },
  [CLEAR_NODE_TO_EDIT]: (state, action) => {
    return {
      ...state,
      nodeToEdit: undefined,
      nodeEditing: undefined,
      nodeEdited: undefined,
      nodeEditError: undefined
    };
  },
  [NEW_NODE]: (state, action) => {
    return {
      ...state,
      nodeCreating: true,
      nodeCreateError: undefined,
      nodeCreated: undefined
    };
  },
  [NEW_NODE_SUCCESS]: (state, action) => {
    if (!action.parentTreeNode.childrenLoaded) { // 父节点还没有被展开,无需手动加入孩子
      return {
        ...state,
        nodeCreating: undefined,
        nodeCreateError: undefined,
        nodeCreated: true
      };
    }
    const newNodeData = action.payload.data;
    const realParent = action.parentTreeNode.parent ? action.parentTreeNode : state.organizationRoot;
    const teacherList = [];
    action.org_head_teac_list && action.org_head_teac_list.map((item) => {
      teacherList.push({username: item.value, name: item.label});
    });
    const counselorList = [];
    action.org_fudao_teac_list && action.org_fudao_teac_list.map((item) => {
      counselorList.push({username: item.value, name: item.label});
    });
    const newTreeNode = new TreeNode(newNodeData.org_type, newNodeData.parent_id, newNodeData.id, newNodeData.name,
      newNodeData.name_en, [],
      newNodeData.path, newNodeData.sort, realParent, action.attribute, '1', teacherList, counselorList, '1');
    if (realParent.isLeaf) { // 父节点原来是最低级的组织节点
      realParent.isLeaf = false;
      realParent.children = [newTreeNode];
      realParent.childrenLoaded = true;
    } else { // 父节点原来是高层节点
      realParent.children = appendToArray(action.parentTreeNode.children, [newTreeNode]);
    }
    realParent.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot);
    updateToNewRoot(newRoot);
    return {
      ...state,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      organizationRoot: newRoot,
      nodeCreated: true,
      nodeCreating: undefined,
      nodeCreateError: undefined
    };
  },
  [NEW_NODE_FAIL]: (state, action) => {
    return {
      ...state,
      nodeCreating: undefined,
      nodeCreateError: action.payload
    };
  },
  [NODE_ASCEND]: (state, action) => {
    return {
      ...state,
      nodeOrdering: true,
      nodeOrdered: undefined,
      nodeOrderError: undefined
    };
  },
  [NODE_ASCEND_SUCCESS]: (state, action) => {
    if (!state.activeNode || !state.activeNode.parent) {
      return state;
    }
    const sort = action.destNode.sort;
    action.destNode.sort = state.activeNode.sort;
    state.activeNode.sort = sort;
    state.activeNode.parent.children = ascendElement(state.activeNode.parent.children, state.activeNode);
    state.activeNode.uuid = uuid.v4();
    action.destNode.uuid = uuid.v4();
    state.activeNode.parent.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot);
    updateToNewRoot(newRoot);
    return {
      ...state,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      nodeOrdered: true,
      nodeOrdering: undefined,
      organizationRoot: newRoot
    };
  },
  [NODE_ASCEND_FAIL]: (state, action) => {
    return {
      ...state,
      nodeOrdering: undefined,
      nodeOrderError: action.payload
    };
  },
  [NODE_DESCEND]: (state, action) => {
    return {
      ...state,
      nodeOrdering: true,
      nodeOrdered: undefined,
      nodeOrderError: undefined
    };
  },
  [NODE_DESCEND_SUCCESS]: (state, action) => {
    if (!state.activeNode || !state.activeNode.parent) {
      return state;
    }
    const sort = action.destNode.sort;
    action.destNode.sort = state.activeNode.sort;
    state.activeNode.sort = sort;
    state.activeNode.parent.children = descendElement(state.activeNode.parent.children, state.activeNode);
    state.activeNode.parent.uuid = uuid.v4();
    state.activeNode.uuid = uuid.v4();
    action.destNode.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot);
    updateToNewRoot(newRoot);
    return {
      ...state,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      nodeOrdered: true,
      nodeOrdering: undefined,
      organizationRoot: newRoot
    };
  },
  [NODE_DESCEND_FAIL]: (state, action) => {
    return {
      ...state,
      nodeOrdering: undefined,
      nodeOrderError: action.payload
    };
  },
  [SET_NEW_NODE_PARENT]: (state, action) => {
    return {
      ...state,
      newNodeParentPreset: action.treeNode
      // newNodeParent: action.treeNode
    };
  },
  [SELECT_NEW_NODE_PARENT]: (state, action) => {
    return {
      ...state,
      newNodeParent: action.treeNode
    };
  },
  [CLEAR_NEW_NODE_PARENT]: (state, action) => {
    return {
      ...state,
      newNodeParentPreset: undefined,
      newNodeParent: undefined,
      nodeCreating: undefined,
      nodeCreateError: undefined,
      nodeCreated: undefined
    };
  },
  [SET_ACTIVE_NODE]: (state, action) => {
    if (state.activeNode === action.treeNode) {
      return state;
    }
    return {
      ...state,
      userQuery: Object.assign({}, state.userQuery, {isSearchMode: false}),
      activeNode: action.treeNode
    };
  },
  [CLEAR_ACTIVE_NODE]: (state, action) => {
    return {
      ...state,
      activeNode: undefined
    };
  },
  [USER_LIST_AT_PAGE]: (state, action) => {
    return {
      ...state,
      userQuery: action.query,
      userListing: true,
      userListError: undefined
    };
  },
  [USER_LIST]: (state, action) => {
    const query = action.query;
    if (!query.page) {
      query.page = 1;
    }
    if (!query.count) {
      query.count = USER_COUNT_PER_PAGE;
    }
    if (query.org_id === state.organizationRoot.id) {
      return {
        ...state,
        activeNode: state.organizationRoot,
        userQuery: query,
        userListing: true,
        // userListResult: undefined,
        userListError: undefined
      };
    }
    return {
      ...state,
      userQuery: query,
      userListing: true,
      // userListResult: undefined,
      userListError: undefined,
      isReset: false
    };
  },
  [USER_LIST_SUCCESS]: (state, action) => {
    if (action.query !== state.userQuery) {
      return state;
    }
    const users = action.payload.data.list.map((user) => Object.assign({}, user, {uuid: uuid.v4()}));
    const isReset = action.resetCurrentPage === 1;
    return {
      ...state,
      userListing: undefined,
      userListResult: Object.assign({}, {
        total_count: parseInt(action.payload.data.total_count),
        active: action.payload.data.activity.activity,
        graduation: action.payload.data.activity.graduation,
        list: users
      }, { query: action.query }),
      userListError: undefined,
      isReset: isReset
    };
  },
  [USER_LIST_FAIL]: (state, action) => {
    if (action.query !== state.userQuery) {
      return state;
    }
    return {
      ...state,
      userListing: undefined,
      userListResult: undefined,
      userListError: action.payload,
      isReset: false
    };
  },
  [SET_USER_TO_DELETE]: (state, action) => {
    return {
      ...state,
      userToDelete: action.user
    };
  },
  [DELETE_USER]: (state, action) => {
    return {
      ...state,
      deleteUserLoading: true,
      deleteUserLoaded: false,
      deleteUserError: undefined
    };
  },
  [DELETE_USER_FAIL]: (state, action) => {
    return {
      ...state,
      deleteUserLoading: false,
      deleteUserLoaded: false,
      deleteUserError: action.payload
    };
  },
  [DELETE_USER_SUCCESS]: (state, action) => {
    const newUserList = Object.assign({}, state.userListResult,
      {total_count: parseInt(state.userListResult.total_count) - 1},
      {list: removeArrayElement(state.userListResult.list, action.user)});
    const userNode = {id: 'u' + action.user.username, path: action.user.path}; // 前缀u是必须的
    const treeNode = findParentNodeOfUser(state.organizationRoot, userNode);
    if (!treeNode) {
      return {
        ...state,
        userListResult: newUserList,
        deleteUserLoading: false,
        deleteUserLoaded: true,
        deleteUserError: false,
        userToDelete: undefined
      };
    }
    const childNode = treeNode.users.find((ele, index, arr) => ele.id === userNode.id);
    treeNode.users = removeArrayElement(treeNode.users, childNode);
    treeNode.uuid = uuid.v4();
    const newRoot = Object.assign({}, state.organizationRoot);
    updateToNewRoot(newRoot);
    return {
      ...state,
      userListResult: newUserList,
      deleteUserLoading: false,
      deleteUserLoaded: true,
      deleteUserError: false,
      userToDelete: undefined,
      activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
      organizationRoot: newRoot
    };
  },

  [CLEAR_USER_TO_DELETE]: (state, action) => {
    return {
      ...state,
      userToDelete: undefined
    };
  },
  [SET_USER_TO_DISABLE]: (state, action) => {
    return {
      ...state,
      userToDisable: action.user
    };
  },
  [DISABLE_USER]: (state, action) => {
    /*
     state.userToDisable.status = 0;
     state.userToDisable.uuid = uuid.v4();
     */
    const updatedUser = Object.assign({}, state.userToDisable, {status: 0, uuid: uuid.v4()});
    return {
      ...state,
      userListResult: Object.assign({}, state.userListResult,
        {list: replaceArrayElement(state.userListResult.list, state.userToDisable, updatedUser)}),
      userToDisable: undefined
    };
  },
  [CLEAR_USER_TO_DISABLE]: (state, action) => {
    return {
      ...state,
      userToDisable: undefined
    };
  },
  [SET_USER_TO_ENABLE]: (state, action) => {
    return {
      ...state,
      userToEnable: action.user
    };
  },
  [ENABLE_USER]: (state, action) => {
    /*
     state.userToDisable.status = 0;
     state.userToDisable.uuid = uuid.v4();
     */
    const updatedUser = Object.assign({}, state.userToEnable, {status: 1, uuid: uuid.v4()});
    return {
      ...state,
      userListResult: Object.assign({}, state.userListResult,
        {list: replaceArrayElement(state.userListResult.list, state.userToEnable, updatedUser)}),
      userToEnable: undefined
    };
  },
  [CLEAR_USER_TO_ENABLE]: (state, action) => {
    return {
      ...state,
      userToEnable: undefined
    };
  },
  [SET_USER_TO_EDIT]: (state, action) => {
    return {
      ...state,
      userToEdit: action.user
    };
  },
  [CLEAR_USER_TO_EDIT]: (state, action) => {
    return {
      ...state,
      userToEdit: undefined
    };
  },
  [SET_USER_STATUS]: (state, action) => {
    return {
      ...state,
      setUserStatusLoading: true,
      setUserStatusLoaded: false,
      setUserStatusError: undefined
    };
  },
  [SET_USER_STATUS_FAIL]: (state, action) => {
    return {
      ...state,
      setUserStatusLoading: false,
      setUserStatusLoaded: false,
      setUserStatusError: action.payload
    };
  },
  [SET_USER_STATUS_SUCCESS]: (state, action) => {
    if (!state.userListResult) {
      return state;
    }

    let tempUser = state.userListResult.list.find(user => user.username === action.user.username);
    if (!tempUser) {
      return state;
    }
    const updatedUser = Object.assign({}, tempUser, {status: action.status, uuid: uuid.v4()});
    return {
      ...state,
      userListResult: Object.assign({}, state.userListResult,
        {list: replaceArrayElement(state.userListResult.list, tempUser, updatedUser)}),
      setUserStatusLoading: false,
      setUserStatusLoaded: true,
      setUserStatusError: false,
      userToEnable: undefined,
      userToDisable: undefined
    };
  },
  [USER_INSERT_UPDATE_HANDLE]: (state, action) => {
    // 当用户组织结构通过搜索方式进行修改的时候，由于无对应组织节点，所以手动把回写用户信息修改掉
    if (action.user.seachOrgFlag) {
      const currentUser = action.user;
      const newUserList = state.userListResult && state.userListResult.list.map((item) => {
        if (item.username === action.user.username) {
          return Object.assign(item, {student_number: currentUser.student_number},
            {path_name: currentUser.path_name},
            {name: currentUser.name},
            {sex: currentUser.sex},
            {identity: currentUser.identity},
            {status: currentUser.status}
          );
        }
        return item;
      });
      return {
        ...state,
        userListResult: Object.assign({}, state.userListResult,
          {
            list: newUserList,
            total_count: parseInt(state.userListResult.total_count) - 1
          })
      };
    }
    const userNode = new UserNode(undefined, action.user.username, action.user.name, action.user.path, '');
    // 用户修改之前的部门对象,如果没有被加载,则为undefined
    const oldTreeNode = findParentNodeOfUserById(state.organizationRoot, userNode);
    // 用户编辑之后的部门对象,如果没有被加载,则为undefined
    const treeNode = findParentNodeOfUser(state.organizationRoot, userNode);
    const tempUser = state.userListResult.list.find(ele => ele.username === action.user.username);
    if (tempUser) { // 更新用户信息
      const newUser = Object.assign({}, action.user);
      newUser.uuid = uuid.v4();
      // 用户信息更新后,所属部门也发生变化, 此时treeNode必然不是undefined
      if (oldTreeNode !== treeNode && treeNode.childrenLoaded) {
      // if (oldTreeNode !== treeNode) {
        if (oldTreeNode) {
          // step 1: 把用户从老部门节点删除
          oldTreeNode.users = removeArrayElement(oldTreeNode.users,
            oldTreeNode.users.find(ele => ele.id === userNode.id));
          oldTreeNode.uuid = uuid.v4();
        }
        // step 2: 把用户放入新部门中
        treeNode.users = appendToArray(treeNode.users, [userNode]);
        userNode.parent = treeNode;
        treeNode.uuid = uuid.v4();
        const newRoot = Object.assign({}, state.organizationRoot);
        updateToNewRoot(newRoot);
        if (isMyChild(state.activeNode, userNode)) {
          /*
          // 当编辑用户，将用户组织结构改变时，将该用户从当前组织中移除
          const updateObj =Object.assign({}, state.userListResult,
            {list: replaceArrayElement(state.userListResult.list, tempUser, newUser)});
          const removeIndex=updateObj.list.find((item)=>{
            return item.path == treeNode.path;
          });
          if(removeIndex){
            updateObj.list=removeArrayElement(updateObj.list, removeIndex);
          }
          // 结束
          */
          return {
            ...state,
            activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
            organizationRoot: newRoot,
            userListResult: Object.assign({}, state.userListResult,
              {list: replaceArrayElement(state.userListResult.list, tempUser, newUser)})
            // userListResult:updateObj
          };
        }
        return {
          ...state,
          activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
          organizationRoot: newRoot,
          userListResult: Object.assign({}, state.userListResult,
            {
              list: removeArrayElement(state.userListResult.list, tempUser),
              total_count: parseInt(state.userListResult.total_count) - 1
            }) // 查找结果中把该用户删除,因为它已经不是当前节点的用户了
        };
      }
      // 仅仅是用户的基本信息发生了变化
      if (treeNode) { // 部门节点已加载
        const user = treeNode.users.find(ele => ele.id === userNode.id);
        if (user) {
          user.name = userNode.name;
          user.uuid = uuid.v4();
        }
        treeNode.uuid = uuid.v4();
        const newRoot = Object.assign({}, state.organizationRoot);
        updateToNewRoot(newRoot);
        /*
        // 当编辑用户，将用户组织结构改变时，将该用户从当前组织中移除
        const userList =Object.assign({}, state.userListResult,
          {list: replaceArrayElement(state.userListResult.list, tempUser, newUser)});
        const userIndex=userList.list.find((item)=>{
          return item.path == treeNode.path;
        });
        if(userIndex){
          userList.list=removeArrayElement(userList.list, userIndex);
        }
        // 结束
        */
        return {
          ...state,
          activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
          organizationRoot: newRoot,
          userListResult: Object.assign({}, state.userListResult,
            {list: replaceArrayElement(state.userListResult.list, tempUser, newUser)})
          // userListResult: userList
        };
      }
      // 对应的部门节点还没有被加载
      return {
        ...state,
        userListResult: Object.assign({}, state.userListResult,
          {list: replaceArrayElement(state.userListResult.list, tempUser, newUser)})
      };
    } else { // 新增用户
      treeNode.users = appendToArray(treeNode.users, [userNode]);
      userNode.parent = treeNode;
      treeNode.uuid = uuid.v4();
      const newRoot = Object.assign({}, state.organizationRoot);
      updateToNewRoot(newRoot);
      const newUser = Object.assign({}, action.user);
      newUser.uuid = uuid.v4();
      return {
        ...state,
        activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
        organizationRoot: newRoot,
        userListResult: Object.assign({}, state.userListResult,
          {total_count: parseInt(parseInt(state.userListResult.total_count)) + 1},
          {list: preAppendToArray(state.userListResult.list, [newUser])})
      };
    }
  },
  [SEARCH_ORG_LAST_LOADING]: (state, action) => {
    return {
      ...state,
      searchOrgLastLoading: true,
      searchOrgLastSuccess: false,
      searchOrgLastFail: undefined
    };
  },
  [SEARCH_ORG_LAST_SUCCESS]: (state, action) => {
    return {
      ...state,
      orgLastList: action.payload.data,
      searchOrgLastLoading: false,
      searchOrgLastSuccess: true,
      searchOrgLastFail: undefined
    };
  },
  [SEARCH_ORG_LAST_FAIL]: (state, action) => {
    return {
      ...state,
      orgLastList: undefined,
      searchOrgLastLoading: false,
      searchOrgLastSuccess: false,
      searchOrgLastFail: action.payload
    };
  },
  [CHANGE_GRADUATION_LOADING]: (state, action) => {
    return {
      ...state,
      nodeLoading: true,
      markGraduationLoading: true,
      markGraduationSuccess: false,
      markGraduationFail: undefined
    };
  },
  [CHANGE_GRADUATION_SUCCESS]: (state, action) => {
    // 组织结构树上直接修改节点
    if (action.node) {
      const currentNode = changeNodeGraduationStatus(state.activeNode, action.status);
      const newRoot = Object.assign({}, state.organizationRoot);
      updateToNewRoot(newRoot);
      return {
        ...state,
        nodeLoading: undefined,
        markGraduationLoading: false,
        markGraduationSuccess: true,
        markGraduationFail: undefined,
        // activeNode: state.organizationRoot === state.activeNode ? newRoot : state.activeNode,
        activeNode: state.organizationRoot === currentNode ? newRoot : currentNode,
        organizationRoot: newRoot
      };
    }
    return {
      ...state,
      nodeLoading: undefined,
      markGraduationLoading: false,
      markGraduationSuccess: true,
      markGraduationFail: undefined
    };
  },
  [CHANGE_GRADUATION_FAIL]: (state, action) => {
    return {
      ...state,
      markGraduationLoading: false,
      markGraduationSuccess: false,
      markGraduationFail: action.payload
    };
  },
  [SEARCH_ORG_LOADING]: (state, action) => {
    return {
      ...state,
      searchOrgList: [],
      searchOrgLoading: true,
      searchOrgSuccess: false,
      searchOrgFail: undefined,
      searchName: action.queryName
    };
  },
  [SEARCH_ORG_SUCCESS]: (state, action) => {
    if (state.searchOrgLoading && state.searchName === action.queryName) {
      return {
        ...state,
        searchOrgList: action.payload.data,
        searchOrgLoading: false,
        searchOrgSuccess: true,
        searchOrgFail: undefined,
        searchName: action.queryName
      };
    }
    return state;
  },
  [SEARCH_ORG_FAIL]: (state, action) => {
    if (!state.searchOrgLoading || state.queryName !== action.queryName) {
      return state;
    }
    return {
      ...state,
      searchOrgList: [],
      searchOrgLoading: false,
      searchOrgSuccess: false,
      searchOrgFail: action.payload,
      searchName: action.queryName
    };
  },
  [LOGOUT_SUCCESS]: (state, action) => {
    return initialState;
  },
  [RELOGIN]: (state, action) => {
    return initialState;
  },
  // 人员批量置顶
  [SET_BATCH_TOP_NODE]: (state, action) => {
    return {
      ...state,
      batchTopNode: action.node
    };
  },
  [CLEAR_BATCH_TOP_NODE]: (state, action) => {
    return {
      ...state,
      batchTopNode: undefined
    };
  },
  [ORG_USER_LIST]: (state, action) => {
    return {
      ...state,
      batchTopListLoading: true,
      batchTopList: undefined,
      batchTopListErr: undefined
    };
  },
  [ORG_USER_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      batchTopListLoading: false,
      batchTopList: action.payload.data,
      batchTopListErr: undefined
    };
  },
  [ORG_USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      batchTopListLoading: false,
      batchTopList: undefined,
      batchTopListErr: action.payload.errmsg
    };
  },
  [SET_BATCH_TOP]: (state, action) => {
    return {
      ...state,
      batchTopSetting: true,
      batchTopSetResult: undefined,
      batchTopSetErr: undefined
    };
  },
  [SET_BATCH_TOP_SUCCESS]: (state, action) => {
    return {
      ...state,
      batchTopSetting: false,
      batchTopSetResult: action.payload.data,
      batchTopSetErr: undefined
    };
  },
  [SET_BATCH_TOP_FAIL]: (state, action) => {
    return {
      ...state,
      batchTopSetting: false,
      batchTopSetResult: undefined,
      batchTopSetErr: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  userQuery: {
    isSearchMode: false
  }
};

/**
 * 批量修改毕业／恢复毕业节点
 * @param node 当前毕业节点
 * @param status 毕业状态
 * @returns 修改毕业状态的节点
 */
function changeNodeGraduationStatus (node, status) {
  node.status = status === 9 ? '9' : '0';
  if (node.childrenLoaded) {
    node.children.map((item) => {
      return changeNodeGraduationStatus(item, status);
    });
  }
  return node;
}

const organizationReducer = makeReducer(ACTION_HANDLERS, initialState);
export default organizationReducer;
