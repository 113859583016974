/**
 * Created by liming on 16/6/14.
 */
import makeReducer from 'store/makeReducer';
import uuid from 'uuid';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import {RELOGIN} from 'store/modules/reloginService';
import {LOGOUT_SUCCESS} from '../../Login/modules/strings';
import ApiClient, { formatUrl } from 'helpers/ApiClient';
import { preAppendToArray, removeArrayElement, sort } from 'helpers/arrayUtils';

// ------------------------------------
// Constants
// ------------------------------------
export const LABEL_LIST = 'whistlems/LABEL/LABEL_LIST';
export const LABEL_LIST_SUCCESS = 'whistlems/LABEL/LABEL_LIST_SUCCESS';
export const LABEL_LIST_FAIL = 'whistlems/LABEL/LABEL_LIST_FAIL';

export const LABEL_ADD = 'whistlems/LABEL/LABEL_ADD';
export const LABEL_ADD_SUCCESS = 'whistlems/LABEL/LABEL_ADD_SUCCESS';
export const LABEL_ADD_FAIL = 'whistlems/LABEL/LABEL_ADD_FAIL';

export const LABEL_DELETE = 'whistlems/LABEL/LABEL_DELETE';
export const LABEL_DELETE_SUCCESS = 'whistlems/LABEL/LABEL_DELETE_SUCCESS';
export const LABEL_DELETE_FAIL = 'whistlems/LABEL/LABEL_DELETE_FAIL';

export const LABEL_MODIFY = 'whistlems/LABEL/LABEL_MODIFY';
export const LABEL_MODIFY_SUCCESS = 'whistlems/LABEL/LABEL_MODIFY_SUCCESS';
export const LABEL_MODIFY_FAIL = 'whistlems/LABEL/LABEL_MODIFY_FAIL';

export const LABEL_USER_ADD = 'whistlems/LABEL/LABEL_USER_ADD';
export const LABEL_USER_ADD_SUCCESS = 'whistlems/LABEL/LABEL_USER_ADD_SUCCESS';
export const LABEL_USER_ADD_FAIL = 'whistlems/LABEL/LABEL_USER_ADD_FAIL';

export const LABEL_USER_DELETE = 'whistlems/LABEL/LABEL_USER_DELETE';
export const LABEL_USER_DELETE_SUCCESS = 'whistlems/LABEL/LABEL_USER_DELETE_SUCCESS';
export const LABEL_USER_DELETE_FAIL = 'whistlems/LABEL/LABEL_USER_DELETE_FAIL';

export const LABEL_USER_LIST = 'whistlems/LABEL/LABEL_USER_LIST';
export const LABEL_USER_LIST_SUCCESS = 'whistlems/LABEL/LABEL_USER_LIST_SUCCESS';
export const LABEL_USER_LIST_FAIL = 'whistlems/LABEL/LABEL_USER_LIST_FAIL';

export const IMPORT_LABEL_USER = 'whistlems/LABEL/IMPORT_LABEL_USER';
export const IMPORT_LABEL_USER_SUCCESS = 'whistlems/LABEL/IMPORT_LABEL_USER_SUCCESS';
export const IMPORT_LABEL_USER_FAIL = 'whistlems/LABEL/IMPORT_LABEL_USER_FAIL';

// 标签搜索
export const LABEL_USER_SEARCH = 'whistlems/LABEL/LABEL_USER_SEARCH';
export const LABEL_USER_SEARCH_SUCCESS = LABEL_USER_SEARCH + '_SUCCESS';
export const LABEL_USER_SEARCH_FAIL = LABEL_USER_SEARCH + '_FAIL';

export const SET_ACTIVE_NODE = 'whistlems/TAG/SET_ACTIVE_NODE';

export const CLEAR_USER_LIST = 'whistlems/TAG/CLEAR_USER_LIST';

export const ORG_USER_RESULT = 'ORG_USER_RESULT';
export const ORG_USER_RESULT_SUCCESS = ORG_USER_RESULT + '_SUCCESS';
export const ORG_USER_RESULT_FAIL = ORG_USER_RESULT + '_FAIL';
export const DELETE_USER_TO_RESULT = 'DELETE_USER_TO_RESULT';
export const ADD_ORG_USERS_TO_LIST = 'ADD_ORG_USERS_TO_LIST';
export const CLEAR_ORG_USERS_TO_LIST = 'CLEAR_ORG_USERS_TO_LIST';
export const ADD_USERS_TO_RESULT = 'ADD_USERS_TO_RESULT';
export const ADD_USER_TO_RESULT = 'ADD_USER_TO_RESULT';
export const CLEAR_USER_TO_RESULT = 'CLEAR_USER_TO_RESULT';

export const USER_COUNT_PER_PAGE = 20; // 每页显示最大用户数

// ------------------------------------
// Utils
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
/*
 {
 "id": "2087", //标签id
 "label_name": "xx" //标签名称
 "sort": "1", //排序值
 },
 */
export const getLabelList = () => {
  return {
    types: [LABEL_LIST, LABEL_LIST_SUCCESS, LABEL_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelList', {})
    })
  };
};

export const addLabel = (name) => {
  return {
    types: [LABEL_ADD, LABEL_ADD_SUCCESS, LABEL_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelAdd', {
        name
      })
    })
  };
};

export const deleteLabel = (id) => {
  return {
    types: [LABEL_DELETE, LABEL_DELETE_SUCCESS, LABEL_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelDelete', {
        label_id: id
      })
    }),
    id
  };
};

export const modifyLabel = (id, name) => {
  return {
    types: [LABEL_MODIFY, LABEL_MODIFY_SUCCESS, LABEL_MODIFY_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelModify', {
        label_id: id,
        name
      })
    }),
    id,
    name
  };
};

/*
 labelId：标签id （必填）
 username： uid（必填）
 student_number： 学工号  （必填）

 */
export const addLabelUser = (labelId, users) => {
  return {
    types: [LABEL_USER_ADD, LABEL_USER_ADD_SUCCESS, LABEL_USER_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelUserAdd', {
        label_id: labelId,
        userInfos: users
      })
    })
  };
};

/*
 删除用户
 */
export const deleteLabelUser = (userInfo) => {
  const delLen = userInfo.length; // 删除的人员数
  const ids = userInfo.map(item => item.nodeId).join();
  const usernames = userInfo.map(item => item.uid).join();
  return {
    types: [LABEL_USER_DELETE, LABEL_USER_DELETE_SUCCESS, LABEL_USER_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelUserDelete', {
        label_id: ids,
        username: usernames
      })
    }),
    delLen
  };
};

export const labelUserList = (id, page, count) => {
  return {
    types: [LABEL_USER_LIST, LABEL_USER_LIST_SUCCESS, LABEL_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'labelUserList', {
        label_id: id,
        page,
        count
      })
    }),
    id,
    page,
    count
  };
};

export const importLabelUser = (labelId, file) => {
  const pathObj = formatParams('label', 'importLabelUser', {file_length: file.size, label_id: labelId});
  return (dispatch, getState) => {
    dispatch({
      type: IMPORT_LABEL_USER
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: IMPORT_LABEL_USER_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: IMPORT_LABEL_USER_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 标签搜索方法
 * searchObj: { page, search_text, post_id }
 */
export const labelUserSearch = (searchObj) => {
  if (!searchObj.page) {
    searchObj.page = 1;
  }
  return {
    types: [LABEL_USER_SEARCH, LABEL_USER_SEARCH_SUCCESS, LABEL_USER_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'globalSearch', {
        data_type: 3,
        count: USER_COUNT_PER_PAGE,
        ...searchObj
      })
    })
  };
};

export const setActiveNode = (node) => {
  return {
    type: SET_ACTIVE_NODE,
    node
  };
};


export const clearUserList = () => {
  return {
    type: CLEAR_USER_LIST
  };
};

export const queryUserByOrgId = (label) => {
  return {
    types: [ORG_USER_RESULT, ORG_USER_RESULT_SUCCESS, ORG_USER_RESULT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      // data: formatParams('user', 'getAllUsersByOrgId', {
      data: formatParams('user', 'userList', {
        ...label
      })
    }),
    label
  };
};

// 将组织数据添加到中间列表中
export const addOrgUserToList = (user, total) => {
  return {
    type: ADD_ORG_USERS_TO_LIST,
    users: user,
    totalCount: total
  };
};


export const clearOrgUserToList = () => {
  return {
    type: CLEAR_ORG_USERS_TO_LIST
  };
};

export const deleteUserToResult = (user) => {
  return {
    type: DELETE_USER_TO_RESULT,
    user
  };
};

export const addUsersToResult = (users) => {
  return {
    type: ADD_USERS_TO_RESULT,
    users
  };
};

// 将中间筛选后的数据全部添加到已选列表中
export const addAllUsersToResult = (users) => {
  return {
    type: ADD_USERS_TO_RESULT,
    users,
    isAll: true
  };
};

export const addUserToResult = (user) => {
  return {
    type: ADD_USER_TO_RESULT,
    user
  };
};

export const clearUserToResult = () => {
  return {
    type: CLEAR_USER_TO_RESULT
  };
};


export const actions = {
  getLabelList,
  addLabel,
  deleteLabel,
  modifyLabel,
  addLabelUser,
  deleteLabelUser,
  labelUserList,
  setActiveNode,
  clearUserList,
  queryUserByOrgId,
  deleteUserToResult,
  addUsersToResult,
  addUserToResult,
  clearUserToResult,
  addOrgUserToList,
  clearOrgUserToList,
  addAllUsersToResult,
  labelUserSearch
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LABEL_LIST]: (state, action) => {
    return {
      ...state,
      listing: true,
      listLoaded: undefined,
      listError: undefined,
      labelList: []
    };
  },
  [LABEL_LIST_SUCCESS]: (state, action) => {
    const list = action.payload.data;
    list.forEach((item) => {
      item.uuid = uuid.v4();
    });
    return {
      ...state,
      listing: undefined,
      listLoaded: true,
      labelList: sort(list)
    };
  },
  [LABEL_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      listing: undefined,
      listLoaded: undefined,
      labelList: []
    };
  },
  [LABEL_ADD]: (state, action) => {
    return {
      ...state,
      addLabeling: true,
      addLabelLoaded: undefined,
      addLabelError: undefined
    };
  },
  [LABEL_ADD_SUCCESS]: (state, action) => {
    const newOrg = action.payload.data;
    newOrg.uuid = uuid.v4();
    const newList = [newOrg, ...state.labelList.slice(0)];
    return {
      ...state,
      addLabeling: undefined,
      addLabelLoaded: true,
      addLabelError: undefined,
      labelList: newList
    };
  },
  [LABEL_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      addLabeling: undefined,
      addLabelLoaded: undefined,
      addLabelError: action.payload
    };
  },
  [LABEL_DELETE]: (state, action) => {
    return {
      ...state,
      deleteLabeling: true,
      deleteLabelLoaded: undefined,
      deleteLabelError: undefined
    };
  },
  [LABEL_DELETE_SUCCESS]: (state, action) => {
    const id = action.id;
    const list = state.labelList;
    const index = list.findIndex(function(value, index, arr) {
      return value.id === id;
    });
    return {
      ...state,
      deleteLabeling: undefined,
      deleteLabelLoaded: true,
      deleteLabelError: undefined,
      labelList: [...list.slice(0, index), ...list.slice(index + 1)]
    };
  },
  [LABEL_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deleteLabeling: undefined,
      deleteLabelLoaded: undefined,
      deleteLabelError: action.payload
    };
  },
  [LABEL_MODIFY]: (state, action) => {
    return {
      ...state,
      modifyLabeling: true,
      modifyLabelLoaded: undefined,
      modifyLabelError: undefined
    };
  },
  [LABEL_MODIFY_SUCCESS]: (state, action) => {
    const id = action.id;
    const list = state.labelList.slice(0);
    const item = list.find(function(value, index, arr) {
      return value.id === id;
    });
    item.name = action.name;
    item.uuid = uuid.v4();
    return {
      ...state,
      modifyLabeling: undefined,
      modifyLabelLoaded: true,
      labelList: list
    };
  },
  [LABEL_MODIFY_FAIL]: (state, action) => {
    return {
      ...state,
      modifyLabeling: undefined,
      modifyLabelLoaded: undefined,
      modifyLabelError: action.payload
    };
  },
  [LABEL_USER_LIST]: (state, action) => {
    // const newList = Object.assign({}, state.userList);
    const newList = {list: [], total: 0};
    newList.query = {id: action.id, page: action.page, count: action.count};
    return {
      ...state,
      userListing: true,
      userListLoaded: undefined,
      userListError: undefined,
      userList: newList
    };
  },
  [LABEL_USER_LIST_SUCCESS]: (state, action) => {
    if (action.id !== state.userList.query.id
      || action.page !== state.userList.query.page && action.count !== state.userList.query.count) {
      return state;
    }
    const list = action.payload.data.list;
    list.forEach((item) => {
      item.uuid = uuid.v4();
      item.id = item.uid;
    });
    const obj = Object.assign({}, state.userList);
    obj.list = list;
    obj.total = action.payload.data.total;
    obj.bindgroup = action.payload.data.bindgroup;
    return {
      ...state,
      userListing: undefined,
      userListLoaded: true,
      userListError: undefined,
      userList: obj
    };
  },
  [LABEL_USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      userListing: undefined,
      userListLoaded: undefined,
      userListError: action.payload
    };
  },
  // 标签搜索
  [LABEL_USER_SEARCH]: (state, action) => {
    const newList = {list: [], total: 0};
    return {
      ...state,
      userListing: true,
      userListLoaded: undefined,
      userListError: undefined,
      userList: newList
    };
  },
  [LABEL_USER_SEARCH_SUCCESS]: (state, action) => {
    const list = action.payload.data.list;
    list.forEach((item) => {
      item.uuid = uuid.v4();
      item.id = item.uid + '_' + item.label_id;
    });
    const obj = Object.assign({}, state.userList);
    obj.list = list;
    obj.total = action.payload.data.count;
    obj.bindgroup = action.payload.data.bindgroup;
    return {
      ...state,
      userListing: undefined,
      userListLoaded: true,
      userListError: undefined,
      userList: obj
    };
  },
  [LABEL_USER_SEARCH_FAIL]: (state, action) => {
    return {
      ...state,
      userListing: undefined,
      userListLoaded: undefined,
      userListError: action.payload
    };
  },
  [LABEL_USER_ADD]: (state, action) => {
    return {
      ...state,
      addUsering: true,
      addUserLoaded: undefined,
      addUserError: undefined
    };
  },
  [LABEL_USER_ADD_SUCCESS]: (state, action) => {
    return {
      ...state,
      addUsering: undefined,
      addUserLoaded: true,
      addUserError: undefined,
      userResult: undefined
    };
  },
  [LABEL_USER_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      addUsering: undefined,
      addUserLoaded: undefined,
      addUserError: action.payload
    };
  },

  [LABEL_USER_DELETE]: (state, action) => {
    return {
      ...state,
      deleteUsering: true,
      deleteUserLoaded: undefined,
      deleteUserError: undefined,
      delLen: 0
    };
  },
  [LABEL_USER_DELETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      deleteUsering: undefined,
      deleteUserLoaded: true,
      deleteUserError: undefined,
      delLen: action.delLen
    };
  },
  [LABEL_USER_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deleteUsering: undefined,
      deleteUserLoaded: undefined,
      deleteUserError: action.payload,
      delLen: 0
    };
  },

  [IMPORT_LABEL_USER]: (state, action) => {
    return {
      ...state,
      importUsering: true,
      importUserLoaded: undefined,
      importUserError: undefined
    };
  },
  [IMPORT_LABEL_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      importUsering: undefined,
      importUserLoaded: true,
      importUserError: undefined,
      importMsg: action.payload.body
    };
  },
  [IMPORT_LABEL_USER_FAIL]: (state, action) => {
    return {
      ...state,
      importUsering: undefined,
      importUserLoaded: undefined,
      importUserError: action.payload
    };
  },

  [SET_ACTIVE_NODE]: (state, action) => {
    return {
      ...state,
      activeNode: action.node
    };
  },

  [CLEAR_USER_LIST]: (state, action) => {
    return {
      ...state,
      userList: { list: [], total: 0 },
      userListing: undefined,
      userListLoaded: undefined,
      userListError: undefined
    };
  },
  [ORG_USER_RESULT]: (state, action) => {
    return {
      ...state,
      orgUserLoading: true,
      orgUserLoaded: false,
      orgUserError: undefined
    };
  },
  [ORG_USER_RESULT_SUCCESS]: (state, action) => {
    return {
      ...state,
      orgUserLoading: false,
      orgUserLoaded: true,
      orgUserError: undefined,
      orgUserResult: Object.assign({}, {
        total: action.payload.data.total_count,
        list: action.payload.data.list
      }),
      currentOrgId: action.label.org_id,
      currentPage: action.payload.data.total_count >= 1 ? action.label.page : 0, // 存放当前中间列表页码
      selectAll: action.label.selectAll
    };
  },
  [ORG_USER_RESULT_FAIL]: (state, action) => {
    return {
      ...state,
      orgUserLoading: false,
      orgUserLoaded: false,
      orgUserError: action.payload.errmsg
    };
  },
  [ADD_ORG_USERS_TO_LIST]: (state, action) => {
    return {
      ...state,
      orgUserList: Object.assign({}, {list: action.users}, {totalCount: action.totalCount})
    };
  },
  [CLEAR_ORG_USERS_TO_LIST]: (state, action) => {
    return {
      ...state,
      orgUserList: undefined,
      currentPage: '',
      currentOrgId: ''
    };
  },
  [ADD_USERS_TO_RESULT]: (state, action) => {
    let tempResult = state.userResult;
    const orgUsers = action.users;
    if (tempResult) {
      if (action.isAll) {
        orgUsers.forEach(user => {
          if (!tempResult.find(item => item.username === user.username)) {
            tempResult.push(user);
          }
        });
      } else {
        if (!tempResult.find(item => item.username === orgUsers.username)) {
          tempResult.push(orgUsers);
        }
      }
    } else {
      if (action.isAll) {
        tempResult = orgUsers;
      } else {
        const userArray = [];
        userArray.push(orgUsers);
        tempResult = userArray;
      }
    }
    return {
      ...state,
      userResult: tempResult.slice()
    };
  },
  [DELETE_USER_TO_RESULT]: (state, action) => {
    return {
      ...state,
      userResult: removeArrayElement(state.userResult, action.user)
    };
  },
  [ADD_USER_TO_RESULT]: (state, action) => {
    if (!state.userResult) {
      state.userResult = [];
    }
    return {
      ...state,
      userResult: preAppendToArray(state.userResult, [action.user])
    };
  },
  [CLEAR_USER_TO_RESULT]: (state, action) => {
    return {
      ...state,
      userResult: undefined,
      currentOrgId: '',
      currentPage: ''
    };
  },
  [LOGOUT_SUCCESS]: (state, action) => {
    return initialState;
  },
  [RELOGIN]: (state, action) => {
    return initialState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  labelList: [],
  userList: { list: [], total: 0 }
};

const labelReducer = makeReducer(ACTION_HANDLERS, initialState);
export default labelReducer;
