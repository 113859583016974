import RouteErrorView from 'components/ErrorView/RouteErrorView';
import { report } from 'helpers/errorReport';
import {getAuthInfo, USER_TYPE_SUBADMIN} from 'helpers/AuthHelper';
import { getAuthMenu } from 'config/menu';

export const getErrorCallback = (callback) => (err) => {
  console.error('Route load error!', err);
  report(err);
  callback(null, RouteErrorView);
};

export const getRouteReplace = (replace, module, superRoute, adminRoute) => {
  const auth = getAuthInfo();
  if (auth.userType === USER_TYPE_SUBADMIN) {
    replace(`${module}/${adminRoute}`);
  } else {
    replace(`${module}/${superRoute}`);
  }
};

export function getHomeRoute(replace, superRoute) {
  const auth = getAuthInfo();
  if (auth && auth.userType === USER_TYPE_SUBADMIN) {
    const authMenu = getAuthMenu();
    if (authMenu && authMenu.length > 0) {
      const subMenu = authMenu[0].subMenu;
      if (subMenu && subMenu.length > 0) {
        replace(subMenu[0].target);
      } else {
        replace(authMenu[0].target);
      }
    } else {
      replace('/main/noauth');
    }
  } else {
    replace(superRoute);
  }
};
