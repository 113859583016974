/**
 * 权限管理
 */
import React from 'react';

export default {
  'authmanage.assistant.title': '助理账号',
  'authmanage.assistant.hint': <span>
    1. 助理账号可以指定人员分担管理员的工作。<br />
    2. 添加成功后可用微哨的用户名和密码登录管理端。<br />
    3. 最多可添加10个助理账号，助理账号不能再次设置助理，其他权限与您完全相同。<br />
    4. 超级管理员能够管理所有管理员创建的助理账号，管理员仅能够管理自己创建的。<br />
  </span>
};
