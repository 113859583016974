import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classes from './MultiOrganization.scss';
import OrganizationNavView from '../OrganizationNavView';
import OrganizationOperationView from '../OrganizationOperationView';
import LoadingView from 'components/LoadingView/LoadingView';
import Dialog from 'components/Dialog';
import { reduxForm, Field, propTypes } from 'redux-form';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import TextInputWithErrorTips from 'components/TextInputWithErrorTips';
import { createValidator, required, notAllowedAllBlank } from 'helpers/WhistleFormValidator';
import { showErrorToast } from 'helpers/toastUtils';

const Input = ({
  id,
  maxLength,
  autoFocus,
  disabled,
  input,
  meta: {touched, error}
}) => (
  <TextInputWithErrorTips
    inputProps={{
      id,
      maxLength,
      autoFocus,
      disabled,
      placeholder: `最多${maxLength}个字符`
    }}
    reduxFormProps={input}
    error={touched ? error : ''}
    errorBlock
  />
);

Input.defaultProps = {
  maxLength: 24
};

Input.propTypes = {
  id: PropTypes.string,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object
};

const Org = ({orgIndex, activeIndex, item, onActiveChange, onCreateNewTreeNode, isLast}) => {
  const onClick = () => {
    onActiveChange(orgIndex, item);
  };
  return (
    <div className={classes.itemContainer}>
      <div className={classes.multiItem} onClick={onClick}>
        <span className={classes.nameContainer}>
          <AutoShrinkTextWithTip text={item.name} maxSize={200} fontSize={14} />
        </span>
        <span className={orgIndex === activeIndex && item.isOpen
          ? 'wsicon wsicon-arrowup' : 'wsicon wsicon-arrowdown'} />
      </div>
      {orgIndex === activeIndex && item.isOpen
        && <div><OrganizationOperationView onCreateNewTreeNode={onCreateNewTreeNode} />
          <OrganizationNavView style={!isLast && {borderBottom: '1px solid #cccccc'} || {}} /></div>}
    </div>
  );
};

Org.propTypes = {
  orgIndex: PropTypes.number,
  activeIndex: PropTypes.number,
  item: PropTypes.object,
  onActiveChange: PropTypes.func,
  onCreateNewTreeNode: PropTypes.func,
  isLast: PropTypes.bool
};

const MultiOrg = ({orgList, activeIndex, onActiveChange, onCreateNewTreeNode}) => (
  <div className={classes.multiContainer}>
    {
      orgList && orgList.length > 0 && orgList.map((item, index) =>
        <Org key={'org_' + index} orgIndex={index + 1}
          activeIndex={activeIndex} item={item} onActiveChange={onActiveChange}
          onCreateNewTreeNode={onCreateNewTreeNode} isLast={index === orgList.length - 1} />
      )
    }
  </div>
);

MultiOrg.propTypes = {
  orgList: PropTypes.array,
  activeIndex: PropTypes.number,
  onActiveChange: PropTypes.func,
  onCreateNewTreeNode: PropTypes.func
};

class MultiOrganization extends PureComponent {

  state = {
    activeIndex: 1,
    orgList: undefined,
    showCreate: false,
    activeInfo: undefined
  };

  componentDidMount() {
    const {optFlag, organizationRoot, activeIndex, multiOrgRoot} = this.props;
    if (optFlag && organizationRoot) {
      const childrenOrgs = organizationRoot.children;
      const list = childrenOrgs && childrenOrgs.length > 0 && childrenOrgs.map((item, index) => ({
        id: item.id,
        name: item.name,
        isOpen: activeIndex ? index === activeIndex - 1 : index === 0
      }));
      const orgIndex = activeIndex || 1;
      this.setState({ orgList: list, activeIndex: orgIndex }, () => {
        multiOrgRoot((childrenOrgs && childrenOrgs.length > 0 && childrenOrgs[orgIndex - 1].id) || 'undefined');
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const {organizationRoot, nodeCreated, nodeCreateError, activeNode} = newProps;
    if (nodeCreated && !this.props.nodeCreated) {
      this.setState({showCreate: false});
      this.props.initialize({orgName: ''});
    } else {
      if (nodeCreateError && nodeCreateError !== this.props.nodeCreateError) {
        showErrorToast(nodeCreateError.errmsg);
      }
    }
    if (organizationRoot && organizationRoot !== this.props.organizationRoot) {
      const {orgList, activeInfo} = this.state;
      const childrenOrgs = organizationRoot.children;
      this.props.multiOrgRoot(activeInfo && activeInfo.id || 'undefined');
      let activeOrg;
      if (orgList && orgList.length > 0) {
        const opened = orgList.find(item => item.isOpen);
        if (opened) {
          activeOrg = childrenOrgs && childrenOrgs.length > 0 && childrenOrgs.find(item => item.id === opened.id);
          if (!activeOrg) {
            this.setState({activeIndex: 1});
          }
        }
      }
      const list = childrenOrgs && childrenOrgs.length > 0 && childrenOrgs.map((item, index) => ({
        id: item.id,
        name: item.name,
        isOpen: activeOrg ? item.id === activeOrg.id : index === 0
      }));
      this.setState({ orgList: list });
    }
    if (activeNode && activeNode !== this.props.activeNode) {
      if (parseInt(activeNode.parentId) === 0 && !activeNode.childrenLoaded) {
        this.props.loadOrgNode(activeNode);
      }
    }
  }

  onActiveChange = (orgIndex, info) => {
    const {activeIndex, orgList} = this.state;
    const orgs = orgList.map(item => ({
      id: item.id,
      name: item.name,
      isOpen: item.id === info.id && !item.isOpen
    }));
    this.setState({activeIndex: orgIndex, orgList: orgs});
    if (activeIndex !== orgIndex) {
      this.setState({activeInfo: info}, () => {
        this.props.multiOrgRoot(info.id, true);
        this.props.clearOpenedList(orgIndex);
      });
    }
  };

  showCreate = () => {
    this.setState({showCreate: true});
  }

  onCreateHide = () => {
    this.setState({ showCreate: false }, () => this.props.initialize({ orgName: '' }));
  };

  onSubmit = (data) => {
    const { createNewOrgNode, organizationRoot } = this.props;
    createNewOrgNode(organizationRoot, data.orgName, data.orgNameEn, '0');
  };

  render() {
    const {activeIndex, orgList, showCreate} = this.state;
    const {contentHeight, nodeLoading, nodeOrdering, handleSubmit, pristine, invalid,
      nodeCreating, onCreateNewTreeNode} = this.props;
    return (
      <div className={classes.multicOrgContainer} style={{height: contentHeight - 66}}>
        <Dialog
          className={classes.dialogContainer}
          show={showCreate}
          onHide={this.onCreateHide}
          title='新建组织架构'
          positiveButton={{
            onClick: handleSubmit(this.onSubmit),
            loading: nodeCreating,
            disabled: (pristine || invalid)
          }}
          negativeButton={{ onClick: this.onCreateHide, disabled: nodeCreating }}
        >
          <div className={classes.contentContainer}>
            <span style={{marginRight: '12px', marginTop: '6px'}}>组织架构名称</span>
            <div style={{flex: '1'}}>
              <Field name='orgName' component={Input} maxLength={100} />
            </div>
          </div>
          <div className={classes.contentContainer}>
            <span style={{ margin: '6px 12px 0 28px' }}>英文名称</span>
            <div style={{flex: '1'}}>
              <Field name='orgNameEn' component={Input} maxLength={100} />
            </div>
          </div>
        </Dialog>
        <div style={{height: contentHeight - 120, overflowY: 'auto'}}>
          <MultiOrg contentHeight={contentHeight} orgList={orgList} activeIndex={activeIndex}
            onActiveChange={this.onActiveChange} onCreateNewTreeNode={onCreateNewTreeNode} />
        </div>
        <div className={classes.createContainer}>
          {orgList && orgList.length < 10 && <span onClick={this.showCreate}>
            <span className={'wsicon wsicon-add wsicon-lg'} />
            <span>新建组织架构</span>
          </span> || <span style={{color: '#C4C4D3', borderColor: '#C4C4D3', cursor: 'no-drop'}}>
            <span className={'wsicon wsicon-add wsicon-lg'} />
            <span>新建组织架构</span>
          </span>}
        </div>
        {(nodeLoading || nodeOrdering) && <LoadingView style={{width: '260px'}} />}
      </div>
    );
  }
}

MultiOrganization.propTypes = {
  ...propTypes,
  contentHeight: PropTypes.number,
  loadOrgRoot: PropTypes.func,
  nodeLoading: PropTypes.bool,
  nodeOrdering: PropTypes.bool,
  onCreateNewTreeNode: PropTypes.func,
  organizationRoot: PropTypes.object,
  multiOrgRoot: PropTypes.func,
  createNewOrgNode: PropTypes.func,
  nodeCreating: PropTypes.bool,
  nodeCreated: PropTypes.bool,
  nodeCreateError: PropTypes.object,
  activeNode: PropTypes.object,
  loadOrgNode: PropTypes.func,
  queryUsers: PropTypes.func,
  clearOpenedList: PropTypes.func,
  activeIndex: PropTypes.number,
  optFlag: PropTypes.bool
};

const reduxFormConfig = {
  form: 'multiOrg',
  validate: createValidator({
    orgName: [required, notAllowedAllBlank],
    orgNameEn: [notAllowedAllBlank]
  })
};

export default reduxForm(reduxFormConfig)(MultiOrganization);
