/**
 * 人员批量置顶
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { clearBatchTopNode, getOrgUserList, setBatchTop, queryUsers } from '../../modules/organization';
import classes from './BatchTopping.scss';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';
import { connect } from 'react-redux';
import Dialog from 'components/Dialog';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ClearSearch from 'components/ClearSearch';
import CheckBox from 'components/CheckBox';
import { replaceArrayElement, removeArrayElement } from 'helpers/arrayUtils';
import LoadingView from 'components/LoadingView';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import dragIcon from './assets/item.png';

const DEFAULT_TITLE = '人员置顶';

const LeftItem = ({ name, studentNumber, checked, clickHandler, highlight }) => (
  <div className={classes.leftItem} onClick={clickHandler}>
    <div className={classes.checkBox}>
      <CheckBox checked={checked} />
    </div>
    <div className={classes.text}>
      <AutoShrinkTextWithTip text={name} fontSize={14} maxSize={200} highlight={highlight} />
      （<AutoShrinkTextWithTip text={studentNumber} fontSize={14} maxSize={180} highlight={highlight} />）
    </div>
  </div>
);

LeftItem.propTypes = {
  name: PropTypes.string,
  studentNumber: PropTypes.string,
  checked: PropTypes.bool,
  clickHandler: PropTypes.func,
  highlight: PropTypes.string
};

// 计算排序后的数组
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: 'none',
    background: isDragging ? '#f3fbf7' : '#fff',
    ...draggableStyle
  };
};

class BatchTopping extends Component {

  static propTypes = {
    clearBatchTopNode: PropTypes.func,
    batchTopNode: PropTypes.object,
    getOrgUserList: PropTypes.func,
    batchTopList: PropTypes.array,
    setBatchTop: PropTypes.func,
    batchTopSetResult: PropTypes.string,
    queryUsers: PropTypes.func,
    batchTopListErr: PropTypes.string,
    batchTopSetErr: PropTypes.string,
    batchTopSetting: PropTypes.bool,
    batchTopListLoading: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.leftScrollBox = React.createRef();
    this.state = {
      leftList: [],
      rightList: [],
      searchText: ''
    };
  }

  componentDidMount() {
    const { batchTopNode, getOrgUserList } = this.props;
    const nodeId = batchTopNode && batchTopNode.id;
    getOrgUserList({ org_id: nodeId });
  }

  componentWillReceiveProps = (nextProps) => {
    const { batchTopList, batchTopSetResult, batchTopListErr, batchTopSetErr } = nextProps;
    if (batchTopList && batchTopList.length && batchTopList !== this.props.batchTopList) {
      this.setState({
        leftList: batchTopList,
        rightList: batchTopList.filter(item => item.top_value !== '0')
          .sort((item1, item2) => item2.top_value - item1.top_value)
      });
    }
    if (batchTopSetResult && batchTopSetResult !== this.props.batchTopSetResult) {
      const { batchTopNode, queryUsers } = this.props;
      const orgId = batchTopNode && batchTopNode.id;
      queryUsers({ org_id: orgId });
      showSuccessToast('保存成功');
      this.onHide();
    }
    if (batchTopListErr && batchTopListErr !== this.props.batchTopListErr) {
      showErrorToast(batchTopListErr);
    }
    if (batchTopSetErr && batchTopSetErr !== this.props.batchTopSetErr) {
      showErrorToast(batchTopSetErr);
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { rightList } = this.state;

    const newList = reorder(
      rightList,
      result.source.index,
      result.destination.index
    );

    this.setState({
      rightList: newList
    });
  }

  onHide = () => {
    const { clearBatchTopNode } = this.props;
    clearBatchTopNode();
  }

  handleSubmit = () => {
    const { batchTopList, setBatchTop, batchTopNode } = this.props;
    const { rightList } = this.state;
    const nodeId = batchTopNode && batchTopNode.id;
    const unTopArray = batchTopList && batchTopList.filter((item) => {
      return item.top_value !== '0' && !rightList.some(user => user.username === item.username);
    });
    setBatchTop({
      org_id: nodeId,
      user_top: rightList.map(item => item.username).join(),
      user_untop: unTopArray.map(item => item.username).join()
    });
  }

  onSearch = (value) => {
    this.setState({
      searchText: value.trim()
    }, () => {
      this.leftScrollBox.current.scrollTop = 0;
    });
  }

  clearSearch = () => {
    this.setState({
      searchText: ''
    }, () => {
      this.leftScrollBox.current.scrollTop = 0;
    });
  }

  check = (username) => () => {
    const { leftList } = this.state;
    let { rightList } = this.state;
    const ele = leftList.find(item => item.username === username);
    const newEle = Object.assign({}, ele, {
      top_value: ele.top_value === '0' ? '1' : '0'
    });
    if (ele.top_value === '0') {
      rightList = [ ele, ...rightList ];
    } else {
      const eleToRemove = rightList.find(item => item.username === username);
      rightList = removeArrayElement(rightList, eleToRemove);
    }
    this.setState({
      leftList: replaceArrayElement(leftList, ele, newEle),
      rightList
    });
  }

  unCheck = (username) => () => {
    const { leftList, rightList } = this.state;
    const leftEle = leftList.find(item => item.username === username);
    const newLeftEle = Object.assign({}, leftEle, { top_value: '0' });
    const rightEle = rightList.find(item => item.username === username);
    this.setState({
      leftList: replaceArrayElement(leftList, leftEle, newLeftEle),
      rightList: removeArrayElement(rightList, rightEle)
    });
  }

  render() {
    const { batchTopNode, batchTopSetting, batchTopListErr, batchTopListLoading } = this.props;
    const { leftList, searchText, rightList } = this.state;
    const orgName = (batchTopNode && batchTopNode.name) || DEFAULT_TITLE;
    let renderList = [ ...leftList ];
    if (searchText) {
      renderList = renderList.filter(item => {
        const { name, student_number: studentNumber, pinyin } = item;
        const pinyinArr = pinyin ? pinyin.split('.').filter(Boolean).map(item => item.toLocaleLowerCase()) : [];
        const pinyinContains = pinyinArr.some(subPinyin => subPinyin.indexOf(searchText.toLocaleLowerCase()) > -1);
        return name.indexOf(searchText) > -1 || studentNumber.indexOf(searchText) > -1 || pinyinContains;
      });
    }
    const hasLeftList = !!renderList.length;
    const hasRightList = !!rightList.length;
    return (
      <Dialog show onHide={this.onHide} size='md' title={orgName} className={classes.dialog}
        positiveButton={{
          text: '保存',
          loading: batchTopSetting,
          disabled: batchTopListLoading || batchTopListErr,
          onClick: this.handleSubmit
        }}
      >
        <div className={classes.content}>
          <div className={classNames(classes.part, classes.left)}>
            <div className={classes.title}>请选择需要置顶的人员</div>
            <div className={classes.listBox}>
              <ClearSearch
                placeholder='请输入姓名或学工号'
                width={225}
                maxLength={15}
                onSearchHandler={this.onSearch}
                crossStyle={{marginRight: '20px'}}
                searchStyle={{fontSize: '20px'}}
                onClearHandler={this.clearSearch}
                disabled={false} />
              <div className={classes.userList} ref={this.leftScrollBox}>
                {hasLeftList && renderList.map((item) => {
                  const { name, student_number: studentNumber, top_value: topValue, username } = item;
                  const clickHandler = this.check(username);
                  const checked = topValue !== '0';
                  return <LeftItem name={name} studentNumber={studentNumber} clickHandler={clickHandler}
                    checked={checked} highlight={searchText} />;
                })}
                {!hasLeftList && searchText && <div className={classes.emptyBox}>暂无符合搜索条件的人员</div>}
              </div>
            </div>
          </div>
          <div className={classNames(classes.part, classes.right)}>
            <div className={classes.title}>已选择（拖拽排序）</div>
            <div className={classes.listBox}>
              {hasRightList && <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      className={classes.sortableBox}
                      ref={provided.innerRef}
                    >
                      {rightList.map((item, index) => {
                        const { username, name, student_number: studentNumber } = item;
                        return (
                          <Draggable key={username} draggableId={username} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className={classes.selectedItem}>
                                  <div className={classes.userInfoBox}>
                                    <img src={dragIcon} className={classes.dragIcon} />
                                    <span className={classes.userInfo}>
                                      <AutoShrinkTextWithTip text={name} fontSize={14} maxSize={180} />
                                      （<AutoShrinkTextWithTip text={studentNumber} fontSize={14} maxSize={160} />）
                                    </span>
                                  </div>
                                  <div className={classes.deleteIcon} onClick={this.unCheck(username)}>
                                    <i className='wsicon wsicon-cross2' />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>}
            </div>
          </div>
          {(batchTopListLoading || batchTopSetting) && <LoadingView />}
        </div>
      </Dialog>
    );
  }
}

const mapActionCreators = {
  clearBatchTopNode,
  getOrgUserList,
  setBatchTop,
  queryUsers
};

const mapStateToProps = (state) => ({
  batchTopNode: state.organization.batchTopNode,
  batchTopListLoading: state.organization.batchTopListLoading,
  batchTopList: state.organization.batchTopList,
  batchTopListErr: state.organization.batchTopListErr,
  batchTopSetting: state.organization.batchTopSetting,
  batchTopSetResult: state.organization.batchTopSetResult,
  batchTopSetErr: state.organization.batchTopSetErr
});

export default connect(mapStateToProps, mapActionCreators)(BatchTopping);
