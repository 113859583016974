import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/14.
 */
import React, { PureComponent } from 'react';
import {loadOrgNode, NODE_GRADUATION} from '../../routes/Organization/modules/organization';
import classes from './SelectableTreeNode.scss';
import { connect } from 'react-redux';
import SelectableTreeNode from './SelectableTreeNode'; // 注意,不是下面定义的SelectableTreeNodeClass,因为它被循环引用了.
import SelectableUserNode from './SelectableUserNode';
import SubNodes from './SubNodes';
import {isMyChild} from './treeUtils';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import Icon from 'components/Icon';

const mapActionCreators = {
  loadOrgNode
};

const mapStateToProps = (state) => ({});

const NODE_BIND_GROUP = '9'; // 节点已绑定群聊

class SelectableTreeNodeClass extends PureComponent {
  static propTypes = {
    secondaryRoot: PropTypes.bool, // 是否是二级管理员
    secondaryRootNode: PropTypes.object, // 二级管理员权限跟节点
    node: PropTypes.object,
    showFocus: PropTypes.bool,
    showUser: PropTypes.bool,
    checkMode: PropTypes.bool, // true表示用checkbox来选择
    onlyLeafNodeCheck: PropTypes.bool, // true 表示只有子节点显示checkbox
    onlyUserNodeCheck: PropTypes.bool, // true 表示只有人的节点显示checkbox
    width: PropTypes.number,
    height: PropTypes.number,
    levelOffset: PropTypes.number,
    activeNode: PropTypes.object,
    openedNodeList: PropTypes.array,
    checkedList: PropTypes.array, // checkMode下使用,记录check的node记录
    loadOrgNode: PropTypes.func,
    onClickCallback: PropTypes.func, // 非checkMode模式下,用户的选择节点的回调
    onCheckCallback: PropTypes.func, // checkMode模式下,用户check的回调
    onUncheckCallback: PropTypes.func, // checkMode模式下,用户uncheck的回调
    replacePresetCallback: PropTypes.func
  }

  state = {
    isCheck: false,
    isHalfCheck: false,
    updateUI: false
  }

  componentWillMount = () => {
    if (this.props.checkMode) {
      if (this.isNodeChecked()) {
        this.props.replacePresetCallback(this.props.node);
        this.setState({isCheck: true, isHalfCheck: false});
      } else {
        if (this.isNodeHalfChecked()) {
          this.setState({isHalfCheck: true, isCheck: false});
        } else {
          this.setState({isCheck: false, isHalfCheck: false});
        }
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {
  }

  componentDidUpdate = (preProps, preState) => {
    if (preProps.checkedList !== this.props.checkedList) {
      if (this.isNodeChecked()) {
        this.props.replacePresetCallback(this.props.node);
        this.setState({isCheck: true, isHalfCheck: false});
      } else {
        if (this.isNodeHalfChecked()) {
          this.setState({isHalfCheck: true, isCheck: false});
        } else {
          this.setState({isCheck: false, isHalfCheck: false});
        }
      }
    }
  }

  // 二级管理员权限管理，跟节点是否全选比较
  compareTwoArray = (rootNode, newArray) => {
    const sameArray = [];
    const org = newArray && newArray.filter((item) => (item.isOrg)) || [];
    const user = newArray && newArray.filter((item) => (!item.isOrg)) || [];
    rootNode && rootNode.children.map((item) => {
      const match = org.find((selectItem) => (selectItem.id === item.id));
      if (match) {
        sameArray.push(item);
      }
    });
    rootNode && rootNode.users.map((item) => {
      const match = user.find((selectItem) => (selectItem.id === item.id));
      if (match) {
        sameArray.push(item);
      }
    });
    return sameArray;
  }

  onToggleNodeCheck = () => {
    if (this.state.isCheck) {
      this.props.onUncheckCallback(this.props.node);
    } else {
      this.props.onCheckCallback(this.props.node);
    }
  }

  onNodeClick = () => {
    if (!this.props.node.isVirtual) { // 虚拟节点不可选
      if (this.props.onClickCallback) {
        this.props.onClickCallback(this.props.node);
      }
    }
  }

  makeChildrenNodes = () => {
    const { node, width, height, levelOffset, openedNodeList, activeNode, checkMode, checkedList,
      showUser, showFocus, onClickCallback, onlyLeafNodeCheck, onlyUserNodeCheck, secondaryRootNode } = this.props;
    if (!checkMode) {
      return node.children.map((child) => {
        return (
          <SelectableTreeNode node={child} key={child.uuid} showUser={showUser} showFocus={showFocus}
            openedNodeList={openedNodeList}
            width={width} height={height} levelOffset={levelOffset}
            onClickCallback={onClickCallback} activeNode={activeNode} />
        );
      });
    }
    return node.children.map((child) => {
      return (
        <SelectableTreeNode node={child} key={child.uuid} checkMode showUser={showUser} showFocus={showFocus}
          openedNodeList={openedNodeList} checkedList={checkedList}
          width={width} height={height} levelOffset={levelOffset}
          replacePresetCallback={this.props.replacePresetCallback}
          onlyLeafNodeCheck={onlyLeafNodeCheck}
          onlyUserNodeCheck={onlyUserNodeCheck}
          onCheckCallback={this.props.onCheckCallback} onClickCallback={onClickCallback}
          onUncheckCallback={this.props.onUncheckCallback} activeNode={activeNode}
          secondaryRootNode={secondaryRootNode}
        />
      );
    }).concat((showUser && node.users) ? node.users.map((user) => {
      return (
        <SelectableUserNode node={user} key={user.uuid} width={width} height={height} levelOffset={levelOffset}
          checkedList={checkedList} showFocus={showFocus}
          replacePresetCallback={this.props.replacePresetCallback}
          onCheckCallback={this.props.onCheckCallback}
          onUncheckCallback={this.props.onUncheckCallback}
          onClickCallback={onClickCallback} activeNode={activeNode} checkMode
          secondaryRootNode={secondaryRootNode}
        />);
    }) : []);
  }

  isNodeActive = () => {
    const {node, activeNode} = this.props;
    if (!activeNode) {
      return false;
    }
    return node.id === activeNode.id;
  }

  isNodeOpened = () => {
    const {openedNodeList, node} = this.props;
    return openedNodeList.indexOf(node.id) >= 0;
  }

  isNodeChecked = () => {
    const {node, checkedList, secondaryRootNode} = this.props;
    let ret = checkedList.find((ele, index, arr) => ele.id === node.id);
    if (ret) {
      return true;
    }
    let parentNode = node.parent;
    while (parentNode) {
      ret = checkedList.find((ele, index, arr) => ele.id === parentNode.id);
      if (ret) {
        return true;
      }
      parentNode = parentNode.parent;
    }
    // 针对二级管理员添加权限处理勾选
    if (secondaryRootNode) {
      const array = this.compareTwoArray(secondaryRootNode, checkedList);
      if (checkedList && checkedList.length > 0
        && array.length === (secondaryRootNode.children.length + secondaryRootNode.users.length)) {
        return true;
      }
    }
    return false;
  }

  isNodeHalfChecked = () => {
    const {node, checkedList, secondaryRootNode} = this.props;
    if (checkedList.find((ele, index, arr) => node.id === ele.id)) {
      return false;
    }
    if (checkedList.find((ele, index, arr) => isMyChild(node, ele))) {
      return true;
    }
    // 针对二极管理员权限相关
    if (secondaryRootNode && node.id === secondaryRootNode.id) {
      const array = this.compareTwoArray(secondaryRootNode, checkedList);
      if (checkedList && checkedList.length > 0
        && array.length < (secondaryRootNode.children.length + secondaryRootNode.users.length)) {
        return true;
      }
    }
    return false;
  }

  toggleNode = (event) => {
    event.stopPropagation();
    const {openedNodeList, node, showUser} = this.props;
    if (this.isNodeOpened()) {
      openedNodeList.splice(openedNodeList.indexOf(node.id), 1);
    } else {
      openedNodeList.push(node.id);
      if (!node.isVirtual) { // 虚拟节点,不如二级管理员的根节点
        if ((!node.isLeaf && !node.childrenLoaded) || (node.isLeaf && showUser && !node.usersLoaded)) {
          this.props.loadOrgNode(node);
        }
      }
    }
    this.setState({updateUI: !this.state.updateUI});
  }


  render() {
    const { node, width, height, levelOffset, checkMode, showFocus, showUser,
      onlyLeafNodeCheck, onlyUserNodeCheck, secondaryRoot } = this.props;
    let level = 0;
    const boxWidth = width - 20; // 20是预留给滚动条的
    let helperNode = node;
    while (helperNode.parent) {
      level++;
      helperNode = helperNode.parent;
    }
    const myOffset = level * levelOffset;
    const activeClass = (showFocus && this.isNodeActive()) ? classes.activeNode : '';
    if (!checkMode) {
      return (
        <div className={classes.nodeContainer}>
          <div className={classes.nodeContent + ' ' + activeClass}
            style={{ height: height + 'px', paddingLeft: myOffset, width: boxWidth }}
            onClick={this.onNodeClick}>
            {
              !node.isLeaf && <div className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }}
                onClick={this.toggleNode}>
                {
                  !this.isNodeOpened()
                  && <Icon type='next-page' style={{ lineHeight: height + 'px' }} />
                }
                {
                  this.isNodeOpened()
                  && <Icon type='triangledown' style={{ lineHeight: height + 'px' }} />
                }
              </div>
            }
            {
              node.isLeaf && <div className={classes.fakeNodeIconContainer} style={{ lineHeight: height + 'px' }} />
            }
            {/* 18是nodeIcon的宽度加上左右两边的border + 1 */}
            <div className={classes.bodyText}
              style={{ width: (boxWidth - 18 - myOffset) + 'px', lineHeight: height + 'px' }}>
              <AutoShrinkTextWithTip text={node.name} maxSize={(boxWidth - 18 - myOffset)} fontSize={14} />
            </div>
          </div>
          {this.isNodeOpened() && <SubNodes node={node} makeChildren={this.makeChildrenNodes} />}
        </div>
      );
    } else if (onlyLeafNodeCheck) { // 此分支针对创建群聊，勾选组织结构树时使用
      /** 1 已创建群聊的组织，默认勾选，不可取消；
       *  2 只有末级节点可进行勾选；
       *  3 已毕业组织节点，显示已毕业，节点置灰不可点击
       * */
      const showNodeName = node.status === NODE_GRADUATION ? ('已毕业-' + node.name) : node.name;
      const nodeColor = node.status === NODE_GRADUATION || node.status_im === NODE_BIND_GROUP ? '#BFBFBF' : '';
      return (<div className={classes.nodeContainer}>
        <div className={classes.nodeContent + ' ' + activeClass}
          style={{ height: height + 'px', paddingLeft: myOffset, width: boxWidth }}
          onClick={this.onNodeClick}>
          {
            !node.isLeaf && <div onClick={this.toggleNode}
              className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }}>
              {
                !this.isNodeOpened()
                && <label><Icon type='next-page' style={{lineHeight: height + 'px'}} /></label>
              }
              {
                this.isNodeOpened()
                && <label><Icon type='triangledown' style={{lineHeight: height + 'px'}} /></label>
              }
            </div>
          }
          {
            node.isLeaf && showUser && <div onClick={this.toggleNode}
              className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }}>
              {
                !this.isNodeOpened()
                && <Icon type='next-page' style={{lineHeight: height + 'px'}} />
              }
              {
                this.isNodeOpened()
                && <Icon type='triangledown' style={{lineHeight: height + 'px'}} />
              }
            </div>
          }
          {node.isLeaf && !showUser
          && <div className={classes.fakeNodeIconContainer} style={{ lineHeight: height + 'px' }} />}
          {/* 只允许末节点勾选，并该节点未绑定群聊,未毕业，该节点可进行勾选, 已毕业该节点disabled */}
          {!node.isVirtual && onlyLeafNodeCheck && node.isLeaf && node.status_im !== NODE_BIND_GROUP
            && <div className={classes.addOnContainer}
              onClick={node.status !== NODE_GRADUATION && this.onToggleNodeCheck}>
              {node.status !== NODE_GRADUATION && this.state.isCheck
                && <Icon type='box-sel' className={classes.checkEffect} />}
              {node.status !== NODE_GRADUATION && !this.state.isCheck && !this.state.isHalfCheck
                && <Icon type='box' />}
              {node.status === NODE_GRADUATION && !this.state.isCheck && !this.state.isHalfCheck
                && <Icon type='box' className={classes.checkDisable} />}
              {node.status !== NODE_GRADUATION && this.state.isHalfCheck
                && <Icon type='box-sel-half' className={classes.checkEffect} />}
            </div>}

          {/* 只允许末级节点勾选，并且该节点已绑定群聊,未毕业，则该节点默认勾选，不可修改, 已毕业该节点disabled */}
          {!node.isVirtual && onlyLeafNodeCheck && node.isLeaf && node.status_im === NODE_BIND_GROUP
            && <div className={classes.addOnContainer}>
              {node.status !== NODE_GRADUATION && <Icon type='box-sel' className={classes.checkEffect} />}
              {node.status === NODE_GRADUATION && <Icon type='box-sel' className={classes.checkDisable} />}
            </div>}
          {/* 18是nodeIcon的宽度加上左右两边的border + 1, 20是addOnContainer的宽度 */}
          {!node.isVirtual && <div className={classes.bodyText}
            style={{ width: (boxWidth - 18 - myOffset - 20) + 'px', lineHeight: height + 'px', color: nodeColor }}>
            <AutoShrinkTextWithTip text={showNodeName} maxSize={(boxWidth - 18 - myOffset - 20)} fontSize={14} />
          </div>
          }
          {/* 虚拟节点不可操作 */}
          {node.isVirtual && <div className={classes.bodyText}
            style={{ width: (boxWidth - 18 - myOffset) + 'px', lineHeight: height + 'px' }}>
            <AutoShrinkTextWithTip text={showNodeName} maxSize={(boxWidth - 18 - myOffset)} fontSize={14} />
          </div>}
        </div>
        {this.isNodeOpened() && <SubNodes node={node} makeChildren={this.makeChildrenNodes} />}
      </div>);
    } else if (onlyUserNodeCheck && showUser) {
      const showNodeName = node.name;
      const nodeColor = '';
      return (<div className={classes.nodeContainer}>
        <div className={classes.nodeContent + ' ' + activeClass}
          style={{ height: height + 'px', paddingLeft: myOffset, width: boxWidth }}
          onClick={this.onNodeClick}>
          {
            !node.isLeaf && <div onClick={this.toggleNode}
              className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }}>
              {
                !this.isNodeOpened()
                && <label><Icon type='next-page' style={{lineHeight: height + 'px'}} /></label>
              }
              {
                this.isNodeOpened()
                && <label><Icon type='triangledown' style={{lineHeight: height + 'px'}} /></label>
              }
            </div>
          }
          {
            node.isLeaf && showUser && <div onClick={this.toggleNode}
              className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }}>
              {
                !this.isNodeOpened()
                && <Icon type='next-page' style={{lineHeight: height + 'px'}} />
              }
              {
                this.isNodeOpened()
                && <Icon type='triangledown' style={{lineHeight: height + 'px'}} />
              }
            </div>
          }
          {/* 18是nodeIcon的宽度加上左右两边的border + 1, 20是addOnContainer的宽度 */}
          {!node.isVirtual && <div className={classes.bodyText}
            style={{ width: (boxWidth - 18 - myOffset - 20) + 'px', lineHeight: height + 'px', color: nodeColor }}>
            <AutoShrinkTextWithTip text={showNodeName} maxSize={(boxWidth - 18 - myOffset - 20)} fontSize={14} />
          </div>
          }
          {/* 虚拟节点不可操作 */}
          {node.isVirtual && <div className={classes.bodyText}
            style={{ width: (boxWidth - 18 - myOffset) + 'px', lineHeight: height + 'px' }}>
            <AutoShrinkTextWithTip text={showNodeName} maxSize={(boxWidth - 18 - myOffset)} fontSize={14} />
          </div>}
        </div>
        {this.isNodeOpened() && <SubNodes node={node} makeChildren={this.makeChildrenNodes} />}
      </div>);
    }
    return (
      <div className={classes.nodeContainer}>
        <div className={classes.nodeContent + ' ' + activeClass}
          style={{ height: height + 'px', paddingLeft: myOffset, width: boxWidth }}
          onClick={this.onNodeClick}>
          {
            !node.isLeaf && <div
              className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }} onClick={this.toggleNode}>
              {
                !this.isNodeOpened()
                && <label><Icon type='next-page' style={{lineHeight: height + 'px'}} /></label>
              }
              {
                this.isNodeOpened()
                && <label><Icon type='triangledown' style={{lineHeight: height + 'px'}} /></label>
              }
            </div>
          }
          {
            node.isLeaf && showUser && <div
              className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }} onClick={this.toggleNode}>
              {
                !this.isNodeOpened()
                && <Icon type='next-page' style={{lineHeight: height + 'px'}} />
              }
              {
                this.isNodeOpened()
                && <Icon type='triangledown' style={{lineHeight: height + 'px'}} />
              }
            </div>
          }
          {node.isLeaf && !showUser && <div className={classes.fakeNodeIconContainer}
            style={{ lineHeight: height + 'px' }} />}
          {/* 节点正常操作，全选，半勾选，不勾选 */}
          { (!node.isVirtual || secondaryRoot) && <div className={classes.addOnContainer}
            onClick={this.onToggleNodeCheck}>
            {this.state.isCheck && <Icon type='box-sel' className={classes.checkEffect} />}
            {this.state.isHalfCheck && <Icon type='box-sel-half' className={classes.checkEffect} />}
            {!this.state.isCheck && !this.state.isHalfCheck && <Icon type='box' />}
          </div>}
          {/* 18是nodeIcon的宽度加上左右两边的border + 1, 20是addOnContainer的宽度 */}
          {(!node.isVirtual || secondaryRoot) && <div className={classes.bodyText}
            style={{ width: (boxWidth - 18 - myOffset - 20) + 'px', lineHeight: height + 'px' }}>
            <AutoShrinkTextWithTip text={node.name} maxSize={(boxWidth - 18 - myOffset - 20)} fontSize={14} />
          </div>
          }
          {/* 虚拟节点不可操作 */}
          {!secondaryRoot && node.isVirtual && <div className={classes.bodyText}
            style={{ width: (boxWidth - 18 - myOffset) + 'px', lineHeight: height + 'px' }}>
            <AutoShrinkTextWithTip text={node.name} maxSize={(boxWidth - 18 - myOffset)} fontSize={14} />
          </div>}
        </div>
        {this.isNodeOpened() && <SubNodes node={node} makeChildren={this.makeChildrenNodes} />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionCreators)(SelectableTreeNodeClass);
