import OrganizationHome from './containers/OrganizationHome';
import BatchImportOrganizationData from './containers/BatchImportOrganizationData';
import BatchUpdateOrganizationData from './containers/BatchUpdateOrganizationData';
import BatchUpdateOrg from './containers/BatchUpdateOrg';
import ManualUpdateOrg from './containers/ManualUpdateOrg';
import MarkGraduation from './containers/MarkGraduation';
import BatchImportCounselor from './containers/BatchImportCounselor';
import ModuleLayout from 'layouts/ModuleLayout';
import RouteLayout from 'layouts/RouteLayout';
import { checkAuth } from '../auth';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '用户中心',
  path: 'user',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/user/organization') }, // IndexRedirect
  childRoutes: [
    {
      name: ' 组织架构',
      path: 'organization',
      component: RouteLayout,
      onEnter: checkAuth,
      indexRoute: {
        name: ' 组织架构',
        component: OrganizationHome
      },
      childRoutes: [
        {
          name: '批量导入用户',
          path: 'batchImport',
          onEnter: checkAuth,
          component: BatchImportOrganizationData
        },
        {
          name: '批量更新用户',
          path: 'batchUpdate',
          onEnter: checkAuth,
          component: BatchUpdateOrganizationData
        },
        {
          name: '批量更新组织',
          path: 'batchUpdateOrg',
          onEnter: checkAuth,
          component: BatchUpdateOrg
        },
        {
          name: '手动调整组织数据',
          path: 'manualUpdateOrg',
          onEnter: checkAuth,
          component: ManualUpdateOrg
        },
        {
          name: '标记已毕业',
          path: 'markGraduation',
          onEnter: checkAuth,
          component: MarkGraduation
        },
        {
          name: '设置班主任/辅导员',
          path: 'batchImportCounselor',
          onEnter: checkAuth,
          component: BatchImportCounselor
        },
        {
          name: '搜索结果',
          path: 'multiQuery',
          onEnter: checkAuth,
          indexRoute: {
            name: '搜索结果',
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const Component = require('./containers/MultiQuery').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'MultiQuery');
            }
          },
          childRoutes: [
            {
              name: '编辑用户',
              path: 'userEdit',
              onEnter: checkAuth,
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const Component = require('./containers/UserEdit').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'Organization');
              }
            },
            {
              name: '查看用户',
              path: 'userView',
              onEnter: checkAuth,
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const Component = require('./containers/UserView').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'Organization');
              }
            }
          ]
        },
        {
          name: '添加用户',
          path: 'userAdd',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/UserAdd').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Organization');
          }
        },
        {
          name: '编辑用户',
          path: 'userEdit',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/UserEdit').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Organization');
          }
        },
        {
          name: '查看用户',
          path: 'userView',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/UserView').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Organization');
          }
        }
      ]
    },
    {
      name: '岗位（审批节点）',
      path: 'position',
      onEnter: checkAuth,
      component: RouteLayout,
      indexRoute: {
        name: '岗位（审批节点）',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const Component = require('./containers/PostManage').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Position');
        }
      },
      childRoutes: [
        {
          name: '全部替换',
          path: 'import',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/PositionImportUserContainer').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Position');
          }
        },
        {
          name: '全部替换',
          path: 'importJob',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/PostJobImport').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Position');
          }
        },
        {
          name: '导入/更新',
          path: 'update',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/PositionUpdateUserContainer').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Position');
          }
        }
      ]
    },
    {
      name: '非常设机构',
      path: 'adhocOrg',
      component: RouteLayout,
      onEnter: checkAuth,
      indexRoute: {
        name: '非常设机构',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const Component = require('./containers/AdhocOrganization').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AdhocOrg');
        }
      },
      childRoutes: [
        {
          name: '导入',
          path: 'import',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/AdhocImportUserContainer').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AdhocOrg');
          }
        }
      ]
    },
    {
      name: '标签',
      path: 'tags',
      component: RouteLayout,
      onEnter: checkAuth,
      indexRoute: {
        name: '标签',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const Component = require('./containers/LabelContainer').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Label');
        }
      },
      childRoutes: [
        {
          name: '导入',
          path: 'import',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/LabelImportUserContainer').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Label');
          }
        }
      ]
    },
    {
      name: '职务',
      path: 'job',
      component: RouteLayout,
      onEnter: checkAuth,
      indexRoute: {
        name: '职务',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const Component = require('./containers/Job').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Job');
        }
      },
      childRoutes: [
        {
          name: '搜索结果',
          path: 'jobQuery',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/JobQuery').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Job');
          }
        },
        {
          name: '导入人员',
          path: 'import',
          onEnter: checkAuth,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const Component = require('./containers/JobUserImport').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Job');
          }
        }
      ]
    }
  ]
});

export default route;
