import PropTypes from 'prop-types';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import classes from './TabView.scss';
import uuid from 'uuid';

/**
 * Tab组件，适用于Tab内容不作为Tab组件的子组件，只是随Tab切换变更内容的场合。
 */
export const TabView = (props) => {
  const { labels, onTabChange, activeTab, justify, className, tabClassName, ...restProps } = props;
  return (
    <Tabs id={uuid.v4()} {...restProps} onSelect={onTabChange} activeKey={activeTab}
      animation={false} className={className} bsStyle={'tabs' + (justify ? ' nav-justified' : '')}>
      {
        labels.map((label, index) => (
          <Tab key={`tab${index}`} eventKey={index} title={label}
            tabClassName={tabClassName || classes.tab} />
        ))
      }
    </Tabs>
  );
};

TabView.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.node).isRequired,
  onTabChange: PropTypes.func,
  activeTab: PropTypes.any,
  justify: PropTypes.bool,
  className: PropTypes.string,
  tabClassName: PropTypes.string
};

export default TabView;
