import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';

const PREFIX = 'whistlems/organization/';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

const EXPORT_USER = PREFIX + 'EXPORT_USER';
const EXPORT_USER_SUCCESS = EXPORT_USER + SUCCESS;
const EXPORT_USER_FAIL = EXPORT_USER + FAIL;

export const MULTI_USER_COUNT_PER_PAGE = 20;


export const exportUser = () => {
  return {
    types: [EXPORT_USER, EXPORT_USER_SUCCESS, EXPORT_USER_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'exportOrgUser', {})
    })
  };
};


const ACTION_HANDLERS = {
  [EXPORT_USER]: (state, action) => {
    return {
      ...state,
      exportUserLoading: true,
      exportUserLoaded: false,
      exportUserError: undefined
    };
  },
  [EXPORT_USER_SUCCESS]: (state, action) => {
    const data = action.payload.data;
    return {
      ...state,
      exportUserLoading: false,
      exportUserLoaded: true,
      exportUserError: undefined,
      exportUserResult: data
    };
  },
  [EXPORT_USER_FAIL]: (state, action) => {
    return {
      ...state,
      exportUserLoading: false,
      exportUserLoaded: false,
      exportUserError: action.payload.errmsg
    };
  }
};

const initialState = {};

const dataExportReducer = makeReducer(ACTION_HANDLERS, initialState);
export default dataExportReducer;
