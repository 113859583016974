import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import Header from 'components/Header';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { logoutRequest } from 'routes/Login/modules/login';
import { passwordFlag } from 'routes/Account/modules/admin';
import { getCachedSchoolList } from 'routes/Login/modules/schoolList';
import { getAuthInfo, ACCOUNT_TYPE_ADMIN, ACCOUNT_TYPE_ASSISTANT, USER_TYPE_ADMIN,
  USER_TYPE_SUBADMIN, getPassTips, clearPassTips, getPassSimple, clearPassSimple } from 'helpers/AuthHelper';
import PasswordModify from 'routes/Account/containers/PasswordModify';
import DataReset from 'routes/Account/containers/DataReset';
import DataExport from 'routes/Organization/containers/DataExport';

const { warning } = Modal;

// const MENU_ACCOUNT = 0;
const MENU_ACCOUNT_MANAGE = 1;
const MENU_ACCOUNT_PASSWORD = 2;
const MENU_ACCOUNT_RESET = 3;
const MENU_ACCOUNT_LOGS = 4;
const MENU_ACCOUNT_EXPORT = 5;
const MENU_ACCOUNT_LOGOUT = 0;

const ACCOUNT_MENU = [
  { key: MENU_ACCOUNT_MANAGE, title: '账号管理' },
  { key: MENU_ACCOUNT_PASSWORD, title: '修改密码' },
  { key: MENU_ACCOUNT_EXPORT, title: '数据导出' },
  { key: MENU_ACCOUNT_LOGS, title: '操作日志' },
  { title: '-' },
  { key: MENU_ACCOUNT_LOGOUT, title: '注销' }
];
// 非管理员账号登录，无修改密码选项
const ACCOUNT_MENU_WHISTLE = ACCOUNT_MENU.filter(item => item.key !== MENU_ACCOUNT_PASSWORD);
// 助理账号（只能微哨号登录）只有注销选项
const ACCOUNT_MENU_ASSISTANT = ACCOUNT_MENU_WHISTLE.filter(item =>
  item.key !== MENU_ACCOUNT_MANAGE && item.key !== MENU_ACCOUNT_RESET);

export class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPwd: false,
      showReset: false,
      showExport: false
    };
  }

  componentDidMount() {
    // Store中无学校列表信息，则从本地缓存中取出并存入store
    if (!this.props.school) {
      this.props.getCachedSchoolList.call(this);
    }
    this.props.passwordFlag();
  }

  componentDidUpdate(prevProps) {
    const { passwordFlagResult } = this.props;
    if (passwordFlagResult && passwordFlagResult !== prevProps.passwordFlagResult) {
      const passwordTips = getPassTips();
      const passSimple = getPassSimple();
      if (Number(passwordFlagResult.password_modify_suggest) === 1 && Number(passSimple) === 1) {
        this.onPassSimple();
      }
      if (passwordTips && Number(passSimple) !== 1) {
        this.onPassTips(passwordTips);
      }
    }
  }

  onMenuClicked = (key, e) => {
    switch (key) {
      case MENU_ACCOUNT_MANAGE:
        this.props.replace('/account');
        break;
      case MENU_ACCOUNT_PASSWORD:
        this.setState({ showPwd: true });
        break;
      case MENU_ACCOUNT_RESET:
        this.setState({ showReset: true });
        break;
      case MENU_ACCOUNT_LOGS:
        this.props.replace('/logs');
        break;
      case MENU_ACCOUNT_LOGOUT:
        this.props.logoutRequest.call(this);
        this.props.replace('/login');
        break;
      case MENU_ACCOUNT_EXPORT:
        this.setState({ showExport: true });
        break;
      default:
        break;
    }
  }

  hidePwdDialog = () => {
    this.setState({ showPwd: false });
  }

  hideResetDialog = () => {
    this.setState({ showReset: false });
  }

  hideExportDialog = () => {
    this.setState({ showExport: false });
  }

  onPassTips = tipsText => {
    warning({
      title: '提示',
      content: tipsText,
      okText: '知道了',
      onOk() {
        clearPassTips();
      }
    });
  }

  onPassSimple = () => {
    warning({
      title: '提示',
      content: '您的密码过于简单，建议修改密码。',
      okText: '知道了',
      onOk() {
        clearPassSimple();
      }
    });
  }

  render() {
    const { showPwd, showReset, showExport } = this.state;
    const auth = getAuthInfo();
    const accountType = auth ? auth.accountType : ACCOUNT_TYPE_ASSISTANT;
    let menuItems;
    if (accountType === ACCOUNT_TYPE_ASSISTANT) {
      menuItems = ACCOUNT_MENU_ASSISTANT;
    } else if (accountType !== ACCOUNT_TYPE_ADMIN) {
      menuItems = ACCOUNT_MENU_WHISTLE;
    } else {
      menuItems = ACCOUNT_MENU;
    }
    if (!auth || auth.userType !== USER_TYPE_ADMIN) { // 只有超级管理员/二级管理员才显示管理日志
      if (auth && auth.userType === USER_TYPE_SUBADMIN) {
        menuItems = menuItems.filter(item => item.key !== MENU_ACCOUNT_RESET && item.key !== MENU_ACCOUNT_MANAGE);
      } else {
        menuItems = menuItems.filter(item => item.key !== MENU_ACCOUNT_LOGS && item.key !== MENU_ACCOUNT_RESET);
      }
    }
    if (auth && !(auth.userType === USER_TYPE_ADMIN && auth.accountType === ACCOUNT_TYPE_ADMIN)) {
      menuItems = menuItems.filter(item => item.key !== MENU_ACCOUNT_EXPORT);
    }
    if (auth && auth.auth && Number(auth.auth.export_status) !== 1) {
      menuItems = menuItems.filter(item => item.key !== MENU_ACCOUNT_EXPORT);
    }
    const { school } = this.props;
    let name = school ? school.name : (auth && auth.displayName);
    name = auth && auth.isTestSchool ? name + '（试用）' : name;
    const icon = school ? school.icon : null;
    const loginName = auth
      ? (auth.userType === USER_TYPE_ADMIN && auth.accountType === ACCOUNT_TYPE_ADMIN
        ? auth.loginAccount : auth.displayName)
      : '';
    return (
      <Header school={name} icon={icon} realName={loginName} menu={menuItems}
        onMenuClicked={this.onMenuClicked}
        showModal={showPwd || showReset || showExport}>
        {showPwd && <PasswordModify closeDialog={this.hidePwdDialog} account={auth && auth.account} />}
        {showReset && <DataReset cancelReset={this.hideResetDialog} />}
        {showExport && <DataExport cancelExport={this.hideExportDialog} />}
      </Header>
    );
  }
}

HeaderContainer.propTypes = {
  school: PropTypes.object,
  replace: PropTypes.func.isRequired,
  logoutRequest: PropTypes.func.isRequired,
  getCachedSchoolList: PropTypes.func.isRequired,
  passwordFlag: PropTypes.func,
  passwordFlagResult: PropTypes.object
};

const mapStateToProps = (state) => {
  const auth = getAuthInfo();
  const school = auth ? state.schoolList.find(school => school.domain === auth.domain) : void 0;
  return {
    school,
    login: state.login,
    passwordFlagResult: state.admin.passwordFlagResult
  };
};

export default connect((mapStateToProps), {
  replace, logoutRequest, getCachedSchoolList, passwordFlag
})(HeaderContainer);
