import { loginExpired } from 'helpers/ApiHelper';
import { relogin } from '../modules/reloginService';

export default function clientMiddleware(client) {
  return ({dispatch, getState}) => {
    return next => action => {
      const { promise, types, ...rest } = action;
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE, RELOGIN] = types;
      next({...rest, type: REQUEST});

      const actionPromise = promise(client);
      actionPromise.then(
        (result) => {
          const { errcode, ret } = result;
          const success = (errcode === 0 || errcode === 200) && ret === 0;
          if (!success && loginExpired(errcode)) {
            if (RELOGIN) { // 请求定义了自己的登录失效处理，则发给指定Action自行处理
              return next({
                ...rest,
                payload: result,
                type: RELOGIN
              });
            }
            dispatch(relogin({
              flag: true,
              code: errcode
            })); // 否则采取统一的登录失效处理
          }
          return next({
            ...rest,
            payload: result,
            type: success ? SUCCESS : FAILURE
          });
        },
        (error) => next({
          ...rest,
          payload: error,
          type: FAILURE
        })
      ).catch((error) => {
        if (__DEV__) {
          console.error('MIDDLEWARE ERROR:', error);
          throw error;
        }
        next({
          ...rest,
          payload: {
            errcode: -1,
            errmsg: error.message,
            data: error
          },
          type: FAILURE
        });
      });

      return actionPromise;
    };
  };
}
