// ------------------------------------
// Constants
// ------------------------------------
import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import { setData } from 'helpers/storageUtils';
import { writeAuthInfo, clearAuthInfo, setSessionTime, clearSessionTime, clearUserQuery } from 'helpers/AuthHelper';
import { LOAD, LOGIN_LOAD, LOGIN_SUCCESS, LOGIN_FAIL, QRCODE_LOGIN_FAIL, LOGOUT_LOAD, LOGOUT_SUCCESS, LOGIN_RESET,
  QRCODE_LOGIN_RESET, QRCODE_LOAD, QRCODE_SUCCESS, QRCODE_FAIL,
  CHECKQRCODE_LOAD, CHECKQRCODE_SUCCESS, CHECKQRCODE_FAIL, SET_SCAN_LOGIN, CLEAN_SCAN_LOGIN,
  GET_VERIFY_KEY, GET_VERIFY_KEY_FAIL, GET_VERIFY_KEY_SUCCESS
} from './strings';
import md5 from 'md5';
import { formatMessage } from 'components/FormattedMessage';

const SSO_MSG = 'whistle/ssoMsg';
const SSO_MSG_FAIL = SSO_MSG + '_FAIL';

// ------------------------------------
// Actions
// ------------------------------------
export const loginRequest = (authInfo) => {
  return {
    types: [LOGIN_LOAD, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) => client.post(API_ROOT + '/login', {
      data: formatParams('user', 'userLogin', {
        ...authInfo,
        password: md5(authInfo.password),
        user_type: authInfo.school ? 'whistle' : 'admin',
        verify_key: authInfo.verify_key,
        verify_value: authInfo.verify_value ? md5(authInfo.verify_value.toLowerCase()) : authInfo.verify_value
        // verify_value: authInfo.verify_value
      })
    })
  };
};

export const logoutRequest = (authInfo) => {
  return {
    types: [LOGOUT_LOAD, LOGOUT_SUCCESS, LOGOUT_SUCCESS, LOGOUT_SUCCESS],
    promise: (client) => client.post(API_ROOT + '/logout', {
      data: formatParams('user', 'userLogout')
    })
  };
};

/**
 * 获取二维码url及对应参数
 * @returns url + 二维码标识参数
 */
export const getQrCodeCharacters = (lastInfo) => {
  return {
    types: [QRCODE_LOAD, QRCODE_SUCCESS, QRCODE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('qrcode', 'generateQRCode', {whistle_info_old: lastInfo})
    })
  };
};
/**
 * 获取用户操作二维码状态
 * @param code: 二维码匹配code
 * @returns 用户操作状态
 */
export const checkQrCode = (code) => {
  return {
    types: [CHECKQRCODE_LOAD, CHECKQRCODE_SUCCESS, CHECKQRCODE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('qrcode', 'checkQRCode', {
        whistle_info: code
      })
    })
  };
};
/**
 * 扫码登录
 * @param authInfo
 * @returns 扫码登录验证信息
 */
export const qrCodeLogin = (authInfo) => {
  return {
    types: [LOGIN_LOAD, LOGIN_SUCCESS, QRCODE_LOGIN_FAIL],
    promise: (client) => client.post(API_ROOT + '/login', {
      data: formatParams('user', 'userLogin', authInfo)
    })
  };
};

/**
 * 获取验证码对应的key值
 */
export const getVerifyKey = (verifyKey) => {
  return {
    types: [GET_VERIFY_KEY, GET_VERIFY_KEY_SUCCESS, GET_VERIFY_KEY_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'getVerifyKey', {
      // data: formatParams('user', 'getVerifyCode ', {
        verify_key: verifyKey
      })
    })
  };
};

/**
 * sso
 */
export const getSSOMsg = (verifyKey) => {
  return {
    types: [SSO_MSG, LOGIN_SUCCESS, SSO_MSG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('qrcode', 'ssoLogin')
    }),
    ssoFlag: true
  };
};

export const resetLoginInfo = () => {
  return {
    type: LOGIN_RESET
  };
};
export const resetQrCodeLoginInfo = () => {
  return {
    type: QRCODE_LOGIN_RESET
  };
};
export const setIntervalScan = () => {
  return {
    type: SET_SCAN_LOGIN
  };
};

export const cleanIntervalScan = () => {
  return {
    type: CLEAN_SCAN_LOGIN
  };
};
export const actions = {
  loginRequest,
  logoutRequest,
  qrCodeLogin,
  getQrCodeCharacters,
  checkQrCode,
  setIntervalScan,
  cleanIntervalScan,
  getVerifyKey,
  getSSOMsg
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOGIN_LOAD]: (state, action) => {
    return {
      ...state,
      text: '正在登录，请稍候…',
      state: LOGIN_LOAD
    };
  },
  [LOGIN_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    const ssoFlag = action.ssoFlag;
    writeAuthInfo(data);
    const now = Date.now();
    setSessionTime(now);
    setData('newtime', data.notice_last_create_time);
    return {
      ...state,
      ssoMsgLoading: false,
      text: '登录成功，正在跳转…',
      ssoMsgSuccess: ssoFlag,
      state: LOGIN_SUCCESS
    };
  },
  [LOGIN_FAIL]: (state, action) => {
    const err = action.payload;
    let msg;
    switch (err.errcode) {
      case 500:
        msg = '代理服务异常，请联系管理员！';
        break;
      case 10002:
        msg = '参数错误，请联系管理员！';
        break;
      case 20001:
        msg = '账号或密码错误。';
        break;
      case 20002:
      case 20004:
        msg = formatMessage({ id: 'login.error.account.cannotLogin' });
        break;
      case 20011:
        msg = formatMessage({ id: 'login.error.account.disabled' });
        break;
      case 20013:
        msg = '验证码错误。';
        break;
      case 20014:
        msg = '验证码失效。';
        break;
      case 20015:
        msg = '登录超时，请重试。';
        break;
      default:
        msg = err.errmsg || '网络错误，请联系管理员！';
        break;
    }
    return {
      text: msg,
      state: LOGIN_FAIL,
      errorCode: err.errcode,
      verifyObj: Object.assign({}, action.payload.data) // 登录失败时，将返回回来的登录错误次数存下，用于标识是否显示验证码
    };
  },
  [SSO_MSG]: (state, action) => ({
    ...state,
    ssoMsgLoading: true,
    ssoMsgSuccess: undefined,
    ssoMsgErr: undefined
  }),
  [SSO_MSG_FAIL]: (state, action) => ({
    ...state,
    ssoMsgLoading: false,
    ssoMsgSuccess: undefined,
    ssoMsgErr: action.payload
  }),
  [QRCODE_LOGIN_FAIL]: (state, action) => {
    return {
      ...state,
      qrCodeErrorMsg: action.payload.errcode,
      state: QRCODE_LOGIN_FAIL
    };
  },
  [LOGIN_RESET]: (state, action) => {
    return {
      ...state,
      verifyObj: state.verifyObj,
      text: '',
      state: LOGIN_RESET
    };
  },
  [QRCODE_LOGIN_RESET]: (state, action) => {
    return {
      qrCodeErrorMsg: '',
      state: QRCODE_LOGIN_RESET
    };
  },
  [LOGOUT_LOAD]: (state, action) => {
    return {text: '注销中…', state: LOGOUT_LOAD};
  },
  [LOGOUT_SUCCESS]: (state, action) => {
    clearAuthInfo();
    clearSessionTime();
    clearUserQuery();
    setData('oldtime', 0);
    return {text: '注销成功', state: LOGOUT_SUCCESS};
  },
  [QRCODE_LOAD]: (state, action) => {
    return {
      ...state,
      qrCodeLoading: true,
      qrCodeLoaded: false,
      qrCodeLoadError: undefined
    };
  },
  [QRCODE_SUCCESS]: (state, action) => {
    return {
      ...state,
      qrCodeObj: action.payload.data,
      qrCodeLoading: false,
      qrCodeLoaded: true,
      qrCodeLoadError: undefined
    };
  },
  [QRCODE_FAIL]: (state, action) => {
    return {
      ...state,
      qrCodeLoading: true,
      qrCodeLoaded: false,
      qrCodeObj: undefined,
      qrCodeLoadError: action.payload
    };
  },

  [CHECKQRCODE_LOAD]: (state, action) => {
    return {
      ...state,
      checkQrCodeLoding: true,
      checkQrCodeLoaded: false,
      checkQrCodeLoadError: undefined
    };
  },
  [CHECKQRCODE_SUCCESS]: (state, action) => {
    let isQrSuc = false;
    let isQrfail = false;
    if (action.payload.data.flag === 1) {
      isQrSuc = false;
      isQrfail = false;
    }
    if (action.payload.data.flag === 2) {
      isQrSuc = true;
      isQrfail = false;
    }
    if (action.payload.data.flag === 3) {
      isQrSuc = false;
      isQrfail = true;
    }
    if (action.payload.data.flag === 4) {
      isQrSuc = false;
      isQrfail = false;
    }

    return {
      ...state,
      isSuccess: isQrSuc,
      isFailure: isQrfail,
      student_number: action.payload.data.student_number,
      school: action.payload.data.school,
      qrCodeFlag: action.payload.data.flag,
      checkQrCodeLoading: false,
      checkQrCodeLoaded: true,
      checkQrCodeLoadError: undefined
    };
  },
  [CHECKQRCODE_FAIL]: (state, action) => {
    return {
      ...state,
      isSuccess: false,
      isFailure: true,
      checkQrCodeLoading: false,
      checkQrCodeLoaded: false,
      checkQrCodeLoadError: action.payload
    };
  },
  [SET_SCAN_LOGIN]: (state, action) => {
    return {
      ...state,
      cleanScanLogin: false,
      isSuccess: false,
      isFailure: false
    };
  },
  [CLEAN_SCAN_LOGIN]: (state, action) => {
    return {
      ...state,
      cleanScanLogin: true
    };
  },
  [GET_VERIFY_KEY]: (state, action) => {
    return {
      ...state,
      verifyKeyLoad: true,
      verifyKeyLoaded: false,
      verifyKeyLoadError: undefined
    };
  },
  [GET_VERIFY_KEY_SUCCESS]: (state, action) => {
    return {
      ...state,
      errorCode: '',
      verifyObj: action.payload.data,
      verifyKeyLoad: false,
      verifyKeyLoaded: true,
      verifyKeyLoadError: undefined
    };
  },
  [GET_VERIFY_KEY_FAIL]: (state, action) => {
    return {
      ...state,
      verifyObj: undefined,
      verifyKeyLoad: false,
      verifyKeyLoaded: false,
      verifyKeyLoadError: action.payload.data
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  text: '正在加载,请稍候…',
  state: LOAD,
  qrCodeObj: undefined
};

const loginReducer = makeReducer(ACTION_HANDLERS, initialState);
export default loginReducer;
