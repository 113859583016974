import makeReducer from 'store/makeReducer';

const SET_DATA_TO_RICH = 'whistlems/common/richeditor/SET_DATA_TO_RICH';
const CLEAR_DATA_TO_RICH = 'whistlems/common/richeditor/CLEAR_DATA_TO_RICH';

export function setDataToRich(data) {
  return {
    type: SET_DATA_TO_RICH,
    data
  };
};

export function clearRichData() {
  return {
    type: CLEAR_DATA_TO_RICH
  };
};

export const actions = {
  setDataToRich,
  clearRichData
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_DATA_TO_RICH]: (state, action) => {
    return {
      ...state,
      richData: action.data
    };
  },
  [CLEAR_DATA_TO_RICH]: (state, action) => {
    return {
      ...state,
      richData: undefined
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

const richEditor = makeReducer(ACTION_HANDLERS, initialState);
export default richEditor;
