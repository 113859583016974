import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classes from './QueryOperation.scss';
import ClearSearch from 'components/ClearSearch';
import SelectInput from 'components/SelectInput';
import SelectSearch from 'components/SelectSearch';
import PopOver from 'components/PopOver';

class QueryOperation extends PureComponent {

  onSearchHandler = (searchValue) => {
    if (searchValue.trim()) {
      this.props.onSearchHandler(searchValue.trim());
    }
  };

  onClearChange = (value) => {
    const { onClearChange } = this.props;
    if (onClearChange) {
      onClearChange(value);
    }
  }

  onClearSearch = () => {
    const { onClearSearch } = this.props;
    if (onClearSearch) {
      onClearSearch();
    }
  }

  onSelectSearchInitialValue = () => {
    const { onSelectSearchInitialValue } = this.props;
    if (onSelectSearchInitialValue) {
      onSelectSearchInitialValue();
    }
  }

  onSelectSearch = (data) => {
    const { onSelectSearch } = this.props;
    if (onSelectSearch) {
      onSelectSearch(data);
    }
  }

  onSelectSearchClear = () => {
    const { onSelectSearchClear } = this.props;
    if (onSelectSearchClear) {
      onSelectSearchClear();
    }
  }

  render() {
    const { searchWidth, placeholder, searchValue, selectOps, btnOps, children, selectQuery } = this.props;
    return (
      <div className={classes.queryOperation}>
        {children}
        {
          selectQuery
            ? <SelectSearch isOrg defaultValue='组织名称' onResult={this.onSelectSearch}
              width={250} height={32} onClear={this.onSelectSearchClear}
              isShowFullPath initialValue={this.onSelectSearchInitialValue}
              suggestionsContainerOpen={classes.suggestionsContainerOpen} />
            : <ClearSearch width={searchWidth || 250} placeholder={placeholder || '姓名/学工号'}
              crossStyle={{marginRight: '20px'}} searchStyle={{fontSize: '20px'}}
              onSearchHandler={this.onSearchHandler} onClearChange={this.onClearChange}
              onClearHandler={this.onClearSearch} value={searchValue || ''} />
        }
        {
          selectOps && selectOps.length > 0 && selectOps.map((item, index) =>
            <div className={classes.selectDiv} key={'select_' + index} style={{width: item.width || '220px'}}>
              <SelectInput options={item.options} showName={item.showName || ''} value={item.value || ''}
                simpleValue={item.simpleValue} onChange={item.onChange}
                placeholder={item.placeholder || '请选择'} maxSize={item.maxSize || 200} />
            </div>
          )
        }
        {
          btnOps && btnOps.length > 0 && btnOps.map((item, index) =>
            <span key={'btn_' + index} className={'wsbtn-ghost wsbtn-ghost-primary wsbtn-large'}
              onClick={item.onClick}>
              {item.name || '返回'}
              {item.popTitle && <PopOver id={'popOver0'} selfStyle={{ marginLeft: '6px' }} >
                {item.popTitle}
              </PopOver>}
            </span>
          )
        }
      </div>
    );
  }
}

QueryOperation.propTypes = {
  children: PropTypes.any,
  searchWidth: PropTypes.number,
  placeholder: PropTypes.string,
  searchValue: PropTypes.string,
  onSearchHandler: PropTypes.func,
  onClearChange: PropTypes.func,
  onClearSearch: PropTypes.func,
  selectOps: PropTypes.array,
  btnOps: PropTypes.array,
  selectQuery: PropTypes.bool,
  onSelectSearch: PropTypes.func,
  onSelectSearchClear: PropTypes.func,
  onSelectSearchInitialValue: PropTypes.func
};

export default QueryOperation;
