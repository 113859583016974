import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';
import { getAuthInfo } from 'helpers/AuthHelper';

const checkChat = (nextState, replace) => {
  const auth = getAuthInfo();
  if (!auth || auth.hideChatManage) {
    replace('/chatgroup');
  }
};

export const route = (store) => ({
  name: '通讯录管理',
  path: 'chatgroup',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/chatgroup/sortBookSet') }, // IndexRedirect
  childRoutes: [
    {
      name: '群聊管理',
      path: 'group',
      onEnter: checkChat,
      indexRoute: {
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'addressBook', reducer });
            const Component = require('./containers/Chat').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AddressBook');
        }
      },
      childRoutes: [
        {
          name: '导入管理员',
          path: 'importManager',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'addressBook', reducer });
              const Component = require('./containers/ImportManager').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AddressBook');
          }
        }
      ]
    },
    {
      name: '通讯录排序配置',
      path: 'sortBookSet',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules').default;
          injectReducer(store, { key: 'addressBook', reducer });
          const Component = require('./containers/SortBookSet').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'AddressBook');
      }
    },
    {
      name: '通讯录可见设置',
      path: 'addressBookSet',
      indexRoute: {
        name: '通讯录可见设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'addressBook', reducer });
            const Component = require('./containers/AddressBookSet').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'AddressBook');
        }
      },
      childRoutes: [
        {
          name: '新建通讯录权限',
          path: 'addBookPermit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'addressBook', reducer });
              const Component = require('./containers/BookPermitOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AddressBook');
          }
        },
        {
          name: '编辑通讯录权限',
          path: 'editBookPermit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'addressBook', reducer });
              const Component = require('./containers/BookPermitOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'AddressBook');
          }
        }
      ]
    }
  ]
});

export default route;
