import makeReducer from '../makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
// ------------------------------------
// Constants
// ------------------------------------

const PREFIX = 'whistlems/common/usersearch';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

export const USER_SEARCH = PREFIX + 'USER_SEARCH';
export const USER_SEARCH_LOAD = USER_SEARCH + '_LOAD';
export const USER_SEARCH_SUCCESS = USER_SEARCH + SUCCESS;
export const USER_SEARCH_FAIL = USER_SEARCH + FAIL;

const USER_QUERY_FOR_ORG = PREFIX + 'USER_QUERY_FOR_ORG';
const USER_QUERY_FOR_ORG_SUCCESS = USER_QUERY_FOR_ORG + SUCCESS;
const USER_QUERY_FOR_ORG_FAIL = USER_QUERY_FOR_ORG + FAIL;

const USER_LIST = PREFIX + 'USER_LIST';
const USER_LIST_SUCCESS = USER_LIST + SUCCESS;
const USER_LIST_FAIL = USER_LIST + FAIL;

const TREE_SEARCH = PREFIX + 'TREE_SEARCH';
const TREE_SEARCH_SUCCESS = TREE_SEARCH + SUCCESS;
const TREE_SEARCH_FAIL = TREE_SEARCH + FAIL;

const ROOT_ORG_LIST = PREFIX + 'ROOT_ORG_LIST';
const ROOT_ORG_LIST_SUCCESS = ROOT_ORG_LIST + SUCCESS;
const ROOT_ORG_LIST_FAIL = ROOT_ORG_LIST + FAIL;


// ------------------------------------
// Actions
// ------------------------------------
export function initQuery(value = initialState) {
  return {
    type: USER_SEARCH,
    payload: value
  };
};

/**
 * 按照姓名查人员
 */
export const queryByWord = (query, orgId = '', page = 1, count = 20) => {
  return {
    types: [USER_SEARCH_LOAD, USER_SEARCH_SUCCESS, USER_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      // data: formatParams('user', 'userList', {
      data: formatParams('user', 'searchGlobalUserList', {
        status: 1,
        name: query,
        student_number: '',
        page: page,
        count: count,
        org_id: orgId,
        SearchFlag: 1
      })
    }),
    query
  };
};

export const queryOrg = (query, page = 1, count = 20) => {
  return {
    types: [USER_SEARCH_LOAD, USER_SEARCH_SUCCESS, USER_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'globalSearch', {
        search_text: query,
        data_type: 6,
        page: page,
        count: count
      })
    }),
    query
  };
};

export const userQueryByOrgId = (orgId) => {
  return {
    types: [USER_QUERY_FOR_ORG, USER_QUERY_FOR_ORG_SUCCESS, USER_QUERY_FOR_ORG_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('role', 'getUserByOrgId', {
        org_id: orgId
      })
    })
  };
};

export const userList = () => {
  return {
    types: [USER_LIST, USER_LIST_SUCCESS, USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('role', 'roleUserList', {
      })
    })
  };
};

export const treeSearch = (searchContent, dataType = 1, orgId, onlyUser, roleId, uid) => {
  return {
    types: [TREE_SEARCH, TREE_SEARCH_SUCCESS, TREE_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'searchInfo', {
        search_text: searchContent,
        data_type: dataType,
        org_id: orgId,
        only_user: onlyUser,
        role_id: roleId,
        uid: uid
      })
    })
  };
};

export const rootOrgList = () => {
  return {
    types: [ROOT_ORG_LIST, ROOT_ORG_LIST_SUCCESS, ROOT_ORG_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'getRootOrgInfo', {
      })
    })
  };
};

export const actions = {
  initQuery,
  queryByWord,
  userQueryByOrgId,
  userList
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [USER_SEARCH]: (state, action) => {
    const { searchState, searchValue, query, treeSearchResult } = action.payload;
    const rootOrgListResult = state.rootOrgListResult;
    return { searchState, searchValue, query, treeSearchResult, rootOrgListResult };
  },
  [USER_SEARCH_LOAD]: (state, action) => {
    return { ...initialState,
      query: action.query,
      userListResult: state.userListResult,
      rootOrgListResult: state.rootOrgListResult };
  },
  [USER_SEARCH_SUCCESS]: (state, action) => {
    if (state.searchState === USER_SEARCH_LOAD && state.query === action.query) {
      const { data } = action.payload;
      return { searchState: USER_SEARCH_SUCCESS,
        searchValue: data.list,
        query: action.query,
        userListResult: state.userListResult,
        rootOrgListResult: state.rootOrgListResult };
    }
    return state;
  },
  [USER_SEARCH_FAIL]: (state, action) => {
    if (state.searchState !== USER_SEARCH_LOAD || state.query !== action.query) {
      return state;
    }
    return { searchState: USER_SEARCH_FAIL,
      searchValue: [],
      query: action.query,
      rootOrgListResult: state.rootOrgListResult };
  },
  [USER_QUERY_FOR_ORG]: (state, action) => {
    return {
      ...state,
      queryLoading: true,
      queryLoaded: false,
      queryError: undefined
    };
  },
  [USER_QUERY_FOR_ORG_SUCCESS]: (state, action) => {
    return {
      ...state,
      queryLoading: false,
      queryLoaded: true,
      queryError: undefined,
      queryResult: action.payload.data
    };
  },
  [USER_QUERY_FOR_ORG_FAIL]: (state, action) => {
    return {
      ...state,
      queryLoading: false,
      queryLoaded: false,
      queryError: action.payload.errmsg
    };
  },
  [USER_LIST]: (state, action) => {
    return {
      ...state,
      userListLoading: true,
      userListLoaded: false,
      userListError: undefined
    };
  },
  [USER_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      userListLoading: false,
      userListLoaded: true,
      userListError: undefined,
      userListResult: action.payload.data
    };
  },
  [USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      userListLoading: false,
      userListLoaded: false,
      userListError: action.payload.errmsg
    };
  },
  [TREE_SEARCH]: (state, action) => {
    return {
      ...state,
      treeSearchLoading: true,
      treeSearchLoaded: false,
      treeSearchError: undefined
    };
  },
  [TREE_SEARCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      treeSearchLoading: false,
      treeSearchLoaded: true,
      treeSearchError: undefined,
      treeSearchResult: action.payload.data
    };
  },
  [TREE_SEARCH_FAIL]: (state, action) => {
    return {
      ...state,
      treeSearchLoading: false,
      treeSearchLoaded: false,
      treeSearchError: action.payload.errmsg
    };
  },
  [ROOT_ORG_LIST]: (state, action) => {
    return {
      ...state,
      rootOrgListLoading: true,
      rootOrgListLoaded: false,
      rootOrgListError: undefined
    };
  },
  [ROOT_ORG_LIST_SUCCESS]: (state, action) => {
    const orgList = action.payload.data;
    let list = [];
    if (orgList && orgList.length > 0) {
      list = orgList.map(item => Object.assign({value: item.id}, {label: item.name}));
    }
    list.unshift({value: '', label: '全部'});
    return {
      ...state,
      rootOrgListLoading: false,
      rootOrgListLoaded: true,
      rootOrgListError: undefined,
      rootOrgListResult: list
    };
  },
  [ROOT_ORG_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      rootOrgListLoading: false,
      rootOrgListLoaded: false,
      rootOrgListError: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  searchState: USER_SEARCH_LOAD,
  searchValue: [],
  query: ''
};

const userSearchReducer = makeReducer(ACTION_HANDLERS, initialState);
export default userSearchReducer;
