/**
 * 消息管理
 */
export default {

  // 通知
  'message.notice.readNum.label': '实际送达人数',
  'message.notice.readNum.hint': '通知发送后实际推送到客户端、企业微信等平台的人数，未送达的原因有微哨账号未激活、没有安装客户端等。',

  'message.notice.send.default': '[温馨提示]您使用的版本过低，无法阅读此通知，请升级微哨版本后查看。', // 发消息，消息体默认信息

  // 短信
  'message.sms.xxx': ''

};

