/**
 * Created by liming on 16/6/12.
 * 批量更新人员
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExcelImportView from 'components/ExcelImportView';
import IconHeader from 'components/IconHeader';
import { batchUpdateSubmit, batchUpdateOtherSubmit } from '../modules/user';
import { loadOrgRoot } from '../modules/organization';
import TabView from 'components/TabView';
import { formatMessage } from 'components/FormattedMessage';

const TAB_LABELS = ['行政组织架构', '其他组织架构'];

const TIPS = formatMessage({ id: 'org.batchUpdate.adminOrg.tip' });

const TIPS_OTHER = [
  '1. 姓名、学工号、所属单位组织/机构均为必填项。', '',
  '2. 姓名、学工号用于确认其他组织架构中存在的用户，组织架构名称即模版中的1级组织名称。', '',
  '3. 职务为非必填项，当导入的数据在职务列表中不存在时，将自动创建对应职务。', '',
  '4. 职务：同一用户导入多职务时，使用“，”分隔。', '',
  '5. 模版中的1级组织名称请填写“组织架构名称”，此模版不能填写“行政组织架构”。', '',
  '6. excel中数字请以文本形式显示，避免首位0丢失或者位数较多显示为科学计数。'
];

const TEMPLATE_TITLE = [
  '查找条件', '身份', '姓名', '学工号', '性别', '证件类型', '证件号码', '登录密码', '1级组织-编号', '1级组织-名称',
  '2级组织-编号', '2级组织-名称', '3级组织-编号', '3级组织-名称', '4级组织-编号', '4级组织-名称',
  '5级组织-编号', '5级组织-名称', '6级组织-编号', '6级组织-名称', '7级组织-编号', '7级组织-名称',
  'Email', '手机1', '固定电话', '民族', '出生日期', '籍贯省份', '籍贯市', '籍贯地区（县）',
  '家庭住址', '考生号', '准考证号', '所在年级', '入学年份', '学制', '学历', '职务', '政治面貌',
  '微信同步字段', 'QQ同步字段', '手机1短号', '固话短号', '手机2', '手机2短号', '手机3', '手机3短号', '状态', '录取类别'
];

const TEMPLATE_TITLE_OTHER = [
  '姓名', '学工号', '1级组织-编号', '1级组织-名称',
  '2级组织-编号', '2级组织-名称', '3级组织-编号', '3级组织-名称', '4级组织-编号', '4级组织-名称',
  '5级组织-编号', '5级组织-名称', '6级组织-编号', '6级组织-名称', '7级组织-编号', '7级组织-名称',
  '职务'
];

const LOADING_TIP = '参考时间：2-3万的数据：20分钟，5-6万的数据：30分钟';

class BatchUpdateOrganizationData extends PureComponent {

  state = {
    activeTab: 0
  };

  onTabChange = (value) => {
    this.setState({activeTab: value});
  };

  render() {
    const {activeTab} = this.state;
    const {contentHeight, batchUpdateSubmit, batchUpdateOtherSubmit, onFinish, importMsg, importErrmsg} = this.props;
    const ownProps = {
      templates: activeTab === 0 ? TEMPLATE_TITLE : TEMPLATE_TITLE_OTHER,
      templateFileUrl: activeTab === 0
        ? `${__BASEPATH__}/tpl/${formatMessage({ id: 'commom.tpl.update.adminOrg' })}.xls`
        : `${__BASEPATH__}/tpl/${formatMessage({ id: 'commom.tpl.update.otherOrg' })}.xls`,
      tips: activeTab === 0 ? TIPS : TIPS_OTHER,
      loadingTips: LOADING_TIP,
      submit: activeTab === 0 ? batchUpdateSubmit : batchUpdateOtherSubmit,
      onFinish,
      importMsg,
      importErrmsg,
      excelHeight: contentHeight - 200
    };
    return (
      <div className='container content-container' style={{ height: contentHeight }}>
        <IconHeader title='批量更新用户' iconClassName='userimport' />
        <TabView labels={TAB_LABELS} onTabChange={this.onTabChange}
          activeTab={activeTab} />
        <div style={
          {paddingTop: '12px',
            border: '1px solid #dddddd',
            borderTop: 'none',
            height: contentHeight - 120,
            overflowY: 'auto'}
        }>
          <ExcelImportView {...ownProps} />
        </div>
      </div>
    );
  }
}

BatchUpdateOrganizationData.propTypes = {
  contentHeight: PropTypes.number,
  batchUpdateSubmit: PropTypes.func,
  batchUpdateOtherSubmit: PropTypes.func,
  onFinish: PropTypes.func,
  importMsg: PropTypes.object,
  importErrmsg: PropTypes.object
};

const mapActionCreators = {
  batchUpdateSubmit,
  batchUpdateOtherSubmit,
  onFinish: loadOrgRoot
};

const mapStateToProps = (state) => ({
  contentHeight: state.flexSize.contentHeight,
  importMsg: state.user.updateMsg,
  importErrmsg: state.user.updateUserError
});

export default connect(mapStateToProps, mapActionCreators)(BatchUpdateOrganizationData);
