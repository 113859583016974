import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/15.
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import classes from './DropdownOrgTreePicker.scss';
import OrganizationNodeChooserView from './OrganizationNodeChooserView';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';

export const DropDownWrapper = React.forwardRef(
  ({ className, inputClassName, label, labelStyle, children }, ref) => (
    <div className={classNames('form-group', classes.dropdownWidget, className)}>
      {
        label && <label className={classNames('control-label', classes.inputLabel)} style={labelStyle}
          htmlFor='newTreeParentNodeName'>{label}</label>
      }
      <div className={classNames(classes.inputContent, 'fakeInput', inputClassName)} ref={ref}>
        {children}
      </div>
    </div>
  )
);
DropDownWrapper.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  children: PropTypes.any
};

export const DropDownArrow = ({ style, onClick }) => (
  <span className={classes.img} style={style} onClick={onClick}>
    <span className={classes.selectIcon} />
  </span>
);
DropDownArrow.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func
};

/**
 * 部门节点的下拉选择器.
 * 该下拉列表有两种模式:
 * 一种不可选模式,节点默认选择readOnlyNode,此模式仅供展示使用.(比如,用户通过组织节点菜单"创建下级",这时可以使用该模式.readOnly传入该节点即可)
 * 一种是下拉模式,可以通过下拉树选择节点,可以传入relatedNode来指定初始所选中的节点.
 * 传入参数说明见propTypes说明
 * 其中readOnlyNode和relatedNode不可同时设置,如果同时设置,只有readOnlyNode起作用.
 */
export default class DropdownWidget extends Component {

  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    labelName: PropTypes.string, // 左侧标签名字
    expandRoot: PropTypes.bool, // 是否默认展开根节点
    readOnlyNode: PropTypes.object, // 只读的选定节点,界面表现为一个只读的input标签,而且不可激活下拉列表
    relatedNode: PropTypes.object, // 预先设定的选定节点,界面可以点击输入框激活下拉列表
    selectNodeCallback: PropTypes.func, // 节点选择时的回调函数
    isOrgNav: PropTypes.bool, // 当编辑或新建组织结构的时候，需改变三角箭头定位位置,label不显示加粗
    placeholder: PropTypes.string,
    fromFlag: PropTypes.string,
    notAcceptCallback: PropTypes.func, // 节点选择是否被接受的回调函数
    height: PropTypes.number
  }

  state = {
    showTreeNodePanel: false,
    boxWidth: 0
  }

  treeContainerRef = React.createRef();

  componentWillMount = () => {
    if (this.props.readOnlyNode) {
      this.selectedNode = this.props.readOnlyNode;
    }
    if (this.props.relatedNode) {
      this.selectedNode = this.props.relatedNode;
    }
  }

  componentDidMount = () => {
    const treeContainer = this.treeContainerRef.current;
    if (!this.props.readOnlyNode && treeContainer) {
      if (this.state.boxWidth !== treeContainer.offsetWidth) {
        this.setState({boxWidth: treeContainer.offsetWidth});
      }
    }
  }

  onSelectNode = (node) => {
    this.selectedNode = node;
    if (this.props.selectNodeCallback) {
      this.props.selectNodeCallback(node);
    }
  }

  openTreeNodePanel = () => {
    this.setState({showTreeNodePanel: true});
  }

  closeTreeNodePanel = () => {
    this.setState({showTreeNodePanel: false});
  }

  selectedNode = null;

  render() {
    const { readOnlyNode, labelName, expandRoot, className, inputClassName, isOrgNav, placeholder,
      fromFlag, notAcceptCallback, height, width } = this.props;
    const orgNavSpan = { top: isOrgNav ? '12px' : (height ? (height - 20) / 2 + 'px' : '10px') };
    const orgNavLabel = { fontWeight: isOrgNav ? 'normal' : 'bold' };
    const showNameStyle = height ? { lineHeight: height - 12 + 'px' } : {};
    if (readOnlyNode) {
      return (
        <DropDownWrapper className={className} inputClassName={inputClassName}
          label={labelName} labelStyle={orgNavLabel}>
          <input type='text' className={'form-control ' + classes.inputText} id='newTreeParentNodeName'
            defaultValue={readOnlyNode.name} readOnly />
          <DropDownArrow style={orgNavSpan} />
        </DropDownWrapper>
      );
    } else {
      const { showTreeNodePanel } = this.state;
      const selectedName = notAcceptCallback
        ? (this.selectedNode && notAcceptCallback() ? this.selectedNode.name : undefined)
        : (this.selectedNode ? this.selectedNode.name : undefined);
      return (
        <DropDownWrapper className={className} inputClassName={inputClassName}
          label={labelName} labelStyle={orgNavLabel} ref={this.treeContainerRef}>
          {
            !showTreeNodePanel && !selectedName && <input
              type='text' className={'form-control'} id='newTreeParentNodeName'
              placeholder={placeholder || '请选择'} onFocus={this.openTreeNodePanel} defaultValue={selectedName} />
          }
          {(showTreeNodePanel || (!showTreeNodePanel && selectedName)) && <div
            className={classNames('form-control', classes.inputText)} style={showNameStyle}
            id='newTreeParentNodeName' onClick={this.openTreeNodePanel}>
            {selectedName ? <AutoShrinkTextWithTip text={selectedName} maxSize={width ? width - 40 : 372 - 40}
              fontSize={14} /> : ''}
          </div>}
          <DropDownArrow style={orgNavSpan} onClick={this.openTreeNodePanel} />
          <OrganizationNodeChooserView handlePanelClose={this.closeTreeNodePanel}
            expandRoot={expandRoot} boxWidth={this.state.boxWidth}
            selectNodeCallback={this.onSelectNode} presetSelectedNode={this.selectedNode}
            outsideClickIgnoreClass={'fakeInput'} show={showTreeNodePanel}
            fromFlag={fromFlag} />
        </DropDownWrapper>
      );
    }
  }

}

