import { readAuthInfo, getDomain } from './AuthHelper';

const RAVEN_DSN = 'https://36ff3978e4104dbfbefbebe050b2c581@o508461.ingest.sentry.io/5601021';

const checkEnv = () => __PROD__ && window && window.Raven;

export function init(cb) {
  if (checkEnv()) {
    const config = {
      release: __VERSION__,
      maxBreadcrumbs: 20
    };
    window.Raven.config(RAVEN_DSN, config).install();
    window.Raven.context(cb);
  } else {
    cb();
  }
}

export function report(error, extraInfo) {
  if (checkEnv()) {
    const errorInfo = {
      ...extraInfo,
      domain: getDomain(),
      auth: readAuthInfo()
    };
    window.Raven.captureException(error, { extra: errorInfo });
  } else {
    console.error(error, extraInfo);
  }
}

export function prepareReport(cb) {
  if (checkEnv() && cb) {
    window.Raven.setDataCallback((data, original) => {
      const reportData = cb(data);
      return original ? original(reportData) : reportData;
    });
  }
}

export function collectContext(data) {
  if (checkEnv()) {
    window.Raven.captureBreadcrumb(data);
  }
}
