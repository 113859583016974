/**
 * Created by liuzhijia on 2017/7/17.
 * 标记毕业item
 */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './MarkGraduationItem.scss';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import Icon from 'components/Icon';
import {NODE_GRADUATION} from '../modules/organization';
import { getOrgPathText } from 'helpers/orgUtils';

class MarkGraduationItem extends React.PureComponent {

  constructor(props) {
    super();
    this.state = {
      checked: props.checked
    };
  }

  componentWillReceiveProps(newProps) {
    const {checked} = newProps;
    if (checked !== this.props.checked) {
      this.setState({ checked });
    }
  }

  toggleChkState = (e) => {
    e.preventDefault();
    const {item, onChkStateChange} = this.props;
    onChkStateChange(item.on, item.id);
  }

  markItem = (e) => {
    e.preventDefault();
    const {item, markItem} = this.props;
    markItem(item.id, item.status);
  }

  restoreItem = (e) => {
    e.preventDefault();
    const {item, restoreItem} = this.props;
    restoreItem(item.id, item.status);
  }

  render() {
    const {item, width} = this.props;
    const { checked } = this.state;
    const pathArray = item && item.source.split(',');
    const pathName = pathArray && getOrgPathText(pathArray.slice(0, pathArray.length - 1), '>');
    // const pathName = item && item.source.replace(new RegExp(',', 'g'), '>');
    const hasGraduated = item.status === NODE_GRADUATION;
    const graduationStatus = hasGraduated ? '已毕业' : '未毕业';
    return (
      <tr>
        <td style={{width: width * 0.3}}><span className={classes.chkspan} onClick={this.toggleChkState}>
          <Icon type={checked ? 'box-sel' : 'box'} className={checked ? classes.chksel : classes.chk}
            style={{fontSize: '15px'}} />
          {'  '}
          <AutoShrinkTextWithTip text={item.name} maxSize={width * 0.3 - 60} fontSize={14} showTips />
        </span></td>
        <td style={{width: width * 0.1}}><AutoShrinkTextWithTip text={graduationStatus}
          maxSize={width * 0.1 - 5} fontSize={14} showTips /></td>
        <td style={{width: width * 0.5}}><AutoShrinkTextWithTip text={pathName}
          maxSize={width * 0.5 - 5} fontSize={14} showTips /></td>
        <td style={{ textAlign: 'center', width: width * 0.1 }}>
          {!hasGraduated && <a className={classes.abtn + ' btn-link'} onClick={this.markItem}>标记毕业</a>}
          {hasGraduated && <a className={classes.abtn + ' btn-link'} onClick={this.restoreItem}>恢复</a>}
        </td>
      </tr>
    );
  }
}

MarkGraduationItem.propTypes = {
  width: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  onChkStateChange: PropTypes.func.isRequired,
  markItem: PropTypes.func.isRequired,
  restoreItem: PropTypes.func.isRequired
};

export default MarkGraduationItem;
