import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import classNames from 'classnames';
import { FormattedMessage } from 'components/FormattedMessage';
import classes from './ExpandableList.scss';

const ItemTitle = (props) => {
  const { titleColor, className, expanded, arrow, uuid, icon, title, onClick } = props;
  const style = {
    cursor: 'pointer',
    margin: 0,
    color: titleColor
  };
  let indicator;
  if (arrow) {
    indicator = <Icon type={expanded ? 'arrowup' : 'arrowdown'} className={classes.list_item_ind} />;
  }
  const renderIcon = <Icon type={icon} className={classNames(classes.list_item_icon)} />;

  return (
    <div
      aria-controls={`expandable-list-item-body-${uuid}`}
      className={classNames(classes.list_item_title, className)}
      id={`expandable-list-item-title-${uuid}`}
      onClick={onClick}
      style={style}
    >
      {renderIcon}
      <FormattedMessage id={title} />
      {indicator}
    </div>
  );
};

ItemTitle.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  uuid: PropTypes.string,
  expanded: PropTypes.bool,
  arrow: PropTypes.bool
};

export default ItemTitle;
