/**
 * Created by liming on 16/6/28.
 */

export function isMyChild(parentNode, childToCheck) {
  let parentPath = parentNode.path || '';
  if (parentPath === childToCheck.path && parentNode.id !== childToCheck.id) { // childToCheck为用户节点对象时会发生这样的问题
    return true;
  }
  parentPath += ',';
  if (parentPath.length > childToCheck.path.length) {
    return false;
  }
  const subPath = childToCheck.path.substr(0, parentPath.length);
  if (subPath === parentPath) {
    return true; // parent node
  }
  return false;
}

function isSameNode(node1, node2) {
  return (node1.isOrg === node2.isOrg) && (node1.id === node2.id);
}
/**
 * 遍历树节点数组nodeSet,如果某个节点和它的直接子节点都包含在nodeSet中,那么该节点的子节点以及孙子要从nodeSet中剔除
 * 其中节点的数据结构为{id, parent, path, children}, path的格式为"<id>(,<id>)*"
 * @param nodeSet
 */
export function reduceTreeNodeSet(nodeSet, nodeToInclude, isIncludeUser) {
  if (!nodeToInclude.parent) { // 根节点被选中了,直接返回根节点数组
    return [nodeToInclude];
  }
  const helperSet = nodeSet.slice();
  if (nodeToInclude.isOrg) {
    // 先删除nodeToInclude的孩子以及孙子
    const childToDelete = [];
    helperSet.forEach((node) => {
      if (isMyChild(nodeToInclude, node)) {
        // helperSet.splice(helperSet.indexOf(node), 1);
        childToDelete.push(node);
      }
    });
    childToDelete.forEach((node) => helperSet.splice(helperSet.indexOf(node), 1));
  }
  helperSet.push(nodeToInclude);
  let endFlag = false;
  let nodeToCheck = nodeToInclude.parent;
  if (nodeToCheck.isVirtual) {
    return helperSet;
  }
  if (!isIncludeUser) {
    while (!endFlag) {
      if (nodeToCheck.children.every((ele, index, arr) => helperSet.find((el, idx, ar) => isSameNode(el, ele)))) {
        // 先删除直接子节点
        nodeToCheck.children.forEach((child) => {
          helperSet.splice(helperSet.indexOf(helperSet.find((ele, index, arr) => isSameNode(ele, child))), 1);
        });
        helperSet.push(nodeToCheck);
        if (helperSet.length === 1) {
          if (!nodeToCheck.parent || (nodeToCheck.parent && nodeToCheck.parent.isVirtual)) {
            // nodeToCheck是根节点,或者它的父亲是虚拟跟节点,合并完毕
            endFlag = true;
          } else {
            nodeToCheck = nodeToCheck.parent;
          }
        } else {
          if (nodeToCheck.parent) {
            if (nodeToCheck.parent.isVirtual) {
              endFlag = true;
            } else {
              nodeToCheck = nodeToCheck.parent;
            }
          } else {
            endFlag = true;
          }
        }
      } else {
        endFlag = true;
      }
    }
  } else {
    while (!endFlag) {
      if (nodeToCheck.children.every((ele, index, arr) => helperSet.find((el, idx, ar) => isSameNode(ele, el)))
        && nodeToCheck.users.every((ele, index, arr) => helperSet.find((el, idx, ar) => isSameNode(ele, el)))) {
        // 先删除直接子节点
        nodeToCheck.children.forEach((child) => {
          helperSet.splice(helperSet.indexOf(helperSet.find((ele, index, arr) => isSameNode(ele, child))), 1);
        });
        nodeToCheck.users.forEach((user) => {
          helperSet.splice(helperSet.indexOf(helperSet.find((ele, index, arr) => isSameNode(ele, user))), 1);
        });
        helperSet.push(nodeToCheck);
        if (helperSet.length === 1) { // 合并完毕
          if (!nodeToCheck.parent || (nodeToCheck.parent && nodeToCheck.parent.isVirtual)) {
            // nodeToCheck是根节点,或者它的父亲是虚拟跟节点,合并完毕
            endFlag = true;
          } else {
            nodeToCheck = nodeToCheck.parent;
          }
        } else {
          if (nodeToCheck.parent) {
            if (nodeToCheck.parent.isVirtual) {
              endFlag = true;
            } else {
              nodeToCheck = nodeToCheck.parent;
            }
          } else {
            endFlag = true;
          }
        }
      } else {
        endFlag = true;
      }
    }
  }
  return helperSet;
}

/**
 * 构造一个新的节点集合,使得新的集合不包含nodeToExclude.
 * 注意,nodeToExclude可能是nodeSet中某个节点的子节点或更深的孙子节点
 * @param nodeSet
 * @param nodeToExclude
 */
export function deduceTreeNodeSet(nodeSet, nodeToExclude, isIncludeUser) {
  const helperSet = nodeSet.slice();
  let bingo = helperSet.find((ele, index, arr) => isSameNode(ele, nodeToExclude));
  if (bingo) {
    helperSet.splice(helperSet.indexOf(bingo), 1);
    return helperSet;
  }
  bingo = helperSet.find((ele, index, arr) => ele.isOrg && isMyChild(ele, nodeToExclude));
  if (bingo) {
    helperSet.splice(helperSet.indexOf(bingo), 1);
    let endFlag = false;
    let helperNode = bingo;
    if (!isIncludeUser) {
      while (!endFlag) {
        let children = helperNode.children;
        children.forEach((child) => {
          if (child.id === nodeToExclude.id) {
            endFlag = true;
          } else {
            if (isMyChild(child, nodeToExclude)) {
              helperNode = child;
            } else {
              helperSet.push(child);
            }
          }
        });
      }
    } else {
      while (!endFlag) {
        let children = helperNode.children.concat(helperNode.users || []);
        children.forEach((child) => {
          if (child.id === nodeToExclude.id) {
            endFlag = true;
          } else {
            if (isMyChild(child, nodeToExclude)) {
              if (child.isOrg) {
                helperNode = child;
              } else {
                helperSet.push(child);
              }
            } else {
              helperSet.push(child);
            }
          }
        });
      }
    }
  }
  return helperSet;
}

export function removeUserIdPrefix(nodeList) {
  return nodeList.map((node) => {
    if (node.isOrg) {
      return node;
    }
    const newNode = Object.assign({}, node, {id: node.id.substr(1)});
    return newNode;
  });
}

export function addUserIdPrefix(nodeList) {
  return nodeList.map((node) => {
    if (node.isOrg) {
      return node;
    }
    const newNode = Object.assign({}, node, {id: 'u' + node.id});
    return newNode;
  });
}
