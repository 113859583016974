/**
 * 导航栏菜单项
 */
import { USER_TYPE_ADMIN, USER_TYPE_SUBADMIN, ACCOUNT_TYPE_ASSISTANT, getAuthInfo } from 'helpers/AuthHelper';

export const MENU_STATISTICS = {
  id: 0,
  target: 'statistics',
  title: 'menu.statistics',
  icon: 'statistics',
  subMenu: [
    {
      id: 10,
      target: 'statistics/overview',
      title: 'menu.statistics.overview',
      icon: 'dataprofile'
    },
    {
      id: 11,
      target: 'statistics/activeUser',
      title: 'menu.statistics.activeUser',
      icon: 'userretention' // 活跃用户和用户留存的图标弄反了，所以现在看着名称有点别
    },
    {
      id: 12,
      target: 'statistics/userRetention',
      title: 'menu.statistics.userRetention',
      icon: 'activeuser'
    },
    {
      id: 13,
      target: 'statistics/appVersion',
      title: 'menu.statistics.appVersion',
      icon: 'terminal'
    },
    {
      id: 14,
      target: 'statistics/appUse',
      title: 'menu.statistics.appUse',
      icon: 'appversion'
    },
    {
      id: 15,
      target: 'statistics/terminal',
      title: 'menu.statistics.terminal',
      icon: 'appuse'
    }
  ]
};
export const MENU_USER = {
  id: 1,
  target: 'user',
  title: 'menu.user',
  icon: 'user',
  subMenu: [
    {
      id: 100,
      target: 'user/organization',
      title: 'menu.user.organization',
      icon: 'organization'
    },
    {
      id: 101,
      target: 'user/adhocOrg',
      title: 'menu.user.adhocOrg',
      icon: 'adhoc'
    },
    {
      id: 102,
      target: 'user/position',
      title: 'menu.user.position',
      icon: 'post'
    },
    {
      id: 103,
      target: 'user/tags',
      title: 'menu.user.tags',
      icon: 'tag'
    },
    {
      id: 104,
      target: 'user/job',
      title: 'menu.user.job',
      icon: 'job'
    }
  ]
};
export const MENU_CHATGROUP = {
  id: 2,
  target: 'chatgroup',
  title: 'menu.chatgroup',
  icon: 'addressbook',
  subMenu: [
    {
      id: 202,
      target: 'chatgroup/sortBookSet',
      title: 'menu.chatgroup.sortBookSet',
      icon: 'addressbooksort'
    },
    {
      id: 200,
      target: 'chatgroup/addressBookSet',
      title: 'menu.chatgroup.addressBookSet',
      icon: 'addressbookset'
    },
    {
      id: 201,
      target: 'chatgroup/group',
      title: 'menu.chatgroup.group',
      icon: 'chatgroup'
    }
  ]
};
export const MENU_APPSTORE = {
  id: 3,
  target: 'appstore',
  title: 'menu.appstore',
  icon: 'appstore',
  subMenu: [
    {
      id: 300,
      target: 'appstore/board',
      title: 'menu.appstore.board',
      icon: 'appboard'
    },
    {
      id: 301,
      target: 'appstore/market',
      title: 'menu.appstore.market',
      icon: 'appmarket'
    },
    {
      id: 303,
      target: 'appstore/comment',
      title: 'menu.appstore.comment',
      icon: 'comment'
    },
    {
      id: 302,
      target: 'appstore/netConfig',
      title: 'menu.appstore.netConfig',
      icon: 'ipconfig'
    },
    {
      id: 305,
      target: 'appstore/cardManage',
      title: 'menu.appstore.cardManage',
      icon: 'card-manage'
    },
    {
      id: 304,
      target: 'appstore/businessSystem',
      title: 'menu.appstore.businessSystem',
      icon: 'business-system'
    }
  ]
};
export const MENU_NOTICE = {
  id: 4,
  target: 'notice',
  title: 'menu.notice',
  icon: 'notice',
  subMenu: [
    {
      id: 400,
      target: 'notice/noticeList',
      title: 'menu.notice.noticeList',
      icon: 'noticelist'
    },
    {
      id: 401,
      target: 'notice/permission',
      title: 'menu.notice.permission',
      icon: 'permission'
    },
    {
      id: 403,
      target: 'notice/smsList',
      title: 'menu.notice.smsList',
      icon: 'messagelist'
    },
    {
      id: 402,
      target: 'notice/smsplatform',
      title: 'menu.notice.smsplatform',
      icon: 'message'
    },
    {
      id: 404,
      target: 'notice/email',
      title: 'menu.notice.email',
      icon: 'messagelist'
    }
  ]
};
export const MENU_CUSTOMIZE = {
  id: 5,
  target: 'customize',
  title: 'menu.customize',
  icon: 'app-manage',
  subMenu: [
    {
      id: 500,
      target: 'customize/splash',
      title: 'menu.customize.splash',
      icon: 'splash'
    },
    {
      id: 501,
      target: 'customize/disclaimer',
      title: 'menu.customize.disclaimer',
      icon: 'disclaimer'
    },
    {
      id: 502,
      target: 'customize/accpasstips',
      title: 'menu.customize.accounttips',
      icon: 'acctips'
    },
    {
      id: 503,
      target: 'customize/homeTitleBar',
      title: 'menu.customize.homeTitleBar',
      icon: 'hometitle'
    },
    {
      id: 504,
      target: 'customize/banner',
      title: 'menu.customize.banner',
      icon: 'banner'
    },
    {
      id: 505,
      target: 'customize/schoolBackground',
      title: 'menu.customize.schoolBackground',
      icon: 'ecard'
    },
    {
      id: 506,
      target: 'customize/whiteList',
      title: 'menu.customize.whiteList',
      icon: 'whitelist'
    }
  ]
};
export const MENU_THIRDPARTY = {
  id: 8,
  target: 'thirdparty',
  title: 'menu.thirdparty',
  icon: 'thirdparty',
  subMenu: [
    {
      id: 800,
      target: 'thirdparty/qywx',
      title: 'menu.thirdparty.qywx',
      icon: 'enterprise'
    },
    {
      id: 801,
      target: 'thirdparty/qq',
      title: 'menu.thirdparty.qq',
      icon: 'qq'
    },
    {
      id: 802,
      target: 'thirdparty/fwh',
      title: 'menu.thirdparty.fwh',
      icon: 'servicenumber'
    },
    {
      id: 803,
      target: 'thirdparty/dingtalk',
      title: 'menu.thirdparty.dingtalk',
      icon: 'dingtalk'
    },
    {
      id: 804,
      target: 'thirdparty/yiban',
      title: 'menu.thirdparty.yiban',
      icon: 'yiban'
    }
  ]
};
export const MENU_CREDIT = {
  id: 9,
  target: 'credit',
  title: 'menu.credit',
  icon: 'credit'
};
export const MENU_SYSTEM_MANAGEMENT = {
  id: 10,
  target: 'system',
  title: 'menu.system',
  icon: 'systemmanage',
  subMenu: [
    {
      id: 1000,
      target: 'system/version',
      title: 'menu.system.version',
      icon: 'versionmanage'
    },
    {
      id: 1001,
      target: 'system/appName',
      title: 'menu.system.appName',
      icon: 'app-name'
    },
    {
      id: 1002,
      target: 'system/messagePush',
      title: 'menu.system.messagePush',
      icon: 'message-push'
    }
  ]
};
export const SUBADMIN_MENU_APPSTORE = {
  id: 11,
  target: 'appstore',
  title: 'menu.subadmin.appstore',
  icon: 'appstore'
};
export const MENU_AUTH_MANAGE = {
  id: 12,
  target: 'permission',
  title: 'menu.permission',
  icon: 'authmanage',
  subMenu: [
    {
      id: 1200,
      target: 'permission/role',
      title: 'menu.permission.role',
      icon: 'role'
    },
    {
      id: 1201,
      target: 'permission/manager',
      title: 'menu.permission.manager',
      icon: 'authorize'
    },
    {
      id: 1202,
      target: 'permission/assistant',
      title: 'menu.permission.assistant',
      icon: 'assistant'
    }
  ]
};

export const SUBADMIN_MENU_AUTH_MANAGE = {
  id: 13,
  target: 'permission',
  title: 'menu.subadmin.permission',
  icon: 'systemmanage',
  subMenu: [
    {
      id: 1300,
      target: 'permission/assistant',
      title: 'menu.subadmin.permission.assistant',
      icon: 'assistant'
    }
  ]
};

export const MENU_SMART_QA = {
  id: 14,
  target: 'smartqa',
  title: 'menu.smartqa',
  icon: 'smartservice',
  subMenu: [
    {
      id: 1400,
      target: 'smartqa/library',
      title: 'menu.smartqa.library',
      icon: 'question-library'
    },
    {
      id: 1401,
      target: 'smartqa/learning',
      title: 'menu.smartqa.learning',
      icon: 'question-learning'
    },
    {
      id: 1402,
      target: 'smartqa/statistics',
      title: 'menu.smartqa.statistics',
      icon: 'question-statistics'
    },
    {
      id: 1403,
      target: 'smartqa/setting',
      title: 'menu.smartqa.setting',
      icon: 'smart-setting'
    }
  ]
};

export const MENU_PORTAL = {
  id: 15,
  target: 'portal',
  title: 'menu.portal',
  icon: 'portal-set',
  subMenu: [
    {
      id: 1500,
      target: 'portal/basic-set',
      title: 'menu.portal.basicset',
      icon: 'basic-set'
    },
    {
      id: 1501,
      target: 'portal/banner-set',
      title: 'menu.portal.bannerset',
      icon: 'banner'
    },
    {
      id: 1502,
      target: 'portal/layout-set',
      title: 'menu.portal.layoutset',
      icon: 'layout-set'
    },
    {
      id: 1503,
      target: 'portal/menu-set',
      title: 'menu.portal.menuset',
      icon: 'menu-set'
    },
    {
      id: 1504,
      target: 'portal/help-set',
      title: 'menu.portal.helpset',
      icon: 'help-set'
    },
    {
      id: 1505,
      target: 'portal/memo-set',
      title: 'menu.portal.memoset',
      icon: 'memo-set'
    },
    {
      id: 1506,
      target: 'portal/backstage',
      title: 'menu.portal.backstage',
      icon: 'backstage'
    },
    {
      id: 1507,
      target: 'portal/pc-work',
      title: 'menu.portal.pcwork',
      icon: 'portal-set'
    }
  ]
};

export const MENU_GENERAL = {
  id: 16,
  target: 'general',
  title: 'menu.general',
  icon: 'general',
  subMenu: [
    {
      id: 1600,
      target: 'general/tips',
      title: 'menu.general.tips',
      icon: 'tips'
    }
  ]
};

export const MENU_AUDIT_LOG = {
  id: 17,
  target: 'auditlog',
  title: 'menu.auditlog',
  icon: 'message'
};

export const ADMIN_MENU = [
  MENU_STATISTICS,
  MENU_USER,
  MENU_APPSTORE,
  MENU_NOTICE,
  MENU_CHATGROUP,
  MENU_AUTH_MANAGE,
  MENU_GENERAL,
  MENU_CUSTOMIZE,
  MENU_PORTAL,
  MENU_THIRDPARTY,
  MENU_CREDIT,
  MENU_SYSTEM_MANAGEMENT,
  MENU_SMART_QA,
  MENU_AUDIT_LOG
];
export const AUTH_MENU = [
  MENU_STATISTICS,
  MENU_USER,
  MENU_APPSTORE,
  MENU_NOTICE,
  MENU_CHATGROUP,
  MENU_GENERAL,
  MENU_CUSTOMIZE,
  MENU_PORTAL,
  MENU_SMART_QA,
  MENU_AUDIT_LOG
];
export const NOT_AUTH_SUBMENU = [
  100, 200, 202, 301, 302, 303, 501, 502, 503
];
// 超级管理员黑名单
export const ACL_MENU = [17];

export function authMenu() {
  const list = [];
  if (AUTH_MENU && AUTH_MENU.length > 0) {
    AUTH_MENU.map(item => {
      const subList = item.subMenu;
      const listSub = [];
      if (subList && subList.length > 0) {
        subList.map(itemSub => listSub.push(itemSub));
      }
      const temp = Object.assign({}, item, {subMenu: listSub});
      list.push(temp);
    });
  }
  return list;
};

export function getAuthMenu() {
  const menuList = authMenu();
  const auth = getAuthInfo();
  const menuAuth = auth.authMenu;
  const accountType = auth.accountType;
  const hideComment = auth.hideComment; // 是否隐藏评论
  const list = [];
  if (menuAuth && menuAuth.length > 0) {
    if (menuList && menuList.length > 0) {
      menuList.map(item => {
        const subList = [];
        const subMenu = item.subMenu;
        const authMenus = menuAuth.filter(it => it.id === item.id);
        if (authMenus && authMenus.length > 0) {
          authMenus.map(au => {
            const sub = au.sub;
            if (sub && sub.length > 0) {
              sub.map(sub => {
                subMenu && subMenu.length > 0 && subMenu.find(sm => {
                  if (subList && subList.length > 0) {
                    const subInfo = subList.find(si => si.id === sm.id);
                    !subInfo && sm.id === sub && subList.push(sm);
                    if (sub === 300 && !hideComment) {
                      const info = subList.find(s => s.id === 303);
                      if (!info) {
                        sm.id === 303 && subList.push(sm);
                      }
                    }
                  } else {
                    sm.id === sub && subList.push(sm);
                    if (sub === 300 && sm.id === 303 && !hideComment) {
                      subList.push(sm);
                    }
                  }
                });
              });
            }
          });
          if (subList.length > 0) {
            list.push(Object.assign({}, item, { subMenu: subList }));
          } else {
            const { subMenu, ...info } = item;
            list.push(info);
          }
        }
      });
    }
  }
  if (accountType !== ACCOUNT_TYPE_ASSISTANT) {
    list.push(SUBADMIN_MENU_AUTH_MANAGE);
  }
  return list;
}

export function getHomePath(userType) {
  const authMenu = getAuthMenu();
  switch (userType) {
    case USER_TYPE_SUBADMIN:
      const [menuInfo] = authMenu;
      const [activeMenu] = menuInfo.subMenu;
      if (activeMenu && activeMenu.target) {
        return activeMenu.target;
      }
      return menuInfo.target;
    case USER_TYPE_ADMIN:
    default:
      return ADMIN_MENU[0].target;
  }
};

