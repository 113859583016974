import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: 'PC门户管理',
  path: 'portal',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: {
    onEnter: (nextState, replace) => replace('/portal/backstage')
  },
  childRoutes: [
    {
      name: '基本设置',
      path: 'basic-set',
      indexRoute: {
        name: '基本设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/basicSet').default;
            injectReducer(store, { key: 'basicSet', reducer });
            const Component = require('./containers/BasicSet').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'BasicSet');
        }
      },
      childRoutes: []
    },
    {
      name: '轮播图设置',
      path: 'banner-set',
      indexRoute: {
        name: '轮播图设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('../Customize/modules').default;
            injectReducer(store, { key: 'customize', reducer });
            const Component = require('../Customize/containers/BannerSetting').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Banner');
        }
      },
      childRoutes: [
        {
          name: '创建横幅',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('../Customize/modules').default;
              injectReducer(store, { key: 'customize', reducer });
              const Component = require('../Customize/containers/BannerEditor').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Customize');
          }
        },
        {
          name: '修改横幅',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('../Customize/modules').default;
              injectReducer(store, { key: 'customize', reducer });
              const Component = require('../Customize/containers/BannerEditor').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Customize');
          }
        },
        {
          name: '使用统计',
          path: 'statistics',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('../Customize/modules').default;
              injectReducer(store, { key: 'customize', reducer });
              const Component = require('../Customize/containers/BannerStatistics').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Customize');
          }
        }
      ]
    },
    {
      name: '首页布局设置',
      path: 'layout-set',
      indexRoute: {
        name: '基本设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/layoutSet').default;
            injectReducer(store, { key: 'layoutSet', reducer });
            const Component = require('./containers/LayoutSet').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'LayoutSet');
        }
      },
      childRoutes: []
    },
    {
      name: '首页菜单设置',
      path: 'menu-set',
      indexRoute: {
        name: '首页菜单设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/menuSet').default;
            injectReducer(store, { key: 'menuSet', reducer });
            const Component = require('./containers/MenuSet').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'MenuSet');
        }
      },
      childRoutes: [
        {
          name: '菜单排序',
          path: 'sort',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/menuSet').default;
              injectReducer(store, { key: 'menuSet', reducer });
              const Component = require('./containers/MenuSort').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'MenuSet');
          }
        }
      ]
    },
    {
      name: '帮助设置',
      path: 'help-set',
      indexRoute: {
        name: '帮助设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/helpSet').default;
            injectReducer(store, { key: 'helpSet', reducer });
            const Component = require('./containers/HelpSet').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'HelpSet');
        }
      },
      childRoutes: [
        {
          name: '新建帮助',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/helpSet').default;
              injectReducer(store, { key: 'helpSet', reducer });
              const Component = require('./containers/HelpForm').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'HelpSet');
          }
        },
        {
          name: '编辑帮助',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/helpSet').default;
              injectReducer(store, { key: 'helpSet', reducer });
              const Component = require('./containers/HelpForm').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'HelpSet');
          }
        }
      ]
    },
    {
      name: '备忘',
      path: 'memo-set',
      indexRoute: {
        name: '备忘',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/memo').default;
            injectReducer(store, { key: 'memo', reducer });
            const Component = require('./containers/Memo').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Memo');
        }
      },
      childRoutes: [
        {
          name: '备忘排序',
          path: 'sort',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/memo').default;
              injectReducer(store, { key: 'memo', reducer });
              const Component = require('./containers/SortMemo').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Memo');
          }
        },
        {
          name: '添加备忘',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/memo').default;
              injectReducer(store, { key: 'memo', reducer });
              const Component = require('./containers/MemoOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Memo');
          }
        },
        {
          name: '编辑备忘',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/memo').default;
              injectReducer(store, { key: 'memo', reducer });
              const Component = require('./containers/MemoOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Memo');
          }
        }
      ]
    },
    {
      name: '统一后台管理',
      path: 'backstage',
      indexRoute: {
        name: '后台列表',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/backstage').default;
            injectReducer(store, { key: 'backstage', reducer });
            const Component = require('./containers/Backstage').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Backstage');
        }
      },
      childRoutes: [
        {
          name: '添加后台',
          path: 'add',
          staticName: true,
          indexRoute: {
            name: '添加后台',
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules/backstage').default;
                injectReducer(store, { key: 'backstage', reducer });
                const Component = require('./containers/BackstageForm').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'Backstage');
            }
          }
        },
        {
          name: '编辑后台',
          path: 'edit/:id',
          staticName: true,
          indexRoute: {
            name: '添加后台',
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules/backstage').default;
                injectReducer(store, { key: 'backstage', reducer });
                const Component = require('./containers/BackstageForm').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'Backstage');
            }
          }
        }
      ]
    },
    {
      name: '工作门户',
      path: 'pc-work',
      indexRoute: {
        name: '基本设置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/workPortal').default;
            injectReducer(store, { key: 'workPortal', reducer });
            const Component = require('./containers/WorkPortal').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'WorkPortal');
        }
      },
      childRoutes: []
    }
  ]
});

export default route;
