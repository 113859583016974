import { getAuthInfo, getSessionTime, setSessionTime } from 'helpers/AuthHelper';
import { checkAcl } from 'config/acl';
import { getHomePath } from 'config/menu';
const moment = require('moment');

const LOGIN_TIMEOUT = 30; // 登录超时时间（分钟）

/**
 * Enter hook for react-router to check if user has logged in.
 */
export function checkAuth(nextState, replace) {
  let loggedIn = false;
  const auth = getAuthInfo();
  const session = getSessionTime();
  const source = nextState.location;
  if (auth && !isNaN(session)) {
    const now = Date.now();
    const startTime = moment(session);
    const expired = moment(now).isAfter(startTime.add(LOGIN_TIMEOUT, 'minutes'));
    if (!expired) {
      setSessionTime(now); // Refresh session time for router event when valid
    }
    loggedIn = !expired;
    source.notsso = true;
  }
  if (loggedIn) {
    const userType = auth.userType;
    if (!checkAcl(userType, auth.accountType, source.pathname)) { // 通过ACL检查则放行，否则强制跳转
      replace(getHomePath(userType));
    }
  } else {
    replace({
      pathname: '/login',
      state: { source }
    });
  }
};

export default {
  checkAuth
};
