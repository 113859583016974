import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classes from './MultiOrgUserForm.scss';
import Dialog from 'components/Dialog';
import SelectSearch from 'components/SelectSearch';
import SelectInput from 'components/SelectInput';
import DropdownOrgTreePicker from 'components/OrgTreePicker/DropdownOrgTreePicker';
import {MULTI_USER} from 'helpers/strings';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';
import { multiOrgAddUser, queryUsers } from '../../modules/organization';

class MultiOrgUserForm extends PureComponent {

  state = {
    selectedUser: undefined,
    showOrgSearch: false,
    parentId: undefined,
    orgPath: undefined,
    selectJob: ''
  };

  componentDidMount() {
    const { activeNode } = this.props;
    if (activeNode && parseInt(activeNode.orgType) === 2) {
      this.setState({ orgId: activeNode.id, orgPath: activeNode.path });
    }
  }

  componentWillReceiveProps(newProps) {
    const {multiOrgAddUserLoaded, multiOrgAddUserError} = newProps;
    if (multiOrgAddUserLoaded && !this.props.multiOrgAddUserLoaded) {
      showSuccessToast('添加成功');
      if (this.state.orgPath.replace(',', '-').indexOf(this.props.activeNode.path.replace(',', '-')) !== -1) {
        this.props.queryUsers({org_id: this.props.activeNode.id});
      }
      this.props.onHide();
    } else {
      if (multiOrgAddUserError && multiOrgAddUserError !== this.props.multiOrgAddUserError) {
        showErrorToast(multiOrgAddUserError);
      }
    }
  }

  onSelectUserWhenAdd = (user) => {
    this.setState({selectedUser: user});
  }

  onSelectUserCleared = () => {
    this.setState({selectedUser: undefined});
  }

  onTreeNodeSelected = (node) => {
    this.setState({
      orgId: node.id,
      orgPath: node.path || '',
      parentId: node.parentId,
      orgError: false
    });
  };

  isRoot = () => {
    const {parentId} = this.state;
    if (parentId === '-1') {
      this.setState({orgId: undefined});
      return false;
    } else {
      return true;
    }
  }

  onChangeSelect = (value) => {
    this.setState({selectJob: value});
  };

  onSubmit = () => {
    const {selectedUser, orgId, selectJob} = this.state;
    this.props.multiOrgAddUser(selectedUser.username, orgId, selectJob);
  };

  render() {
    const {selectedUser, orgId, selectJob} = this.state;
    const {onHide, multiOrgAddUserLoading, jobListResult, activeNode} = this.props;
    const jobOptions = (jobListResult && jobListResult.map(item => ({ label: item.name, value: item.id }))) || [];
    jobOptions.unshift({ label: '无', value: '' });
    return (
      <Dialog className={classes.dialogContainer} show onHide={onHide} title={'添加用户'}
        positiveButton={{
          onClick: this.onSubmit,
          loading: multiOrgAddUserLoading,
          disabled: (!selectedUser || !orgId)
        }}
        negativeButton={{ onClick: onHide, disabled: multiOrgAddUserLoading }}
      >
        <div className={classes.formContainer}>
          <div className={classes.userContainer}>
            <span>用户</span>
            <div className={classes.searchDiv}>
              <SelectSearch defaultValue='请输入姓名' onResult={this.onSelectUserWhenAdd}
                width={372} onClear={this.onSelectUserCleared} />
            </div>
          </div>
          <div className={classes.orgContainer}>
            <span className={classes.spanOrg}>所属单位组织/机构</span>
            <div className={classes.selectOrg}>
              <DropdownOrgTreePicker expandRoot selectNodeCallback={this.onTreeNodeSelected}
                fromFlag={MULTI_USER} notAcceptCallback={this.isRoot}
                relatedNode={activeNode && parseInt(activeNode.orgType) === 2 ? activeNode : undefined} />
            </div>
          </div>
          <div className={classes.jobContainer}>
            <span className={classes.spanJob}>职务</span>
            <div className={classes.selectJob}>
              <SelectInput placeholder={'请选择'} simpleValue maxSize={220}
                style={{height: '42px'}}
                value={selectJob} options={jobOptions} onChange={this.onChangeSelect} />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

MultiOrgUserForm.propTypes = {
  onHide: PropTypes.func,
  activeNode: PropTypes.object,
  queryUsers: PropTypes.func,
  jobListResult: PropTypes.array
};

const mapActionCreators = {
  multiOrgAddUser,
  queryUsers
};

const mapStateToProps = (state) => ({
  multiOrgAddUserLoading: state.organization.multiOrgAddUserLoading,
  multiOrgAddUserLoaded: state.organization.multiOrgAddUserLoaded,
  multiOrgAddUserError: state.organization.multiOrgAddUserError
});

export default connect(mapStateToProps, mapActionCreators)(MultiOrgUserForm);
