/**
 * Created by liuzhijia on 2017/7/18.
 * 批量导入班主任/辅导员
 */
import ExcelImportView from 'components/ExcelImportView';
import { connect } from 'react-redux';
import { batchImportCounselor } from '../modules/user';
import { loadOrgRoot } from '../modules/organization';

const TIPS = [
  '1. 姓名：必填项，不得超过40字。', '',
  '2. 学工号：必填项，不得超过20个字符。', '',
  '3. 导入类型：班主任、辅导员。'
];

const TEMPLATE_TITLE = [
  '姓名', '学工号', '导入类型', '1级组织-编号', '1级组织-名称', '2级组织-编号', '2级组织-名称', '3级组织-编号', '3级组织-名称',
  '4级组织-编号', '4级组织-名称', '5级组织-编号', '5级组织-名称', '6级组织-编号', '6级组织-名称', '7级组织-编号', '7级组织-名称'
];

const LOADING_TIP = '参考时间：2-3万的数据：20分钟，5-6万的数据：30分钟';

const ownProps = {
  name: '批量设置班主任/辅导员',
  templates: TEMPLATE_TITLE,
  templateFileUrl: `${__BASEPATH__}/tpl/班主任辅导员导入模板.xls`,
  tips: TIPS,
  loadingTips: LOADING_TIP
};

const mapActionCreators = {
  submit: batchImportCounselor,
  onFinish: loadOrgRoot
};

const mapStateToProps = (state) => ({
  importMsg: state.user.updateCounselorMsg,
  importErrmsg: state.user.updateCounselorError,
  ...ownProps
});

export default connect(mapStateToProps, mapActionCreators)(ExcelImportView);
