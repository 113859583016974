import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { getErrorCallback, getHomeRoute } from '../routeUtils';

export const route = (store) => ({
  path: 'main',
  name: '主页',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => getHomeRoute(replace, '/statistics') }, // IndexRedirect
  childRoutes: [
    {
      path: 'noauth',
      name: '无权限',
      indexRoute: {
        name: '无权限',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const Component = require('./containers/NoAuth').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Main');
        }
      }
    }
  ]
});

export default route;

