import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '通用管理',
  path: 'general',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: {
    onEnter: (nextState, replace) => replace('/general/tips')
  },
  childRoutes: [
    {
      name: '小贴士',
      path: 'tips',
      indexRoute: {
        name: '小贴士',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules/tips').default;
            injectReducer(store, { key: 'tips', reducer });
            const Component = require('./containers/Tips').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'General');
        }
      },
      childRoutes: [
        {
          name: '添加小贴士',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/tips').default;
              injectReducer(store, { key: 'tips', reducer });
              const Component = require('./containers/TipsOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'General');
          }
        },
        {
          name: '编辑小贴士',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules/tips').default;
              injectReducer(store, { key: 'tips', reducer });
              const Component = require('./containers/TipsOperate').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'General');
          }
        }
      ]
    }
  ]
});

export default route;
