import DataResetView from '../components/DataResetView';
import { connect } from 'react-redux';
import { resetOrg, clearResetOrg } from '../modules/admin';
import { loadOrgRoot, queryUsers } from '../../Organization/modules/organization';

const mapActionCreators = {
  resetOrg,
  clearResetOrg,
  loadOrgRoot,
  queryUsers
};

const mapStateToProps = (state) => ({
  orgResetting: state.admin.orgResetting,
  orgReset: state.admin.orgReset,
  orgResetError: state.admin.orgResetError,
  rootLoaded: state.organization.rootLoaded,
  activeNode: state.organization.activeNode
});

export default connect(mapStateToProps, mapActionCreators)(DataResetView);
