/**
 * Created on 21/01/06.
 * 手动调整组织数据
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Select, Cascader, Button, Empty, Tooltip } from 'antd';
import { showErrorToast, showSuccessToast } from 'helpers/toastUtils';
import IconHeader from 'components/IconHeader';
import Dialog from 'components/Dialog';
import classes from './ManualUpdateOrg.scss';
import {
  getOrgDetail, deleteOrg,
  saveManualOrg, getOrgData, resetData
} from 'routes/Organization/modules/manualUpdateOrg';
import OrgTree from './OrgTree';
import { get, isEqual } from 'lodash';


const ManualUpdateOrg = (props) => {
  const { contentWidth, contentHeight, clouldOrg: data = [], selectedNode, treeData, push } = props;
  const { getOrgDetail, deleteOrg, saveManualOrg, getOrgData, resetData } = props;
  const { deleteOrgResult, saveOrgResult, deleteOrgError, saveOrgError } = props;

  const { Option } = Select;
  // InitData
  const [isShowDialog, setIsShowDialog] = useState(false); // 是否显示弹框
  const [dialogData, setDialogData] = useState({}); // 设置弹框内容
  const [cascaderOptions, setCascaderOptions] = useState([]); // 地端节点树联动数据
  const [dataResult, setDataResult] = useState([]); // 最终存储数据

  // Effect
  // resetData
  useEffect(() => {
    resetData();
  }, []);

  useEffect(() => {
    if (deleteOrgResult || saveOrgResult) {
      showSuccessToast('操作成功');
    }
  }, [deleteOrgResult, saveOrgResult]);

  useEffect(() => {
    if (deleteOrgError || saveOrgError) {
      showErrorToast(deleteOrgError || saveOrgError || '操作失败');
    }
  }, [deleteOrgError, saveOrgError]);

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }
    // 构建存储数据初始值
    const temp = data.map(x => Object.assign({}, { cloud_id: x.id, opt_type: '', local_id: '', error: '' }));
    setDataResult(temp);
  }, [data]); // 监听 需要操作的节点的详情数据

  useEffect(() => {
    if (!selectedNode) {
      setDataResult([]);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (!treeData || treeData.length === 0) {
      return;
    }
    const temp = handleCascaderOptions(treeData);
    setCascaderOptions(temp);
  }, [treeData]);


  // Event
  // 左侧树节点选中回调事件
  const onOrgNodeChecked = node => {
    return new Promise((resolve, reject) => {
      if (!node || !node.id) {
        return reject('node id is empty');
      }
      // 切换节点提示
      const hasOpeate = dataResult.filter(x => !!x.opt_type || !!x.local_id).length > 0;
      if (selectedNode && selectedNode.org_id !== node.id && hasOpeate) {
        setIsShowDialog(true);
        return setDialogData({
          key: 'selectedChange',
          onClick: () => getOrgDetail({ org_id: node.id }).then(() => resolve(setIsShowDialog(false)))
        });
      }
      return resolve(getOrgDetail({ org_id: node.id }));
    });
  };
  // 操作切换事件
  const onActionChange = ({index, item, value}) => {
    let errorMsg = +value === 1 ? '' : item.error; // 删除操作，置空错误消息
    const op = () => {
      const temp = Object.assign(item,
        {
          opt_type: value,
          local_id: +value === 1 ? undefined : item.local_id || '',
          error: errorMsg
        }
      );
      const newDataresult = dataResult.map(x => item.cloud_id === x.cloud_id ? temp : x);
      setIsShowDialog(false);
      return setDataResult(newDataresult);
    };
    // 删除选中提示
    if (+value === 1) {
      setIsShowDialog(true);
      return setDialogData({
        key: 'deleteSelected',
        onClick: () => op()
      });
    }
    op();
  };

  // 地端数据切换事件
  const onCascaderChange = ({value, selectedOptions, item}) => {
    // 选中值一样，不进行操作
    if (isEqual(value, item.local_id)) {
      return;
    }
    const oldItem = {...item};
    // 源头数据重复 检查
    let errorMsg = checkErrorMsg(value, item.opt_type);
    let sameDataCount = 0;
    let newDataresult = dataResult.map(x => {
      // 修改本行数据
      let temp = x;
      if (item.cloud_id === x.cloud_id) {
        temp = {...item, local_id: value, error: errorMsg};
      }
      // 计算选择同等数值的个数
      if (isEqual(temp.local_id, oldItem.local_id)) {
        sameDataCount += 1;
      }
      return temp;
    }).map(x => {
      // 修改同等数值的其他行数据，目的：清空error信息
      if (Array.isArray(x.local_id) && isEqual(x.local_id, oldItem.local_id) && sameDataCount === 1) {
        return {...x, error: ''};
      }
      return x;
    });
    setDataResult(newDataresult);
  };

  // 动态加载地端源头数据
  const onCascaderLoadData = (selectedOptions) => {
    const orgId = get([...selectedOptions].pop(), 'node.id') || '';
    if (!orgId) {
      return showErrorToast('参数错误(orgId)');
    }
    return getOrgData(orgId);
  };
  // 隐藏弹框事件
  const onHideDialog = () => {
    setDialogData({});
    setIsShowDialog(false);
  };
  // 删除已选,事件
  const onBatchDelete = () => {
    if (!selectedNode || !selectedNode.id) {
      return showErrorToast('没有找到选中的节点');
    }
    const orgId = selectedNode && selectedNode.id;
    setIsShowDialog(true);
    return setDialogData({
      key: 'delete',
      onClick: () => deleteOrg(orgId).then((res) => {
        const {errcode} = res;
        if (+errcode === 0) {
          resetData();
        }
        setIsShowDialog(false);
      })
    });
  };
  // 保存按钮事件
  const onSaveData = () => {
    // 数据检查
    if (!dataResult || !Array.isArray(dataResult) || dataResult.length === 0) {
      return showErrorToast('存储数据，格式有误！');
    }
    let newDataresult = [];
    dataResult.map(x => {
      let errorMsg = '';
      if (!x.opt_type || (+x.opt_type !== 1 && (!x.local_id || !x.local_id.length))) {
        errorMsg = '必填项';
      }
      newDataresult.push(Object.assign(x, {error: x.error || errorMsg}));
    });
    setDataResult(newDataresult);
    const checkRes = newDataresult.filter(x => x.error).length;
    if (checkRes > 0) {
      return;
    }
    // 处理数据
    const temp = dataResult.map(x => Object.assign({}, x,
      {local_id: Array.isArray(x.local_id) ? [...x.local_id].pop() : x.local_id}
    ));
    // 弹框提示
    setIsShowDialog(true);
    return setDialogData({
      key: 'saveConfirm',
      onClick: () => saveManualOrg({org_data: JSON.stringify(temp)}).then((res) => {
        const { errcode } = res;
        if (+errcode === 0) {
          onBackPage();
        }
        setIsShowDialog(false);
      })
    });
  };

  // 返回到组织页
  const onBackPage = () => {
    resetData();
    push('/user/organization');
  };


  // DataHandle
  // 处理地端选择框数据
  const handleCascaderOptions = (data) => {
    if (Array.isArray(data)) {
      return data.map(x => handleCascaderOptions(x));
    }
    const children = data.children;
    const haveChildren = Array.isArray(children) && children.length > 0 && children.filter(x => !!x).length > 0;
    const label = (
      <Tooltip title={data.name} placement='top'>
        <span className={classes.cascaderContainer}>{data.name}</span>
      </Tooltip>
    );
    return {
      value: data.id,
      label: label,
      loading: false,
      isLeaf: +data.child_list === 0,
      disabled: !data.disabled,
      node: data,
      children: haveChildren ? data.children.map(i => handleCascaderOptions(i)) : null
    };
  };

  // 检查错误信息
  const checkErrorMsg = (localId, optType) => {
    let errorMsg = '';
    if (Array.isArray(localId) && localId.length > 0 && +optType !== 1) {
      const checkLength = dataResult.filter(x => +x.opt_type !== 1 && isEqual(x.local_id, localId)).length;
      if (checkLength > 0) {
        errorMsg = '源头数据重复';
      }
    }
    return errorMsg;
  };


  // Render
  const renderOrgName = (data) => {
    return (
      data.map((item, index) =>
        <Tooltip title={item.path_name} placement='top' key={index}>
          <div className={classes.borderText}>
            {item.org_name}
          </div>
        </Tooltip>
      )
    );
  };

  const ACTION_HINT = `1). 删除：删除选中节点，组织中对应用户将移动到临时节点中。
2). 节点权限移交给：将该节点内的人员与目标节点内的人员合并去重，并且将之前直接指派给该节点的权限，赋予到目标节点上，移交完成后该节点将被删除。
3). 人员移动到：该节点解散并删除，人员全部移动到所选择的新节点内，该节点所具有的权限同时删除。`;

  const renderActionSel = data => {
    const ACTION_ENUM = {
      1: '删除',
      2: '节点权限移交给',
      3: '人员移动到'
    };
    return data.map((item, index) =>
      <div className={classes.selectContainer} key={'action-div' + index}>
        <Select style={{ width: '100%' }} placeholder='请选择' value={item.opt_type || undefined}
          onChange={(value) => onActionChange({index, item, value})}>
          { Object.keys(ACTION_ENUM).map(op => <Option key={'action' + op} value={op}>{ACTION_ENUM[op]}</Option>) }
        </Select>
      </div>);
  };

  const renderDialog = () => {
    const { key, onClick } = dialogData || {};
    if (!key) {
      return null;
    }
    // 结果数据 替换文案
    const [opreateCount, deleteCount] = [
      (dataResult || []).filter(x => +x.opt_type !== 1).length || 0,
      (dataResult || []).filter(x => +x.opt_type === 1).length || 0
    ];
    const actions = [
      {
        key: 'delete',
        content: `确定删除该节点吗？
        选中节点以及所有云端子节点将被删除，
        节点中对应用户将移动到临时节点中`
      },
      {
        key: 'selectedChange',
        content: `确定选择新的节点吗？
        切换后该节点已选择的配置将被重置`
      },
      {
        key: 'deleteSelected',
        content: `确定切换到删除吗？
        切换后该节点已选择的源头数据将被重置`
      },
      {
        key: 'saveConfirm',
        content: `确定调整吗？
        本次数据调整将调整 ${opreateCount} 个组织，删除 ${deleteCount} 个组织`
      }
    ];
    const { content } = actions.find(x => x.key === key) || {};
    return <Dialog show={isShowDialog && content} onHide={onHideDialog} content={content}
      title='提示' positiveButton={{ onClick: onClick }} negativeButton={{onClick: onHideDialog}} />;
  };

  const renderSyncOrigin = data => {
    return data.map((item, index) =>
      <div className={classes.selectContainer}>
        {item.local_id !== undefined && <Cascader options={cascaderOptions} loadData={onCascaderLoadData}
          onChange={(value, selectedOptions) => onCascaderChange({index, item, value, selectedOptions})}
          value={item.local_id || undefined}
          changeOnSelect
          displayRender={label => [...label].pop()}
          placeholder='请选择' style={{ width: '100%' }} />}
      </div>
    );
  };

  const renderErrorMsg = data => {
    return data.map((item, index) =>
      <div className={classes.selectContainer} style={{color: 'red'}}>
        {item.error}
      </div>
    );
  };

  return (
    <div className={'container content-container'} style={{width: contentWidth, height: contentHeight}}>
      <IconHeader iconClassName='organization' title='手动调整组织架构' />
      <div className={classes.contentContainer}>
        <div className={classes.leftContainer}>
          <IconHeader title='请选择' hint='显示云端、地端组织数据和临时组织节点，其中云端数据包括管理端手动创建和Excel导入的数据。' />
          <div className={classes.borderContainer}>
            <div className={classes.headerContainer}>
              组织架构
              <button type='button' className='btn btn-link'
                disabled={dataResult.length === 0} onClick={onBatchDelete}>删除已选</button>
            </div>
            <div className={classes.treeContainer} style={{ height: contentHeight - 300 }}>
              <OrgTree onTreeSelect={onOrgNodeChecked} />
            </div>
          </div>
        </div>

        <div className={classes.columnContainer}>
          <IconHeader title='已选择/云端组织架构' />
          { renderOrgName(data) }
        </div>

        <div className={classes.columnContainer}>
          <IconHeader title='操作' hint={ACTION_HINT} />
          { renderActionSel(dataResult) }
        </div>

        <div className={classes.columnContainer}>
          <IconHeader title='选择数据同步源头数据' />
          { renderSyncOrigin(dataResult) }
        </div>
        <div className={classes.columnContainer}>
          <IconHeader title='' />
          { renderErrorMsg(dataResult) }
        </div>
        { renderDialog() }
      </div>
      {!selectedNode && <div className={classes.emptyContainer}>
        <Empty description='请选择左侧的组织节点树进行操作' />
      </div>}
      <div className={classes.btnContainer}>
        <Button type='primary' className={classes.btnSave} onClick={onSaveData} > 保存 </Button>
        <Button onClick={onBackPage}> 取消 </Button>
      </div>
    </div>
  );
};

ManualUpdateOrg.propTypes = {
  contentHeight: PropTypes.number,
  contentWidth: PropTypes.number,
  clouldOrg: PropTypes.array,
  selectedNode: PropTypes.object,
  treeData: PropTypes.array,
  // methods
  getOrgDetail: PropTypes.func,
  deleteOrg: PropTypes.func,
  saveManualOrg: PropTypes.func,
  getOrgData: PropTypes.func,
  push: PropTypes.func,
  resetData: PropTypes.func,
  deleteOrgResult: PropTypes.string,
  saveOrgResult: PropTypes.string,
  deleteOrgError: PropTypes.string,
  saveOrgError: PropTypes.string
};

const mapActionCreators = {
  getOrgDetail,
  getOrgData,
  deleteOrg,
  push,
  saveManualOrg,
  resetData
};

const mapStateToProps = (state) => ({
  contentHeight: state.flexSize.contentHeight,
  contentWidth: state.flexSize.contentWidth,
  clouldOrg: state.manualUpdateOrg.getOrgDetailResult,
  selectedNode: state.manualUpdateOrg.selectedNode,
  treeData: state.manualUpdateOrg.justWorkOrgList,
  deleteOrgResult: state.manualUpdateOrg.deleteOrgResult,
  deleteOrgError: state.manualUpdateOrg.deleteOrgError,
  saveOrgResult: state.manualUpdateOrg.saveOrgResult,
  saveOrgError: state.manualUpdateOrg.saveOrgError
});

export default connect(mapStateToProps, mapActionCreators)(ManualUpdateOrg);
