export const CURRENT_YEAR = new Date().getFullYear();
export const YEARS = new Array(CURRENT_YEAR - 2010 + 1).fill(CURRENT_YEAR)
  .map((item, index) => (item - index) + '')
  .map((value) => ({ label: value, value }));

// 格式化整数
export const fixLenNum = (value) => {
  if (!value) {
    return '00';
  }
  let delValue = Number(value);
  if (delValue < 10) {
    return '0' + delValue;
  } else {
    return delValue;
  }
};

// 将秒数转化为时间格式 xx:xx:xx
// 回头将它优化成循环
// 另外看看moment能不能实现
export const secTransform = (seconds) => {
  let delSecond = Math.floor(seconds);
  if (!seconds || !delSecond) {
    return '00:00:00';
  }
  const second = delSecond % 60;
  const minutes = Math.floor(delSecond / 60);
  const minute = minutes % 60;
  const hour = Math.floor(minutes / 60);
  return `${fixLenNum(hour)}:${fixLenNum(minute)}:${fixLenNum(second)}`;
};
