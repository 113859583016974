/**
 * 账号管理
 */
import React from 'react';

export default {
  'account.title': '管理员绑定微哨',
  'account.notbind.text': '没有绑定微哨',
  'account.bind.button': '绑定微哨',
  'account.bind.tips': <span>
    1. 只可以绑定一个微哨用户。<br />
    2. 绑定成功后可用微哨的用户名和密码登录管理端。
  </span>,
  'account.bind.dialog.title': '绑定微哨',
  'account.delete.dialog.content': '确认删除已绑定微哨？'
};
