import PropTypes from 'prop-types';
import React from 'react';
import BsPagination from 'react-bootstrap/lib/Pagination';
import classes from './Pagination.scss';

class PageItem extends React.PureComponent {

  onClick = () => {
    const { value, active } = this.props;
    if (value === active) {
      return;
    }
    this.props.onClick(value);
  }

  render() {
    const { value, active } = this.props;
    return (
      <BsPagination.Item active={active === value} onClick={this.onClick}>
        {value}
      </BsPagination.Item>
    );
  }
}
PageItem.propTypes = {
  value: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

/**
 * 分页组件
 * @param align 格式 //center left right
 * @param pageIndex 当前页
 * @param pageCount 总页数
 * @param sidePages 当前页后预览页数
 * @param changePage 翻页回调函数
 * @constructor
 */
export class Pagination extends React.Component {

  state = {
    jumpPageNum: ''
  }

  onJumpInputChange = (e) => {
    let value = e.target.value;
    if (value && !isNaN(value)) {
      let num = parseInt(value);
      if (num < 1) {
        value = 1;
      }
      if (num > this.props.pageCount) {
        value = this.props.pageCount;
      }
    } else {
      value = '';
    }
    this.setState({ jumpPageNum: value });
  }

  search = (event) => {
    const inputValue = parseInt(this.state.jumpPageNum);
    if (event.keyCode === 13 || (event.type === 'click' && inputValue)) {
      this.setState({ jumpPageNum: '' }, () => {
        this.props.changePage(inputValue);
      });
    }
  }

  onChangePage = (page) => {
    this.setState({ jumpPageNum: '' }, () => {
      this.props.changePage(page);
    });
  }

  renderItems = () => {
    const { pageIndex, pageCount, sidePages } = this.props;
    const items = [];
    if (pageCount < 8) {
      for (let i = 1; i <= pageCount; i++) {
        items.push(
          <PageItem key={'p' + i} value={i} active={pageIndex} onClick={this.onChangePage} />
        );
      }
    } else {
      items.push(
        <PageItem key={'p1'} value={1} active={pageIndex} onClick={this.onChangePage} />
      );
      const sideNum = Math.ceil((sidePages - 1) / 2);
      if (pageIndex - sideNum > 2) {
        items.push(<BsPagination.Ellipsis key='ellipsis_prev' disabled />);
      }
      const start = Math.min(Math.max(pageIndex - sideNum, 2), pageCount - sidePages + 1);
      const end = Math.max(Math.min(pageIndex + sideNum, pageCount - 1), sidePages);
      for (let i = start; i <= end; i++) {
        items.push(
          <PageItem key={'p' + i} value={i} active={pageIndex} onClick={this.onChangePage} />
        );
      }
      if ((pageCount - 1) > (pageIndex + sideNum)) {
        items.push(<BsPagination.Ellipsis key='ellipsis_next' disabled />);
      }
      items.push(
        <PageItem key={'p' + pageCount} value={pageCount} active={pageIndex} onClick={this.onChangePage} />
      );
    }
    return items;
  }

  toPrev = () => {
    const { changePage, pageIndex } = this.props;
    this.setState({ jumpPageNum: '' }, () => {
      changePage(pageIndex - 1);
    });
  }

  toNext = () => {
    const { changePage, pageIndex } = this.props;
    this.setState({ jumpPageNum: '' }, () => {
      changePage(pageIndex + 1);
    });
  }

  render() {
    const { align, pageIndex, pageCount, isShowJumpPage, selfMargin } = this.props;
    let prev = pageIndex === 1 ? false : '上一页';
    let next = pageIndex === pageCount ? false : '下一页';
    if (pageCount <= 1) {
      return null;
    }
    let cssStyle = {
      textAlign: align || 'right'
    };
    const jumpPage = isShowJumpPage && <div className={classes.container}>
      <span className={classes.info}>跳转到</span>
      <input className={'form-control ' + classes.inputArea} type='text'
        value={this.state.jumpPageNum} onChange={this.onJumpInputChange} />
      <span className={'wsbtn-ghost wsbtn-ghost-primary wsbtn-small ' + classes.jump} onClick={this.search}>GO</span>
    </div>;
    const changeMargin = selfMargin ? classes.pager : '';
    return (
      <div className={classes.pageContainer} style={cssStyle}>
        <BsPagination bsClass={'pagination ' + changeMargin}>
          {prev && <BsPagination.Prev onClick={this.toPrev}>{prev}</BsPagination.Prev>}
          {this.renderItems()}
          {next && <BsPagination.Next onClick={this.toNext}>{next}</BsPagination.Next>}
        </BsPagination>
        {jumpPage}
      </div>
    );
  };
}

Pagination.propTypes = {
  align: PropTypes.string,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  sidePages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  isShowJumpPage: PropTypes.bool, // 标记是否显示跳转某页
  totalPage: PropTypes.number, // 总页数
  selfMargin: PropTypes.bool // 是否改变分页默认margin,引用修改后的css
};

Pagination.defaultProps = {
  sidePages: 5
};

export default Pagination;
