import PropTypes from 'prop-types';
import React from 'react';
import classes from './Note.scss';
import PopOver from 'components/PopOver';
import uuid from 'uuid';

export const Note = ({ text, style, hint, position }) => (
  <blockquote className={classes.quote} style={style || {}}>
    <p>{text}</p>
    {hint && <div className={classes.popOver}>
      <PopOver id={uuid.v4()} position={position || 'right'}>{hint}</PopOver></div>}
  </blockquote>
);

Note.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  hint: PropTypes.node,
  position: PropTypes.string
};

export default Note;
