/**
 * 通过国际化配置显示文本
 * 1.目前写死为中文；
 * 2.命名与 API 和 umi/local 一致，为了以后方便替换
 */
import PropTypes from 'prop-types';
import React from 'react';
import copywriting from '../../locals/zh-cn';

export const FormattedMessage = ({ id }) => {
  return (
    <span>{copywriting[id] || id || ''}</span>
  );
};

FormattedMessage.propTypes = {
  id: PropTypes.string.isRequired
};

export const formatMessage = ({ id }) => copywriting[id] || id || '';
