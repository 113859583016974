/**
 * Created by liming on 16/4/4.
 */
const FONT_FAMILY = '"Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif';

export function getTextWidth(text, fontSize, fontFamily = FONT_FAMILY) {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = 'normal ' + fontSize + 'px ' + fontFamily;
  const metrics = context.measureText(text);
  return metrics.width;
};

export function isTextFitInSize(text, fontSize, maxSize, fontFamily) {
  const textWidth = getTextWidth(text + '…', fontSize, fontFamily);
  return textWidth < maxSize;
};

export function findTextFitPosition(text, fontSize, maxSize, fontFamily = FONT_FAMILY) {
  let index = 0;
  for (; index < text.length; index++) {
    if (getTextWidth(text.substr(0, index) + '…', fontSize, fontFamily) > maxSize) {
      break;
    }
  }
  return index - 1;
}

// 获取指定长度字符串
export function getPropText(text, fontSize, maxSize, fontFamily = FONT_FAMILY) {
  const textWidth = getTextWidth(text, fontSize, fontFamily);

  if (textWidth < maxSize) {
    return text;
  }
  const fitTextPosition = findTextFitPosition(text, fontSize, maxSize, fontFamily);
  const fitText = text.substr(0, fitTextPosition) + '…';
  return fitText;
};

/**
 * 字符串模糊匹配是否包含给定搜索词，用于拼音搜索，在全拼中搜索简拼及全拼的一部分。
 * @param source Source string
 * @param query Query word
 * @returns 是否包含
 */
export function heuristicContains(source, query) {
  if (source === undefined || query === undefined) {
    return false;
  }
  const len = source.length;
  if (len === 0) {
    return query.length === 0;
  }
  let cursor = 0;
  for (const c of query) {
    let i = cursor;
    for (; i < len; i++) {
      if (source[i] === c) {
        cursor = i + 1;
        break;
      }
    }
    if (cursor !== i + 1) {
      return false;
    }
  }
  return true;
};


/**
 * 获取合法的URL。如果原始URL未包含http(s)协议头，则自动补上http://。
 * @param {string} url URL
 */
export function getValidUrl(url) {
  if (url && url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
    url = 'http://' + url;
  }
  return url;
};
