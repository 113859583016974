import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import Icon from 'components/Icon';

/**
 * 浮动气泡组件
 * positon:气泡小尖头方向 eg:'top', 'right', 'bottom', 'left'
 * title:气泡标题
 * id:唯一标识
 */
export const PopOver = ({ id, position = 'top', selfStyle, title = '', children }) => (
  <OverlayTrigger trigger={['hover', 'focus']} placement={position}
    overlay={<Popover title={title} id={id}>{children}</Popover>}>
    <Icon type='question' className='text-primary' style={selfStyle} />
  </OverlayTrigger>
);

PopOver.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.oneOf([ 'top', 'bottom', 'left', 'right' ]),
  title: PropTypes.string,
  selfStyle: PropTypes.object,
  children: PropTypes.any
};

export default PopOver;
