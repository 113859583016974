function useStorageSafely(type, cb) {
  try {
    const storage = window[type];
    return storage && cb(storage);
  } catch (e) {
    console.warn(e);
  }
  return null;
}

/**
 * 安全使用localStorage
 */
function safeLocalStorage(cb) {
  return useStorageSafely('localStorage', cb);
}

/**
 * 存储
 */
export function setData(key, value) {
  safeLocalStorage(storage => storage.setItem(key, value));
}

/**
 * 读取
 */
export function getData(key) {
  return safeLocalStorage(storage => storage.getItem(key));
}

/**
 * 删除
 */
export function clearData(key) {
  safeLocalStorage(storage => storage.removeItem(key));
}

/**
 * 增加存储监听
 */
export function addStorageListener(listener) {
  addEventListener('storage', listener);
}

/**
 * 移除存储监听
 */
export function removeStorageListener(listener) {
  removeEventListener('storage', listener);
}

/**
 * 检查数据是否发生变化
 * @param {Event} ev 存储事件。对于Web Storage，其类型应为{StorageEvent}
 * @param {string} key 存储项key
 */
export function isDataChanged(ev, key) {
  if (ev instanceof StorageEvent && ev.key === key) {
    return ev.oldValue !== ev.newValue;
  }
  return false;
}
