import PropTypes from 'prop-types';
import React from 'react';
import classes from './ExcelImportView.scss';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import { getOrgPathText } from 'helpers/orgUtils';

const OldTypeItem = ({ item, index, chkCss, itemClick }) => {
  const onClick = () => {
    itemClick(index, item.on);
  };

  return (
    <div className={classes.oldTypeItem}>
      <i className={'wsicon ' + chkCss} style={{marginTop: '-3px', cursor: 'pointer'}}
        onClick={onClick} />
      <AutoShrinkTextWithTip text={item.path_name} maxSize={70} fontSize={14} />
    </div>
  );
};

export class ContrastItem extends React.PureComponent {

  itemClick = (index, on) => {
    const { contrastItem } = this.props;
    this.props.contrastItemClick(contrastItem.uid, index, on);
  }

  renderOldType = () => {
    const { contrastItem } = this.props;
    return contrastItem.old_type.map((item, index) => {
      const chkCss = item.on ? 'wsicon-box-sel ' + classes.chksel : 'wsicon-box ' + classes.chk;
      return (
        <OldTypeItem item={item} index={index} chkCss={chkCss} itemClick={this.itemClick} />
      );
    });
  }

  renderNewType = () => {
    const { contrastItem } = this.props;
    const newType = contrastItem.new_type;
    let result = '';
    for (var i = 0, len = newType.length; i < len; i++) {
      if (i === len - 1) {
        result += newType[i].path_name;
      } else {
        result += newType[i].path_name + ',';
      }
    }
    return result;
  }

  render() {
    const { contrastItem, importResultFlag } = this.props;
    const isPostUser = importResultFlag === 'user';
    return (
      <tr>
        <td style={{width: '75px'}}>
          <AutoShrinkTextWithTip text={isPostUser ? contrastItem.name : contrastItem.job_name}
            maxSize={55} fontSize={14} />
        </td>
        <td style={{width: '100px'}}>
          <AutoShrinkTextWithTip
            text={isPostUser ? contrastItem.student_number : getOrgPathText(contrastItem.R_path_name)}
            maxSize={80} fontSize={14} />
        </td>
        <td style={{width: '125px'}}>
          <AutoShrinkTextWithTip text={contrastItem.post_name} maxSize={105} fontSize={14} />
        </td>
        <td>
          <div className={classes.oldTypeWarp}>
            {this.renderOldType()}
          </div>
        </td>
        <td style={{width: '155px'}}>
          <AutoShrinkTextWithTip text={this.renderNewType()} maxSize={135} fontSize={14} />
        </td>
      </tr>
    );
  }
}

ContrastItem.propTypes = {
  contrastItem: PropTypes.object,
  importResultFlag: PropTypes.string
};

export default ContrastItem;
