import PropTypes from 'prop-types';
import React from 'react';
import classes from './IconHeader.scss';
import PopOver from 'components/PopOver';
import classNames from 'classnames';
import Icon from 'components/Icon';

/**
 * 详情页带小图标的title
 * @param iconClassName 图标样式
 * @param title  标题
 * @param children 按钮内容
 * @constructor
 */
export const IconHeader = ({ iconClassName, title, hint, more, children, className }) => (
  <div className={classNames(classes.wrapper, className)}>
    <div className={classes.col}>
      {iconClassName && <Icon type={iconClassName} className={classes.icon} />}
      <span className={classes.title}>{title}</span>
      {
        hint && <PopOver id='header-hint' position='right' >
          <span style={{whiteSpace: 'pre-wrap'}}>{hint}</span>
        </PopOver>
      }
      {more}
    </div>
    <div className={classes.col} style={{textAlign: 'right'}}>{children}</div>
  </div>
);

IconHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hint: PropTypes.any,
  more: PropTypes.any,
  iconClassName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};

export default IconHeader;
