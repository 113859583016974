/**
 * 公共文案
 */
export default {
  'common.select.noresult': '无匹配的结果',

  // common error
  'common.error.auth.noUploadUrl': '学校配置错误，请联系微哨管理员！',
  'common.error.auth.notExist': '登录已失效，请重新登录！',

  // commom tpl
  'commom.tpl.import.chatmanager': '微哨群管理员导入模板',
  'commom.tpl.import.adhoc': '微哨非常设机构导入模板',
  'commom.tpl.import.adminOrg': '微哨行政组织架构用户导入模板',
  'commom.tpl.import.otherOrg': '微哨非行政组织架构用户导入模板',
  'commom.tpl.import.post.user': '微哨岗位导入更新模板_用户',
  'commom.tpl.import.post.job': '微哨岗位导入更新模板_职务',
  'commom.tpl.import.job': '微哨职务导入模板',
  'commom.tpl.import.label': '微哨标签导入模板',
  'commom.tpl.update.adminOrg': '微哨行政组织架构用户更新模板',
  'commom.tpl.update.otherOrg': '微哨非行政组织架构用户更新模板',
  'commom.tpl.replace.post.user': '微哨岗位全部替换模板_用户',
  'commom.tpl.replace.post.job': '微哨岗位全部替换模板_职务',
  'commom.tpl.import.smartQuestion': '微哨智能问答知识库导入模板'
};
