import PropTypes from 'prop-types';
import React from 'react';
import ErrorBoundary from 'components/ErrorView/ErrorBoundary';
import classNames from 'classnames';
import { LocaleProvider } from 'antd';
import locale from 'antd/lib/locale-provider/zh_CN';

import classes from './CoreLayout.scss';
import 'styles/core.scss';

const CoreLayout = ({ children }) => (
  <LocaleProvider locale={locale}>
    <div className={classNames('container', classes.root)}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </div>
  </LocaleProvider>
);

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default CoreLayout;
