import ModuleLayout from 'layouts/ModuleLayout';
// import RouteLayout from 'layouts/RouteLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback } from '../routeUtils';

export const route = (store) => ({
  name: '第三方门户管理',
  path: 'thirdparty',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) => replace('/thirdparty/qywx') }, // IndexRedirect
  childRoutes: [
    {
      name: '企业微信',
      path: 'qywx',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules/bindWechat').default;
          injectReducer(store, { key: 'wechat', reducer });
          const Component = require('./containers/BindQywx').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'ThirdParty');
      }
    },
    {
      name: 'QQ智慧校园',
      path: 'qq',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules/bindQQ').default;
          injectReducer(store, { key: 'qq', reducer });
          const Component = require('./containers/BindQQ').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'ThirdParty');
      }
    },
    {
      name: '微信服务号',
      path: 'fwh',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules/bindFwh').default;
          injectReducer(store, { key: 'bindFwh', reducer });
          const Component = require('./containers/BindFwh').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'ThirdParty');
      }
    },
    {
      name: '钉钉',
      path: 'dingtalk',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules/bindDingtalk').default;
          injectReducer(store, { key: 'dingtalk', reducer });
          const Component = require('./containers/BindDingtalk').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'ThirdParty');
      }
    },
    {
      name: '易班',
      path: 'yiban',
      getComponent(nextState, callback) {
        require.ensure([], (require) => {
          const reducer = require('./modules/bindYiban').default;
          injectReducer(store, { key: 'yiban', reducer });
          const Component = require('./containers/BindYiban').default;
          callback(null, Component);
        }, getErrorCallback(callback), 'ThirdParty');
      }
    }
  ]
});

export default route;
