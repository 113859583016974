import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import classes from './AnnouncementNotice.scss';
import { getTextWidth } from 'helpers/textUtils';
import { getData, setData } from 'helpers/storageUtils';
import Icon from 'components/Icon';

export class AnnouncementNotice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iconright: 0,
      oldtime: getData('oldtime'),
      newtime: getData('newtime')
    };
  }

  componentWillMount() {
    let path = location.pathname;
    let arr = path.split('/');
    if (arr[1] === 'announcement') {
      let newtime = getData('newtime');
      setData('oldtime', Number(newtime));
      this.setState({
        oldtime: getData('oldtime')
      });
    }
    const width = getTextWidth(this.props.title, 16 + 'px');
    if (width > 288) {
      this.setState({
        iconright: width
      });
    } else {
      this.setState({
        iconright: width + 60
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const {lastTime} = newProps;
    if (lastTime && lastTime !== this.props.lastTime) {
      const newTime = getData('newtime');
      if (lastTime > newTime) {
        setData('oldtime', lastTime);
        setData('newtime', lastTime);
        this.setState({
          oldtime: lastTime,
          newtime: lastTime
        });
      }
    }
  }

  onAnnouncementClick = () => {
    let newtime = getData('newtime');
    setData('oldtime', Number(newtime));
    this.setState({
      oldtime: getData('oldtime')
    });
    let path = location.pathname;
    let arr = path.split('/');
    if (arr[1] === 'announcement' && arr[2] !== '1' && !arr[3]) {
      this.props.push('announcement/' + Number(arr[2]));
    } else {
      this.props.push('announcement/1');
    }
  }

  render() {
    const right = this.state.iconright;
    let isShow = this.state.newtime - this.state.oldtime > 0;
    const tooltip = (<Tooltip><div>系统公告</div></Tooltip>);
    return (
      <div className={classes.anTipContainer} style={{ right: right + 'px' }}>
        <div className={classes.anTip}>
          <OverlayTrigger placement='bottom' overlay={tooltip}>
            <Icon className={classes.noticeIcon} type='notification' onClick={this.onAnnouncementClick} />
          </OverlayTrigger>
          {isShow && <span />}
        </div>
      </div>
    );
  }
}

AnnouncementNotice.propTypes = {
  lastTime: PropTypes.string,
  title: PropTypes.string,
  push: PropTypes.func
};

const mapActionCreators = {
  push
};

const mapStateToProps = (state) => ({
  lastTime: state.announceList && state.announceList.lastTime
});

export default connect(mapStateToProps, mapActionCreators)(AnnouncementNotice);
