/**
 * Created by liming on 16/7/12.
 */
import { YEARS } from 'helpers/dateTimeUtils';

export const USER_IDENTITY_TEACHER = '教职工';
export const USER_IDENTITY_STUDENT = '学生';
export const USER_IDENTITY_OTHER = '其他';
export const ORG_TYPE_NONE = '无';
export const ORG_TYPE_MAJOR = '院系';
export const ORG_TYPE_CLASS = '班级';
export const ORG_TYPE_OMG = '行政单位';
export const ORG_TYPE_SPECIALTY = '专业';
export const SPECIAL_CHARS = '!@#$%^&*()_+=- /.,<>?:;"\'\\{}|[]~`';

export const CUSTOM_GRADE = 'grade';
export const CUSTOM_JOB = 'job';
export const CUSTOM_STATUS = 'status';
export const CUSTOM_ACTIVE = 'active';

/* tab卡 */
export const USERLABELS = [
  '学生',
  '教职工',
  '其他'
];

/* 用户添加类型 */
export const USERTYPES = [
  'student',
  'teacher',
  'other'
];

export const QUERY_ROLE_OPTIONS = [
  {label: '身份(全部)', value: ''},
  {label: USER_IDENTITY_TEACHER, value: 'teacher'},
  {label: USER_IDENTITY_STUDENT, value: 'student'},
  {label: USER_IDENTITY_OTHER, value: 'other'}
];

export const SEX = [
  {label: '性别(全部)', value: ''},
  {label: '女', value: 'girl'},
  {label: '男', value: 'boy'}
];

export const QUERY_STATUS_OPTIONS = [
  {label: '状态(全部)', value: ''},
  {label: '启用', value: '1'},
  {label: '禁用', value: '2'},
  {label: '已毕业', value: '9'}
];

export const ACTIVE_OPTIONS = [
  {label: '全部', value: ''},
  {label: '已激活', value: '1'},
  {label: '未激活', value: '2'}
];

export const GRADE = [{label: '全部', value: ''}].concat(YEARS);

export const POLITICAL_STATUS = [
  {label: '政治面貌(全部)', value: ''},
  {label: '中共党员', value: '01'},
  {label: '中共预备党员', value: '02'},
  {label: '共青团员', value: '03'},
  {label: '民革会员', value: '04'},
  {label: '民盟盟员', value: '05'},
  {label: '民建会员', value: '06'},
  {label: '民进会员', value: '07'},
  {label: '农工党党员', value: '08'},
  {label: '致公党党员', value: '09'},
  {label: '九三学社社员', value: '10'},
  {label: '台盟盟员', value: '11'},
  {label: '无党派民主人士', value: '12'},
  {label: '群众', value: '13'}
];
