import makeReducer from 'store/makeReducer';
import { API_ROOT } from 'helpers/ApiHelper';
import { writeAuthInfo, setSessionTime } from 'helpers/AuthHelper';
import { formatMessage } from 'components/FormattedMessage';
import { setData } from 'helpers/storageUtils';

// ------------------------------------
// Constants
// ------------------------------------
const LOCAL = 'whistlems/oauth/';
export const OAUTH_LOGIN_LOAD = LOCAL + 'OAUTH_LOGIN_LOAD';
export const OAUTH_LOGIN_SUCCESS = LOCAL + 'OAUTH_LOGIN_SUCCESS';
export const OAUTH_LOGIN_FAIL = LOCAL + 'OAUTH_LOGIN_FAIL';

// ------------------------------------
// Actions
// ------------------------------------
export const oauthLogin = (code, state) => {
  return {
    types: [OAUTH_LOGIN_LOAD, OAUTH_LOGIN_SUCCESS, OAUTH_LOGIN_FAIL],
    promise: (client) => client.post(API_ROOT + '/oauthLogin', {
      data: {
        code: code,
        state: state
      }
    })
  };
};

export const actions = {
  oauthLogin
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [OAUTH_LOGIN_LOAD]: (state, action) => {
    return {
      ...state,
      text: '正在登录，请稍候…',
      state: OAUTH_LOGIN_LOAD
    };
  },
  [OAUTH_LOGIN_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    const ssoFlag = action.ssoFlag;
    writeAuthInfo(data);
    const now = Date.now();
    setSessionTime(now);
    setData('newtime', data.notice_last_create_time);
    return {
      ...state,
      ssoMsgLoading: false,
      text: '登录成功，正在跳转…',
      ssoMsgSuccess: ssoFlag,
      state: OAUTH_LOGIN_SUCCESS
    };
  },
  [OAUTH_LOGIN_FAIL]: (state, action) => {
    const err = action.payload;
    let msg;
    switch (err.errcode) {
      case 500:
        msg = '代理服务异常，请联系管理员！';
        break;
      case 10002:
        msg = '参数错误，请联系管理员！';
        break;
      case 20001:
        msg = '账号或密码错误。';
        break;
      case 20002:
      case 20004:
        msg = formatMessage({ id: 'login.error.account.cannotLogin' });
        break;
      case 20011:
        msg = formatMessage({ id: 'login.error.account.disabled' });
        break;
      case 20013:
        msg = '验证码错误。';
        break;
      case 20014:
        msg = '验证码失效。';
        break;
      case 20015:
        msg = '登录超时，请重试。';
        break;
      default:
        msg = err.errmsg || '网络错误，请联系管理员！';
        break;
    }
    return {
      text: msg,
      state: OAUTH_LOGIN_FAIL,
      errorCode: err.errcode
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = [];
const schoolListReducer = makeReducer(ACTION_HANDLERS, initialState);
export default schoolListReducer;
