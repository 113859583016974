import makeReducer from '../makeReducer';
import { clearAuthInfo, clearUserQuery } from 'helpers/AuthHelper';
// ------------------------------------
// Constants
// ------------------------------------
export const RELOGIN = 'whistlems/common/auth/RELOGIN';

// ------------------------------------
// Actions
// ------------------------------------
export function relogin(relogin = initialState) {
  return {
    type: RELOGIN,
    payload: relogin
  };
};


export const actions = {
  relogin
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RELOGIN]: (state, action) => {
    clearAuthInfo();
    clearUserQuery();
    return action.payload;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  flag: false,
  code: 0
};

const reloginReducer = makeReducer(ACTION_HANDLERS, initialState);
export default reloginReducer;
