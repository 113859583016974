import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import classes from './ExpandableList.scss';

export class ItemBody extends React.Component {
  getStyle() {
    return {
      maxHeight: this.props.maxHeight,
      overflow: this.props.overflow,
      transition: `max-height ${this.props.duration}ms ease`
    };
  }

  render() {
    return (
      <div aria-labelledby={`expandable-list-item-title-${this.props.uuid}`}
        className={classNames(classes.list_item_body, this.props.className)}
        id={`expandable-list-item-body-${this.props.uuid}`}
        style={this.getStyle()}>
        <div className={classes.list_item_body_wrapper}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ItemBody.propTypes = {
  className: PropTypes.string,
  maxHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  duration: PropTypes.number,
  overflow: PropTypes.string,
  uuid: PropTypes.string,
  children: PropTypes.element
};

export default ItemBody;
