/**
 * Created by liming on 16/6/14.
 */
import makeReducer from 'store/makeReducer';
import uuid from 'uuid';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import { RELOGIN } from 'store/modules/reloginService';
import { LOGOUT_SUCCESS } from '../../Login/modules/strings';
import ApiClient, { formatUrl } from 'helpers/ApiClient';
import { getOrgPathText } from 'helpers/orgUtils';
// ------------------------------------
// Constants
// ------------------------------------

const _SUCCESS = '_SUCCESS';
const _FAIL = '_FAIL';

// 岗位列表
export const POSITION_LIST = 'whistlems/position/POSITION_LIST';
export const POSITION_LIST_SUCCESS = 'whistlems/position/POSITION_LIST_SUCCESS';
export const POSITION_LIST_FAIL = 'whistlems/position/POSITION_LIST_FAIL';

// 新建岗位
export const POST_ADD = 'whistlems/position/POST_ADD';
export const POST_ADD_SUCCESS = 'whistlems/position/POST_ADD_SUCCESS';
export const POST_ADD_FAIL = 'whistlems/position/POST_ADD_FAIL';

// 岗位删除
export const POST_DELETE = 'whistlems/position/POST_DELETE';
export const POST_DELETE_SUCCESS = 'whistlems/position/POST_DELETE_SUCCESS';
export const POST_DELETE_FAIL = 'whistlems/position/POST_DELETE_FAIL';

// 重命名
export const POST_MODIFY = 'whistlems/position/POST_MODIFY';
export const POST_MODIFY_SUCCESS = 'whistlems/position/POST_MODIFY_SUCCESS';
export const POST_MODIFY_FAIL = 'whistlems/position/POST_MODIFY_FAIL';

export const POST_USER_ADD = 'whistlems/position/POST_USER_ADD';
export const POST_USER_ADD_SUCCESS = 'whistlems/position/POST_USER_ADD_SUCCESS';
export const POST_USER_ADD_FAIL = 'whistlems/position/POST_USER_ADD_FAIL';

// 岗位指定人员删除，批量删除
export const POST_USER_DELETE = 'whistlems/position/POST_USER_DELETE';
export const POST_USER_DELETE_SUCCESS = 'whistlems/position/POST_USER_DELETE_SUCCESS';
export const POST_USER_DELETE_FAIL = 'whistlems/position/POST_USER_DELETE_FAIL';

// 岗位关联职务删除、批量删除
const POST_JOB_DELETE = 'whistlems/position/POST_JOB_DELETE';
const POST_JOB_DELETE_SUCCESS = POST_JOB_DELETE + _SUCCESS;
const POST_JOB_DELETE_FAIL = POST_JOB_DELETE + _FAIL;

export const POST_USER_LIST = 'whistlems/position/POST_USER_LIST';
export const POST_USER_LIST_SUCCESS = 'whistlems/position/POST_USER_LIST_SUCCESS';
export const POST_USER_LIST_FAIL = 'whistlems/position/POST_USER_LIST_FAIL';

export const IMPORT_POST_RELATION = 'whistlems/position/IMPORT_POST_RELATION';
export const IMPORT_POST_RELATION_SUCCESS = 'whistlems/position/IMPORT_POST_RELATION_SUCCESS';
export const IMPORT_POST_RELATION_FAIL = 'whistlems/position/IMPORT_POST_RELATION_FAIL';

export const IMPORT_RESULT_SUBMIT = 'whistlems/position/IMPORT_RESULT_SUBMIT';
export const IMPORT_RESULT_SUBMIT_SUCCESS = 'whistlems/position/IMPORT_RESULT_SUBMIT_SUCCESS';
export const IMPORT_RESULT_SUBMIT_FAIL = 'whistlems/position/IMPORT_RESULT_SUBMIT_FAIL';

export const IMPORT_REPLACE = 'whistlems/position/IMPORT_REPLACE';
export const IMPORT_REPLACE_SUCCESS = 'whistlems/position/IMPORT_REPLACE_SUCCESS';
export const IMPORT_REPLACE_FAIL = 'whistlems/position/IMPORT_REPLACE_FAIL';

// 岗位指定人员搜索
export const POST_USER_SEARCH = 'whistlems/position/POST_USER_SEARCH';
export const POST_USER_SEARCH_SUCCESS = POST_USER_SEARCH + _SUCCESS;
export const POST_USER_SEARCH_FAIL = POST_USER_SEARCH + _FAIL;

// 岗位关联职务搜索
export const POST_JOB_SEARCH = 'whistlems/position/POST_JOB_SEARCH';
export const POST_JOB_SEARCH_SUCCESS = POST_JOB_SEARCH + _SUCCESS;
export const POST_JOB_SEARCH_FAIL = POST_JOB_SEARCH + _FAIL;

// 编辑用户岗位信息
export const EDIT_USER_POST = 'whistlems/position/EDIT_USER_POST';
export const EDIT_USER_POST_SUCCESS = EDIT_USER_POST + _SUCCESS;
export const EDIT_USER_POST_FAIL = EDIT_USER_POST + _FAIL;

// 岗位关联职务列表
export const POST_JOB_LIST = 'whistlems/position/POST_JOB_LIST';
export const POST_JOB_LIST_SUCCESS = POST_JOB_LIST + _SUCCESS;
export const POST_JOB_LIST_FAIL = POST_JOB_LIST + _FAIL;

// 岗位关联职务添加
export const POST_JOB_ADD = 'whistlems/position/POST_JOB_ADD';
export const POST_JOB_ADD_SUCCESS = POST_JOB_ADD + _SUCCESS;
export const POST_JOB_ADD_FAIL = POST_JOB_ADD + _FAIL;

// 岗位关联职务编辑
export const POST_JOB_EDIT = 'whistlems/position/POST_JOB_EDIT';
export const POST_JOB_EDIT_SUCCESS = POST_JOB_EDIT + _SUCCESS;
export const POST_JOB_EDIT_FAIL = POST_JOB_EDIT + _FAIL;

// 岗位关联职务人数
export const POST_JOB_COUNT = 'whistlems/position/POST_JOB_COUNT';
export const POST_JOB_COUNT_SUCCESS = POST_JOB_COUNT + _SUCCESS;
export const POST_JOB_COUNT_FAIL = POST_JOB_COUNT + _FAIL;

// 岗位关联职务人员
export const POST_JOB_USERS = 'whistlems/position/POST_JOB_USERS';
export const POST_JOB_USERS_SUCCESS = POST_JOB_USERS + _SUCCESS;
export const POST_JOB_USERS_FAIL = POST_JOB_USERS + _FAIL;

export const SET_ACTIVE_NODE = 'whistlems/position/SET_ACTIVE_NODE';

export const CLEAR_USER_LIST = 'whistlems/position/CLEAR_USER_LIST';

export const ORG_POST_LIST = 'whistlems/position/ORG_POST_LIST';
export const ORG_POST_LIST_SUCCESS = ORG_POST_LIST + _SUCCESS;
export const ORG_POST_LIST_FAIL = ORG_POST_LIST + _FAIL;

export const USER_COUNT_PER_PAGE = 20; // 每页显示最大用户数
export const POSITION_LABEL = 'position';

// ------------------------------------
// Utils
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
/*
 {
 "id": "2087", //岗位id
 "post_name": "xx" //岗位名称
 "sort": "1", //排序值
 },
 */
// 获取岗位列表
export const getPositionList = () => {
  return {
    types: [POSITION_LIST, POSITION_LIST_SUCCESS, POSITION_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postList', {})
    })
  };
};

// 添加岗位
export const addPost = (name) => {
  return {
    types: [POST_ADD, POST_ADD_SUCCESS, POST_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postAdd', {
        name
      })
    })
  };
};

// 删除岗位
export const deletePost = (id) => {
  return {
    types: [POST_DELETE, POST_DELETE_SUCCESS, POST_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postDelete', {
        post_id: id
      })
    }),
    id
  };
};

// 岗位重命名
export const modifyPost = (id, name) => {
  return {
    types: [POST_MODIFY, POST_MODIFY_SUCCESS, POST_MODIFY_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postModify', {
        post_id: id,
        name
      })
    }),
    id,
    name
  };
};

/*
 post_id：岗位id （必填）
 org_id：组织节点id （必填）
 org_name：组织名称 （必填）
 username： uid（必填）
 student_number： 学工号  （必填）
 name： 名称  （必填）
 type: 普通组织：0， 非常设机构：1  （必填）
 */

export const addPostUser = (postId, org, user, orgIdDefault) => {
  return {
    types: [POST_USER_ADD, POST_USER_ADD_SUCCESS, POST_USER_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postUserAdd', {
        post_id: postId,
        org_id: org.id,
        org_name: org.name,
        username: user.username,
        student_number: user.student_number,
        name: user.name,
        type: org.type,
        org_id_default: orgIdDefault
      })
    })
  };
};

/*
 删除岗位指定人员

 传入的参数：
 userInfo: 对象数组[{postId, organization_id, uid},{...}, ...]

 接口参数：
 org_id：组织节点id数组，使用逗号分隔
 post_id：岗位id数组，使用逗号分隔
 username：uid的数组， 使用逗号分隔
 */
export const deletePostUser = (userInfo) => {
  const delLen = userInfo.length;
  const ids = userInfo.map(item => item.postId).join();
  const orgIdStrs = userInfo.map(item => item.organization_id).join();
  const userStrs = userInfo.map(item => item.uid).join();
  return {
    types: [POST_USER_DELETE, POST_USER_DELETE_SUCCESS, POST_USER_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postUserDelete', {
        post_id: ids,
        org_id: orgIdStrs,
        username: userStrs
      })
    }),
    delLen
  };
};

/*
 删除岗位关联职务

 传入的参数：
 userInfo: 对象数组[{jobId, postId, rangeOrgId, authType, manageOrgId, type},{...}, ...]

 接口参数：
 job_id：职务id数组，使用逗号分隔
 post_id：岗位id数组，使用逗号分隔
 range_orgId 职务所在范围id数组，使用逗号分隔
 auth_type  0 使用用户所在范围， 1 自定义范围 使用逗号分隔
 manage_orgId 管理范围 orgId 使用逗号分隔
 type 管理范围类型 0，组织， 1 非常设机构 使用逗号分隔
 */
export const deletePostJob = (postJobInfo) => {
  const delLen = postJobInfo.length;
  const jobIds = postJobInfo.map(item => item.jobId).join();
  const postIds = postJobInfo.map(item => item.postId).join();
  const rangeOrgIds = postJobInfo.map(item => item.rangeOrgId).join();
  const authTypes = postJobInfo.map(item => item.authType).join();
  const manageOrgIds = postJobInfo.map(item => item.manageOrgId).join();
  const types = postJobInfo.map(item => item.type).join();
  return {
    types: [POST_JOB_DELETE, POST_JOB_DELETE_SUCCESS, POST_JOB_DELETE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postJobDelete', {
        job_id: jobIds,
        post_id: postIds,
        range_orgId: rangeOrgIds,
        auth_type: authTypes,
        manage_orgId: manageOrgIds,
        type: types
      })
    }),
    delLen
  };
};

// 岗位关联人员列表
export const postUserList = (id, page, count) => {
  const pageParam = page || 1;
  const countParam = count || USER_COUNT_PER_PAGE;
  return {
    types: [POST_USER_LIST, POST_USER_LIST_SUCCESS, POST_USER_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postUserList', {
        post_id: id,
        page: pageParam,
        count: countParam
      })
    }),
    id,
    page: pageParam,
    count: countParam
  };
};

// 岗位关联职务列表
export const getPostJobList = (queryObj = {}) => {
  const id = queryObj && queryObj.id;
  const page = queryObj && queryObj.page || 1;
  const count = queryObj && queryObj.count || USER_COUNT_PER_PAGE;
  return {
    types: [POST_JOB_LIST, POST_JOB_LIST_SUCCESS, POST_JOB_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postJobList', {
        post_id: id,
        page,
        count
      })
    }),
    id,
    page,
    count
  };
};

export const importPostUser = (postId, file) => {
  const pathObj = formatParams('org', 'importPostUser', {file_length: file.size, post_id: postId});
  return (dispatch, getState) => {
    dispatch({
      type: IMPORT_POST_RELATION
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: IMPORT_POST_RELATION_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: IMPORT_POST_RELATION_FAIL,
          payload: err
        });
      });
  };
};

export const importUserResultSubmit = (sc, fc, url, data) => {
  return {
    types: [IMPORT_RESULT_SUBMIT, IMPORT_RESULT_SUBMIT_SUCCESS, IMPORT_RESULT_SUBMIT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'importUser', {
        success_count: sc,
        fail_count: fc,
        url,
        data: JSON.stringify(data)
      })
    })
  };
};

export const importPostJob = (postId, file) => {
  const pathObj = formatParams('org', 'importPostJob1', {file_length: file.size, post_id: postId});
  return (dispatch, getState) => {
    dispatch({
      type: IMPORT_POST_RELATION
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: IMPORT_POST_RELATION_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: IMPORT_POST_RELATION_FAIL,
          payload: err
        });
      });
  };
};

export const importJobResultSubmit = (sc, fc, url, data) => {
  return {
    types: [IMPORT_RESULT_SUBMIT, IMPORT_RESULT_SUBMIT_SUCCESS, IMPORT_RESULT_SUBMIT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'importPostJob2', {
        success_count: sc,
        fail_count: fc,
        url,
        data: JSON.stringify(data)
      })
    })
  };
};

export const importReplaceUser = (postId, file) => {
  const pathObj = formatParams('org', 'importReplaceUser', {file_length: file.size, post_id: postId});
  return (dispatch, getState) => {
    dispatch({
      type: IMPORT_REPLACE
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: IMPORT_REPLACE_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: IMPORT_REPLACE_FAIL,
          payload: err
        });
      });
  };
};

export const importReplaceJob = (postId, file) => {
  const pathObj = formatParams('org', 'importReplacePostJob', {file_length: file.size, post_id: postId});
  return (dispatch, getState) => {
    dispatch({
      type: IMPORT_REPLACE
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: IMPORT_REPLACE_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: IMPORT_REPLACE_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 岗位指定人员搜索
 * searchObj: { page, search_text, post_id }
 */
export const postUserSearch = (searchObj) => {
  if (!searchObj.page) {
    searchObj.page = 1;
  }
  return {
    types: [POST_USER_SEARCH, POST_USER_SEARCH_SUCCESS, POST_USER_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'globalSearch', {
        data_type: 2,
        count: USER_COUNT_PER_PAGE,
        ...searchObj
      })
    }),
    postSearchObject: searchObj
  };
};

/**
 * 岗位关联职务搜索
 * searchObj: { page, search_text, post_id, data_type }
 */
export const postJobSearch = (searchObj) => {
  if (!searchObj.page) {
    searchObj.page = 1;
  }
  return {
    types: [POST_JOB_SEARCH, POST_JOB_SEARCH_SUCCESS, POST_JOB_SEARCH_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'globalSearch', {
        data_type: 5,
        count: USER_COUNT_PER_PAGE,
        ...searchObj
      })
    }),
    postSearchObject: searchObj
  };
};

/**
 * 用户岗位信息编辑
 */
export const editUserPost = (stuNumber, postInfo) => {
  const delLen = JSON.parse(postInfo).length ? 0 : 1;
  return {
    types: [EDIT_USER_POST, EDIT_USER_POST_SUCCESS, EDIT_USER_POST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postUserEdit', {
        student_number: stuNumber,
        data: postInfo
      })
    }),
    delLen: delLen
  };
};

export const postJobAdd = (postId, job, range) => {
  const { org, authType, authRange } = range;
  return {
    types: [POST_JOB_ADD, POST_JOB_ADD_SUCCESS, POST_JOB_ADD_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postJobAdd', {
        post_id: postId,
        job_id: job.id,
        org_id: org.id,
        auth_type: authType,
        type: authType === 1 ? authRange.type : undefined,
        manage_orgId: authType === 1 ? authRange.id : undefined
      })
    })
  };
};

export const postJobEdit = (postId, job, oldRange, newRange) => {
  const { org, authType, authRange } = oldRange;
  const { org: newOrg, authType: newAuthType, authRange: newAuthRange } = newRange;
  return {
    types: [POST_JOB_EDIT, POST_JOB_EDIT_SUCCESS, POST_JOB_EDIT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postJobEdit', {
        post_id: postId,
        job_id: job.id,
        range_orgId_old: org.id,
        auth_type_old: authType,
        type_old: authType === 1 ? authRange.type : undefined,
        manage_orgId_old: authType === 1 ? authRange.id : undefined,
        range_orgId_new: newOrg.id,
        auth_type_new: newAuthType,
        type_new: newAuthType === 1 ? newAuthRange.type : undefined,
        manage_orgId_new: newAuthType === 1 ? newAuthRange.id : undefined
      })
    })
  };
};

export const resetJobCount = () => {
  return {
    type: POST_JOB_COUNT
  };
};
export const postJobCount = (jobId, orgId) => {
  return {
    types: [POST_JOB_COUNT, POST_JOB_COUNT_SUCCESS, POST_JOB_COUNT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postJobUserCount', {
        job_id: jobId,
        org_id: orgId
      })
    }),
    key: `${jobId}_${orgId}`
  };
};

export const postJobUsers = (jobId, orgId, page, count) => {
  return {
    types: [POST_JOB_USERS, POST_JOB_USERS_SUCCESS, POST_JOB_USERS_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'postJobgetUser', {
        job_id: jobId,
        org_id: orgId,
        page,
        count
      })
    })
  };
};

export const setActiveNode = (node) => {
  return {
    type: SET_ACTIVE_NODE,
    node
  };
};


export const clearUserList = () => {
  return {
    type: SET_ACTIVE_NODE
  };
};

export const orgPostList = (data) => {
  return {
    types: [ORG_POST_LIST, ORG_POST_LIST_SUCCESS, ORG_POST_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'orgPostList', {
        ...data
      })
    })
  };
};


export const actions = {
  getPositionList,
  addPost,
  deletePost,
  modifyPost,
  addPostUser,
  deletePostUser,
  postUserList,
  getPostJobList,
  setActiveNode,
  clearUserList,
  postUserSearch,
  editUserPost,
  postJobAdd,
  postJobEdit,
  postJobUsers,
  resetJobCount,
  postJobCount,
  orgPostList
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [POSITION_LIST]: (state, action) => {
    return {
      ...state,
      listing: true,
      listLoaded: undefined,
      listError: undefined,
      positionList: []
    };
  },
  [POSITION_LIST_SUCCESS]: (state, action) => {
    let list = action.payload.data;
    list = list.filter(item =>
      Number(item.id) !== 1002 && Number(item.id) !== 1001 && Number(item.id) !== 1000); // 1002是岗位“其他”的id，其他id均大于1002
    list.forEach((item) => {
      item.uuid = uuid.v4();
    });
    return {
      ...state,
      listing: undefined,
      listLoaded: true,
      positionList: list,
      listError: undefined
    };
  },
  [POSITION_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      listing: undefined,
      listLoaded: undefined,
      positionList: [],
      listError: action.payload.errmsg
    };
  },
  [POST_ADD]: (state, action) => {
    return {
      ...state,
      addPosting: true,
      addPostLoaded: undefined,
      addPostError: undefined
    };
  },
  [POST_ADD_SUCCESS]: (state, action) => {
    const newOrg = action.payload.data;
    newOrg.uuid = uuid.v4();
    const newList = [newOrg, ...state.positionList.slice(0)];
    return {
      ...state,
      addPosting: undefined,
      addPostLoaded: true,
      addPostError: undefined,
      positionList: newList
    };
  },
  [POST_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      addPosting: undefined,
      addPostLoaded: undefined,
      addPostError: action.payload
    };
  },
  [POST_DELETE]: (state, action) => {
    return {
      ...state,
      deletePosting: true,
      deletePostLoaded: undefined,
      deletePostError: undefined
    };
  },
  [POST_DELETE_SUCCESS]: (state, action) => {
    const id = action.id;
    const list = state.positionList;
    const index = list.findIndex(function(value, index, arr) {
      return value.id === id;
    });
    return {
      ...state,
      deletePosting: undefined,
      deletePostLoaded: true,
      deletePostError: undefined,
      positionList: [...list.slice(0, index), ...list.slice(index + 1)]
    };
  },
  [POST_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deletePosting: undefined,
      deletePostLoaded: undefined,
      deletePostError: action.payload
    };
  },
  [POST_MODIFY]: (state, action) => {
    return {
      ...state,
      modifyPosting: true,
      modifyPostLoaded: undefined,
      modifyPostError: undefined
    };
  },
  [POST_MODIFY_SUCCESS]: (state, action) => {
    const id = action.id;
    const list = state.positionList.slice(0);
    const item = list.find(function(value, index, arr) {
      return value.id === id;
    });
    item.name = action.name;
    item.uuid = uuid.v4();
    return {
      ...state,
      modifyPosting: undefined,
      modifyPostLoaded: true,
      positionList: list
    };
  },
  [POST_MODIFY_FAIL]: (state, action) => {
    return {
      ...state,
      modifyPosting: undefined,
      modifyPostLoaded: undefined,
      modifyPostError: action.payload
    };
  },
  [POST_USER_LIST]: (state, action) => {
    const newList = {list: [], total: 0};
    newList.query = {id: action.id, page: action.page, count: action.count};
    return {
      ...state,
      userListing: true,
      userListLoaded: undefined,
      userListError: undefined,
      userList: newList
    };
  },
  [POST_USER_LIST_SUCCESS]: (state, action) => {
    if (action.id !== state.userList.query.id
      || action.page !== state.userList.query.page && action.count !== state.userList.query.count) {
      return state;
    }
    const list = action.payload.data.list;
    list.forEach((item) => {
      item.uuid = uuid.v4();
      item.id = `${item.username}_${item.organization_id}`;
      item.path_name = getOrgPathText(item.path_name);
    });
    const obj = Object.assign({}, state.userList);
    obj.list = list;
    obj.total = action.payload.data.total;
    return {
      ...state,
      userListing: undefined,
      userListLoaded: true,
      userListError: undefined,
      userList: obj
    };
  },
  [POST_USER_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      userListing: undefined,
      userListLoaded: undefined,
      userListError: action.payload
    };
  },
  // 岗位指定人员搜索
  [POST_USER_SEARCH]: (state, action) => {
    const newList = {list: [], total: 0};
    return {
      ...state,
      userListing: true,
      userListLoaded: undefined,
      userListError: undefined,
      userList: newList
    };
  },
  [POST_USER_SEARCH_SUCCESS]: (state, action) => {
    const list = action.payload.data.list;
    list.forEach((item) => {
      item.uuid = uuid.v4();
      item.id = `${item.username}_${item.organization_id}`;
      item.path_name = getOrgPathText(item.path_name);
    });
    const obj = Object.assign({}, state.userList);
    obj.list = list;
    obj.post_list = action.payload.data.post_list;
    obj.job_list = action.payload.data.job_list;
    obj.total = action.payload.data.count;
    return {
      ...state,
      userListing: undefined,
      userListLoaded: true,
      userListError: undefined,
      userList: obj,
      postSearchObject: action.postSearchObject
    };
  },
  [POST_USER_SEARCH_FAIL]: (state, action) => {
    return {
      ...state,
      userListing: undefined,
      userListLoaded: undefined,
      userListError: action.payload
    };
  },
  [POST_JOB_LIST]: (state, action) => {
    return {
      ...state,
      postJobListLoading: true,
      postJobListLoaded: false,
      postJobListResult: undefined,
      postJobListErr: undefined
    };
  },
  [POST_JOB_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      postJobListLoading: false,
      postJobListLoaded: true,
      postJobListResult: action.payload.data,
      postJobListErr: undefined,
      postUserJobDataId: action.id
    };
  },
  [POST_JOB_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      postJobListLoading: false,
      postJobListLoaded: false,
      postJobListResult: undefined,
      postJobListErr: action.payload.errmsg
    };
  },
  // 岗位关联职务搜索
  [POST_JOB_SEARCH]: (state, action) => {
    return {
      ...state,
      postJobListLoading: true,
      postJobListLoaded: false,
      postJobListResult: undefined,
      postJobListErr: undefined
    };
  },
  [POST_JOB_SEARCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      postJobListLoading: false,
      postJobListLoaded: true,
      postJobListResult: action.payload.data,
      postJobListErr: undefined,
      postSearchObject: action.postSearchObject
    };
  },
  [POST_JOB_SEARCH_FAIL]: (state, action) => {
    return {
      ...state,
      postJobListLoading: false,
      postJobListLoaded: false,
      postJobListResult: undefined,
      postJobListErr: action.payload.errmsg
    };
  },
  [POST_USER_ADD]: (state, action) => {
    return {
      ...state,
      addUsering: true,
      addUserLoaded: undefined,
      addUserError: undefined
    };
  },
  [POST_USER_ADD_SUCCESS]: (state, action) => {
    const newUser = action.payload.data;
    newUser.uuid = uuid.v4();
    const userList = Object.assign({}, state.userList);
    userList.list = [newUser, ...userList.list.slice(0)];
    userList.total++;
    return {
      ...state,
      addUsering: undefined,
      addUserLoaded: true,
      addUserError: undefined,
      userList: userList
    };
  },
  [POST_USER_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      addUsering: undefined,
      addUserLoaded: undefined,
      addUserError: action.payload
    };
  },

  [POST_USER_DELETE]: (state, action) => {
    return {
      ...state,
      deleteUsering: true,
      deleteUserLoaded: undefined,
      deleteUserError: undefined,
      delLen: 0
    };
  },
  [POST_USER_DELETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      deleteUsering: undefined,
      deleteUserLoaded: true,
      deleteUserError: undefined,
      delLen: action.delLen
    };
  },
  [POST_USER_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deleteUsering: undefined,
      deleteUserLoaded: undefined,
      deleteUserError: action.payload,
      delLen: 0
    };
  },

  [POST_JOB_DELETE]: (state, action) => {
    return {
      ...state,
      deleteJobing: true,
      deleteJobLoaded: undefined,
      deleteJobError: undefined,
      delJobLen: 0
    };
  },
  [POST_JOB_DELETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      deleteJobing: undefined,
      deleteJobLoaded: true,
      deleteJobError: undefined,
      delJobLen: action.delLen
    };
  },
  [POST_JOB_DELETE_FAIL]: (state, action) => {
    return {
      ...state,
      deleteJobing: false,
      deleteJobLoaded: undefined,
      deleteJobError: action.payload,
      delJobLen: 0
    };
  },

  [IMPORT_POST_RELATION]: (state, action) => {
    return {
      ...state,
      importPostRelationLoading: true,
      importPostRelationLoaded: undefined,
      importPostRelationError: undefined
    };
  },
  [IMPORT_POST_RELATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      importPostRelationLoading: undefined,
      importPostRelationLoaded: true,
      importPostRelationError: undefined,
      importMsg: action.payload.body
    };
  },
  [IMPORT_POST_RELATION_FAIL]: (state, action) => {
    return {
      ...state,
      importPostRelationLoading: undefined,
      importPostRelationLoaded: undefined,
      importPostRelationError: action.payload
    };
  },

  [IMPORT_RESULT_SUBMIT]: (state, action) => {
    return {
      ...state
    };
  },
  [IMPORT_RESULT_SUBMIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      importMsg: action.payload
    };
  },
  [IMPORT_RESULT_SUBMIT_FAIL]: (state, action) => {
    return {
      ...state,
      importPostRelationError: action.payload
    };
  },

  [IMPORT_REPLACE]: (state, action) => {
    return {
      ...state
    };
  },
  [IMPORT_REPLACE_SUCCESS]: (state, action) => {
    return {
      ...state,
      importMsg: action.payload.body
    };
  },
  [IMPORT_REPLACE_FAIL]: (state, action) => {
    return {
      ...state,
      importPostRelationError: action.payload
    };
  },

  [SET_ACTIVE_NODE]: (state, action) => {
    return {
      ...state,
      activeNode: action.node
    };
  },

  [CLEAR_USER_LIST]: (state, action) => {
    return {
      ...state,
      userList: { list: [], total: 0 },
      userListing: undefined,
      userListLoaded: undefined,
      userListError: undefined
    };
  },
  [LOGOUT_SUCCESS]: (state, action) => {
    return initialState;
  },
  [RELOGIN]: (state, action) => {
    return initialState;
  },
  [EDIT_USER_POST]: (state, action) => {
    return {
      ...state,
      userPostEditing: true,
      userPostEditRs: undefined,
      userPostEditErr: undefined,
      delLen: 0
    };
  },
  [EDIT_USER_POST_SUCCESS]: (state, action) => {
    return {
      ...state,
      userPostEditing: false,
      userPostEditRs: action.payload.data,
      userPostEditErr: undefined,
      delLen: action.delLen
    };
  },
  [EDIT_USER_POST_FAIL]: (state, action) => {
    return {
      ...state,
      userPostEditing: false,
      userPostEditRs: undefined,
      userPostEditErr: action.payload.errmsg,
      delLen: 0
    };
  },
  [POST_JOB_ADD]: (state, action) => {
    return {
      ...state,
      postJobAdding: true,
      postJobAddError: undefined
    };
  },
  [POST_JOB_ADD_SUCCESS]: (state, action) => {
    return {
      ...state,
      postJobAdding: false,
      postJobAddError: undefined
    };
  },
  [POST_JOB_ADD_FAIL]: (state, action) => {
    return {
      ...state,
      postJobAdding: false,
      postJobAddError: action.payload.errmsg
    };
  },
  [POST_JOB_EDIT]: (state, action) => {
    return {
      ...state,
      postJobEditing: true,
      postJobEditError: undefined
    };
  },
  [POST_JOB_EDIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      postJobEditing: false,
      postJobEditError: undefined
    };
  },
  [POST_JOB_EDIT_FAIL]: (state, action) => {
    return {
      ...state,
      postJobEditing: false,
      postJobEditError: action.payload.errmsg
    };
  },
  [POST_JOB_USERS]: (state, action) => {
    return {
      ...state,
      postJobUsersLoading: true,
      postJobUsersResult: undefined,
      postJobUsersErr: undefined
    };
  },
  [POST_JOB_USERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      postJobUsersLoading: false,
      postJobUsersResult: action.payload.data,
      postJobUsersErr: undefined
    };
  },
  [POST_JOB_USERS_FAIL]: (state, action) => {
    return {
      ...state,
      postJobUsersLoading: false,
      postJobUsersResult: undefined,
      postJobUsersErr: action.payload.errmsg
    };
  },
  [POST_JOB_COUNT]: (state, action) => {
    return {
      ...state,
      postJobCountKey: action.key,
      postJobCountLoading: true,
      postJobCount: undefined,
      postJobCountError: undefined
    };
  },
  [POST_JOB_COUNT_SUCCESS]: (state, action) => {
    const {data} = action.payload;
    const key = `${data.job_id}_${data.org_id}`;
    if (key !== action.key || key !== state.postJobCountKey) {
      return state;
    }
    return {
      ...state,
      postJobCountKey: undefined,
      postJobCountLoading: false,
      postJobCount: parseInt(data.count),
      postJobCountError: undefined
    };
  },
  [POST_JOB_COUNT_FAIL]: (state, action) => {
    return {
      ...state,
      postJobCountKey: undefined,
      postJobCountLoading: false,
      postJobCount: undefined,
      postJobCountError: action.payload.errmsg
    };
  },
  [ORG_POST_LIST]: (state, action) => {
    return {
      ...state,
      orgPostListLoading: true,
      orgPostListLoaded: false,
      orgPostListError: undefined
    };
  },
  [ORG_POST_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      orgPostListLoading: false,
      orgPostListLoaded: true,
      orgPostListError: undefined,
      orgPostListResult: action.payload.data
    };
  },
  [ORG_POST_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      orgPostListLoading: false,
      orgPostListLoaded: false,
      orgPostListError: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  positionList: [],
  userList: { list: [], total: 0 }
};

const positionReducer = makeReducer(ACTION_HANDLERS, initialState);
export default positionReducer;
