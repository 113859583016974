import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import Icon from 'components/Icon';
import classes from './SelectSearch.scss';
import { connect } from 'react-redux';
import { initQuery, queryByWord, USER_SEARCH_SUCCESS, queryOrg } from 'store/modules/userSearch';
import { debounce } from 'lodash';
import { getOrgPathText } from 'helpers/orgUtils';

const theme = {
  container: 'react-autosuggest__container',
  containerOpen: 'react-autosuggest__container--open',
  input: 'react-autosuggest__input',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsContainer: 'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'react-autosuggest__suggestions-list',
  suggestion: 'react-autosuggest__suggestion',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title'
};

const initialState = {
  value: '',
  showQuery: true,
  suggestions: [],
  selected: void 0
};

const DEFAULT_WIDTH = 309;

export class SelectSearch extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.query = debounce(this.queryByWord, 300);
  }

  componentWillMount() {
    const { initQuery, value } = this.props;
    initQuery.call(this);
    if (value) {
      this.setState({ value, showQuery: false });
    }
  }

  componentWillReceiveProps(newProps) {
    const { initialValue } = this.props;
    if (newProps.searchState === USER_SEARCH_SUCCESS && newProps.searchState !== this.props.searchState) {
      this.setState({
        suggestions: newProps.searchValue
      });
    }
    if (initialValue && initialValue() && this.state.selected) {
      this.setState(initialState);
    }
  }

  queryByWord = (name) => {
    const {queryByWord, orgId, isOrg, queryOrg} = this.props;
    if (name && name.trim().length > 0) {
      if (isOrg) {
        queryOrg(name.trim());
      } else {
        queryByWord(name.trim(), orgId && orgId || 'civil'); // civil只查行政
      }
    } else {
      this.setState({
        suggestions: []
      });
    }
  }

  updateValue = (newValue) => {
    if (newValue !== this.state.value) {
      this.setState({
        value: newValue
      });
    }
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const options = (inputLength > 0 && this.state.suggestions) || [];
    return options;
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.name;
  }

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    this.setState({
      selected: suggestion,
      value: suggestion.name
    });
    const { onResult } = this.props;
    if (onResult) {
      onResult(suggestion);
    }
  }

  renderSuggestion = (suggestion) => {
    const { isShowFullPath, isOrg } = this.props;
    let pathName = suggestion.path_name;
    let maxSize = (this.props.width || DEFAULT_WIDTH) - 50;
    pathName = isShowFullPath ? getOrgPathText(pathName)
      : pathName.substring(pathName.lastIndexOf(',') + 1, pathName.length);
    return (
      <div className={classes.items}>
        <div className={classes.item}>
          <AutoShrinkTextWithTip
            text={isOrg ? suggestion.name : suggestion.name + '(' + suggestion.student_number + ')'}
            maxSize={maxSize} fontSize={14} showTips />
        </div>
        <div className={classes.orgname + ' ' + classes.item}>
          <AutoShrinkTextWithTip text={pathName} maxSize={maxSize} fontSize={14} showTips />
        </div>
      </div>
    );
  }

  /**
   * The hack is required here to prevent page navigation when enter is pressed without any typing.
   */
  onKeyDownHack = (e) => {
    if (e.keyCode === 13) { // Enter key
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onChange = (event, { newValue, method }) => {
    event.preventDefault();
    switch (method) {
      case 'type':
        if (newValue !== this.state.value) {
          this.updateValue(newValue);
          this.query(newValue);
        }
        break;
      case 'down':
      case 'up':
        // Apply built-in behaviors
        break;
      case 'escape':
        // Clear input
        this.updateValue('');
        break;
      case 'enter':
      case 'click':
        // Set value
        this.updateValue(newValue);
        break;
      default:
        break;
    }
  }

  onSuggestionsUpdateRequested = ({ value, reason }) => {
    if (reason === 'input-changed') {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onFocus = () => {
    this.setState({
      showQuery: false,
      value: '',
      suggestions: []
    });
  }

  onBlur = () => {
    if (this.state.selected) {
      this.updateValue(this.state.selected.name);
    } else {
      this.setState(initialState);
    }
  }

  clearHandler = () => {
    this.state.selected = void 0;
    this.setState(initialState);
    if (this.props.onClear) {
      this.props.onClear.call(this);
    }
  }

  render() {
    const { value, suggestions, selected, showQuery } = this.state;
    const { height, showSelected, defaultValue: propDefaultValue, width: propWidth,
      suggestionsContainerOpen, disabled } = this.props;
    let defaultValue = (propDefaultValue || '请输入');
    defaultValue = '       ' + defaultValue;
    const inputProps = {
      placeholder: showQuery ? defaultValue : '',
      type: 'text',
      value,
      disabled,
      onChange: this.onChange,
      onKeyDown: this.onKeyDownHack,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      style: {
        width: `${this.props.width || DEFAULT_WIDTH}px`,
        height: `${height || 42}px`,
        lineHeight: `${height || 42}px`,
        paddingLeft: '10px',
        paddingRight: !disabled && '24px' || '10px',
        color: '#333',
        border: '1px #cccccc solid'
      },
      className: classes.searchInput
    };
    const width = propWidth || DEFAULT_WIDTH;
    let showCross = value.length > 0;
    const iconStyle = height ? { top: (height - 22) / 2 + 2 + 'px' } : {};
    const crossIconStyle = height ? { top: (height - 16) / 2 + 2 + 'px' } : {};
    const actualTheme = {
      ...theme,
      suggestionsContainerOpen: suggestionsContainerOpen || theme.suggestionsContainerOpen
    };
    return (
      <div className={classes.container}>
        <div className={classes.container} style={{width: width + 'px'}}>
          {showQuery ? <Icon type='search' className={classes.searchicon} style={iconStyle} /> : ''}
          <Autosuggest suggestions={suggestions} highlightFirstSuggestion
            onSuggestionsFetchRequested={this.onSuggestionsUpdateRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            onSuggestionSelected={this.onSuggestionSelected}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps} theme={actualTheme} />
          {
            showCross && !disabled ? <Icon type='cross' className={classes.searchcross} style={crossIconStyle}
              onClick={this.clearHandler} /> : ''
          }
        </div>
        {
          showSelected && <div className={classes.hint} style={{width}}>
            <AutoShrinkTextWithTip text={selected ? '已选择：' + selected.name : '请选择'}
              fontSize={14} maxSize={width} />
          </div>
        }
      </div>
    );
  }
}

SelectSearch.propTypes = {
  searchState: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  defaultValue: PropTypes.string,
  onResult: PropTypes.func,
  onClear: PropTypes.func,
  initQuery: PropTypes.func.isRequired,
  queryByWord: PropTypes.func.isRequired,
  showSelected: PropTypes.bool,
  orgId: PropTypes.string,
  suggestionsContainerOpen: PropTypes.string,
  isShowFullPath: PropTypes.bool, // 是否显示全路径,
  initialValue: PropTypes.func, // 根据外部条件清空所选值
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isOrg: PropTypes.bool,
  queryOrg: PropTypes.func
};


const mapStateToProps = (state) => {
  const {searchState, searchValue} = state.userSearch;
  return {
    searchState, searchValue
  };
};
export default connect((mapStateToProps), {
  initQuery,
  queryByWord,
  queryOrg
})(SelectSearch);
