/**
 * Created by anguo on 2016/6/21.
 */
import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import ApiClient, { formatUrl } from 'helpers/ApiClient';
import { removeArrayElement } from 'helpers/arrayUtils';
import { POLITICAL_STATUS } from '../components/UserAddEditForm/data';

const PREFIX = 'whistlems/organization/user/';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';
const LOAD = '_LOAD';

export const USER_INSERT = PREFIX + 'USER_INSERT';
const USER_INSERT_LOAD = USER_INSERT + LOAD;
const USER_INSERT_SUCCESS = USER_INSERT + SUCCESS;
const USER_INSERT_FAIL = USER_INSERT + FAIL;

export const USER_UPDATE = PREFIX + 'USER_UPDATE';
const USER_UPDATE_LOAD = USER_UPDATE + LOAD;
const USER_UPDATE_SUCCESS = USER_UPDATE + SUCCESS;
const USER_UPDATE_FAIL = USER_UPDATE + FAIL;

export const BATCH_IMPORT = PREFIX + 'BATCH_IMPORT';
const BATCH_IMPORT_SUCCESS = BATCH_IMPORT + SUCCESS;
const BATCH_IMPORT_FAIL = BATCH_IMPORT + FAIL;

export const BATCH_UPDATE = PREFIX + 'BATCH_UPDATE';
const BATCH_UPDATE_SUCCESS = BATCH_UPDATE + SUCCESS;
const BATCH_UPDATE_FAIL = BATCH_UPDATE + FAIL;

export const BATCH_UPDATE_ORG = PREFIX + 'BATCH_UPDATE_ORG';
const BATCH_UPDATE_ORG_SUCCESS = BATCH_UPDATE_ORG + SUCCESS;
const BATCH_UPDATE_ORG_FAIL = BATCH_UPDATE_ORG + FAIL;

export const USER_INFO = PREFIX + 'USER_INFO';
const USER_INFO_LOAD = USER_INFO + LOAD;
const USER_INFO_SUCCESS = USER_INFO + SUCCESS;
const USER_INFO_FAIL = USER_INFO + FAIL;

export const ORG_POST_LIST = PREFIX + 'ORG_POST_LIST';
const ORG_POST_LIST_SUCCESS = ORG_POST_LIST + SUCCESS;
const ORG_POST_LIST_FAIL = ORG_POST_LIST + FAIL;

export const ORG_POST_PROPS_TO_DEL = PREFIX + 'ORG_POST_PROPS_TO_DEL';

const USER_LABEL = PREFIX + 'USER_LABEL';
const USER_LABEL_SUCCESS = USER_LABEL + SUCCESS;
const USER_LABEL_FAIL = USER_LABEL + FAIL;

const BATCH_IMPORT_COUNSELOR = PREFIX + 'BATCH_IMPORT_COUNSELOR';
const BATCH_IMPORT_COUNSELOR_SUCCESS = BATCH_IMPORT_COUNSELOR + SUCCESS;
const BATCH_IMPORT_COUNSELOR_FAIL = BATCH_IMPORT_COUNSELOR + FAIL;

const GET_USER_INFO = PREFIX + 'GET_USER_INFO';
const GET_USER_INFO_SUCCESS = GET_USER_INFO + SUCCESS;
const GET_USER_INFO_FAIL = GET_USER_INFO + FAIL;

// 组织结构树导出
const ORG_EXPORT = PREFIX + 'ORG_EXPORT';
const ORG_EXPORT_SUCCESS = ORG_EXPORT + SUCCESS;
const ORG_EXPORT_FAIL = ORG_EXPORT + FAIL;

/**
 * 用户添加
 * @param userInfo
 * @returns {{types: *[], promise: (function(): *), userInfo: *}}
 */
export const userSubmit = (userInfo) => {
  return {
    types: [USER_INSERT_LOAD, USER_INSERT_SUCCESS, USER_INSERT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userAdd', {
        ...userInfo
      })
    }),
    userInfo
  };
};
/**
 * 用户更新
 * @param userInfo
 * @returns {{types: *[], promise: (function(): *), userInfo: *}}
 */
export const userUpdateSubmit = (userInfo) => {
  return {
    types: [USER_UPDATE_LOAD, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userModify', {
        ...userInfo
      })
    }),
    userInfo
  };
};
/**
 * 用户导入
 * @param file
 * @returns {*|Request|Promise.<TResult>}
 */
export const batchImportSubmit = (file, sha256) => {
  const pathObj = formatParams('user', 'importAll', { file_length: file.size, sha: sha256 });
  return (dispatch, getState) => {
    dispatch({
      type: BATCH_IMPORT
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: BATCH_IMPORT_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: BATCH_IMPORT_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 用户导入非行政组织
 * @param file
 * @returns {*|Request|Promise.<TResult>}
 */
export const batchImportOtherSubmit = (file, sha256) => {
  const pathObj = formatParams('user', 'importOther', { file_length: file.size, sha: sha256 });
  return (dispatch, getState) => {
    dispatch({
      type: BATCH_IMPORT
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: BATCH_IMPORT_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: BATCH_IMPORT_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 用户批量更新
 * @param file
 * @returns {{types: *[], promise: (function(): *)}}
 */
export const batchUpdateSubmit = (file, sha256) => {
  const pathObj = formatParams('user', 'updateAll', { file_length: file.size, sha: sha256 });
  return (dispatch, getState) => {
    dispatch({
      type: BATCH_UPDATE
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: BATCH_UPDATE_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: BATCH_UPDATE_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 组织批量更新
 * @param file
 * @returns {{types: *[], promise: (function(): *)}}
 */
export const batchUpdateOrgSubmit = (file, sha256) => {
  const pathObj = formatParams('user', 'updateOrg', { file_length: file.size, sha: sha256 });
  return (dispatch, getState) => {
    dispatch({
      type: BATCH_UPDATE_ORG
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: BATCH_UPDATE_ORG_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: BATCH_UPDATE_ORG_FAIL,
          payload: err
        });
      });
  };
};

/**
 * 用户批量更新非行政组织
 * @param file
 * @returns {{types: *[], promise: (function(): *)}}
 */
export const batchUpdateOtherSubmit = (file, sha256) => {
  const pathObj = formatParams('user', 'updateOther', { file_length: file.size, sha: sha256 });
  return (dispatch, getState) => {
    dispatch({
      type: BATCH_UPDATE
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: BATCH_UPDATE_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: BATCH_UPDATE_FAIL,
          payload: err
        });
      });
  };
};

export const batchImportCounselor = (file) => {
  const pathObj = formatParams('org', 'importOrgTeacher', { file_length: file.size });
  return (dispatch, getState) => {
    dispatch({
      type: BATCH_IMPORT_COUNSELOR
    });
    ApiClient.uploadFile(file, formatUrl(API_ROOT), { params: pathObj }, null, null, true)
      .then(result => {
        dispatch({
          type: BATCH_IMPORT_COUNSELOR_SUCCESS,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: BATCH_IMPORT_COUNSELOR_FAIL,
          payload: err
        });
      });
  };
};

export const userInfoByStuNum = (stuNum, isgray, istop, seachOrgFlag) => {
  return {
    types: [USER_INFO_LOAD, USER_INFO_SUCCESS, USER_INFO_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'userInfo', {
        student_number: stuNum
      })
    }),
    stuNum,
    istop,
    isgray,
    seachOrgFlag
  };
};

export const getUserInfo = (uid) => {
  return {
    types: [GET_USER_INFO, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'getUserInfo', {
        uid
      })
    })
  };
};

/**
 * 获取用户对应的标签信息
 */
export const getUserLabel = (userName) => {
  return {
    types: [USER_LABEL, USER_LABEL_SUCCESS, USER_LABEL_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('label', 'getUserlabelInfo', {
        username: userName
      })
    })
  };
};

export const orgPostList = (userId) => {
  return {
    types: [ORG_POST_LIST, ORG_POST_LIST_SUCCESS, ORG_POST_LIST_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('org', 'userOrgPostList', {
        username: userId
      })
    }),
    userId
  };
};

export const orgPostPropsToDel = (obj) => {
  return {
    type: ORG_POST_PROPS_TO_DEL,
    obj
  };
};

export const exportOrg = (userInfo) => {
  return {
    types: [ORG_EXPORT, ORG_EXPORT_SUCCESS, ORG_EXPORT_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('user', 'exportOrg')
    })
  };
};

export const actions = {
  userSubmit,
  userUpdateSubmit,
  batchImportSubmit,
  batchUpdateSubmit,
  batchUpdateOtherSubmit,
  userInfoByStuNum,
  getUserLabel,
  // postList,
  orgPostList,
  orgPostPropsToDel,
  getUserInfo
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [USER_INSERT_LOAD]: (state, action) => {
    return {
      ...state,
      userInsertLoading: true,
      userInsertLoaded: false,
      userInsertError: undefined
    };
  },
  [USER_INSERT_SUCCESS]: (state, action) => {
    return {
      ...state,
      userInsertLoading: false,
      userInsertLoaded: true,
      userInsertError: undefined,
      userToInsert: action.userInfo
    };
  },
  [USER_INSERT_FAIL]: (state, action) => {
    return {
      ...state,
      userInsertLoading: false,
      userInsertLoaded: false,
      userInsertError: action.payload
    };
  },
  [USER_UPDATE_LOAD]: (state, action) => {
    return {
      ...state,
      userUpdateLoading: true,
      userUpdateLoaded: false,
      userUpdateError: undefined
    };
  },
  [USER_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      userUpdateLoading: false,
      userUpdateLoaded: true,
      userUpdateError: undefined,
      userToUpdate: action.userInfo,
      orgPostListResult: undefined
    };
  },
  [USER_UPDATE_FAIL]: (state, action) => {
    return {
      ...state,
      userUpdateLoading: false,
      userUpdateLoaded: false,
      userUpdateError: action.payload,
      orgPostListResult: undefined
    };
  },
  [BATCH_IMPORT]: (state, action) => {
    return {
      ...state,
      importUsering: true,
      importUserLoaded: false,
      importUserError: undefined
    };
  },
  [BATCH_IMPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      importUsering: false,
      importUserLoaded: true,
      importUserError: undefined,
      importMsg: action.payload.body
    };
  },
  [BATCH_IMPORT_FAIL]: (state, action) => {
    return {
      ...state,
      importUsering: false,
      importUserLoaded: false,
      importUserError: action.payload
    };
  },
  [BATCH_UPDATE]: (state, action) => {
    return {
      ...state,
      updateUsering: true,
      updateUserLoaded: false,
      updateUsertError: undefined
    };
  },
  [BATCH_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateUsering: false,
      updateUserLoaded: true,
      updateUserError: undefined,
      updateMsg: action.payload.body
    };
  },
  [BATCH_UPDATE_FAIL]: (state, action) => {
    return {
      ...state,
      updateUsering: false,
      updateUserLoaded: false,
      updateUserError: action.payload
    };
  },
  [BATCH_UPDATE_ORG]: (state, action) => {
    return {
      ...state,
      updateOrging: true,
      updateOrgLoaded: false,
      updateOrgError: undefined
    };
  },
  [BATCH_UPDATE_ORG_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateOrging: false,
      updateOrgLoaded: true,
      updateOrgError: undefined,
      updateMsg: action.payload.body
    };
  },
  [BATCH_UPDATE_ORG_FAIL]: (state, action) => {
    return {
      ...state,
      updateOrging: false,
      updateOrgLoaded: false,
      updateOrgError: action.payload
    };
  },
  [BATCH_IMPORT_COUNSELOR]: (state, action) => {
    return {
      ...state,
      updateCounselorLoading: true,
      updateCounselorLoaded: false,
      updateCounselorError: undefined
    };
  },
  [BATCH_IMPORT_COUNSELOR_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateCounselorMsg: action.payload.body,
      updateCounselorLoading: false,
      updateCounselorLoaded: true,
      updateCounselorError: undefined
    };
  },
  [BATCH_IMPORT_COUNSELOR_FAIL]: (state, action) => {
    return {
      ...state,
      updateCounselorLoading: false,
      updateCounselorLoaded: false,
      updateCounselorError: action.payload
    };
  },
  [USER_INFO_LOAD]: (state, action) => {
    return {
      ...state,
      userInfoLoading: true,
      userInfoError: undefined,
      userToStuNum: undefined
    };
  },
  [USER_INFO_SUCCESS]: (state, action) => {
    const newUserInfo = Object.assign({}, action.payload.data, {isgray: action.isgray}, {istop: action.istop},
      {seachOrgFlag: action.seachOrgFlag});
    return {
      ...state,
      userInfoLoading: false,
      userInfoError: undefined,
      // userToStuNum: action.payload.data
      userToStuNum: newUserInfo
    };
  },
  [USER_INFO_FAIL]: (state, action) => {
    return {
      ...state,
      userInfoLoading: false,
      userInfoError: action.payload,
      userToStuNum: undefined
    };
  },

  [USER_LABEL]: (state, action) => {
    return {
      ...state,
      userLabelLoading: true,
      userLabelError: undefined,
      userLabel: undefined
    };
  },
  [USER_LABEL_SUCCESS]: (state, action) => {
    return {
      ...state,
      userLabelLoading: false,
      userLabelError: undefined,
      userLabel: action.payload.data
    };
  },
  [USER_LABEL_FAIL]: (state, action) => {
    return {
      ...state,
      userLabelLoading: false,
      userLabelError: action.payload,
      userLabel: undefined
    };
  },
  [ORG_POST_LIST]: (state, action) => {
    return {
      ...state,
      orgPostListing: true,
      orgPostListResult: undefined,
      orgPostListError: undefined
    };
  },
  [ORG_POST_LIST_SUCCESS]: (state, action) => {
    const list = action.payload.data && action.payload.data.list;
    const newList = list.filter(item =>
      Number(item.post_id) !== 1002 && Number(item.post_id) !== 1001 && Number(item.post_id) !== 1000);
    return {
      ...state,
      orgPostListing: undefined,
      orgPostListResult: newList,
      orgPostListError: undefined
    };
  },
  [ORG_POST_LIST_FAIL]: (state, action) => {
    return {
      ...state,
      orgPostListing: undefined,
      orgPostListResult: undefined,
      orgPostListError: action.payload
    };
  },
  [ORG_POST_PROPS_TO_DEL]: (state, action) => {
    return {
      ...state,
      orgPostListing: undefined,
      orgPostListResult: removeArrayElement(state.orgPostListResult, action.obj)
    };
  },
  [GET_USER_INFO]: (state, action) => {
    return {
      ...state,
      userInfomationLoading: true,
      userInfomationLoaded: false,
      userInfomationResult: undefined,
      userInfomationErr: undefined
    };
  },
  [GET_USER_INFO_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    const result = data && data.result;
    const curYear = new Date().getFullYear();
    let contactList = [
      { cellphone: result && result.celphone1, cornet: result && result.phone_cornet1 },
      { cellphone: result && result.celphone2, cornet: result && result.phone_cornet2 },
      { cellphone: result && result.celphone3, cornet: result && result.phone_cornet3 }
    ];
    // contactList = contactList.filter(contact => contact.cellphone || contact.cornet);
    let schoolYear = result && result.school_year && parseInt(result.school_year);
    schoolYear = (schoolYear && schoolYear >= 2010 && schoolYear <= curYear) ? schoolYear : '';
    let sznj = result && result.sznj && parseInt(result.sznj);
    sznj = (sznj && sznj >= 2010 && sznj <= curYear) ? sznj : '';
    const zzmmValid = result && result.zzmm && POLITICAL_STATUS.find(item => item.value === result.zzmm);
    const formValues = {
      nameEn: result && result.name_en || '',
      studentNumber: result && result.student_number,
      cardNumber: result && result.card_number,
      cardType: result && result.card_type,
      examineeNumber: result && result.examinee_number,
      admissionNumber: result && result.admission_number,
      landlineCornet: result && result.landline_cornet,
      nation: result && result.mz,
      province: result && result.nativeplace_province,
      city: result && result.nativeplace_city,
      county: result && result.nativeplace_district,
      schoolYear: schoolYear,
      sznj: sznj,
      admissionType: result && result.admission_type,
      disabledState: result && result.status,
      studentStatus: result && result.user_type,
      teacherStatus: result && result.user_type,
      politicalStatus: (result && result.zzmm) ? (zzmmValid ? result.zzmm : '') : '',
      education: result && result.pycc,
      contactList: (contactList && contactList.length) ? contactList : [{}],
      wx: result && result.weixin_num,
      service_openid: result && result.service_openid,
      qq: result && result.qq_num
    };
    const returnObj = {
      ...data.result,
      post: data.post,
      label: data.label,
      user_job: data.user_job,
      ...formValues
    };
    return {
      ...state,
      userInfomationLoading: false,
      userInfomationLoaded: true,
      userInfomationResult: returnObj,
      userInfomationErr: undefined
    };
  },
  [GET_USER_INFO_FAIL]: (state, action) => {
    return {
      ...state,
      userInfomationLoading: false,
      userInfomationLoaded: false,
      userInfomationResult: undefined,
      userInfomationErr: action.payload.errmsg
    };
  },
  [ORG_EXPORT]: (state, action) => {
    return {
      ...state,
      orgExportLoading: true,
      orgExportResult: undefined,
      orgExportError: undefined
    };
  },
  [ORG_EXPORT_SUCCESS]: (state, action) => {
    return {
      ...state,
      orgExportLoading: false,
      orgExportResult: action.payload.data,
      orgExportError: undefined
    };
  },
  [ORG_EXPORT_FAIL]: (state, action) => {
    return {
      ...state,
      orgExportLoading: false,
      orgExportResult: undefined,
      orgExportError: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
const userReducer = makeReducer(ACTION_HANDLERS, initialState);
export default userReducer;
