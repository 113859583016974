'use strict';

module.exports = function () {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement('script');
    hm.src = '//hm.baidu.com/hm.js?f09b5bd0d2dd7395a86d4d6cbf46d373';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  })();
};
