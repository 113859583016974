import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import uuid from 'uuid';
import { isUndefined } from 'lodash';
import PopOver from 'components/PopOver';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import NoDataView from 'components/NoDataView';
import Pagination from 'components/Pagination';
import OperationWidget from './OperationWidget';
import classes from './Table.scss';
import ChkView from 'components/OrgTree/CheckViewItem';

const UP = 'up';
const DOWN = 'down';

const EmptyView = ({width, height, text, style}) => (
  <div className='empty' style={style || {}}>
    <NoDataView width={width} height={height} text={text} />
  </div>
);
EmptyView.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string,
  style: PropTypes.object
};

const ThHandle = ({key, item, sortField, sortType, onFieldSort}) => {
  const field = item && item.field || undefined;
  const onSort = () => {
    const type = field === sortField ? (sortType === UP ? DOWN : UP) : DOWN;
    item.handlerSort && item.handlerSort(field, type);
    onFieldSort(field, type);
  };
  const upClass = sortField === field && sortType === UP ? classes.sortActive : '';
  const downClass = sortField === field && sortType === DOWN ? classes.sortActive : '';
  return <Fragment>
    <span className={classes.sortText} style={item.handlerSort && {cursor: 'pointer'} || {}} onClick={onSort}>
      <span>{item.title}</span>
      {item.handlerSort && <span className={classes.sortContainer}>
        <i className={'wsicon wsicon-triangleup ' + classes.sortUp + ' ' + upClass} />
        <i className={'wsicon wsicon-triangledown ' + classes.sortDown + ' ' + downClass} />
      </span>}
    </span>
    {item.hint && <PopOver id={'hint_' + key}>{item.hint}</PopOver>}
  </Fragment>;
};

ThHandle.propTypes = {
  key: PropTypes.number,
  item: PropTypes.object,
  sortField: PropTypes.string,
  sortType: PropTypes.string,
  onFieldSort: PropTypes.func
};

/**
 * 固定的表头
 * checked: 是否为选中状态
 * clickEvent: 点击事件
 */
class TableHead extends React.Component {

  state = {
    checked: false
  }

  componentWillReceiveProps = (newProps) => {
    const { checked, data } = newProps;
    if (checked !== this.props.checked) {
      this.setState({ checked });
    }
    if (data && data !== this.props.data) {
      this.setState({ checked: false });
    }
  }

  clickEvent = () => {
    const { checked } = this.state;
    const { clickHandler, data } = this.props;
    this.setState({ checked: !checked });
    if (checked) {
      clickHandler([], !checked);
    } else {
      clickHandler(data, !checked);
    }
  }

  render() {
    const { meta, sortField, sortType, onFieldSort } = this.props;
    let { checked } = this.state;
    return (
      <thead>
        <tr>
          {
            meta.map((item, index) => {
              const key = uuid.v4();
              const style = {
                width: item.maxWidth,
                position: 'relative'
              };
              return (<th key={key} style={style}>
                <div className={classes.thTitle}>
                  {
                    item.hasChkBox
                      && <span className={classes.chkContainer}>
                        <ChkView item={{on: checked}} handleClick={this.clickEvent}
                          id='selAll' classes={classes} />
                      </span>
                  }
                  <ThHandle key={index} item={item} handlerSort={item.handlerSort}
                    sortField={sortField} sortType={sortType} onFieldSort={onFieldSort} />
                </div>
              </th>);
            })
          }
        </tr>
      </thead>
    );
  }
}
TableHead.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object).isRequired,
  clickHandler: PropTypes.func,
  data: PropTypes.array,
  checked: PropTypes.bool,
  sortField: PropTypes.string,
  sortType: PropTypes.string,
  onFieldSort: PropTypes.func
};

const TableBody = ({data, meta, chkClick, checkedItemIds}) => (
  <tbody>
    { data.map(item => <Row key={uuid.v4()} meta={meta} item={item} chkClick={chkClick}
      checkedItemIds={checkedItemIds} />) }
  </tbody>
);
TableBody.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chkClick: PropTypes.func,
  checkedItemIds: PropTypes.array
};

const Row = ({meta, item, chkClick, checkedItemIds}) => (
  <tr>
    {
      meta.map(column => (
        <td key={uuid.v4()} className={classes.theadClass}
          style={{width: column.maxWidth, textAlign: column.textAlign || 'left'}}>
          { getColumnData(column, item, column.hasChkBox, chkClick, checkedItemIds) }
        </td>
      ))
    }
  </tr>
);
Row.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.object.isRequired,
  chkClick: PropTypes.func,
  checkedItemIds: PropTypes.array
};

const getColumnData = (column, item, hasChkBox, chkClick, checkedItemIds) => {
  if (hasChkBox) {
    return (
      <Fragment>
        <div className={classes.chkItemContainer}>
          <ChkView item={{on: checkedItemIds.indexOf(item) !== -1}}
            handleClick={chkClick(item)} id={item} classes={classes} />
        </div>
        <div className={classes.chkTextContainer}>
          <AutoShrinkTextWithTip text={item[column.field] || null} maxSize={column.maxWidth - 40} fontSize={14}
            showTips />
        </div>
      </Fragment>
    );
  }
  if (column.ops) { // 交互型列
    let opArr = column.ops;
    if (column.customize) {
      // 个性化交互列
      opArr = column.ops(item);
    }
    return (
      <OperationWidget ops={opArr} data={item} />
    );
  }
  if (column.handlerNode) {
    const data = column.handlerNode(item[column.field], item);
    return data;
  }
  if (column.handlerImg) {
    return column.handlerImg(item, column.maxWidth);
  }
  if (column.handler || column.field) { // 展示型列
    const text = column.handler ? column.handler(item) : item[column.field];
    if (!isUndefined(text) && text !== null) {
      const lines = column.lines;
      return (
        <AutoShrinkTextWithTip text={text.toString()} lines={lines} className={lines && classes.linesCell}
          maxSize={column.maxWidth - 10} fontSize={14} showTips />
      );
    }
  }
  return null;
};

/**
 * Table控件，采用MS统一风格，表头固定，内容可滚动，支持分页显示和跳转操作。
 *
 * 属性说明：
 * - meta：表格元信息，为数组形式，依次表示表格中的各列信息。其中每列的元信息为一个JSON对象，其格式如下：
 *    - 展示型：{title: <列标题>, field: <JSON数据中的key>, handler: <显示处理回调（可选）>}
 *    - 交互型：{title: <列标题>, ops: [{text: <按钮1>, op: <按钮1回调>}, ... {text: <按钮n>, op: <按钮n回调>}]}
 * - data：表格数据，为数组形式。每个元素对应表格中的一行：对于展示型列，其内容为field字段中定义的key的对应值；对于交互型列，整个对象会作为按钮回调的参数。
 *
 * @author anguo
 *
 * updated by xujianwen on 2018/03/05.
 * 增加chkbox
 */
class Table extends React.Component {

  state = {
    checkedItemIds: [],
    selAll: false,
    sortField: undefined,
    sortType: DOWN
  }

  componentDidMount() {
    const { sortField, sortType } = this.props;
    this.setState({ sortField, sortType });
  }

  componentWillReceiveProps(newProps) {
    const { data } = newProps;
    if (data && data !== this.props.data) {
      this.setState({
        checkedItemIds: [],
        selAll: false
      });
    }
  }

  chkClick = (params) => {
    const { checkedItemIds } = this.state;
    const { data, updateSelList } = this.props;
    let tempCheckItems = checkedItemIds;
    return () => {
      const paramsIndex = tempCheckItems.indexOf(params);
      const listLength = data && data.length;
      if (paramsIndex === -1) {
        tempCheckItems.push(params);
      } else {
        tempCheckItems = [...tempCheckItems.slice(0, paramsIndex), ...tempCheckItems.slice(paramsIndex + 1)];
      }
      const selLength = tempCheckItems.length;
      this.setState({
        checkedItemIds: tempCheckItems,
        selAll: listLength === selLength
      });
      updateSelList(tempCheckItems);
    };
  }

  clickAll = (selArr, selAll) => {
    this.setState({
      checkedItemIds: selArr,
      selAll
    });
    this.props.updateSelList(selArr);
  }

  onFieldSort = (sortField, sortType) => {
    this.setState({ sortField, sortType });
  };

  render() {
    const { contentWidth, contentHeight, meta, data, total, pageCount, pageNum, onPageChange, emptyText,
      noOverflow, emptyStyle, tableHeight } = this.props;
    const { checkedItemIds, selAll, sortField, sortType } = this.state;
    const count = Math.ceil(total / pageCount);
    const divHeight = count > 1 ? 196 : (55 + 40 + 42 + 12); // 55:iconHeader,40:table上提示文字，42：表头，12距页面下边距
    const theadProps = {
      meta,
      data,
      checked: selAll,
      clickHandler: this.clickAll,
      sortField,
      sortType,
      onFieldSort: this.onFieldSort
    };
    const tbodyProps = {
      chkClick: this.chkClick,
      checkedItemIds,
      meta,
      data
    };
    return (
      <Fragment>
        {noOverflow ? (
          <Fragment>
            <table className={'table-hover ' + classes.detailtable}>
              <TableHead {...theadProps} />
            </table>
            {
              data && data.length ? <table className={'table-hover ' + classes.detailtable}>
                <TableBody {...tbodyProps} />
              </table> : <EmptyView text={emptyText} style={emptyStyle} />
            }
          </Fragment>
        ) : (
          <div style={{ height: contentHeight - 180 }}>
            <table className={'table-hover ' + classes.detailtable}>
              <TableHead {...theadProps} />
            </table>
            <div className={classes.tbodyDiv}
              style={{ height: tableHeight || (contentHeight - divHeight), width: contentWidth - 12 }}>
              <div style={{ width: contentWidth - 24 }}>
                {
                  data && data.length ? <table className={'table-hover ' + classes.detailtable}>
                    <TableBody {...tbodyProps} />
                  </table> : <EmptyView text={emptyText} height={contentHeight - 55 - 40 - 42 - 13}
                    style={emptyStyle} />
                }
              </div>
            </div>
          </div>
        )}
        {total > count && <div style={{ width: contentWidth - 24, marginTop: noOverflow ? '0px' : '27px' }}>
          <Pagination pageIndex={pageNum} pageCount={count} selfMargin sidePages={5}
            changePage={onPageChange} isShowJumpPage />
        </div>}
      </Fragment>
    );
  }
}

Table.propTypes = {
  meta: PropTypes.array.isRequired, // 表头
  data: PropTypes.array, // 内容
  total: PropTypes.number, // 数据总条数
  pageCount: PropTypes.number, // 每页显示的条数
  pageNum: PropTypes.number, // 当前页
  onPageChange: PropTypes.func, // 页面变化回调
  contentWidth: PropTypes.number.isRequired, // 宽度
  contentHeight: PropTypes.number.isRequired, // 长度
  tableHeight: PropTypes.number,
  emptyText: PropTypes.string, // 无数据文案
  noOverflow: PropTypes.bool,
  hasChkBox: PropTypes.bool,
  updateSelList: PropTypes.func,
  sortField: PropTypes.string, // 排序字段
  sortType: PropTypes.string, // 排序方式（升序：'up'，降序：'down'）
  emptyStyle: PropTypes.object
};

Table.defaultProps = {
  emptyText: '暂无数据'
};

export default Table;
