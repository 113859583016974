import PropTypes from 'prop-types';
import React from 'react';
import LaddaButton, { SLIDE_UP } from 'react-ladda';

export const LoadingButton = ({ children, loading, ...btnProps }) => (
  <LaddaButton loading={loading} data-style={SLIDE_UP} {...btnProps}>{children}</LaddaButton>
);

LoadingButton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool
};

export default LoadingButton;
