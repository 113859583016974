import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import uuid from 'uuid';
import ItemBody from './ItemBody';
import ItemTitle from './ItemTitle';
import classNames from 'classnames';
import classes from './ExpandableList.scss';

export class Item extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      maxHeight: props.expanded ? 'none' : 0,
      overflow: props.expanded ? 'visible' : 'hidden',
      duration: 300
    };
  }

  componentWillMount() {
    this.uuid = uuid.v4();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      if (this.props.expanded) {
        this.maybeExpand();
      } else {
        this.handleCollapse();
      }
    }
  }

  getBodyNode() {
    return ReactDOM.findDOMNode(this.refs.body);
  }

  startTransition() {
    this.setState({
      maxHeight: this.maxHeight,
      overflow: 'hidden'
    });
    clearTimeout(this.timeout);
  }

  maybeExpand() {
    const bodyNode = this.getBodyNode();
    if (bodyNode) {
      const images = bodyNode.querySelectorAll('img');
      if (images.length > 0) {
        this.preloadImages(bodyNode, images);
        return;
      }
    }

    this.handleExpand();
  }

  handleExpand() {
    this.startTransition();
    this.timeout = setTimeout(() => this.setState({
      maxHeight: 'none',
      overflow: 'visible'
    }), this.state.duration);
  }

  handleCollapse() {
    this.startTransition();
    this.timeout = setTimeout(() => {
      this.setState({
        maxHeight: 0,
        overflow: 'hidden'
      });
    }, 0);
  }

  get maxHeight() {
    const body = this.getBodyNode();
    return `${body ? body.scrollHeight : 0}px`;
  }

  // Wait for images to load before calculating maxHeight
  preloadImages(node, images = []) {
    let imagesLoaded = 0;
    const imgLoaded = () => {
      imagesLoaded++;

      if (imagesLoaded === images.length) {
        this.handleExpand();
      }
    };

    for (let i = 0; i < images.length; i++) {
      let img = new Image();
      img.src = images[i].src;
      img.onload = img.onerror = imgLoaded;
    }
  }

  getProps() {
    const props = {
      className: classNames(
        classes.list_item,
        this.props.className,
        { [classes.list_item_expanded]: this.props.expanded },
        this.props.expandedClassName && { [this.props.expandedClassName]: this.props.expanded },
        { [classes.list_item_active]: this.props.active }
      ),
      role: 'tabpanel',
      style: this.props.style
    };

    if (this.props.expanded) {
      props['aria-expanded'] = true;
    } else {
      props['aria-hidden'] = true;
    }

    return props;
  }

  getBody() {
    const children = this.props.children;
    if (children) {
      return (
        <ItemBody
          maxHeight={this.state.maxHeight}
          duration={this.state.duration}
          className={this.props.bodyClassName}
          overflow={this.state.overflow}
          ref='body'
          uuid={this.uuid}>
          {children}
        </ItemBody>
      );
    }
    return null;
  }

  getTitle() {
    const { titleClassName, icon, title, expanded, arrow, onClick, titleColor } = this.props;
    return (
      <ItemTitle
        className={titleClassName}
        icon={icon}
        title={title}
        expanded={expanded}
        arrow={arrow}
        onClick={onClick}
        titleColor={titleColor}
        uuid={this.uuid} />
    );
  }

  render() {
    return (
      <div {...this.getProps()} ref='item'>
        {this.getTitle()}
        {this.getBody()}
      </div>
    );
  }

}

Item.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  expanded: PropTypes.bool,
  arrow: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  expandedClassName: PropTypes.string,
  style: PropTypes.object,
  titleClassName: PropTypes.string,
  children: PropTypes.element
};

export default Item;
