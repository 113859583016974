/**
 * 应用中心
 */
export default {
  'app.type.schoolOfficial': '学校应用',
  'app.type.developer': '开发者应用',
  'app.type.whistle': '微哨应用',
  'app.developer.whistle': '微哨',
  'app.trialSchool.tip': '当前为试用状态，只能上架2个应用。想上架更多应用，请联系微哨销售人员购买。',
  'app.form.appRedirectUrl.label': '应用回调地址',
  'app.form.appRedirectUrl.help': '必须以http://或者https://开头，支持80和443端口，填写的url需要正确响应微哨发送的Token验证。'
};
