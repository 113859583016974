import makeReducer from 'store/makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';
import { getList, setList } from 'helpers/SchoolListHelper';

// ------------------------------------
// Constants
// ------------------------------------
const SCHOOL_LIST = 'whistlems/auth/SCHOOL_LIST';
const LOAD = SCHOOL_LIST + '_LOAD';
const SUCCESS = SCHOOL_LIST + '_SUCCESS';
const FAIL = SCHOOL_LIST + '_FAIL';

// ------------------------------------
// Actions
// ------------------------------------
function schoolList(list = initialState) {
  return {
    type: SCHOOL_LIST,
    payload: list
  };
}

const getSchoolListFromLocal = () => new Promise(
  (resolve, reject) => {
    const list = getList();
    if (list) {
      resolve(list);
    } else {
      reject();
    }
  }
);

export const getCachedSchoolList = () => {
  return (dispatch, getState) => {
    return getSchoolListFromLocal()
      .then(list => {
        dispatch(schoolList(list));
      })
      .catch(() => {});
  };
};

export const getLatestSchoolList = () => {
  return {
    types: [LOAD, SUCCESS, FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('config', 'schoolList')
    })
  };
};

export const getSchoolList = () => {
  return (dispatch, getState) => {
    return getSchoolListFromLocal()
      .then(list => {
        dispatch(schoolList(list));
        dispatch(getLatestSchoolList());
      })
      .catch(() => {
        dispatch(getLatestSchoolList());
      });
  };
};

export const actions = {
  getCachedSchoolList,
  getLatestSchoolList,
  getSchoolList
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SCHOOL_LIST]: (state, action) => {
    return action.payload;
  },
  [LOAD]: (state, action) => {
    return state;
  },
  [SUCCESS]: (state, action) => {
    const { data } = action.payload;
    const list = data.sort((a, b) => a.name.localeCompare(b.name));
    setList(list);
    return list;
  },
  [FAIL]: (state, action) => {
    // const err = action.payload;
    return initialState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = [];
const schoolListReducer = makeReducer(ACTION_HANDLERS, initialState);
export default schoolListReducer;
