/**
 * Created by pyt on 2016/6/22.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'components/Dialog';
import LoadingButton from 'components/LoadingButton';
import { getAuthInfo } from 'helpers/AuthHelper';
import clesses from './DataExport.scss';
import { exportUser } from '../modules/dataExport';
import { showSuccessToast } from 'helpers/toastUtils';

const mapStateToProps = (state) => ({
  exportUserLoading: state.dataExport.exportUserLoading,
  exportUserLoaded: state.dataExport.exportUserLoaded,
  exportUserError: state.dataExport.exportUserError,
  exportUserResult: state.dataExport.exportUserResult
});

const mapActionCreators = {
  exportUser
};


export class DataExport extends React.Component {

  componentWillReceiveProps(newProps) {
    // const { exportUserLoaded, exportUserError } = newProps;
    // if (exportUserLoaded && !this.props.exportUserLoaded) {
    //   showSuccessToast('导出成功，请查收邮件！');
    //   this.props.cancelExport();
    // }
    // if (exportUserError && exportUserError !== this.props.exportUserError) {
    //   showErrorToast(exportUserError);
    // }
  }

  onExportUser = () => {
    const { exportUser, cancelExport } = this.props;
    exportUser();
    showSuccessToast('因用户量过大，数据正在后台处理中；请5～10分钟后到邮箱查看，如果未收到请联系服务人员；');
    cancelExport();
  }

  render() {
    const { cancelExport } = this.props;
    const authInfo = getAuthInfo();
    const sendInfo = authInfo && authInfo.auth && authInfo.auth.send_email_info
      && JSON.parse(authInfo.auth.send_email_info) || undefined;
    const name = sendInfo && sendInfo.name || '';
    const phone = sendInfo && sendInfo.phone || '';
    const email = sendInfo && sendInfo.email || '';
    return (
      <Dialog
        modalProps={{ id: 'data-export-modal' }}
        title='数据导出'
        size='md'
        show
        onHide={cancelExport}
      >
        <div className={clesses.dataExport}>
          <div className={clesses.textContainer}>
            <span>学校接口人：</span>
            <span>{name && `${name}（手机${phone}）` || ''}</span>
          </div>
          <div className={clesses.textContainer}>
            <span>导出邮箱：</span>
            <span>{email}</span>
          </div>
          <div className={clesses.textContainer}>
            导出结果将通过短信通知老师，如需修改手机和邮箱，请联系工程师；
          </div>
          <div className={clesses.btnContainer}>
            <LoadingButton
              className={'btn btn-primary wsbtn-large'}
              // loading={exportUserLoading}
              onClick={this.onExportUser}
            >
              导出组织/人员数据
            </LoadingButton>
            <LoadingButton
              className={'btn btn-primary wsbtn-large'}
              disabled
            >
              导出岗位数据
            </LoadingButton>
            <LoadingButton
              className={'btn btn-primary wsbtn-large'}
              disabled
            >
              导出标签数据
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    );
  }
}

DataExport.propTypes = {
  cancelExport: PropTypes.func,
  exportUser: PropTypes.func
  // exportUserLoading: PropTypes.bool,
  // exportUserLoaded: PropTypes.bool,
  // exportUserError: PropTypes.string
};

export default connect(mapStateToProps, mapActionCreators)(DataExport);

