import PropTypes from 'prop-types';
import React from 'react';
import classes from './RouteLayout.scss';

const RouteLayout = ({ children }) => (
  <div className={classes.container}>{children}</div>
);

RouteLayout.propTypes = {
  children: PropTypes.element
};

export default RouteLayout;
