import makeReducer from '../makeReducer';
import { API_ROOT, formatParams } from 'helpers/ApiHelper';

// ------------------------------------
// Constants
// ------------------------------------

const PREFIX = 'whistlems/common/setapp/';

const SUCCESS = '_SUCCESS';
const FAIL = '_FAIL';

const APP = PREFIX + 'APP';
const APP_SUCCESS = APP + SUCCESS;
const APP_FAIL = APP + FAIL;

const SCHOOL_APP_SUCCESS = PREFIX + 'SCHOOL_APP' + SUCCESS;
const WHISTLE_APP_SUCCESS = PREFIX + 'WHISTLE_APP' + SUCCESS;
const DEV_APP_SUCCESS = PREFIX + 'DEV_APP' + SUCCESS;

// ------------------------------------
// Actions
// ------------------------------------

export const appList = () => {
  return {
    types: [APP, APP_SUCCESS, APP_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('role', 'roleAppList', {})
    })
  };
};

export const schoolApps = () => {
  return {
    types: [APP, SCHOOL_APP_SUCCESS, APP_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('appstore', 'getAppList', {
        status: 3,
        is_school_official: 1
      })
    })
  };
};

export const whistleApps = () => {
  return {
    types: [APP, WHISTLE_APP_SUCCESS, APP_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('appstore', 'getAppList', {
        status: 3,
        is_school_official: 2
      })
    })
  };
};

export const devApps = () => {
  return {
    types: [APP, DEV_APP_SUCCESS, APP_FAIL],
    promise: (client) => client.post(API_ROOT, {
      data: formatParams('appstore', 'getAppList', {
        status: 3,
        is_school_official: 0
      })
    })
  };
};


export const actions = {
  schoolApps,
  whistleApps,
  devApps,
  appList
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [APP]: (state, action) => {
    return {
      ...state,
      appLoading: true,
      appLoaded: false,
      appError: undefined
    };
  },
  [APP_SUCCESS]: (state, action) => {
    const data = action.payload.data;
    return {
      ...state,
      appLoading: false,
      appLoaded: true,
      appError: undefined,
      schoolAppResult: data && data.school || [],
      whistleAppResult: data && data.weishao || [],
      devAppResult: data && data.developer || []
    };
  },
  [SCHOOL_APP_SUCCESS]: (state, action) => {
    return {
      ...state,
      appLoading: false,
      appLoaded: true,
      appError: undefined,
      schoolAppResult: action.payload.data
    };
  },
  [WHISTLE_APP_SUCCESS]: (state, action) => {
    return {
      ...state,
      appLoading: false,
      appLoaded: true,
      appError: undefined,
      whistleAppResult: action.payload.data
    };
  },
  [DEV_APP_SUCCESS]: (state, action) => {
    return {
      ...state,
      appLoading: false,
      appLoaded: true,
      appError: undefined,
      devAppResult: action.payload.data
    };
  },
  [APP_FAIL]: (state, action) => {
    return {
      ...state,
      appLoading: false,
      appLoaded: false,
      appError: action.payload.errmsg
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

const setAppReducer = makeReducer(ACTION_HANDLERS, initialState);
export default setAppReducer;
