/**
 * 新建组织
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, propTypes, Field, formValueSelector } from 'redux-form';
import S from 'string';
import Select from 'react-select';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { relogin } from 'store/modules/reloginService';
import { MULTI_ORG } from 'helpers/strings';
import { showErrorToast } from 'helpers/toastUtils';
import DropdownWidget from 'components/OrgTreePicker/DropdownOrgTreePicker';
import TextInputWithErrorTips from 'components/TextInputWithErrorTips';
import Dialog from 'components/Dialog';
import { clearNewNodeParent, selectNewNodeParent, clearNodeToEdit } from '../modules/organization';
import { ORG_TYPE_MAJOR, ORG_TYPE_CLASS, ORG_TYPE_OMG, ORG_TYPE_NONE, ORG_TYPE_SPECIALTY } from '../strings/strings';

import classes from './NewOrEditTreeNodeView.scss';


let siblingNodes = [];
let nodeInvolved;
let orgTypeInvolved;
const validate = values => {
  const errors = {};
  const { parentNodeName, nameEn } = values;
  if (!parentNodeName) {
    errors.parentNodeName = '组织名称不能为空';
  } else {
    if (S(parentNodeName).isEmpty()) {
      errors.parentNodeName = '组织名称不能都是空白字符';
    } else {
      const trimedName = S(parentNodeName).trim().s;
      if (siblingNodes && siblingNodes.find(node => node && node !== nodeInvolved && (node.name === trimedName))) {
        errors.parentNodeName = '存在相同名字的同级部门，请修改';
      }
    }
  }
  if (nameEn && S(parentNodeName).isEmpty()) {
    errors.parentNodeName = '不能都是空白字符';
  }
  return errors;
};

const Input = ({ input, meta: {touched, error}, showError, readOnly, placeholder, length }) => (
  <TextInputWithErrorTips className={classes.inputContent}
    inputProps={{
      name: 'username',
      id: 'newTreeNodeName',
      maxLength: length,
      placeholder: placeholder || `最多${length}个字符`,
      style: {height: '42px', lineHeight: '42px'}
    }}
    reduxFormProps={input}
    errorBlock
    error={showError && touched ? error : ''}
    readOnly={readOnly}
  />
);

Input.defaultProps = {
  length: 24
};

Input.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  length: PropTypes.number
};

const NameInput = ({label, name, showError, readOnly, placeholder, length}) => (
  <div className={classes.formBlock}>
    <label htmlFor='newTreeNodeName' className={classes.inputLabel}>{label}</label>
    <Field
      name={name}
      component={Input}
      showError={showError}
      readOnly={readOnly}
      placeholder={placeholder}
      length={length}
    />
  </div>
);

NameInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  length: PropTypes.number
};

const OrgTypeSelect = ({ id, options, value, onChange, searchable = false }) => (
  <div className={classes.formBlock}>
    <label htmlFor={id} className={classNames(classes.inputLabel, classes.orgTypeLabel)}>
      组织属性</label>
    <div id={id} className={classes.inputSelect}>
      <Select style={{height: '42px'}} options={options} clearable={false} matchProp='label' simpleValue
        name='selectedOrgType' placeholder='请选择' value={value} onChange={onChange} searchable={searchable} />
    </div>
  </div>
);

OrgTypeSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  searchable: PropTypes.bool
};

class NewOrEditTreeNodeView extends Component {

  static propTypes = {
    ...propTypes,
    onHide: PropTypes.func,
    operationType: PropTypes.oneOf(['create', 'edit']),
    selectNewNodeParent: PropTypes.func,
    onCommitTreeNode: PropTypes.func,
    onCommitEditTreeNode: PropTypes.func,
    clearNewNodeParent: PropTypes.func,
    clearNodeToEdit: PropTypes.func,
    newNodeParent: PropTypes.object,
    newNodeParentPreset: PropTypes.object,
    nodeToEdit: PropTypes.object,
    nodeCreating: PropTypes.bool,
    nodeCreateError: PropTypes.object,
    nodeEditing: PropTypes.bool,
    nodeEditError: PropTypes.object,
    orgType: PropTypes.string
  }

  state = {
    updateUI: false,
    missingParent: false,
    selectedOrgType: '0',
    nodeNameInvalid: false,
    teachers: '', // 班主任
    counselors: '' // 辅导员
  }

  componentWillMount = () => {
    this.newParentOfEditNode = this.props.nodeToEdit ? this.props.nodeToEdit.parent : undefined;
    this.parentOfNewNode = this.props.newNodeParentPreset ? this.props.newNodeParentPreset : undefined;
    nodeInvolved = undefined;
    if (this.props.operationType === 'create') {
      if (this.parentOfNewNode) {
        siblingNodes = this.parentOfNewNode.childrenLoaded ? this.parentOfNewNode.children : [];
        this.setState({missingParent: false});
      } else {
        this.setState({missingParent: true});
      }
    } else {
      if (this.newParentOfEditNode) {
        siblingNodes = this.newParentOfEditNode.childrenLoaded ? this.newParentOfEditNode.children : [];
      }
      const validAttr = this.props.nodeToEdit.attribute;
      this.setState({
        missingParent: false,
        selectedOrgType: validAttr
      });
    }
    if (this.props.nodeToEdit) {
      nodeInvolved = this.props.nodeToEdit;
      orgTypeInvolved = nodeInvolved.attribute;
      const teacherList = [];
      nodeInvolved && nodeInvolved.teacherList.map((item) => {
        teacherList.push({value: item.username, label: item.name});
      });
      const counselorsList = [];
      nodeInvolved && nodeInvolved.counselorList.map((item) => {
        counselorsList.push({value: item.username, label: item.name});
      });
      this.props.initialize({
        parentNodeName: this.props.nodeToEdit.name,
        nameEn: this.props.nodeToEdit.nameEn || ''
      });
      this.setState({
        teachers: teacherList,
        counselors: counselorsList
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const {nodeCreating, nodeEditing} = this.props;
    if (nodeCreating && nextProps.nodeCreateError) {
      showErrorToast('新建失败:' + nextProps.nodeCreateError.errmsg);
    }
    if (nodeEditing && nextProps.nodeEditError) {
      showErrorToast('保存失败:' + nextProps.nodeEditError.errmsg);
    }
  }
  componentWillUpdate = (nextProps, nextState) => {
    const orgTypeInvolvedDup = nextState.selectedOrgType || '0';
    if (orgTypeInvolvedDup !== orgTypeInvolved) {
      orgTypeInvolved = orgTypeInvolvedDup;
      if (nodeInvolved && orgTypeInvolved !== nodeInvolved.attribute) {
        this.omitDupName = true;
      } else {
        this.omitDupName = false;
      }
    }
  }
  componentWillUnmount = () => {
    this.props.clearNewNodeParent();
    this.props.clearNodeToEdit();
    this.props.reset();
    siblingNodes = undefined;
    nodeInvolved = undefined;
    orgTypeInvolved = undefined;
  }

  onEditNodeParentSelected = (node) => {
    this.newParentOfEditNode = node;
    this.setState({
      updateUI: !this.state.updateUI,
      missingParent: false
    });
  }

  onNewNodeParentSelected = (node) => {
    this.parentOfNewNode = node;
    this.props.selectNewNodeParent(node);
    siblingNodes = this.parentOfNewNode.childrenLoaded ? this.parentOfNewNode.children : [];
    this.setState({missingParent: false});
  }

  onCreateSubmit = (data) => {
    const {selectedOrgType, teachers, counselors} = this.state;
    this.props.onCommitTreeNode(
      this.parentOfNewNode,
      S(data.parentNodeName).trim().s,
      data.nameEn ? S(data.nameEn).trim().s : '',
      selectedOrgType,
      teachers,
      counselors
    );
  }

  onEditSubmit = (data) => {
    const {nodeToEdit} = this.props;
    const {selectedOrgType, teachers, counselors} = this.state;
    this.props.onCommitEditTreeNode(
      nodeToEdit,
      S(data.parentNodeName).trim().s,
      data.nameEn ? S(data.nameEn).trim().s : '',
      selectedOrgType,
      teachers,
      counselors
    );
  }

  onOrgTypeChanged = (newValue) => {
    this.setState({
      selectedOrgType: newValue
    });
  }

  handleTeacherChange = (value) => {
    this.setState({
      teachers: value
    });

  }

  handleCounselorChange = (value) => {
    this.setState({
      counselors: value
    });
  }

  // 是否修改班主任
  teacherChange = () => {
    const {operationType, nodeToEdit} = this.props;
    const {teachers} = this.state;
    // 编辑状态
    if (operationType === 'edit') {
      if (teachers && nodeToEdit.teacherList.length !== teachers.length) {
        return false;
      }
      const changeItem = [];
      nodeToEdit.teacherList.map((item) => {
        let isChange = false;
        teachers && teachers.map((teacherItem) => {
          if (item.username !== teacherItem.value) {
            isChange = true;
          }
        });
        if (isChange) {
          changeItem.push(item);
        }
      });
      if (changeItem.length > 0) {
        return false;
      }
    }
    return true;
  }

  // 是否修改辅导员
  counselorChange = () => {
    const {operationType, nodeToEdit} = this.props;
    const {counselors} = this.state;
    // 编辑状态
    if (operationType === 'edit') {
      if (counselors && nodeToEdit.counselorList.length !== counselors.length) {
        return false;
      }
      const changeItem = [];
      nodeToEdit.counselorList.map((item) => {
        let isChange = false;
        counselors && counselors.map((counselorsItem) => {
          if (item.username !== counselorsItem.value) {
            isChange = true;
          }
        });
        if (isChange) {
          changeItem.push(item);
        }
      });
      if (changeItem.length > 0) {
        return false;
      }
    }
    return true;
  }

  showChangeAttributeOnlyHint = () => {
    const {parentNodeNameValue, nameEnValue} = this.props;
    if (nodeInvolved && nodeInvolved.name === parentNodeNameValue
      && nodeInvolved.nameEn === nameEnValue && nodeInvolved.attribute === orgTypeInvolved) {
      return '如果不修改名称，请调整组织属性';
    }
    return undefined;
  }

  parentOfNewNode = null;
  newParentOfEditNode = null;
  omitDupName = false;

  getFormView = () => {
    const {operationType, newNodeParent, nodeToEdit, newNodeParentPreset, nodeCreating, nodeEditing,
      multiOrgRootResult} = this.props;
    const isAdminOrg = multiOrgRootResult && multiOrgRootResult.orgType === '1';
    const orgTypeOptions = [
      { value: '0', label: ORG_TYPE_NONE },
      { value: '1', label: ORG_TYPE_MAJOR },
      { value: '2', label: ORG_TYPE_CLASS },
      { value: '3', label: ORG_TYPE_OMG },
      { value: '4', label: ORG_TYPE_SPECIALTY }
    ];
    if (operationType === 'create') {
      return (
        <form className={classes.newTreeNodePanel}>
          <DropdownWidget
            widgetType={operationType}
            labelName='上级组织'
            readOnlyNode={newNodeParentPreset}
            expandRoot
            relatedNode={newNodeParent}
            selectNodeCallback={this.onNewNodeParentSelected}
            isOrgNav
            fromFlag={MULTI_ORG}
          />
          <NameInput
            label='组织名称'
            name='parentNodeName'
            showError
            readOnly={nodeCreating}
            length={100}
          />
          <NameInput
            label='英文名'
            placeholder='请输入英文名'
            name='nameEn'
            showError
            readOnly={nodeCreating}
            length={100}
          />
          {
            isAdminOrg && <OrgTypeSelect
              id='orgTypeSelectForNew'
              options={orgTypeOptions}
              value={this.state.selectedOrgType}
              onChange={this.onOrgTypeChanged}
            />
          }
        </form>
      );
    } else if (operationType === 'edit') {
      return (
        <form className={classes.newTreeNodePanel}>
          {
            nodeToEdit && this.newParentOfEditNode && nodeToEdit.parentId !== '0' && <DropdownWidget
              expandRoot
              readOnlyNode={this.newParentOfEditNode}
              isOrgNav
              selectNodeCallback={this.onEditNodeParentSelected}
              labelName='上级组织'
            />
          }
          <NameInput
            label='组织名称'
            name='parentNodeName'
            showError={!this.omitDupName}
            readOnly={nodeEditing}
            length={100}
          />
          <NameInput
            label='英文名'
            placeholder='请输入英文名'
            name='nameEn'
            showError={!this.omitDupName}
            readOnly={nodeEditing}
            length={100}
          />
          {
            isAdminOrg && <OrgTypeSelect
              id='orgTypeSelectForEdit'
              options={orgTypeOptions}
              value={this.state.selectedOrgType}
              onChange={this.onOrgTypeChanged}
            />
          }
        </form>
      );
    } else {
      return null;
    }
  }

  render() {
    const { onHide, operationType, handleSubmit, nodeCreating, nodeEditing, invalid } = this.props;
    let title;
    let onSubmit;
    if (operationType === 'create') {
      title = '新建组织';
      onSubmit = this.onCreateSubmit;
    } else {
      title = '编辑组织';
      onSubmit = this.onEditSubmit;
    }
    return (
      <Dialog show onHide={onHide} size='md' title={title} closable={!nodeCreating && !nodeEditing}
        positiveButton={{
          loading: nodeCreating || nodeEditing,
          disabled: this.state.missingParent || (!this.omitDupName && (invalid || this.showChangeAttributeOnlyHint())
          && this.teacherChange() && this.counselorChange()),
          onClick: handleSubmit(onSubmit)
        }}
        negativeButton={{
          disabled: nodeCreating || nodeEditing,
          onClick: onHide
        }}
      >
        {this.getFormView()}
      </Dialog>
    );
  }
}

const reduxFormConfig = {
  form: 'newOrEditNodeForm',
  validate
};
const selector = formValueSelector(reduxFormConfig.form);

const mapActionCreators = {
  selectNewNodeParent,
  clearNewNodeParent,
  clearNodeToEdit,
  relogin
};

const mapStateToProps = (state) => ({
  parentNodeNameValue: selector(state, 'parentNodeName'),
  nameEnValue: selector(state, 'nameEn'),
  newNodeParent: state.organization.newNodeParent,
  newNodeParentPreset: state.organization.newNodeParentPreset,
  nodeToEdit: state.organization.nodeToEdit,
  nodeEditing: state.organization.nodeEditing,
  nodeEditError: state.organization.nodeEditError,
  nodeCreating: state.organization.nodeCreating,
  nodeCreateError: state.organization.nodeCreateError,
  multiOrgRootResult: state.organization.multiOrgRootResult
});

export default connect(mapStateToProps, mapActionCreators)(reduxForm(reduxFormConfig)(NewOrEditTreeNodeView));
