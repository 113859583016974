import React from 'react';
import PropTypes from 'prop-types';
import { report } from 'helpers/errorReport';

export class ErrorBoundary extends React.Component {
  state = {
    error: null
  }

  static getDerivedStateFromError(error) {
    return {
      error
    };
  }

  componentDidCatch(error, info) {
    // Error logging and reporting
    report(error, info);
  }

  render() {
    const { error } = this.state;
    return error ? <div><h1>页面出异常啦！</h1><span>{error.toString()}</span></div> : this.props.children;
  }
};

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default ErrorBoundary;
