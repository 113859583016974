import ModuleLayout from 'layouts/ModuleLayout';
import { checkAuth } from '../auth';
import { injectReducer } from 'store/reducers';
import { getErrorCallback, getRouteReplace } from '../routeUtils';

export const route = (store) => ({
  name: '通知管理',
  path: 'notice',
  component: ModuleLayout,
  onEnter: checkAuth,
  indexRoute: { onEnter: (nextState, replace) =>
    getRouteReplace(replace, 'notice', 'noticeList', 'permission') }, // IndexRedirect
  childRoutes: [
    {
      name: '通知列表',
      path: 'noticeList',
      indexRoute: {
        name: '通知列表',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'notice', reducer });
            const Component = require('./containers/Notice').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Notice');
        }
      },
      childRoutes: [
        {
          name: '通知详情',
          path: 'detail/:id',
          staticName: true,
          indexRoute: {
            name: '通知详情',
            getComponent(nextState, callback) {
              require.ensure([], (require) => {
                const reducer = require('./modules').default;
                injectReducer(store, { key: 'notice', reducer });
                const Component = require('./containers/NoticeDetail').default;
                callback(null, Component);
              }, getErrorCallback(callback), 'Notice');
            }
          },
          childRoutes: [
            {
              name: '通知发送详情',
              path: 'sendDetail',
              getComponent(nextState, callback) {
                require.ensure([], (require) => {
                  const reducer = require('./modules').default;
                  injectReducer(store, { key: 'notice', reducer });
                  const Component = require('./containers/MsgSendDetail').default;
                  callback(null, Component);
                }, getErrorCallback(callback), 'Notice');
              }
            }
          ]
        }
      ]
    },
    {
      name: '发通知管理',
      path: 'permission',
      indexRoute: { onEnter: (nextState, replace) => replace('notice/permission/list') },
      childRoutes: [
        {
          name: '权限列表',
          path: 'list(/:tab/:page)',
          staticName: true,
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'notice', reducer });
              const Component = require('./containers/AuthList').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Notice');
          }
        },
        {
          name: '新建发通知权限',
          path: 'add',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'notice', reducer });
              const Component = require('./containers/AuthAdd').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Notice');
          }
        },
        {
          name: '编辑发通知权限',
          path: 'edit',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'notice', reducer });
              const Component = require('./containers/AuthAdd').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Notice');
          }
        },
        {
          name: '发通知权限详情',
          path: 'detail',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'notice', reducer });
              const Component = require('./containers/AuthAdd').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Notice');
          }
        },
        {
          name: '高级',
          staticName: true,
          path: 'bind(/:tab/:page)',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'notice', reducer });
              const Component = require('./containers/AgentList').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Notice');
          }
        }
      ]
    },
    {
      name: '短信列表',
      path: 'smsList',
      indexRoute: {
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'notice', reducer });
            const Component = require('./containers/SmsList').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Notice');
        }
      },
      childRoutes: [
        {
          name: '查看',
          path: 'detail',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const reducer = require('./modules').default;
              injectReducer(store, { key: 'notice', reducer });
              const Component = require('./containers/SmsDetail').default;
              callback(null, Component);
            }, getErrorCallback(callback), 'Notice');
          }
        }
      ]
    },
    {
      name: '短信平台配置',
      path: 'smsplatform',
      indexRoute: {
        name: '短信平台配置',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'notice', reducer });
            const Component = require('./containers/SmsConfig').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Notice');
        }
      }
    },
    {
      name: '通知发邮件',
      path: 'email',
      indexRoute: {
        name: '通知发邮件',
        getComponent(nextState, callback) {
          require.ensure([], (require) => {
            const reducer = require('./modules').default;
            injectReducer(store, { key: 'notice', reducer });
            const Component = require('./containers/NoticeEmail').default;
            callback(null, Component);
          }, getErrorCallback(callback), 'Notice');
        }
      }
    }
  ]
});

export default route;

