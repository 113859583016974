import PropTypes from 'prop-types';
/**
 * Created by liming on 16/6/20.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNewNodeParent, setNodeToEdit, setNodeToDelete, NODE_GRADUATION,
  changeGraduationStatus, setBatchTopNode } from '../modules/organization';
import onClickOutside from 'react-onclickoutside';
import { getAuthInfo } from 'helpers/AuthHelper';

const mapActionCreators = {
  setNewNodeParent: (node) => setNewNodeParent(node),
  setNodeToEdit: (node) => setNodeToEdit(node),
  setNodeToDelete: (node) => setNodeToDelete(node),
  changeGraduationStatus: (id, status, node) => changeGraduationStatus(id, status, node),
  setBatchTopNode
};

const mapStateToProps = (state) => ({
});

class TreeNodePopupMenu extends Component {
  static propTypes = {
    bindedNode: PropTypes.object,
    setNewNodeParent: PropTypes.func,
    setNodeToEdit: PropTypes.func,
    setNodeToDelete: PropTypes.func,
    changeGraduationStatus: PropTypes.func,
    handleMenuSelect: PropTypes.func,
    handleMenuClose: PropTypes.func,
    setBatchTopNode: PropTypes.func
  }

  onCreateChildNodeClick = () => {
    this.props.setNewNodeParent(this.props.bindedNode);
    this.props.handleMenuSelect();
  }

  onCreateSiblingNodeClick = () => {
    this.props.setNewNodeParent(this.props.bindedNode.parent);
    this.props.handleMenuSelect();
  }

  onEditNodeClick = () => {
    this.props.setNodeToEdit(this.props.bindedNode);
    this.props.handleMenuSelect();
  }

  onDeleteNodeClick = () => {
    this.props.setNodeToDelete(this.props.bindedNode);
    this.props.handleMenuSelect();
  }

  // 恢复毕业节点
  onRestoreClick = () => {
    this.props.changeGraduationStatus(this.props.bindedNode.id, 0, this.props.bindedNode);
    this.props.handleMenuSelect();
  }

  onMarkNodeClick = () => {
    this.props.changeGraduationStatus(this.props.bindedNode.id, 9, this.props.bindedNode);
    this.props.handleMenuSelect();
  }

  handleClickOutside = () => {
    this.props.handleMenuClose();
  }

  onBatchTopping = () => {
    const { bindedNode, setBatchTopNode } = this.props;
    setBatchTopNode({ node: bindedNode });
  }

  render() {
    const { bindedNode } = this.props;
    const notMulti = bindedNode.parentId !== '0'; // 根节点是0，行政组织架构，党团组织结构等
    const notAdminOrg = bindedNode.orgType !== '1'; // 行政组织架构是1，其他组织架构是2
    const auth = getAuthInfo();
    const isGroundUser = auth && auth.isGroundUser || false;

    if (bindedNode.isLeaf && bindedNode.status === NODE_GRADUATION) {
      if (isGroundUser && !notAdminOrg) {
        return (
          <ul className='list-group'>
            {notMulti && !notAdminOrg && <li className='list-group-item list-group-item-danger'
              onClick={this.onRestoreClick}>恢复未毕业</li>}
          </ul>
        );
      }
      return (
        <ul className='list-group'>
          {notMulti && <li className='list-group-item'
            onClick={this.onEditNodeClick}>编辑</li>}
          {notMulti && <li className='list-group-item list-group-item-danger'
            onClick={this.onDeleteNodeClick}>删除</li>}
          {notMulti && !notAdminOrg && <li className='list-group-item list-group-item-danger'
            onClick={this.onRestoreClick}>恢复未毕业</li>}
        </ul>
      );
    }
    if (isGroundUser && !notAdminOrg) {
      return (
        <ul className='list-group'>
          {notMulti && !notAdminOrg && bindedNode.status === NODE_GRADUATION && <li
            className='list-group-item list-group-item-danger'
            onClick={this.onRestoreClick}>恢复未毕业</li>}
          {notMulti && !notAdminOrg && bindedNode.status !== NODE_GRADUATION && <li
            className='list-group-item list-group-item-danger'
            onClick={this.onMarkNodeClick}>标记已毕业</li>}
          {bindedNode.status !== NODE_GRADUATION && <li className='list-group-item list-group-item-danger'
            onClick={this.onBatchTopping}>人员置顶</li>}
        </ul>
      );
    }
    return (
      <ul className='list-group'>
        <li className='list-group-item' onClick={this.onCreateChildNodeClick}>新建下级</li>
        {notMulti && <li className='list-group-item' onClick={this.onCreateSiblingNodeClick}>新建平级</li>}
        {(notMulti || notAdminOrg) && <li className='list-group-item'
          onClick={this.onEditNodeClick}>编辑</li>}
        {(notMulti || notAdminOrg)
        && <li className='list-group-item list-group-item-danger'
          onClick={this.onDeleteNodeClick}>删除</li>}
        {notMulti && !notAdminOrg && bindedNode.status === NODE_GRADUATION && <li
          className='list-group-item list-group-item-danger'
          onClick={this.onRestoreClick}>恢复未毕业</li>}
        {notMulti && !notAdminOrg && bindedNode.status !== NODE_GRADUATION && <li
          className='list-group-item list-group-item-danger'
          onClick={this.onMarkNodeClick}>标记已毕业</li>}
        {bindedNode.status !== NODE_GRADUATION && <li className='list-group-item list-group-item-danger'
          onClick={this.onBatchTopping}>人员置顶</li>}
      </ul>
    );
  }
}
export default connect(mapStateToProps, mapActionCreators)(onClickOutside(TreeNodePopupMenu));
