import PropTypes from 'prop-types';
/**
 * Created by anguo on 2016/9/14.
 */

import React from 'react';

export class OperationButton extends React.PureComponent {
  onClick = () => {
    const {op, data} = this.props;
    op.op(data);
  }

  render() {
    return <button type='button' className='btn btn-link' onClick={this.onClick}>{this.props.op.text}</button>;
  }
}
OperationButton.propTypes = {
  op: PropTypes.object.isRequired,
  data: PropTypes.any
};

export const OperationWidget = ({ops, data}) => (
  <div style={{ textAlign: 'center' }}>
    {ops.map(op => op.handler ? op.handler(data) : <OperationButton key={op.text} op={op} data={data} />)}
  </div>
);

OperationWidget.propTypes = {
  ops: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object
};

export default OperationWidget;
