import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalTitle, ModalFooter } from 'react-bootstrap';
import LoadingButton from 'components/LoadingButton';
import classNames from 'classnames';
import classes from './Dialog.scss';

const getSizeClassName = (size) => {
  switch (size) {
    case 'sm':
    case 'md':
    case 'lg':
    case 'xlg':
      return classes[size];
    default:
      return classes.sm;
  }
};

/**
 * Dialog组件，用于显示提示对话框形式的界面。
 * 属性说明：
 * - show - 是否显示对话框
 * - onHide - 点击对话框关闭按钮时的回调
 * - className - 自定义的对话框样式
 * - size - 尺寸，可选的取值为"sm"/"md"/"lg"/"xlg"
 * - closable - 是否显示关闭按钮，默认显示
 * - title - 对话框标题
 * - content - 对话框内容，可以为字符串、一个或多个React组件。内容可以以属性的方式传入，也可以作为子组件，如：
 *   <Dialog {...props}><div>...</div></Dialog>
 * - positiveButton - 动作（正向）按钮，为JSON对象，其结构如下：
 *   {
 *     text: [按钮文字，默认为‘确定’],
 *     disabled: [true/false，是否为禁用状态],
 *     loading: [true/false，是否为加载中状态],
 *     onClick: [按钮点击回调]
 *   }
 * - negativeButton - 取消（负向）按钮，为JSON对象，其结构同positiveButton。默认按钮文字为‘取消’。
 * - modalProps - 对话框属性，详见react-bootstrap的Modal组件，可供需要时传入特殊属性。
 */
export const Dialog = (props) => {
  const {
    show, onHide, className, size, closable, title, content, children, positiveButton, negativeButton, modalProps
  } = props;
  let posBtn;
  if (positiveButton) {
    const { onClick, text, disabled, loading, ...buttonProps } = positiveButton;
    const btnClass = negativeButton ? classes['button-left'] : classes['button'];
    posBtn = (
      <LoadingButton {...buttonProps} onClick={onClick} disabled={!!disabled || !!loading} loading={loading}
        className={classNames('btn btn-primary wsbtn-large', btnClass)}>
        {text || '确定'}
      </LoadingButton>
    );
  }
  let negBtn;
  if (negativeButton) {
    const { onClick, text, disabled, loading, ...buttonProps } = negativeButton;
    const btnClass = positiveButton ? classes['button-right'] : classes['button'];
    negBtn = (
      <LoadingButton {...buttonProps} onClick={onClick} disabled={!!disabled || !!loading} loading={loading}
        className={classNames('btn btn-primary wsbtn-large', btnClass)}>
        {text || '取消'}
      </LoadingButton>
    );
  }
  const showModal = show || (modalProps && modalProps.show);
  const onHideCb = onHide || (modalProps && modalProps.onHide)
    || (negativeButton && negativeButton.onClick)
    || (positiveButton && positiveButton.onClick);
  return (
    <Modal {...modalProps} show={showModal} onHide={onHideCb} backdrop='static'
      dialogClassName={classNames('custom-modal', getSizeClassName(size), className)}>
      <ModalHeader className={classes.title} closeButton={!(closable === false)}>
        <ModalTitle style={{ textAlign: 'center', fontSize: '16px', color: '#fff' }}>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody className={classes.body}>
        {content ? <pre className={classes.content}>{content}</pre> : children}
      </ModalBody>
      {
        posBtn || negBtn ? (
          <ModalFooter className={classes.footer}>
            {posBtn}
            {negBtn}
          </ModalFooter>
        ) : null
      }
    </Modal>
  );
};

Dialog.propTypes = {
  children: PropTypes.any,
  modalProps: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xlg']),
  closable: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  positiveButton: PropTypes.object,
  negativeButton: PropTypes.object
};

export default Dialog;
