/**
 * Created by liming on 16/6/16.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {loadOrgNode, NODE_GRADUATION, setOpenedList} from '../modules/organization';
import classes from './OperatableTreeNode.scss';
import { connect } from 'react-redux';
import OperatableTreeNode from './OperatableTreeNode';
import TreeNodePopupMenu from './TreeNodePopupMenu';
import AutoShrinkTextWithTip from 'components/AutoShrinkTextWithTip';
import Icon from 'components/Icon';
import SubNodes from 'components/OrgTreePicker/SubNodes';
import { getOrgPathArray } from 'helpers/orgUtils';

const mapActionCreators = {
  loadOrgNode: (node) => loadOrgNode(node),
  setOpenedList
};

const mapStateToProps = (state) => ({});

const Node = ({height, icon, onClick}) => (
  <div className={classes.nodeIconContainer} style={{ lineHeight: height + 'px' }} onClick={onClick}>
    <Icon type={icon} style={{ lineHeight: height + 'px' }} />
  </div>
);
Node.propTypes = {
  height: PropTypes.number,
  icon: PropTypes.string,
  onClick: PropTypes.func
};

const ContextMenu = ({node, onSelect, onClose}) => (
  <div className={classes.popupMenuContainer} style={{ top: 20 }}>
    <TreeNodePopupMenu key={node.uuid} bindedNode={node} handleMenuClose={onClose}
      outsideClickIgnoreClass='treeDiv' handleMenuSelect={onSelect} />
  </div>
);
ContextMenu.propTypes = {
  node: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onClose: PropTypes.func
};

class OperatableTreeNodeClass extends Component {
  static propTypes = {
    node: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    levelOffset: PropTypes.number,
    activeNode: PropTypes.object,
    openedNodeList: PropTypes.array,
    loadOrgNode: PropTypes.func,
    onClickCallback: PropTypes.func,
    setOpenedList: PropTypes.func
  }

  state = {
    updateUI: false,
    showPopupMenu: false,
    popupTop: -1
  }

  componentWillMount = () => {
    this.props.setOpenedList(this.props.openedNodeList);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.node !== nextProps.activeNode && this.props.node === this.props.activeNode) {
      if (this.state.showPopupMenu) { // 焦点变化,收起弹出菜单
        this.setState({showPopupMenu: false});
      }
    }
  }

  onNodeClick = () => {
    if (this.props.onClickCallback) {
      this.props.onClickCallback(this.props.node);
    }
  }

  onSettingClick = () => {
    const btn = this.refs.container;
    const itemTop = btn.getBoundingClientRect().top + document.body.scrollTop;
    let popupTop = this.props.height;
    // 该条item距离窗口的高度大于571像素时
    if (itemTop > 571) {
      // 179是22+popup窗口的高度
      popupTop = -124;
    }
    this.setState({showPopupMenu: true, popupTop: popupTop});
  }

  onMenuClose = () => {
    this.setState({showPopupMenu: false});
  }

  makeChildrenNodes = () => {
    const {node, width, height, levelOffset, openedNodeList, activeNode} = this.props;
    return node.children.map((child) => {
      return (
        <OperatableTreeNode node={child} key={child.uuid} openedNodeList={openedNodeList}
          activeNode={activeNode}
          width={width} height={height} levelOffset={levelOffset}
          onClickCallback={this.props.onClickCallback} />);
    });
  }

  isNodeOpened = () => {
    const {openedNodeList, node} = this.props;
    return openedNodeList.indexOf(node.id) >= 0;
  }

  toggleNode = (event) => {
    event.stopPropagation();
    const {openedNodeList, node} = this.props;
    if (this.isNodeOpened()) {
      openedNodeList.splice(openedNodeList.indexOf(node.id), 1);
    } else {
      openedNodeList.push(node.id);
      if (!node.isLeaf && !node.childrenLoaded) {
        this.props.loadOrgNode(node);
      }
    }
    this.setState({updateUI: !this.state.updateUI});
  }


  render() {
    const {node, width, height, activeNode, levelOffset} = this.props;
    const pathArr = getOrgPathArray(node.path);
    const level = pathArr ? pathArr.length - 1 : 0;
    const myOffset = level * levelOffset;
    const activeClass = node === activeNode ? classes.activeNode : '';
    const textDivLength = width - 17 - myOffset - 52;
    const nodeColor = node.status === NODE_GRADUATION ? '#BFBFBF' : '';
    const currentNodeName = node.status === NODE_GRADUATION ? '已毕业-' + node.name : node.name;
    return (
      <div className={classes.nodeContainer} ref='container'>
        <div className={classes.nodeContent + ' ' + activeClass} key={node.id}
          style={{height: height, paddingLeft: myOffset, color: nodeColor}}
          onClick={this.onNodeClick}>
          {!node.isLeaf && <Node height={height} onClick={this.toggleNode}
            icon={this.isNodeOpened() ? 'triangledown' : 'next-page'} />}
          {node.isLeaf && <div className={classes.nodeIconContainer} style={{lineHeight: height + 'px'}} />}
          {/* 17是nodeIcon的宽度加上左右两边的border */}
          <div className={classes.bodyText}
            style={{width: textDivLength, lineHeight: height + 'px'}}>
            <AutoShrinkTextWithTip text={currentNodeName} maxSize={textDivLength} fontSize={14} />
          </div>
          <div className={classes.operationContainer}>
            <button type='button' className={'btn btn-link ' + classes.settingBtn} onClick={this.onSettingClick}>
              设置
            </button>
          </div>
          {/*
           * treeScroll是外边负责滚动的div,outsideClickIgnoreClass='treeScroll'是为了防止当节点在div最下面时,点击"设置"出弹出菜单,
           * 但是弹出菜单在下方可见范围外,用户需要用鼠标拉动滚动条,结果弹出菜单又收回,导致菜单无法被点击到.
           */}
          {
            this.state.showPopupMenu && <ContextMenu node={this.props.node}
              onSelect={this.onMenuClose} onClose={this.onMenuClose} />
          }
        </div>
        {this.isNodeOpened() && <SubNodes node={node} makeChildren={this.makeChildrenNodes} />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionCreators)(OperatableTreeNodeClass);
