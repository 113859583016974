import common from './zh-cn/common';
import menu from './zh-cn/menu';
import breadcrumbs from './zh-cn/breadcrumbs';
import login from './zh-cn/login';
import app from './zh-cn/app';
import account from './zh-cn/account';
import message from './zh-cn/message';
import authmanage from './zh-cn/authmanage';
import customize from './zh-cn/customize';
import thirdparty from './zh-cn/thirdparty';
import organization from './zh-cn/organization';

import schoolConfig from './schoolConfig';

let currentSchoolConfig = schoolConfig[__DOMAIN__] || {};
currentSchoolConfig = currentSchoolConfig['zh-cn'] || {};

export default {
  ...common,
  ...menu,
  ...breadcrumbs,
  ...login,
  ...app,
  ...account,
  ...message,
  ...authmanage,
  ...customize,
  ...thirdparty,
  ...organization,

  // 定制化文案
  ...currentSchoolConfig
};
