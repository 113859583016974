/**
 * 菜单
 */

export default {
  'menu.header': '微哨管理平台',
  'menu.statistics': '数据统计',
  'menu.statistics.overview': '数据概况',
  'menu.statistics.activeUser': '活跃用户分析',
  'menu.statistics.userRetention': '用户留存分析',
  'menu.statistics.appVersion': '平台版本分析',
  'menu.statistics.appUse': '应用使用分析',
  'menu.statistics.terminal': '用户终端分析',
  'menu.user': '用户中心',
  'menu.user.organization': '组织架构',
  'menu.user.adhocOrg': '非常设机构',
  'menu.user.position': '岗位（审批节点）',
  'menu.user.tags': '标签',
  'menu.user.job': '职务',
  'menu.chatgroup': '通讯录管理',
  'menu.chatgroup.sortBookSet': '通讯录排序',
  'menu.chatgroup.addressBookSet': '通讯录可见设置',
  'menu.chatgroup.group': '群聊管理',
  'menu.appstore': '应用中心',
  'menu.appstore.board': '学校应用',
  'menu.appstore.market': '应用商店',
  'menu.appstore.comment': '评论管理',
  'menu.appstore.netConfig': '内外网配置',
  'menu.appstore.cardManage': '卡片管理',
  'menu.appstore.businessSystem': '业务系统',
  'menu.notice': '消息管理',
  'menu.notice.noticeList': '通知列表',
  'menu.notice.permission': '发通知管理',
  'menu.notice.smsList': '短信列表',
  'menu.notice.smsplatform': '短信平台设置',
  'menu.notice.email': '通知发邮件',
  'menu.customize': '移动端管理',
  'menu.customize.splash': '启动页设置',
  'menu.customize.disclaimer': '启动声明协议',
  'menu.customize.accounttips': '账号密码高级设置',
  'menu.customize.homeTitleBar': '首页标题栏',
  'menu.customize.banner': '轮播图设置',
  'menu.customize.schoolBackground': '校园卡背景',
  'menu.customize.whiteList': '扫一扫白名单',
  'menu.thirdparty': '第三方门户',
  'menu.thirdparty.qywx': '企业微信',
  'menu.thirdparty.qq': 'QQ智慧校园',
  'menu.thirdparty.fwh': '微信服务号',
  'menu.thirdparty.dingtalk': '钉钉',
  'menu.thirdparty.yiban': '易班',
  'menu.credit': '积分商店',
  'menu.system': '系统管理',
  'menu.system.appName': 'APP包名配置',
  'menu.system.messagePush': '消息推送配置',
  'menu.system.version': '版本管理',
  'menu.permission': '权限管理',
  'menu.permission.role': '角色管理',
  'menu.permission.manager': '管理员账号',
  'menu.permission.assistant': '助理账号',
  'menu.smartqa': '智能问答',
  'menu.smartqa.library': '知识库',
  'menu.smartqa.learning': '知识学习',
  'menu.smartqa.statistics': '数据看板',
  'menu.smartqa.setting': '机器人设置',
  'menu.portal': 'PC门户管理',
  'menu.portal.backstage': '统一后台管理',
  'menu.portal.basicset': '基本设置',
  'menu.portal.bannerset': '轮播图设置',
  'menu.portal.layoutset': '首页布局设置',
  'menu.portal.menuset': '首页菜单设置',
  'menu.portal.helpset': '帮助设置',
  'menu.portal.memoset': '备忘设置',
  'menu.portal.pcwork': '工作门户',
  'menu.general': '通用管理',
  'menu.general.tips': '小贴士',
  'menu.auditlog': '审计日志',

  // 管理员应用中心，命名规则稍有不同
  'menu.subadmin.appstore': '应用中心',
  'menu.subadmin.permission': '权限管理',
  'menu.subadmin.permission.assistant': '助理账号'
};

