import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import classes from './TextInputWithErrorTips.scss';

/**
 * 带错误提示的input
 * @param reduxFormProps reduxForm相关数据
 * @param inputProps input相关的设置参数
 * @param error 错误提示
 */
export const TextInputWithErrorTips = (props) => {
  const { className, style, error, errorBlock, inputProps, reduxFormProps } = props;
  const inputClsName = inputProps.className;
  return (
    <div className={classNames(classes.inputContainer, className)} style={style}>
      <input type='text' {...inputProps} {...reduxFormProps}
        className={classNames('form-control', classes.textInput, inputClsName, (error ? classes.redBorder : ''))} />
      {
        error && <div className={classNames(classes.error, (errorBlock ? classes.block : ''))}>{error}</div>
      }
    </div>
  );
};

TextInputWithErrorTips.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  reduxFormProps: PropTypes.object,
  inputProps: PropTypes.object.isRequired,
  error: PropTypes.string,
  errorBlock: PropTypes.bool
};

export default TextInputWithErrorTips;
