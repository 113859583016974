/**
 * 学校配置（用于定制化学校，地段部署）
 */
import neuSchool from './assets/neu/school.png';
import neuQrcode from './assets/neu/qrcode.png';
import neuMenuHeader from './assets/neu/menuHeader.png';


/**
 * 字段说明
 *
 * login.school.icon: 登录页默认显示的图标
 * login.school: 登录页默认显示的文本
 * login.qrcode.default.icon: 二维码记在失败的替换图标
 * menu.header.icon: 登录成功之后 menu header 展示的图标
 * menu.header.text: 登录成功之后 menu header 展示的文本
 */
export default {
  'neu': {

    // 公共(图标)
    'login.school.icon': neuSchool,
    'login.qrcode.default.icon': neuQrcode,
    'menu.header.icon': neuMenuHeader,

    // 国际化
    'zh-cn': {
      // 'login.school': '北京理工默认文字',
      // 'menu.header': '测试配置名称'
    },
    'en-us': {}
  }
};
