import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import relogin from './modules/reloginService';
import richEditor from './modules/richEditor';
import flexSize from './modules/flexSize';
import userSearch from './modules/userSearch';
import orgSearch from './modules/orgSearch';
import checkboxSelector from './modules/checkboxSelector';
import platformSelector from './modules/platformSelector';
import setApp from './modules/setApp';
import schoolList from 'routes/Login/modules/schoolList';
import admin from 'routes/Account/modules/admin';
import organization from 'routes/Organization/modules/organization';
import user from 'routes/Organization/modules/user';
import adhocOrg from 'routes/Organization/modules/adhocOrg';
import position from 'routes/Organization/modules/position';
import label from 'routes/Organization/modules/label';
import multiQuery from 'routes/Organization/modules/multiQuery';
import manualUpdateOrg from 'routes/Organization/modules/manualUpdateOrg';
import job from 'routes/Organization/modules/job';
import dataExport from 'routes/Organization/modules/dataExport';
import login from 'routes/Login/modules/login';
import oauth from 'routes/Oauth/modules/oauth';


export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    router: routerReducer,
    form: formReducer,
    relogin,
    richEditor,
    flexSize,
    userSearch,
    orgSearch,
    checkboxSelector,
    platformSelector,
    setApp,
    // Add sync reducers here
    schoolList,
    admin,
    organization,
    user,
    adhocOrg,
    position,
    label,
    multiQuery,
    manualUpdateOrg,
    job,
    dataExport,
    login,
    oauth,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
